import { createRequestTypes, action } from "../../../utils/redux";

export const UNIT_SIGN = createRequestTypes("UNIT_SIGN");

export const unitSign = {
  request: (data) => action(UNIT_SIGN.REQUEST,data),
  success: (data) => action(UNIT_SIGN.SUCCESS, data),
  failure: (error) => action(UNIT_SIGN.FAILURE, error),
};


export const SHOW_SIGN = "SHOW_SIGN";

export const showSign = (data) => action(SHOW_SIGN, data);


export const SIGN_SUCCESS = "SIGN_SUCCESS";

export const signSuccess = (data) => action(SIGN_SUCCESS, data);
