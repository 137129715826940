import React, { useEffect } from "react";
import styles from "./style.module.scss";
import logo from "../../../assets/images/logo.png";
import cancel from "../../../assets/images/cancel.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { studentProfileData } from "../../../store/actions/employer/employerStudentProfile.action";
import propTypes from "prop-types";
import { employer_path } from "../../../Constants/paths/employer";
import { studentOntrackData } from "../../../store/actions/employer/StudentOnTrackStatus.action";
import { trainer_path } from "../../../Constants/paths/trainer";
import { isSupervisor } from "utils/helper/Student";
import { supervisor_path } from "Constants/paths/supervisor";

const EmployerStudentHeader = (props) => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const history = useHistory();
  const dispatch = useDispatch();
  //To request the employer data on the render time
  useEffect(() => {
    if (props?.headerFor === "Trainer") {
    } else {
      //these action will only call when the user is not Trainer
      dispatch(studentProfileData.request(props.id));
      dispatch(studentOntrackData.request(props.id));
    }
  }, []);

  //To fetch the employer data from the reducer
  const StudentProfileDetails = useSelector(
    (state) => state.employerReducers?.employerStudentProfile
  );
  //To fetch the status of student data from the reducer
  const studentOntrackStatus = useSelector(
    (state) => state.employerReducers?.StudentOnTrackStatus
  );
  const { studentProfile } = StudentProfileDetails;
  const { onTrackStatus } = studentOntrackStatus;

  //To go the Employer Apprentice page
  const handleback = () => {
    if (props.headerFor === "Trainer") {
     isSupervisor() ? history.push(supervisor_path.SUPERVISOR_APPRENTICE_PATH) :history.push(trainer_path.TRAINER_APPRENTICE_PATH);
    }
     else {
      history.push(employer_path.EMPOYLER_APPRENTICES);
    }
  };

  const studentNameOnlyForTrainer = localStorage.getItem("worklogsOf");

  return props.headerFor === "Trainer" ? (
    <>
      <div className={`${styles.trainerMain}`}>
        <div className={`${styles.employerStudentHeader}`}>
          <div className={`${styles.cancelIcon} `} onClick={handleback}>
            <img
              src={cancel}
              className={`${styles.cancelIcon} `}
              alt="cancel icon"
            />{" "}
          </div>
          <div className={`${styles.studentName}`}>
            {studentNameOnlyForTrainer}
          </div>
          <div className={`${styles.logoImg} `}>
            <img src={logo} alt="CTI logo" />{" "}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={`${styles.main}`}>
      <div className={`${styles.employerStudentHeader}`}>
        <div className={`${styles.cancelIcon} `} onClick={handleback}>
          <img
            src={cancel}
            className={`${styles.cancelIcon} `}
            alt="cancel icon"
          />{" "}
        </div>
        <div className={`${styles.studentName}`}>{studentProfile.name}</div>
        <div className={`${styles.logoImg} `}>
          <img src={logo} alt="CTI logo" />{" "}
        </div>
      </div>
    </div>
  );
};

EmployerStudentHeader.propTypes = {
  id: propTypes.string, //get student id
};

export default EmployerStudentHeader;
