import addSupervisorSaga from "./addSupervisor.saga";
import fetchStudentCourseProgressSaga from "./fetchStudentCourseProgress.saga" 
import fetchStudentTheoryAssessmentSaga from "./fetchStudentTheoryAssessments.saga"
const studentSagas = [
    addSupervisorSaga(),
    fetchStudentCourseProgressSaga(),
    fetchStudentTheoryAssessmentSaga()
];

export default studentSagas;
