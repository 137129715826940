import { call, put, takeLatest } from "redux-saga/effects";
import { fetchProfileApi } from "../../../services/profile/fetchProfileInfo";
import { fetchProfile,FETCH_PROFILE } from "../../../store/actions/user/profile.user.actions";

function* handleFetchProfile( action ) {
  try {
    const { data} = yield call(fetchProfileApi.fetchProfile,action.payload);
      yield put(fetchProfile.success({
        status:data?.status,
        statusCode:data?.status_code,
        data:data?.data,
        message:data?.message
      }));

  
  } catch (e) {
    const {data} = e;
    yield put(fetchProfile.failure({
      status:data?.status,
      statusCode:data?.status_code,
      data:data?.data,
      message:data?.message
    
}));
  }
}

function* fetchProfileSaga() {
  yield takeLatest(FETCH_PROFILE.REQUEST, handleFetchProfile);
}

export default fetchProfileSaga;
