import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as studentProgressReport from "../../actions/employer/StudentProgressReport.action";

function *getStudentProgressReportData(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getStudentProgressReport, action.payload)
        //extract the data here     
        yield put(studentProgressReport.studentProgressReportData.success(data));
    } catch (e) {
        yield put(studentProgressReport.studentProgressReportData.failure(e));
    }
}
function *getStudentProgressReportDataSaga(){
    yield (
        takeLatest(studentProgressReport.STUDENT_PROGRESS_REPORT.REQUEST, getStudentProgressReportData)
    );
}

export default getStudentProgressReportDataSaga;