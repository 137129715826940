
import setpinSaga from "./setpin.user.saga";
import loginSaga from "./login.user.saga";
import loginWithPinSaga from "./loginWithPin.user.saga";
import forgotPasswordSaga from "./forgotPassword.user.saga";
import verifySignupSaga from "./verifySignup.user.saga";
import signupSaga from "./signup.user.saga";
import signoutSaga from "./signout.user.saga";
import localStorageSaga from "./localstorage.user.saga";
import resetPasswordSaga from "./resetPassword.user.saga";
import profileUpdateSaga from "./updateProfile.saga";
import changePasswordSaga from "./changePassword.user.saga";
import fetchProfileSaga from "./fetchProfile.user.saga";
import changePinSaga from "./changePin.user.Saga";
import generateLoginLinkSaga from "./generateLoginLink.user.saga";
import loginWithCodeSaga from "./loginWithCode.user.saga";
const userSagas = [
setpinSaga(),
loginSaga(),
loginWithPinSaga(),
forgotPasswordSaga(),
verifySignupSaga(),
signupSaga(),
signoutSaga(),
localStorageSaga(),
resetPasswordSaga(),
profileUpdateSaga(),
changePasswordSaga(),
fetchProfileSaga(),
changePinSaga(),
generateLoginLinkSaga(),
loginWithCodeSaga()
];

export default userSagas;
