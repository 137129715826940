import React from "react";
import EmDashboardHeader from "./EmDashboardHeader/EmDashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { employerData } from "../../store/actions/employer/Employer.action";
import { upcomingWorkshopdata } from "../../store/actions/employer/UpcomingWorkshop";
import { upcomingStudentWorkshopdata } from "../../store/actions/employer/UpcomingStudentWorkshop.action";
import { useEffect } from "react";
import loadinWheel from "../../assets/images/blue-Loading-spinner.gif";
import styles from "./styles.module.scss";
import UpcomingWorkshop from "./UpcomingWorkshop";
import WorklogUnit from "./WorklogUnit";
import { useState } from "react";
import EmContactLogo from "./EmContactLogo";
import EmFooter from "./EmFooter";
import PrevUpButton from "./PrevUpButtons";
import HeaderLabel from "../../components/common/HeaderLabel/index";
import { employer_path } from "../../Constants/paths/employer";

const EmployerDashboard = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();

  //All the data get here by the reducer
  const EmployerData = useSelector(
    (state) => state.employerReducers.employerStudent
  );
  const upcomingWorkshop = useSelector(
    (state) =>
      state.employerReducers?.upcomimgWorkshop?.upcomingworkshop
        ?.upcoming_lessons
  );

  const unitSign = useSelector(
    (state) => state.employerReducers?.upcomimgWorkshop?.upcomingworkshop?.units
  );
  const upcomingWorkshoploading = useSelector(
    (state) => state.employerReducers?.upcomimgWorkshop?.loading
  );
  const upcomingStudentWorkshop = useSelector(
    (state) =>
      state.employerReducers?.upcomingStudentWorkshop?.upcomingStudentworkshop
  );
  const confirmationReducer = useSelector(
    (state) => state.employerReducers?.confirmUpcomingWorkshopReducer
  );

  const loadingConfirmation = confirmationReducer?.loading;
  const confirmationSuccess = confirmationReducer?.confirmsuccess;
  const loadingEmployer = EmployerData.loadingWheel;
  const StudentWorklog = EmployerData?.employers?.students;

  //if the User login than this page is render
  useEffect(() => {
    dispatch(employerData.request());
    dispatch(upcomingWorkshopdata.request());
    dispatch(upcomingStudentWorkshopdata.request());
  }, []);

  //if any changes in data than this page is rerender
  useEffect(() => {
    dispatch(upcomingWorkshopdata.request());
    dispatch(upcomingStudentWorkshopdata.request());
  }, [confirmationSuccess]);

  //To handle the Upcoming and Previous button
  const [activestate, setactivestate] = useState(true);
  const handleUpcoming = () => {
    setactivestate(true);
  };
  const handlePrevious = () => {
    setactivestate(false);
  };

  //data filter for the Upcoming and Previous
  const upcoming_Workshop = upcomingStudentWorkshop?.filter((elem) => {
    return elem.status === "Future";
  });
  const previous_Workshop = upcomingStudentWorkshop?.filter((elem) => {
    return elem.status !== "Future";
  });
  const style = { margin: "-16px", width: "100vw" };

//function to  handle visibility of worklogs data and units data to be signed by the employer
const [showWorklogs,setShowWorklogs] = useState(false);
const [showUnits,setShowUnits] = useState(false);
const handleShowWorklogs = () =>{
  if(showWorklogs){
    setShowWorklogs(false);
  }else{
    setShowWorklogs(true);
  }
setShowUnits(false);
}
const handleShowUnit = () =>{
  if(showUnits){
    setShowUnits(false);
  }else{
    setShowUnits(true);
  }
  setShowWorklogs(false);
}



  return (
    <>
      <EmDashboardHeader />
      <HeaderLabel text="To Do" style={style} />
      {loadingEmployer && !loadingConfirmation && !upcomingWorkshoploading ? (
        <>
          <div className={styles.employerDashboard}>
            <div className={styles.margintop}></div>
            {upcomingWorkshop?.length > 0 || StudentWorklog?.length > 0 || unitSign?.length>0 ? (
              <></>
            ) : (
              <div className={styles.allSetLabel}>You are all set</div>
            )}

            {(StudentWorklog?.length > 0 || unitSign?.length>0) && <div className={styles.worklogUnitCard}>
              <div onClick={handleShowWorklogs} className={showWorklogs ? styles.showWorklogsActive: styles.showWorklogsInactive}>
                <div className={styles.count}>{StudentWorklog?.length ?StudentWorklog?.length : '0' }</div>
                <div className={styles.woklogUnitText}>Worklog Sign</div>
                </div>
              <div onClick={handleShowUnit} className={showUnits ? styles.showWorklogsActive:styles.showWorklogsInactive}>
                <div className={styles.count}>{unitSign?.length ?unitSign?.length : '0' }</div>
                <div className={styles.woklogUnitText}>Unit Sign</div>
              </div>
            </div>
                    }
            {StudentWorklog &&showWorklogs ? (
              <>
                {StudentWorklog?.map((elem) => (
                  <WorklogUnit
                    key={elem.worklog_id}
                    employerData={elem}
                    text="Worklog Sign"
                    path={employer_path.EMPLOYER_DASHBOARD_SIGN}
                  />
                ))}{" "}
              </>
            ) : (
              <> </>
            )}

            {showUnits && unitSign?.map((elem) => (
              <WorklogUnit
                key={elem.worklog_id}
                employerData={elem}
                text="Unit Sign"
                path={employer_path.EMPLOYER_DASHBOARD_UNITSIGN}
                unitSign={true}
              />
            ))}
            {upcomingWorkshop?.map((elem) => (
              <UpcomingWorkshop
                key={elem.lesson_id}
                data={elem}
                confirm={true}
                contactPath={employer_path.EMPLOYER_CONTACT_US}
                workshopName="Upcoming Workshop"
              />
            ))}
            <div className={styles.UnderLine}></div>
            <PrevUpButton
              onUpcomingClick={handleUpcoming}
              onPrevClick={handlePrevious}
              activestate={activestate}
            />
            {upcoming_Workshop?.length === 0 && activestate ? (
              <div className={styles.allSetLabel}>
                There are no upcoming workshops
              </div>
            ) : previous_Workshop?.length === 0 && activestate === false ? (
              <div className={styles.allSetLabel}>
                There are no previous workshops
              </div>
            ) : (
              <></>
            )}
            {activestate ? (
              <>
                {upcoming_Workshop?.map((elem) => (
                  <UpcomingWorkshop
                    key={elem.lesson_id}
                    data={elem}
                    contactPath={employer_path.EMPLOYER_CONTACT_US}
                    workshopName="Upcoming Workshop"
                  />
                ))}
                <div className={styles.UnderLine}></div>
              </>
            ) : (
              <>
                {previous_Workshop?.map((elem) => (
                  <UpcomingWorkshop
                    key={elem.lesson_id}
                    data={elem}
                    label={true}
                    workshopName="Previous Workshop"
                    contactPath={employer_path.EMPLOYER_CONTACT_US}
                  />
                ))}
                <div className={styles.UnderLine}></div>
              </>
            )}

            <EmContactLogo />
          </div>
        </>
      ) : (
        <div className={styles.loadingWheel}>
          <img src={loadinWheel} alt="loadingWheel" />
        </div>
      )}
      <EmFooter
        homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
        apprenticePath={employer_path.APPRENTICE_PATH}
        setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
        active={true}
      />
    </>
  );
};

export default EmployerDashboard;
