import * as LoginWithPinActions from "../../actions/user/loginWithPin.user.actions";

const initialState = {
  loading: false,
  loginWithPinSuccess: false,
  status: "",
  message: "",
  token: "",
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LoginWithPinActions.LOGIN_WITH_PIN.REQUEST:
      return {
        ...state,
        loading: true,
        loginWithPinSuccess: false,
        message: action.payload.message,
      };

    case LoginWithPinActions.LOGIN_WITH_PIN.SUCCESS:
      return {
        ...state,
        loading: false,
        loginWithPinSuccess: true,
        status: action.payload.status,
        token: action.payload.data.token,
        message: action.payload.message,
        user: action.payload.user,
      };

    case LoginWithPinActions.LOGIN_WITH_PIN.FAILURE:
      return {
        ...state,
        loading: false,
        loginWithPinSuccess: false,
        status: action.payload.status,
        message: action.payload.message,
        user: null,
      };

    default:
      return state;
  }
}
