import React from "react";
import requireAuthentication from "../../hoc/requireAuthentication";
import propTypes from 'prop-types';
import styles from "./styles.module.scss"
import { useState } from "react";
import { fetchLocally,removeFromLocalStorage } from "../../utils/localStorage";
import RedCrossIcon from "../../assets/images/RedCross.svg"
// This is for authorised routes
const OnBehalfLayout = (props) => {
  const [internalError,setInternalError] = useState(false)
  window.addEventListener("storage", function () {
    if(fetchLocally("internal_error")){
      setInternalError(true);
    }else{
      setInternalError(false)
    }
}, false);
  
  const handleRemoveError = () =>{
    removeFromLocalStorage.setInternalError();
    setInternalError(false)
  }
  return (
    <>
    <div className={styles.OnBehalfLayout}> 
    {internalError && <div className="internalError">
  <span>Internal Server Error. Please try again later.</span>
  <img className="crossIconImg" src={RedCrossIcon} alt = "Cross Icon" onClick={()=>handleRemoveError()}/> 
  </div>}
  <>
      {props.children}
      </>
    </div>
    </>
  );
};

OnBehalfLayout.propTypes = {
  children: propTypes.string,
};

export default requireAuthentication(OnBehalfLayout);
