import React from 'react'
import AccessDeniedImg from '../../../assets/images/AccessDeniedImg.png'
import './App.css'
import { useHistory } from 'react-router-dom'; 
import { anonymous_path } from '../../../Constants/paths/anonymous';

const AccessDenied = () => {
    const history = useHistory()
    const backHome = () => {
        history.push(anonymous_path.HOME_PATH)
    }
    return (
        <>
            <div className="mainDiv">
                <div className="container1">
                    <div className="deniedBox">
                        <div className="ImgDenied">
                            <img src={AccessDeniedImg} alt="Access denied" />
                        </div>
                        <div className="acessDenied">
                            <h1>Access Denied</h1>
                            <p>Please Go Back To The Page and Try Again!</p>
                            <button onClick={backHome}>Go Back</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccessDenied
