import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as StudentOnTrackStatus from "../../actions/employer/StudentOnTrackStatus.action";

function *getStudentOntrackData(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getStudentOntrackStatus, action.payload)
        //extract the data here     
        yield put(StudentOnTrackStatus.studentOntrackData.success(data));
    } catch (e) {
        yield put(StudentOnTrackStatus.studentOntrackData.failure(e));
    }
}
function *getStudentOntrackDataSaga(){
    yield (
        takeLatest(StudentOnTrackStatus.STUDENT_ONTRACK_STATUS.REQUEST, getStudentOntrackData)
    );
}

export default getStudentOntrackDataSaga;