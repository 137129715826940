import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetPasswordUserAction } from "../../../store/actions/user/resetPassword.user.action";
import Anonymous from "../../../layouts/Anonymous";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
import FormCard from "../../../components/auth/FormCard";

import "./index.scss";
import {  storeInLocalStorage } from "../../../utils/localStorage";
import { login } from "../../../store/actions/user/login.user.actions";
import { verifySignup } from "../../../store/actions/user/verifySignup.user.action";

const ResetPassword = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const { token } = useParams();
  const dispatch = useDispatch();


  // const resetPasswordState = useSelector(selectResetPassword);
  const resetPasswordState = useSelector((state) => state?.user?.resetPassword)
  const verifySignupState = useSelector((state) => state.user.verifySignup);

  // let queryParam = useQueryParameters().get("redirectTo");

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    arePasswordsSame: false,
    errMsg: "",
    isVerifying: true,
    passwordResetted: false,
    isVerified: false,
    alreadySignedUp: null,
    initiateSignup: null,
    otherMsg: null,
    
  });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });

  useEffect(() => {
    storeInLocalStorage.setTokenVerifySignup(token);
    dispatch(verifySignup.request(token));

  }, []);

  useEffect(() => {
    //handle the signup as verification is successfull
    if (
      verifySignupState.verifySuccess === true &&
      verifySignupState.verifyFailure === false
    ) {


      //case 1 - when the verificaion is succes ans link is not expired
      if (verifySignupState.message === "Already Signed Up.") {
        setValues({
          ...values,
          alreadySignedUp: true,
          initiateSignup: false,
        });
        setLoading(false);
      } else if (verifySignupState.status_code >= 400) {
        setValues({
          ...values,
          otherMsg: true,
          initiateSignup: false,
        });
        setLoading(false);
      } else {
        setValues({
          ...values,
          initiateSignup: true,
          alreadySignedUp: false,
        });
        setLoading(false);
      }
    } else if (
      verifySignupState.verifySuccess === false &&
      verifySignupState.verifyFailure === true
    ) {
      //handle the cases when there are some errors
    
      setValues({
        ...values,
        otherMsg: true,
        initiateSignup: false,
      });
      setLoading(false);
      // setError({ status: true, message: verifySignupState.message });
    }

    //as verification is done, set loading to false...
    // setLoading(false);
  }, [verifySignupState]);


  useEffect(() => {
    if (resetPasswordState.loading) {
      setLoading(true);
      setError({ status: false, message: "" });
      setValues({
        ...values,
        isVerifying: true,
        passwordResetted: false,
      });
    }
    if (resetPasswordState.resetPasswordSuccess) {
      setLoading(false);
      setError({ status: false, message: "" });
      setValues({
        ...values,
        isVerifying: false,
        isVerified: true,
        passwordResetted: true,
        ...resetPasswordState.user,
      });
    }
    if (resetPasswordState.resetPasswordFailed) {
      setLoading(false);
      setError({
        status: true,
        message: resetPasswordState.message,
      });
      setValues({
        ...values,
        isVerifying: false,
        isVerified: false,
        passwordResetted: false,
        errMsg: resetPasswordState.message,
      });
    }

  }, [resetPasswordState]);

  const inputChangeHandler = (name) => (event) => {
    setError({ status: false, message: "" });
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const arePasswordsSameCheck = () => {
    const check =
      values.password === values.confirmPassword &&
      values.password.length > 0 &&
      values.confirmPassword.length > 0;

    return check;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError({ status: false, message: "" });
    if (!arePasswordsSameCheck()) {
      setError({
        status: true,
        message: "Password is not matching! Please re-enter the same Password.",
      });
      setValues({
        ...values,
        arePasswordsSame: false,
      });
      return;
    }

    const { password } = values;
    dispatch(
      resetPasswordUserAction.request(password)
    );
  };

  //this function will handle the loging of the user after resettting the password
  const handleLogin = () => {
    const { password } = values;
    const remember_me = false
    dispatch(login.request({ login_id: verifySignupState?.user?.email, password, remember_me }));
  }



  const loading = () => {
    if (isLoading) {
      return (
        <>
          <div
            className="spinner-border text-secondary loader enabled"
            role="status"
          >
            <span className="sr-only">Verifying...</span>
          </div>
        </>
      );
    }
  };

  const formFields = () => (
    <>
      <div className="col-md-12">
            <h5 className="text-center text-gray mb-4">
              {values.initiateSignup &&
                `Welcome, ${verifySignupState?.user?.first_name}`}
              {values.alreadySignedUp && `Hi ${verifySignupState?.user?.first_name}, you have already signed up`}
              {values.otherMsg && `There is something wrong, please contact CTI.`}
            </h5>
          </div>

          {isLoading === true && (
            <div className="d-flex col-md-12 justify-content-center">
              {loading()}
            </div>
          )}
   {values.initiateSignup && <>
      {values.passwordResetted === true ? <>
        <div className="col-md-12">
          <h5 className="text-center text-red mb-4" style={{ color: "green" }}>
            Password Reset Successful
          </h5>
        </div>
        <Button
          type="submit"
          onClick={handleLogin}
        >
          {"Login"}
        </Button>

      </>
        : <form className="form-width-inherit">
          <Input
            icon="fas fa-lock"
            label="Enter Password"
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={inputChangeHandler("password")}
            required
          />
          <Input
            icon="fas fa-lock"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            aria-describedby="confirmPasswordHelp"
            value={values.confirmPassword}
            onChange={inputChangeHandler("confirmPassword")}
            required
          />

          {error.status && (
            <small className="form-text text-danger">{error.message}</small>
          )}

          <Button
            type="submit"
            disable={
              values.password.length === 0 && values.confirmPassword.length === 0
            }
            onClick={handleSubmit}
            loading={isLoading}
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </Button>
        </form>}
    </>}
    </>
  );

  // const notVerified = () => (
  //   <div className="">
  //     <span className="text-danger">{values.errMsg}</span>
  //     <p>
  //       <Link to="/login">Go to login page.</Link>
  //     </p>
  //   </div>
  // );

  const resetPasswordForm = () => {
    return (
      <FormCard title="Reset Password">
        {/* {values.isVerified ? formFields() : notVerified()} */}
        {formFields()}
      </FormCard>
    );
  };

  return (
    <Anonymous loading={values.isVerifying}>
      {values.isVerifying && loading()}
      {resetPasswordForm()}
    </Anonymous>
  );
};

export default ResetPassword;
