import React from 'react'
import styles from './styles.module.scss';
import propTypes from "prop-types";


const HeaderLabel = (props) => {
  return (
    <div className={styles.Label} style={props.style}>
      {props.text}
    </div>
  )
}


HeaderLabel.propTypes = {
  text: propTypes.string,
  style: propTypes.object,
};

export default HeaderLabel