import { createRequestTypes, action } from "../../../utils/redux";
export const CHANGE_PASSWORD = createRequestTypes("CHANGE_PASSWORD");

export const changePassword = {
  request: (data) => action(CHANGE_PASSWORD.REQUEST, data),
  success: (data) => action(CHANGE_PASSWORD.SUCCESS, data),
  failure: (error) => action(CHANGE_PASSWORD.FAILURE, error),
};

export const RESET_CHANGE_PASSWORD = createRequestTypes("RESET_CHANGE_PASSWORD");
export const resetChangePassword = {
  success: () => action(RESET_CHANGE_PASSWORD.SUCCESS)
};


