import { createRequestTypes, action } from "../../../utils/redux";
export const CHANGE_PIN = createRequestTypes("CHANGE_PIN");

export const changePin = {
  request: (data) => action(CHANGE_PIN.REQUEST, data),
  success: (data) => action(CHANGE_PIN.SUCCESS, data),
  failure: (error) => action(CHANGE_PIN.FAILURE, error),
};
export const RESET_CHANGE_PIN = createRequestTypes("RESET_CHANGE_PIN");
export const resetChangePin = {
  success: () => action(RESET_CHANGE_PIN.SUCCESS)
};





