export const ApiEndpoints = Object.freeze({
        getWorklogsForTrainerToSign:{
        url:"trainer/worklogs",
        method:"GET"
      },
      getAllStudentsOfTrainer:{
        url:"trainer/students",
        method:"GET"
      },

      getAllWorklogsOfStudentForTrainer:{
        url:"trainer/worklogs/student/student_id",
        method:"GET"
      },
      updateTrainerInfo:{
        url:"trainer/info",
        method:"PUT"
      }
});