import React from "react";
import WorklogDetailsForUnit from "../../../components/Worklog/WorklogDetailsForUnit/index";
import styles from "./styles.module.scss";
import StudentFooter from "../../../components/Dashboard/StudentFooter/StudentFooter";
import { useDispatch } from "react-redux";
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";

const ViewWorklogDetails = () => {
const dispatch = useDispatch();
  //setting the current page by calling an action to set page
dispatch(studentCurrentPage.success("worklogs"))

  return (
    <div>
      <div className={styles.mainContainer}>
        <div
          className={`pt-3 ${styles.innerContainer}`}
          style={{ minHeight: "100vh" }}
        >
          <WorklogDetailsForUnit />
          <StudentFooter />
        </div>
      </div>
    </div>
  );
};


export default ViewWorklogDetails;
