import { call, put, takeLatest } from "redux-saga/effects";
import { loginWithCodeApi } from "../../../services/anonymous/loginWithCode.service";
import {
  LOGIN_WITH_CODE,
  loginWithCode,
} from "../../../store/actions/user/loginWithCode.user.action"
import { storeInLocalStorage } from "utils/localStorage";


function* handleLoginWithCode({ payload }) {
  try {
    const { data } = yield call(loginWithCodeApi.loginWithCode, payload);
    yield put(loginWithCode.success(data));
    storeInLocalStorage.setTempLogin(true);
    if(data.data){
      storeInLocalStorage.storeFirstName(data?.data?.first_name)
      storeInLocalStorage.storeLastName(data?.data?.last_name)
      
    }
    
  } catch (e) {
    yield put(loginWithCode.failure(e.data));
  }
}

function* loginWithCodeSaga() {
  yield takeLatest(LOGIN_WITH_CODE.REQUEST, handleLoginWithCode);
}

export default loginWithCodeSaga;
