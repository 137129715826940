import React from "react";
import { useState } from "react";
import StudentFooter from "./StudentFooter/StudentFooter";
import EmDashboardHeader from "../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import UpcomingWorkshop from "../../components/EmployerDashboard/UpcomingWorkshop";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import PrevUpButton from "../../components/EmployerDashboard/PrevUpButtons";
import { useDispatch } from "react-redux";
import { lessonConfirmation } from "../../store/actions/worklogs/lessonConfirmation.worklogs.action";
import EmContactLogo from "../../components/EmployerDashboard/EmContactLogo";
import HeaderLabel from "../../components/common/HeaderLabel/index";
import { student_path } from "../../Constants/paths/student";
import overDueWaningIcon from "../../assets/images/overdueWarningIcon.svg"

const StudentDasboard = () => {
  const dispatch = useDispatch();

  //Fetching the data from the reducer
  const fetchNextLesson = useSelector(
    (state) => state.worklog.fetchLessonsReducer
  );

  //reducer data for workshops
  const workshopsReducer = useSelector(
    (state) => state?.worklog?.fetchAllWorkshopsReducer
  );

  //reducer data for the theory assessments
  const theoryAssessmentsReducer  = useSelector(
    (state) => state?.studentReducers?.studentTheoryAssessmentsReducer
  );

  const theoryAssessments = theoryAssessmentsReducer?.theoryAssessments

  const loadingWorkshop = workshopsReducer?.loading;
  const allWorkshops = workshopsReducer?.workshops;

  //contants that have some value
  const nextLessonDate = fetchNextLesson?.lsnDate;
  const lessonId = fetchNextLesson?.lesStudId;
  const studentConfirmed = fetchNextLesson?.studentConfirmed;
  const worklogSubmitted = fetchNextLesson?.worklogSubmitted;
  const overdueTheoryAssessmentsCount = fetchNextLesson?.overdueTheoryAssessmentsCount;


  const [activestate, setactivestate] = useState(true);

  //this function will set active state to true so we can show the upcoming lesson
  const handleUpcoming = () => {
    setactivestate(true);
  };
  //this function will set active state to false so we can show the previous lesson
  const handlePrevious = () => {
    setactivestate(false);
  };

  //this function will trigger the calling of lesson confirmation Api's
  const handleConfirmLesson = () => {
    dispatch(lessonConfirmation.request(lessonId));
  };

  // converting the data to a specific object which have the value from the reducer
  const nextLesson = [
    {
      date: nextLessonDate,
    },
  ];

  //data filter for the Upcoming and Previous
  const upcomimgWorkshops = allWorkshops?.filter((elem) => {
    return elem.status === "Future";
  });
  const prevWorkshops = allWorkshops?.filter((elem) => {
    return elem.status !== "Future" && elem.status !=="Not Booked";
  });
  const style = { margin: "-16px", width: "100vw" };


  return (
    <>
      <EmDashboardHeader />
      <div className={styles.employerDashboard}>
        <HeaderLabel text="To Do" style={style} />
        <div style={{ marginTop: "120px" }}></div>
        {(studentConfirmed === true || studentConfirmed === "") &&
        worklogSubmitted === true ? (
          <div className={styles.allSetLabel}>You are all set</div>
        ) : (
          <></>
        )}
        {nextLessonDate &&
          studentConfirmed !== true &&
          nextLesson?.map((item) => (
            <UpcomingWorkshop
              data={item}
              dataFor="beThere"
              onClick={handleConfirmLesson}
              workshopName="Upcoming Workshop"
              contactPath={student_path.DASHBOARD_CONTACTUS}
            />
          ))}

        {worklogSubmitted === false ? (
          <UpcomingWorkshop
            dataFor="submitReminder"
            contactPath={student_path.CREATE_WORKLOG_PATH}
          />
        ) : (
          <></>
        )}
        {overdueTheoryAssessmentsCount >0 && 
        <div className={styles.overdueTheoryAssessmentsCountContainer}>
          <div>
            <img src={overDueWaningIcon} alt = "Ovedue Warning Icon" />
            <span>Theory</span>
          </div>
          <div className={styles.overdueTheoryAssessmentsCountText}>
            {overdueTheoryAssessmentsCount + " Overdue"}
          </div>
        </div>
        }
        {theoryAssessments?.length>0  && (
          theoryAssessments?.map((item)=>(
            <UpcomingWorkshop
            dataFor="theoryAssessments"
            contactPath={student_path.CREATE_WORKLOG_PATH}
            data ={item} />

          )))}

        <div className={styles.UnderLine}></div>
        <div className={styles.onlineCourseLogin}>
          <a
            href="https://cti.assessapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Online Course Login</p>
          </a>
        </div>

        <PrevUpButton
          onUpcomingClick={handleUpcoming}
          onPrevClick={handlePrevious}
          activestate={activestate}
        />
        {loadingWorkshop === false &&
          (activestate ? (
            upcomimgWorkshops?.length > 0 ? (
              upcomimgWorkshops?.map((item) => (
                <UpcomingWorkshop
                  key={item.lesson_id}
                  data={item}
                  contactPath={student_path.DASHBOARD_CONTACTUS}
                  workshopName={item.lesson_name}
                  dataFor="student"
                />
              ))
            ) : (
              <div className={styles.allSetLabel}>
                There are no upcoming workshops
              </div>
            )
          ) : prevWorkshops?.length > 0 ? (
            prevWorkshops?.map((item) => (
              <UpcomingWorkshop
                key={item.lesson_id}
                data={item}
                contactPath={student_path.DASHBOARD_CONTACTUS}
                workshopName={item.lesson_name}
                label={true}
                dataFor="student"
              />
            ))
          ) : (
            <div className={styles.allSetLabel}>
              There are no previous workshops
            </div>
          ))}
        <div className={styles.UnderLine}></div>
        <EmContactLogo />
      </div>
      <StudentFooter />
    </>
  );
};

export default StudentDasboard;
