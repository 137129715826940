import { createRequestTypes, action } from "../../../utils/redux";


export const SET_PIN = createRequestTypes("SET_PIN");
export const setPin = {
  request: (data) => action(SET_PIN.REQUEST, data),
  success: (data) => action(SET_PIN.SUCCESS, data),
  failure: (error) => action(SET_PIN.FAILURE, error),
};

export const RESET_SET_PIN = createRequestTypes("RESET_SET_PIN");
export const resetSetPin = {
  success: (data) => action(RESET_SET_PIN.SUCCESS, data),
};
