import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from "./styles.module.scss";
import { rejectionSign } from '../../../store/actions/employer/RejectionSign.action';
import propTypes from 'prop-types';
import { useLocation } from 'react-router-dom';



const RejectionComment = (props) => {
  const dispatch = useDispatch();
  const WorklogId = props.studentData?.signUnit.worklog_id
  const StudentId = props.studentData?.signUnit.student_id
  const [value, setValue] = useState("")
  
  const {search} = useLocation()
    const queryData=search?.split("&");
    //this const is having the data of worklog id fetched from the query
    const worklog_id=queryData[1]?.split("=")[1]
    //this const is having the studen id of whom the worklogs belong and fetched from teh query params
    const student_id=queryData[0]?.split("=")[1]

    //Cancel Submission
    const onCancelSubmission=()=>{
      var payload = {
        student_id:StudentId ? StudentId :+student_id ,
        worklog_id:WorklogId ?WorklogId : +worklog_id ,
        comment:value
      }
      dispatch(rejectionSign.request(payload));
    }
  return (
    <div className={styles.RejectPadFull}>
    <div className={styles.RejectPad}>
            <div className={styles.heading}>Rejection Reason</div>
            <div className={styles.textArea}>
                <textarea
                 name="rejectComment"
                 value={value}
                 onChange={(e)=>{setValue(e.target.value)}}
                 placeholder='Please enter your rejection reason here...'
                 id="" />
            </div>
            <div className="col-md pb-4 mx-auto row">
              <div className="centerWidth">
              <div className="centerBtn" >
              <button
                  className="btn btn-active halfBtn btn-info"
                  onClick={onCancelSubmission}
                >
                  Submit
                </button>
              </div>

              <div className="centerBtn">
                <button
                  className="btn btn-danger halfBtn bg-danger"
                   onClick={props.onCancel}                 
                >
                  Cancel
                </button>
              </div>
              </div>
            </div>
    </div>
    </div>
  )
}

RejectionComment.propTypes = {
  onCancel: propTypes.func, //for cancel rejection model
  studentData: propTypes.object, //add rejection comment for particular worklog student 
}

export default RejectionComment