import React from 'react'
import { useState } from 'react';
import styles from "./styles.module.scss";
import ProfileInput from '../../../../components/Setting/ProfileInput';
import ProfileFooter from '../../../../components/Setting/ProfileFooter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { changePin } from '../../../../store/actions/user/changePin.user.action';
import { useSelector } from 'react-redux';
import Overlay from '../../../../components/common/Overlay';
import { useEffect } from 'react';
import { fetchProfile } from "../../../../store/actions/user/profile.user.actions";
import { employer_path } from '../../../../Constants/paths/employer';
import { student_path } from '../../../../Constants/paths/student';
import { resetChangePin } from '../../../../store/actions/user/changePin.user.action';
import { trainer_path } from '../../../../Constants/paths/trainer';
import { staff_path } from '../../../../Constants/paths/staff';
import { supervisor_path } from 'Constants/paths/supervisor';
import { isTempLogin } from 'utils/helper/Student';
import HeaderLabel from 'components/common/HeaderLabel';
import EmDashboardHeader from 'components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader';


const ChangePinPage = () => {
     // this comment for disable useeffect dependency
    /* eslint-disable */


    const changePinData = useSelector(state=>state.user.changePinUserReducer)
    const loadingsuccess = changePinData?.changePinSuccess;
    const errorcurrentpassword = changePinData?.message;
    const changePinFail = changePinData?.changePinFail

    const profileReducer = useSelector(
        (state) => state.user.fetchProfileUserReducer
      );
    
    const UserType = profileReducer?.profile?.type
    const dispatch = useDispatch();
    const history = useHistory();
        const initialValue = {
            currentPassword: "",
            currentPin : "",
            newPin : "",
            confirmPin : ""
        }
        const [errorPinMessage, seterrorPinMessage] = useState("")
        const [values, setValues] = useState(initialValue);
        const [gopath, setgopath] = useState(false);
        const [allinput, setallinput] = useState(false);
        const [pininputDisable, setpininputDisable] = useState(false);
        const [passwordinputDisable, setpasswordinputDisable] = useState(false);
        const [inactivematchError, setinactivematchError ] = useState();

        useEffect(()=>{
            setValues(initialValue)
            dispatch(resetChangePin.success());
            dispatch(fetchProfile.request());
        },[])


        const handleChange=(e)=>{
            const { name, value} = e.target
            setValues({
                ...values,
                [name]: value,
              })
              setinactivematchError(false);
              dispatch(resetChangePin.success())
        }
        const updatedData = {
            new_pin: values.newPin,
            password: values.currentPassword,      
            current_pin: values.currentPin,
            is_temp_login:isTempLogin()
        }
        const handleSave=()=>{
            if(values.newPin !== values.confirmPin){
                seterrorPinMessage(
                   "Confirm pin is not match with New pin "
                );
                setinactivematchError(true);
            }else{
            dispatch(changePin.request(updatedData));
            setgopath(true);
            }
        }
        const handlepath=()=>{
            if(UserType === "E"){
            history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
            }
            else if(UserType === "S"){
            history.push(student_path.STUDENT_DASHBOARD_PATH);
            }else if(UserType === "T"){
                history.push(trainer_path.TRAINER_DASHBOARD_PATH)
              }else if(UserType === "C"){
                history.push(staff_path.STAFF_DASHBOARD_PATH)
              } else if(UserType === "D"){
                history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
              }
            setgopath(false);
        }
        useEffect(()=>{
            if((isTempLogin() && values?.newPin.length >= 4 && values?.confirmPin.length >=4)|| (values?.currentPassword.length>=6 || values?.currentPin.length>=4) && values?.newPin.length >= 4 && values?.confirmPin.length >=4 ){
                setallinput(true);
            }else{
                setallinput(false)
            }
            if(values.currentPassword.length>0){
                setpininputDisable(true);
            }else{
                setpininputDisable(false);
            }
            if(values.currentPin.length>0){
                setpasswordinputDisable(true);
            }else{
                setpasswordinputDisable(false);
            }
        },[values])
        const handleCross=()=>{
            setgopath(false);
            setValues(initialValue);
        }    

//css for header label
const style = { margin: "66px -16px 0px 0px", width: "120%", position: "static" };

    return (
   <>
    <div className={styles.mainContainer} >
    <EmDashboardHeader isImg={true} />
    <HeaderLabel text="Reset Pin" style={style} />
    <div className={styles.main}>
        {loadingsuccess && gopath ? <Overlay onClick={handlepath} text="Reset Pin Successfully" title="Pin Reset" handleCross={handleCross} /> : <></>}
    {!isTempLogin() && <> <ProfileInput
     label="Current Password"
     type="password"
     placeholder="Enter current password"
     name="currentPassword"
     value={values.currentPassword}
     onChange={handleChange}
     id="currentPassword"
     errorMessage={errorcurrentpassword === "Incorrect password." && changePinFail ? errorcurrentpassword  : ""} 
     inputDisable={passwordinputDisable}
     />
     
    <div className={styles.orBorder}><span>OR</span></div>
     <ProfileInput
     label="Current Pin"
     type="number"
     onKeyDown={(e) =>["e", "E", "+", "-","=","'",'"',"."].includes(e.key) && e.preventDefault()}
     placeholder="Enter current pin"
     name="currentPin"
     value={values.currentPin}
     onChange={handleChange}
     errorMessage={errorcurrentpassword === "Incorrect pin."? errorcurrentpassword : ""}
     inputDisable={pininputDisable}
     /></>}
    <ProfileInput
     label="New Pin"
     type="number"
     onKeyDown={(e) =>["e", "E", "+", "-","=","'",'"',"."].includes(e.key) && e.preventDefault()}
     placeholder="Pin must be at least 4 digit"
     name="newPin"
     value={values.newPin}
     onChange={handleChange}
     />
    <ProfileInput
     label="Confirm Pin"
     type="number"
     onKeyDown={(e) =>["e", "E", "+", "-","=","'",'"',"."].includes(e.key) && e.preventDefault()}
     placeholder="Pin must be at least 4 digit"
     name="confirmPin"
     value={values.confirmPin}
     onChange={handleChange}
     errorMessage={inactivematchError && errorPinMessage !== "" ? errorPinMessage : ""}
     />
    </div>
    <ProfileFooter
        disable = {false}
        onClick = {handleSave}
        disabled = {allinput}
    />
    </div>
   </>
    )
}

export default ChangePinPage;