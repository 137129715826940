import * as FetchFrequentEquipmentsActions from "../../../store/actions/worklogs/fetchFrequentWorklogEquiment.action";

const initialState = {
  equipmentId: "",
  equipmentName: "",
  topCount: 0,
  order: 0,
  equipments: [],
  loadingWorklog: false,
  worklogFetchError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FetchFrequentEquipmentsActions.FETCH_FREQUENT_EQUIPMENTS.REQUEST:
      return {
        ...state,
        loadingWorklog: true,
      };
    case FetchFrequentEquipmentsActions.FETCH_FREQUENT_EQUIPMENTS.SUCCESS:
      return {
        ...state,
        equipments: action.payload.equipments,
        loadingWorklog: false,
        worklogFetchError: false,
      };

    case FetchFrequentEquipmentsActions.FETCH_FREQUENT_EQUIPMENTS.FAILURE:
      return {
        ...state,
        Equipment_id: action.payload,
        Equipment_name: action.payload,
        loadingWorklog: false,
        worklogFetchError: true,
      };

    case FetchFrequentEquipmentsActions.CLEAR_FREQUENT_EQUIPMENT:
      return {
        equipments:[]
      }


    default:
      return state;
  }
}
