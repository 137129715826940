import React from "react";
import styles from "./styles.module.scss";
import { Container } from "react-bootstrap";
import Progress from "../../../components/Dashboard/Progress/Progress"

const ProgressPage = () => {


  return (
    <div className={styles.mainContainer}>
      <Container className={`pt-3 ${styles.containerEmployer}`}>
        <Progress />
      </Container>
    </div>
  );
};

export default ProgressPage;
