const TRAINER_DASHBOARD_PATH = "/trainer/dashboard";
const TRAINER_APPRENTICE_PATH = "/trainer/apprentices";
const TRAINER_VIEW_SETTING_PATH= "/trainer/dashboard/settings";
const TRAINER_WORKLOG_SIGN="/trainer/dashboard/worklog/sign";
const TRAINER_STUDENTS_ID = '/trainer/students/:id' 


export const trainer_path = {
    TRAINER_DASHBOARD_PATH,
    TRAINER_APPRENTICE_PATH,
    TRAINER_VIEW_SETTING_PATH,
    TRAINER_WORKLOG_SIGN,
    TRAINER_STUDENTS_ID

}