import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const generateLoginLinkApi = {
  generateLoginLink: (data) => {
    return axiosInstance.post(ApiEndpoints.generateLoginLink.url, {
    user_id:data.email,
    web_request:data.web_request,
    notify:data.notify
    });
  },
};
