import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated, isAuthenticatedPin, isPinAuthSkip , isLoginPinActive, isSupervisor, isStaff, isTempLogin} from '../../utils/helper/Student/index'
import SupervisorLayout from '../../layouts/Supervisor/SupervisorLayout';
import { anonymous_path } from '../../Constants/paths/anonymous';


const SupervisorRoute = ({component: Component, ...rest}) => {
    return (
        <Route
        {...rest}
        render={(props) =>
          isAuthenticated() && (isAuthenticatedPin() || isPinAuthSkip() || isLoginPinActive() || isTempLogin())  &&(isSupervisor() || isStaff())  ? (
            <SupervisorLayout>
              <Component {...props} />
            </SupervisorLayout>
          ) : (
            <Redirect
              to={{
                pathname: anonymous_path.LOGIN_PATH,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
    
}

export default SupervisorRoute;