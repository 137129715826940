import React from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { getWorklogDetailsForSign } from "../../../store/actions/employer/getWorklogDetailsForSign.action";
import * as rejectionAction from "../../../store/actions/employer/RejectionSign.action"
import * as SiteActions from "../../../store/actions/employer/Employer.action"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import propTypes from 'prop-types';
import * as unitSignature from "../../../store/actions/employer/unitSignature.action";

const WorklogUnit = (props) => {
  const data = props.employerData;
  const dispatch = useDispatch();
  const history = useHistory();

//To go the summary worklog and send payload to that page for the sign
  const handleCardClick = () => {
    dispatch(getWorklogDetailsForSign.request({worklog_id:data.worklog_id , student_id:data.student_id}));
    var payload = {
      signSelected: true,
      signUnit: data
    }
    dispatch(rejectionAction.showSign());
    dispatch(SiteActions.showSign(payload));
    history.push(props.path);
  }

//To go the unit Sign page for the submit signature
const handleCardClickUnitSign = () => {
  var payload = {
    signSelected: true,
    signUnit: data,
    signSuccess: false
  }
  dispatch(unitSignature.showSign(payload));
  history.push(props.path);
}

  
  return (
    <>
    {data &&
    <div>
      <div className={styles.workshopbox}>
        <div className={styles.workshopLabel}>
          <ul>
            <li className={`${styles.details}`}>{props.text}</li>
            <li className={`${styles.draftList}`}>
              <span className={`${styles.draft}`}>
                {data.student_name} 
              </span>
            </li>
            <li className={`${styles.dateinfo}`}>{data.task_name ? data.task_name : data?.unit_name}</li>
          </ul>
        </div>
        <div className={`${styles.buttonBox}`}>
            <button className={styles.confirm} data-testid = {props.text} onClick={props.unitSign ? handleCardClickUnitSign : handleCardClick}>Sign</button>
        </div>
      </div>
    </div>}
    </>
  );
};

WorklogUnit.propTypes = {
  employerData: propTypes.object, //get employer data
  text: propTypes.string, 
  path: propTypes.string, //get path for employer dashboard worklog
  student: propTypes.bool, 
  
}


export default WorklogUnit;
