import React from "react";
import requireAuthentication from "../../../hoc/requireAuthentication";

import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import EmployerDashboard from "../../../components/EmployerDashboard";
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("home"))
  return (
     <div className={styles.mainContainer}>
      <Container className={`pt-3 ${styles.containerEmployer}`}>
        <EmployerDashboard />
      </Container>
    </div>
  );
};

export default requireAuthentication(Dashboard);
