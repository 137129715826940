import React from 'react'
import { useHistory } from 'react-router-dom';
import styles from "./styles.module.scss";
import PinActive from "../../../assets/images/PinActive.png";
import { paths } from '../../../Constants/paths/common';
import propTypes from 'prop-types';
import { fetchLocally } from '../../../utils/localStorage';
import { useDispatch } from 'react-redux';
import { resetOnBehalfSignIn } from '../../../store/actions/staff/onBehalfSignIn.action';
import { removeFromLocalStorage } from '../../../utils/localStorage';
import { staff_path } from '../../../Constants/paths/staff';

const ProfileFooter = (props) => {
const history = useHistory();
const dispatch = useDispatch();

    //To redirect change password page
    function handlePassword(){
        history.push(paths.CHANGE_PASSWORD)
    }
    //To redirect change pin page
    function handlePin(){
        history.push(paths.CHANGE_PIN)
    }

 //onBehalf logout and functionality
 const onBehalfToken = fetchLocally("onbehalf_token")
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}   


    return (
    <div className={onBehalfToken ? styles.onBehalfFooter : styles.profileFooter}>
        {onBehalfToken&&<div className={styles.onbehalfLogOut}onClick={handleOnbehalfLogOut}>Close</div>}
        {props.disable ? <></> : 
    <div onClick={handlePassword} className={styles.resetPassword}>
        <span className={styles.footerButton}>
        <div className={styles.IconColor}><i className="icon fas fa-lock"></i></div>
        Reset <br /> Password
        </span>
    </div> }
    <div onClick={(props.disabled && props.onClick)} className={`${props.disabled? styles.active : styles.inActive }`}>
    <span className={styles.footerButton}>
        <div className={props.disabled ? styles.saveButton : styles.savedisableButton}><div><i className="far fa-save"></i></div></div>
        Save
    </span>
    </div>
    {props.disable ? <></> : 
    <div onClick={handlePin} className={styles.resetPindiv}>
        <span className={styles.footerButton}>
        <div className={styles.resetPin}><img src={PinActive} alt="" /></div>
        Reset <br /> Pin
        </span>
    </div>}
    </div>
    )
}

ProfileFooter.propTypes = {
    onClick: propTypes.func, // 
    disabled: propTypes.bool,
    disable: propTypes.bool,
  }

export default ProfileFooter;