import * as OnBehalfActions from "../../actions/staff/onBehalfSignIn.action"

const initialState = {
  //profile
  loading: false,
  success: false,
  status_code : "",
  status: "",
  message: "",
  onBehalfData:""
};

export default function (state = initialState, action) {
  switch (action.type) {
  //profile
    case OnBehalfActions.ONBEHALF_SIGN_IN.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        message: action.payload.message,
      };

    case OnBehalfActions.ONBEHALF_SIGN_IN.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        message: action.payload.message,
        status_code : action.payload.status_code,
        onBehalfData:action.payload.data
      };

    case OnBehalfActions.ONBEHALF_SIGN_IN.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        status_code : action.payload.status_code,
        status: action.payload.status,
        message: action.payload.message,
      };
      case OnBehalfActions.RESET_ONBEHALF_SIGN_IN.SUCCESS:
        return {
          loading: false,
          success: false,
          status_code : "",
          status: "",
          message: "",
          onBehalfData:""
        };
    default:
      return state;
  }
}
