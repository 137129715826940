import { createRequestTypes, action } from "../../../utils/redux";
export const GENERATE_LOGIN_LINK = createRequestTypes("GENERATE_LOGIN_LINK");


export const generateLoginLink = {
  request: (data) => action(GENERATE_LOGIN_LINK.REQUEST, data),
  success: (data) => action(GENERATE_LOGIN_LINK.SUCCESS, data),
  failure: (error) => action(GENERATE_LOGIN_LINK.FAILURE, error),
};

export const RESET_STATE_GENERATE_LOGIN_LINK = createRequestTypes("RESET_STATE_GENERATE_LOGIN_LINK");

export const resetGenerateLoginLink = {
  success: () => action(RESET_STATE_GENERATE_LOGIN_LINK.SUCCESS),
};