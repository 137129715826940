import * as apprenticeStudentData from "../../actions/employer/apprenticeStudent.action";

const initialState = {
    student: [],
    loading: false,
    success: false,
    apprenticeStudentfailure:false,
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case apprenticeStudentData.APPRENTICE_STUDENT.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case apprenticeStudentData.APPRENTICE_STUDENT.SUCCESS:

    return {
      ...state,
      student: action.payload.data,
      loading: false,
      success: true,
      apprenticeStudentfailure:false,
    };

  case apprenticeStudentData.APPRENTICE_STUDENT.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      apprenticeStudentfailure:true,
    };
      default: return state      
    }
}