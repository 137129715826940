import React from "react";
import SubmittedWorklogDetails from "../../../../../components/NewWorklogComponents/SubmittedWorklogDetails/SubmittedWorklogDetails";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchWorklogDetail } from "../../../../../store/actions/worklogs/fetchWorklogDetail.worklogs.action";
import { useSelector } from "react-redux";
import loadingIcon from "../../../../../assets/images/blue-Loading-spinner.gif";
import OtherPageHeader from "../../../../../layouts/NewHeader/OtherPageHeader";
import styles from "../styles.module.scss"

const SubmittedWorklogDetailsPage = () => {
  // this comment for disable useeffect dependency
    /* eslint-disable */
  //this will fetch the id of the worklog
  let { id } = useParams();
  const dispatch = useDispatch();

  //fetching the worklog detials
  const worklogDetails = useSelector(
    (state) => state.worklog?.fetchWorklogDetailReducer
  );
  
//this will fetch the completion status of fetchin the worklog
const failureStatus = worklogDetails?.worklogFetchError
//loading worklog details status 
const loadingWorklogStatus = worklogDetails?.loadingWorklog

  //this effect will run when there is any id is passed in params to fetch the worklog details
  useEffect(() => {
    id && dispatch(fetchWorklogDetail.request(id));
  }, []);

  return (
    <>
      {loadingWorklogStatus ?  (
        //when there is no any worklog id exits it will show the loader till the worklog is fetched from the database
        <div className="loader">
          <img src={loadingIcon} alt="loading icon"></img>
        </div>
      ):
      
      failureStatus ===false? (
        <>
        <OtherPageHeader
        heading={'Summary'}
        navImage={ `back` }
        navHeaderFor={'Summary'}
      />
      {worklogDetails?.worklog?.id > 0 && <SubmittedWorklogDetails />}  
      </>)  : <div className={styles.notFound}>Can’t find that worklog!</div>}
    </>
  );
};

export default SubmittedWorklogDetailsPage;
