import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import backIcon from "../../../assets/images/Back.svg"
import { useHistory } from 'react-router-dom';
import propTypes from "prop-types"
import { remove_login } from "../../../store/actions/user/login.user.actions";

const FormCard = (props) => {
    //To get the image by the Reducer
    const logo = useSelector(state => state.configuration.logo, shallowEqual)

    const history = useHistory();
    const dispatch = useDispatch()

    //Function for the Back button and the Logo for the LoginPage
    function handleBackButton() {
        dispatch(remove_login.success())
        history.replace("/login")
    }
    return (
        <div className='d-flex align-items-center justify-content-center vh-100 overflow-hidden' style={{ position: "relative" }}>
            <div className="card col-md-4 col-lg-4 col-xs-10 col-sm-10 bg-loginform p-0">
                <div className="col-md-12 bg-grey">
                    <div className="col-md text-center pt-4">

                        {(props.title === "Forgot Password?" || props.title === "Reset Password" || props.title === "Generate Login Link" )
                            &&
                            <div onClick={handleBackButton} style={{ position: "absolute", left: "10px", cursor: "pointer" }}>
                                <div>
                                    <img src={backIcon} alt="back icon" />
                                </div>

                                <span>Back</span>
                            </div>
                        }
                        <img
                            src={logo}
                            alt="CTI logo"
                            className="logo align-center pb-4"
                        />
                    </div>
                    <div className="col-md-12">
                        {props.formFor === "verifyPin" ?
                            <h5 className="text-center text-gray pb-4">{props.title}</h5>
                            :
                            <h3 className="text-center text-gray pb-4">{props.title}</h3>}
                    </div>
                </div>
                <div className="col-md-12 row no-gutters form-card">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

FormCard.propTypes={
    children: propTypes.object,
    title:propTypes.string,
    formFor:propTypes.string
}

export default FormCard

