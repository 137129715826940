import { call, put, takeLatest } from "redux-saga/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import { getWorklogDetailsForSign,GET_WORKLOG_DETAILS_FOR_SIGN } from "../../../store/actions/employer/getWorklogDetailsForSign.action";

function* handleFetchWorklogDetailsForSign( action ) {

  try {
    const { data} = yield call(EmployerApi.getWorklogDetailsForSign,action.payload);
   
      yield put(getWorklogDetailsForSign.success({
        status:data?.status,
        statusCode:data?.status_code,
        data:data?.data,
        message:data?.message
      }));

  
  } catch (e) {
    const {data} = e;

    yield put(getWorklogDetailsForSign.failure({
      status:data?.status,
      statusCode:data?.status_code,
      data:data?.data,
      message:data?.message
    
}));
  }
}

function* WorklogDetailsForSignSaga() {
  yield takeLatest(GET_WORKLOG_DETAILS_FOR_SIGN.REQUEST, handleFetchWorklogDetailsForSign);
}

export default WorklogDetailsForSignSaga;
