import React from "react";

import styles from "./styles.module.scss";


const ProgressConstants = () => {

  return (
    <>
          
          <div className={styles.keyTable}>
            <table>
              <tr>
                <th  colspan="2">Key</th>
              </tr>
              <tr className={styles.row}>
                <td className={styles.tableData1}>C</td>
                <td className={styles.tableData2}>Competent</td>
              </tr>
              <tr className={styles.row}>
                <td className={styles.tableData1}>CT</td>
                <td className={styles.tableData2}>Credit Transfer</td>
              </tr>
            </table>
          </div>
    </>
  );
};

export default ProgressConstants;
