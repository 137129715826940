import React from "react";
import EmDashboardHeader from "../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import HeaderLabel from "../../components/common/HeaderLabel";
import EmFooter from "../../components/EmployerDashboard/EmFooter";
import { trainer_path } from "../../Constants/paths/trainer";
import { useSelector } from "react-redux";
import WorklogUnit from "../../components/EmployerDashboard/WorklogUnit";
import styles from "./styles.module.scss";
import { supervisor_path } from "Constants/paths/supervisor";
import { isSupervisor } from "utils/helper/Student";

const TrainerDashboard = () => {
  //this selector will fetch the worklogs that are to be signed by the trainer.
  const worklogsToSignReducer = useSelector(
    (state) => state?.trainerReducers?.getWorklogsForTrainerToSignReducer
  );
  const allWorklogsToBeSigned = worklogsToSignReducer?.worklogs?.students;
  return (
    <>
      {/* this is the header for the trainer */}
      <EmDashboardHeader />

      {/* this lable will show the todo for the trainer */}
      <HeaderLabel text="To Do" style={{ margin: "-16px", width: "100vw" }} />
      <div style={{ marginTop: "120px" }}></div>
      {(worklogsToSignReducer.worklogs==null)? (
        <div className={styles.allSetLabel}>You are all set</div>
      ) : (
        <></>
      )}

      {/* this will show all the worklogs that are to be signed by the trainer */}
      <div className={styles.worklogsSignContainer}>
        {allWorklogsToBeSigned?.length > 0 &&
          allWorklogsToBeSigned.map((item) => {
            return (
              <WorklogUnit
                key={item.worklog_id}
                employerData={item}
                text="Worklog Sign"
                path={
                  isSupervisor()
                    ? supervisor_path.SUPERVISOR_WORKLOG_SIGN
                    : trainer_path.TRAINER_WORKLOG_SIGN
                }
              />
            );
          })}
      </div>

      {/* this is the footer for the trainer */}
      <EmFooter
        homePath={
          isSupervisor()
            ? supervisor_path.SUPERVISOR_DASHBOARD_PATH
            : trainer_path.TRAINER_DASHBOARD_PATH
        }
        apprenticePath={
          isSupervisor()
            ? supervisor_path.SUPERVISOR_APPRENTICE_PATH
            : trainer_path.TRAINER_APPRENTICE_PATH
        }
        setting={
          isSupervisor()
            ? supervisor_path.SUPERVISOR_VIEW_SETTING_PATH
            : trainer_path.TRAINER_VIEW_SETTING_PATH
        }
        active={true}
      />
    </>
  );
};

export default TrainerDashboard;
