import { createRequestTypes, action } from "../../../utils/redux";
export const LOGIN = createRequestTypes("LOGIN");
export const REMEMBER_ME = createRequestTypes("REMEMBER_ME");
export const REMOVE_LOGIN = createRequestTypes("REMOVE_LOGIN");
export const RESET_LOGIN = createRequestTypes("RESET_LOGIN");

export const login = {
  request: (data) => action(LOGIN.REQUEST, data),
  success: (data) => action(LOGIN.SUCCESS, data),
  failure: (error) => action(LOGIN.FAILURE, error),
};

export const remember_me = {
  request: (data) => action(REMEMBER_ME.REQUEST, data),
  success: (data) => action(REMEMBER_ME.SUCCESS, data),
  failure: (error) => action(REMEMBER_ME.FAILURE, error),
};

export const remove_login = {
  request: (data) => action(REMOVE_LOGIN.REQUEST, data),
  success: (data) => action(REMOVE_LOGIN.SUCCESS, data),
  failure: (error) => action(REMOVE_LOGIN.FAILURE, error),
};

export const reset_login = {
  request: (data) => action(RESET_LOGIN.REQUEST, data),
  success: (data) => action(RESET_LOGIN.SUCCESS, data),
  failure: (error) => action(RESET_LOGIN.FAILURE, error),
};
