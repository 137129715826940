import React, { useEffect, useState } from "react";

import EmployerApprenticesLink from "../EmployerApprenticesLink";
import { useDispatch, useSelector } from "react-redux";
import { studentDetailsData } from "../../../store/actions/employer/employerApprentices.action";
import loadingWheel from "../../../assets/images/blue-Loading-spinner.gif";
import styles from "./style.module.scss";
import searchIcon from "../../../assets/images/searchIcon.svg";
import clearSearchIcon from "../../../assets/images/crossSearchIcon.svg";

const EmployerApprenticesList = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  //To send request on render time for the Studentl list
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(studentDetailsData.request());
  }, []);
  //forminputs
  const useFormInputs = (initialValue, field) => {
    //First we will do useState and define the value and setValue
    const [value, setValue] = useState(initialValue);
    //set the value directly according to the input
    function setInputValue(input) {
      setValue(input);
    }
    //This function will set the value of the state of the field email/password etc
    function handleChange(e) {
      setValue(e?.target?.value);
    }
    //cler the value
    function clearInput() {
      setValue("");
    }
    //Finally we return the value(state's data) and the handleChange - the function that will setState of our property
    return {
      value,
      onChange: handleChange,
      clearInput: clearInput,
      setInputValue: setInputValue,
    };
  };
  //To get the data for the student List
  const employerApprentices = useSelector(
    (state) => state.employerReducers?.employerApprentices
  );
  const { studentDetails, loading } = employerApprentices;

  //handling the search functionality
  const searchStudent = useFormInputs("");
  const [searchResultBox, setsearchResultBox] = useState(false);
  const[active,setActive] = useState(true)
  const handleSearchClick = () => {
    setsearchResultBox(true);
  };
  const handleClearSearchInput = () => {
    searchStudent.setInputValue("");
  };

  window.onclick = function (event) {
    if (!event.target.matches("#searchInput")) {
      setsearchResultBox(false);
      const input = document.getElementById("searchInput");
      if (input) {
        input.blur();
      }
    }
  };

// const  handleActiveClick = () =>{
// setActive(true)
// }
// const handleInActiveClick = () =>{
// setActive(false)
// }

  return (
    <>
      {loading ? (
        <img className={styles.loadingWheel} src={loadingWheel} alt="loading" />
      ) : (
        <>
          <div
            id="searchBox"
            className={
              searchResultBox
                ? styles.searchContainerActive
                : styles.searchContainer
            }
          >
            <div className={styles.searchIcon}>
              <img src={searchIcon} alt="Search Icon" />
            </div>
            <div className={styles.searchInput} onClick={handleSearchClick}>
              <input
                className={styles.searchInput}
                id="searchInput"
                type="text"
                onKeyDown={(e) =>
                  ["[","]" , "/", "(" , ")" , "{" , "}" , "|" , "?"].includes(e.key) &&
                  e.preventDefault()
                }
                placeholder="Search by Name"
                value={searchStudent.value}
                onChange={searchStudent.onChange}
              />
            </div>
            <div className={styles.crossIcon}>
              <img
                src={clearSearchIcon}
                alt="Cross Icon"
                onClick={handleClearSearchInput}
              />
            </div>
          </div>
          {/* <div className={styles.activeInactiveContainer}>
            <div className={active ? styles.active : styles.notActive} onClick={handleActiveClick}>Active</div>
            <div className={!active ? styles.inActive: styles.notInActive} onClick={handleInActiveClick}>Inactive</div>
          </div> */}
          <div className={styles.apprenticesLink}>
            {studentDetails
              .filter((item) =>
                {
                  // if(active){
                    return  (item.enrolment_status !== 'Withdrawn - Unit Update' && item.enrolment_status !== 'Withdrawn'  && item.enrolment_status !== 'Completed' && item.enrolment_status !== 'Inactive' )&&item?.name?.toLowerCase().match(searchStudent?.value?.replace(/\\/g, '_').toLowerCase())
                  // }else{
                  //   return (item.enrolment_status === 'Withdrawn - Unit Update' || item.enrolment_status === 'Withdrawn'  || item.enrolment_status === 'Completed' ||item.enrolment_status === 'Inactive')  && item?.name?.toLowerCase().match(searchStudent?.value?.replace(/\\/g, '_').toLowerCase())
                  // }
                 
                }
                 
              )
              ?.map((data) => (
                <EmployerApprenticesLink key={data.student_id} data={data} />
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default EmployerApprenticesList;
