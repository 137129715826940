import React from 'react'
import {Link} from 'react-router-dom'
import styles from './style.module.scss'
import propTypes from "prop-types";
import signIcon from "../../../assets/images/SignIcon.svg"

const EmployerApprenticesLink = (props) => {
  return (
    <Link to={`/employer/students/${props.data.student_id}`} style={{ textDecoration: "none" }}>
      <div className={`${styles.EmpApprenticesLink}`} >
        <div className={`${styles.EmpApprenticesName}`}>{props.data.name}</div>
        <div className={styles.signIcon}>
          <img alt = 'signIcon' src= {signIcon}/>
        </div>
      </div>
    </Link>
  )
}

EmployerApprenticesLink.propTypes = {
  data: propTypes.object,  //get student name and id
}

export default EmployerApprenticesLink