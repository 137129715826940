import React from "react";
import { onBehalfSignIn, resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import styles from "./styles.module.scss"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmDashboardHeader from "../../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import StaffFooter from "./StaffFooter/StaffFooter";
import mailIcon from "../../../assets/images/mailicon.svg";
import phoenIcon from "../../../assets/images/phone.png";
import { removeFromLocalStorage } from "../../../utils/localStorage";

const StaffDashboard = () => {
  const dispatch = useDispatch();
  const onBehalfLoginData = useSelector((state)=>state?.staffReducers?.OnBehalfSignIn);
  const initialValue = {
    email: "",
  };
  const [values, setValues] = useState(initialValue);

  //this will handle the input change
  const handleEmailInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //this will handle the login
  const handleOnBehalfLogin = () => {
    dispatch(onBehalfSignIn.request({ login_id: values.email }));
  };
  const [isClicked, setIsClicked] = useState(false)
  const handleOnFocus = () => {
    dispatch(resetOnBehalfSignIn.success());
    removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
    setIsClicked(true)
  }
  const handleOnBlur = ()=>{
    if(!values.email){
      setIsClicked(false)
    }
  }
 
  return (
    <>
      <EmDashboardHeader />
      <div className={styles.onBehalfLoginContainer}>
        <span className={styles.onBehaflLoginText}>On Behalf Login</span>
        <div className={onBehalfLoginData.status_code === 400  ? styles.loginInputError : isClicked ? styles.loginInputActive : styles.loginInput }>
          <span className={styles.icons}>
            <span><img src={mailIcon} alt ="Mail Icon" className={styles.mailIcon} /></span>
            <span><img src={phoenIcon} alt ="Phone Icon" className={styles.phoneIcon} /></span>
          </span>
          {isClicked ? <label className={styles.label}>Email/ Phone Number</label> : <></>}
          <input
            placeholder={isClicked ? "" : "Email/ Phone Number"}
            type="text"
            name="email"
            value={values.email}
            onChange={handleEmailInput}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          ></input>
        </div>
        {onBehalfLoginData.status_code === 400 ? (
        <span className={styles.errorMessage}>You have entered an incorrect Email/Phone Number</span>
      ) : null}
        <button className={(onBehalfLoginData.status_code !== 400 && values.email) ?styles.loginButton :styles.disableloginButton} disabled={(onBehalfLoginData.status_code !== 400 && values.email)?false: true} onClick={handleOnBehalfLogin}>Login</button>
      </div>  
    <StaffFooter />  
    </>
  );
};

export default StaffDashboard;
