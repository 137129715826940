const EMPOYLER_APPRENTICES = '/employer/apprentices'
const EMPOYLER_STUDENTS_ID = '/employer/students/:id'
const VIEW_EMPLOYER_SETTINGS_PATH = "/employer/dashboard/settings";
const EMPLOYER_DASHBOARD_PATH = "/employer/dashboard";
const STUDENT_SIGN_WORKLOG = "/student/signworklog"
const STUDENT_SIGN_PAGE = "/student/signworklog/sign"
const APPRENTICE_PATH='/employer/apprentices';
const WORKLOG_SUBMISSION = '/employer/student/:id/worklog/submission';
const EMPLOYER_DASHBOARD_WORKLOG = '/employer/dashboard/worklog'
const EMPLOYER_DASHBOARD_SIGN = '/employer/dashboard/worklog/sign';
const SIGNATURE_POPUP_PATH = "/signpopup";
const EMPLOYER_STUDENT_WORKLOG_SIGN = '/employer/apprentices/worklog/sign';
const SIGN_PATH = '/sign';
const EMPLOYER_CONTACT_US = '/employer/dashboard/contactus'
const EMPLOYER_DASHBOARD_UNITSIGN = '/employer/dashboard/unit/sign'

export const employer_path = {
    EMPOYLER_APPRENTICES,
    EMPOYLER_STUDENTS_ID,
    VIEW_EMPLOYER_SETTINGS_PATH,
    EMPLOYER_DASHBOARD_PATH,
    STUDENT_SIGN_WORKLOG,
    STUDENT_SIGN_PAGE,
    APPRENTICE_PATH,
    WORKLOG_SUBMISSION,
    EMPLOYER_DASHBOARD_WORKLOG,
    EMPLOYER_DASHBOARD_SIGN,
    SIGNATURE_POPUP_PATH,
    EMPLOYER_STUDENT_WORKLOG_SIGN,
    SIGN_PATH,
    EMPLOYER_CONTACT_US,
    EMPLOYER_DASHBOARD_UNITSIGN
    
}