import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as studentDetails from "../../actions/employer/employerApprentices.action";

function *getStudentDetailsData(action){
   
    try{
        const {data} = yield call(EmployerApi.getStudentDetails, action.payload)
        //extract the data here     
        yield put(studentDetails.studentDetailsData.success(data));
    } catch (e) {
        yield put(studentDetails.studentDetailsData.failure(e));
    }
}
function *getStudentDetailsDataSaga(){
    yield (
        takeLatest(studentDetails.STUDENT_DETAILS.REQUEST, getStudentDetailsData)
    );
}

export default getStudentDetailsDataSaga;