import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { anonymous_path } from "../Constants/paths/anonymous";

export default (ChildComponent) => {
  class requireAuthentication extends Component {
    //OUR component got rendered
    componentDidMount() {
     // this.navigateAwayToLogin();
    }


    navigateAwayToLogin() {
      const { user, history } = this.props;
      if (!user) {
        history.push(anonymous_path.LOGIN_PATH);
      }
    }
    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps({ user }) {
    return { user: user.login.user };
  }

  return connect(mapStateToProps, {})(withRouter(requireAuthentication));
};
