import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import mainStyles from "../../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/styles.module.scss";
import InputComponent from "../../../components/common/Input/index";
import CustomButton from "../../../components/common/CustomButton";
import CustomRadioButton from "../../../components/common/CustomRadioButton/index";
import UploadImage from "../../../components/common/UploadImage";
import { Collapse } from "react-bootstrap";
import BlueDot from "../../../components/common/BlueDot/index";
import Checkbox from "../../../components/common/Checkbox";
import { useSelector } from "react-redux";
import NextIcon from "../../../assets/images/next.svg";
import backIcon from "../../../assets/images/Back.svg";
import Equipments from "../Equipments/Equipments";
import saveletter from "../../../assets/images/saveDraft.svg";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import { useHistory } from "react-router-dom";
import disableNextIcon from "../../../assets/images/DisabledNext.svg";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { student_path } from "../../../Constants/paths/student";
import propTypes from "prop-types";
import { addSupervisor } from "../../../store/actions/student/addSupervisor.action";
import gloabalStyle from "../../../styles/global.module.scss";
import AddNewSupervisorModal from "../../../components/Dashboard/AddNewSupervisorModal";
import { useDispatch } from "react-redux";
import { fetchWorklogSupervisor } from "../../../store/actions/worklogs/fetchWorklogSupervisor.worklogs.action";
import { decodeToken } from "../../../utils/helper/common/decodeJSONToken";
import disableSaveDraft from "../../../assets/images/disabledSaveDraft.svg"
import { decodeOnBehalfToken } from "../../../utils/helper/common/decodeJSONToken";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../../utils/localStorage";
import { staff_path } from "../../../Constants/paths/staff";

function NewWLStep3(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenData = decodeToken();
  // this comment for disable useeffect dependency
  /* eslint-disable */
  //these are the props passed from parent component
  const {
    task,
    radioButtonPicOptions,
    myRef,
    photoUpload,
    showUploadImg,
    howLong,
    setUpload,
    photoPart,
    supervisor,
    addMore,
    AddMore,
    photoHappening,
    data,
    files,
    setFiles,
    photos,
    setPhotos,
    mergedPhotoArray,
    setMergedPhotoArray,
    mergedEquipments,
    equipments,
    moreEquipments,
    setMergedEquipments,
    blankCheckbox,
    setblankCheckbox,
    setinputChecked,
    addMoreEquipCount,
    setAddMoreEquipCount,
    handleAddEquip,
    addMoreEquipInput,
    setAddMoreEquipInput,
    searchEquipment,
    currentEquipments, setCurrentEquipments,
    selectEquip, setSelectEquip
  } = props;

  const handleSupervisorClick = (id) => {
    if (supervisor.value == id) {
      supervisor.setInputValue("");
    } else {
      supervisor.setInputValue(id);
    }
  };

  //fetching the data of supervisor from reducer
  const fetchWorkLogSupervisorData = useSelector(
    (state) => state.worklog.fetchWorklogSupervisorReducer
  );
  const loadingSupervisor = fetchWorkLogSupervisorData.loadingSupervisor;

  //adding supervisor reducer
  const addSupervisorStatus = useSelector(
    (state) => state.studentReducers?.addNewSupervisorReducer.success
  );
  //fetching the data of worklog creation status
  const worklogCreationReducer = useSelector((state) => state?.worklog?.createWorklogsWorklogsReducer)
  const createWorklogStatus = worklogCreationReducer?.worklogCreated

  //these const and function will enable and disable the buttons
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  //consts which have the value of photos and equipments
  const checkPhotoVal = showUploadImg === true ? "true" : showUploadImg === false ? "false" : ""
  const dataTasklength = props?.data?.taskDetail[0]?.photos?.length > 0;
  const dataTask = dataTasklength ? props?.data?.taskDetail[0]?.photos[0] : "";
  const equipMap = props?.data?.taskDetail[0].equipments;
  const arra2 =
    data !== undefined ? equipMap?.map((item) => item.equipment_id.toString()) : [];

  //this effect will redirect the user to the view all worklogs page
  useEffect(() => {
    if (createWorklogStatus === true) {
      history.push(student_path.VIEW_WORKLOG_PATH);
    }
  }, [createWorklogStatus]);

  //this will set the value true or false to the showUploadImg contant by which we can show the data of yes or no photo
  useEffect(() => {
    setUpload(
      checkPhotoVal === "true" ? true : checkPhotoVal === "false" ? false : ""
    );
  }, [checkPhotoVal]);

  //this effect will concat the array object of Equipments
  useEffect(() => {
    const equipConcat = equipments.value.concat(arra2);
    setMergedEquipments(
      equipments.value?.length || blankCheckbox
        ? equipments.value
        : equipConcat.filter((item) => item !== undefined)
    );
  }, [equipments.value]);

  //this will count the number of string inside the more equipments array
  const moreEquipLength =
    moreEquipments?.value.length > 0
      ? moreEquipments?.value.filter((item) => item !== "").join("").length
      : "";

  //Addnew supervisor Modal
  const [showSupervisor, setShowSupervisor] = useState(false);

  //these function will who and close the popup screen
  const handleSupervisorClose = () => setShowSupervisor(false);
  const initialValue = {
    first_name: "",
    last_name: "",
    phone: "",
  };
  const [values, setValues] = useState(initialValue);
  const handleSupervisorSubmit = () => {
    dispatch(addSupervisor.request(values));
  };
  const handleAddSupervisor = () => {
    setShowSupervisor(true);
  };

  //after the success of add new supervisor fetch the worklog supervisor api
  useEffect(() => {
    if (addSupervisorStatus) {
      dispatch(fetchWorklogSupervisor.request(tokenData.contact_id));
    }
  }, [addSupervisorStatus]);
  const newArrayForAdditionalEquip = moreEquipments.value.length > 0 ? moreEquipments.value?.map(item => item) : []
  const findDuplicateAdditionalEquip = [];
  const res = newArrayForAdditionalEquip.map(item => item.toLowerCase())
  for (let i = 0; i < res.length; i++) {
    const v = res[i]
    for (let j = i + 1; j < res.length; j++) {
      if (v !== "" && res[j] !== '' && v === res[j]) {
        findDuplicateAdditionalEquip.push(j + 1)
      }
    }
  }
  //handlign the signout of onbehalf login
  const handleOnbehalfLogOut =() =>{
    dispatch(resetOnBehalfSignIn.success());
    removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
    history.push(staff_path.STAFF_DASHBOARD_PATH);
  }

  //loader will be visible when worklog is saved as draft and while fetching the supervisor
  return (loadingSupervisor === true  || worklogCreationReducer.loadingWorklogs===true)? (
    <div className="loader">
      <img src={loadingIcon} alt="loading icon"></img>
    </div>
  ) : (
    <div>
      <div className={`temp ${mainStyles.addExtraPadding}`}>
        {/* Overdue tasks and Current Tasks */}

        {/* Photo Div */}
        <div>
          <div
            id="imageUploadDiv"
            ref={myRef}
            className="newContainer"
            style={{ marginTop: "1em" }}
          >
            <div className="d-flex align-items-center mb-3 ">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                {`Have you got a photo of the ${task.value === "" ? "task name" : task.value.name
                  }?`}
              </p>
            </div>
            {radioButtonPicOptions.map((elem, index) => (
              <CustomRadioButton
                key={index}
                id={elem.label}
                name={elem.name}
                value={elem.value}
                label={elem.label}
                isInline={false}
                checked={checkPhotoVal === elem.value}
                setInputValue={photoUpload.setInputValue}
                onClick={() =>
                  setUpload(
                    (elem.label === "Yes" && true) ||
                    (elem.label === "No" && false)
                  )
                }
                setinputChecked={setinputChecked}
              />
            ))}
            <Collapse in={showUploadImg}>
              <div id="example-collapse-text">
                <UploadImage
                  setImageId={props.setImageId}
                  imageId={props.imageId}
                  files={files}
                  setFiles={setFiles}
                  data={data}
                  photos={photos}
                  setPhotos={setPhotos}
                  mergedPhotoArray={mergedPhotoArray}
                  setMergedPhotoArray={setMergedPhotoArray}
                />
              </div>
            </Collapse>
            <div className={styles.topMargin}></div>
            <Collapse in={showUploadImg}>
              <div>
                <div className="d-flex align-items-center mb-3">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What’s happening in this photo?
                  </p>
                </div>
                <InputComponent
                  //   label=""
                  placeholder="Provide details of photo here"
                  type="text"
                  as="textarea"
                  className="customInputComponent"
                  value={photoHappening?.value}
                  onChange={photoHappening.onChange}
                // value='test'
                />
              </div>
            </Collapse>
            <Collapse in={showUploadImg === "" ? false : !showUploadImg}>
              <div>
                <div className="d-flex align-items-center mb-3">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What was your part in the activity?{" "}
                  </p>
                </div>
                <InputComponent
                  //   label="  "
                  placeholder="Details about your part in Activity"
                  type="text"
                  as="textarea"
                  className="customInputComponent"
                  value={photoPart?.value}
                  onChange={photoPart.onChange}
                // value='test'
                />
              </div>
            </Collapse>

            <Collapse in={showUploadImg}>
              <div>
                <div className="d-flex align-items-center mb-3">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <InputComponent
                  className="customInputComponent"
                  // label="How long did it take?"
                  id="howLongForYes"
                  placeholder="Enter the duration here.."
                  type="text"
                  as="textarea"
                  value={howLong?.value}
                  onChange={howLong.onChange}
                />
              </div>
            </Collapse>
            <Collapse in={showUploadImg === "" ? false : !showUploadImg}>
              <div>
                <div className="d-flex align-items-center mb-3">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <InputComponent
                  className="customInputComponent"
                  // label="How long did it take?"
                  id="howLongForNo"
                  placeholder="Enter the duration here.."
                  type="text"
                  as="textarea"
                  value={howLong?.value}
                  onChange={howLong.onChange}
                />
              </div>
            </Collapse>

            {/* It is the div for the equipments */}

            <div className={styles.marginLeft}>
              <Equipments
                data={data}
                task={task}
                searchEquipment={searchEquipment}
                currentEquipments={currentEquipments}
                setCurrentEquipments={setCurrentEquipments}
                selectEquip={selectEquip}
                setSelectEquip={setSelectEquip}
                equipments={equipments}
                mergedEquipments={mergedEquipments}
                equipMap={equipMap}
                AddMore={AddMore}
                addMore={addMore}
                moreEquipments={moreEquipments}
                setblankCheckbox={setblankCheckbox}
                blankCheckbox={blankCheckbox}
                addMoreEquipCount={addMoreEquipCount}
                setAddMoreEquipCount={setAddMoreEquipCount}
                handleAddEquip={handleAddEquip}
                addMoreEquipInput={addMoreEquipInput}
                setAddMoreEquipInput={setAddMoreEquipInput}
              />
            </div>

            {/* Supervisor Div */}
            <Collapse in={showUploadImg} className="mt-4">
              <div>
                <div className="mb-4" id="supervisorDiv">
                  <div className="d-flex align-items-center mb-3">
                    <BlueDot />
                    <p className="removeExtraBotMargin heading">
                      Which supervisor can verify your activity and photo?
                    </p>
                  </div>
                  {fetchWorkLogSupervisorData?.supervisors?.filter(item=>item.contact_id != null)?.map((elem) => (
                    <Checkbox
                      id={`${elem.first_name} ${elem.last_name}`}
                      options={`${elem.first_name} ${elem.last_name}`}
                      label={`${elem.first_name} ${elem.last_name}`}
                      value={elem.contact_id || dataTask.supervisor_id}
                      dataType="supervisor"
                      onClick={() => handleSupervisorClick(elem.contact_id)}
                      supervisor={supervisor}
                    />
                  ))}
                    {fetchWorkLogSupervisorData?.supervisors?.filter(item=>item.temp_emp_id != null)?.map((elem) => (
                    <Checkbox
                      id={`${elem.first_name} ${elem.last_name}`}
                      options={`${elem.first_name} ${elem.last_name}`}
                      label={`${elem.first_name} ${elem.last_name}`}
                      value={(elem.temp_emp_id || dataTask.temp_emp_id)+'temp'}
                      dataType="supervisor"
                      onClick={() => handleSupervisorClick(elem.temp_emp_id+'temp')}
                      supervisor={supervisor}
                    />
                  ))}
                </div>
                <div
                  className={gloabalStyle.buttonMore}
                  style={{ marginBottom: "20px" }}
                >
                  <CustomButton
                    text="Add Supervisor"
                    showbtn="showbtn"
                    type="blue"
                    width="50%"
                    margin="0px 0px 0px 10px"
                    onClick={() => handleAddSupervisor()}
                    btn="show"
                  />
                </div>
                <AddNewSupervisorModal
                  show={showSupervisor}
                  setShow={setShowSupervisor}
                  handleClose={handleSupervisorClose}
                  handleSupervisorSubmit={handleSupervisorSubmit}
                  values={values}
                  setValues={setValues}
                />
              </div>
            </Collapse>
          </div>
        </div>
      </div>

      <div
          className={
            decodeOnBehalfToken()
              ? `d-flex justify-content-center align-items-center fixedBottomContainer newContainer mt-4 OnBehalfButtonPadding`
              : `d-flex justify-content-center align-items-center fixedBottomContainer newContainer mt-4 ButtonPadding`
          }
        >
          {decodeOnBehalfToken() && (
            <div className={"cancel"} onClick={handleOnbehalfLogOut}>
              Close
            </div>
          )}
        <CustomButton
          image={backIcon}
          text="Back"
          type="transparent"
          width="50%"
          margin="0px 5px 0px 0px"
          onClick={() =>{
            props.setStep({
              num: 2,
              text: "Task",
            }),
            searchEquipment.setInputValue("")
          }
          
          }
        />
        <CustomButton
          text="Save as Draft"
          disabled={findDuplicateAdditionalEquip.length == 0 ? false : true}
          type={data?.status === 1 ? "disabled" : "blue"}
          width="50%"
          image={saveletter}
          disableimg={disableSaveDraft}
          margin="0px 5px 0px 0px"
          onClick={() => {
            handleShow();
          }}
        />

        <CustomModal
          modalFor="saveLater"
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          handleErase={props.saveLater}
        />
        <CustomButton
          disabled={
            showUploadImg === true
              ? mergedPhotoArray.length > 0 &&
                String(photoHappening?.value).trim() !== "" &&
                String(howLong?.value).trim() !== "" &&
                (supervisor?.value !== "" || dataTask?.supervisor_id) &&
                (mergedEquipments.length > 0 || moreEquipLength > 0)
                ? false
                : true
              : String(howLong?.value).trim() !== "" &&
                String(photoPart?.value).trim() !== "" &&
                ((equipments.value.length > 0 ||
                  mergedEquipments.length > 0 ||
                  moreEquipLength > 0) && findDuplicateAdditionalEquip.length == 0
                  ? true
                  : false)
                ? false
                : true
          }
          text="Next"
          image={NextIcon}
          disableimg={disableNextIcon}
          width="50%"
          onClick={() =>{
            props.setStep({
              num: 4,
              text: "Comments",
            }),
          searchEquipment.setInputValue("")
          }
          }
        />
      </div>
    </div>
  );
}

NewWLStep3.propTypes = {
  constructed: propTypes.object,
  errorMessage: propTypes.object,
  setStep: propTypes.func,
  data: propTypes.object,
  dataAccordion1: propTypes.array,
  dataAccordion2: propTypes.array,
  task: propTypes.object,
  executeScroll: propTypes.func,
  radioButtonPicOptions: propTypes.array,
  myRef: propTypes.object,
  showUploadImg: propTypes.bool,
  howLong: propTypes.object,
  photoPart: propTypes.object,
  photoUpload: propTypes.object,
  photoHappening: propTypes.object,
  setUpload: propTypes.func,
  supervisor: propTypes.object,
  supervisors: propTypes.array,
  moreSupervisors: propTypes.object,
  addMore: propTypes.func,
  AddMore: propTypes.func,
  profs: propTypes.object,
  files: propTypes.array,
  setFiles: propTypes.func,
  imageId: propTypes.any,
  setImageId: propTypes.func,
  photos: propTypes.array,
  setPhotos: propTypes.func,
  mergedPhotoArray: propTypes.array,
  setMergedPhotoArray: propTypes.func,
  mergedEquipment: propTypes.array,
  setMergedEquipments: propTypes.func,
  equipments: propTypes.object,
  moreEquipments: propTypes.object,
  saveLater: propTypes.func,
  blankCheckbox: propTypes.bool,
  setblankCheckbox: propTypes.func,
  setinputChecked: propTypes.func,
};

export default NewWLStep3;
