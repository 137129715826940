import { call, put, takeLatest } from "redux-saga/effects";
import { StudentOwnContact } from "../../../services/worklogs/studentOwnContact.service";
import {
    STUDENT_OWN_CONTACTS,
  studentowncontacts,
} from "../../../store/actions/student/studentOwnContact.action";

function* handleStudentOwnContacts({ payload }) {
  try {
    const { data } = yield call(StudentOwnContact.getcontacts, payload);
    yield put(studentowncontacts.success(data));
  } catch (e) {
    yield put(studentowncontacts.failure(e.data));
  }
}

function* getStudentOwnContactsSaga() {
  yield takeLatest(STUDENT_OWN_CONTACTS.REQUEST, handleStudentOwnContacts);
}

export default getStudentOwnContactsSaga;
