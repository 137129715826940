import { call, put, takeLatest } from "redux-saga/effects";
import { signupApi } from "../../../services/anonymous/signup.service";
import {
  SIGNUP_USER,
  signupUserAction,
} from "../../../store/actions/user/signup.user.action";

function* handleSignup({ payload }) {
  //onsole.log("iNNN Signup sAGAAAAAA");
  try {


    const { data } = yield call(signupApi.signup, payload);
    yield put(
      signupUserAction.success({
        status: data.status,
        message: data.message,
        user: data.user,
      })
    );

    // saveAuthToken(data.token, false);
  } catch (e) {
    yield put(signupUserAction.failure(e.data));
  }
}

function* signupSaga() {
  yield takeLatest(SIGNUP_USER.REQUEST, handleSignup);
}

export default signupSaga;
