import React from "react";
import WorklogDetailsForUnitCard from "../WorklogDetailsForUnitCard/index";
import styles from "./styles.module.scss";
import logo from "../../../../assets/images/logo.png";
import left from "../../../../assets/images/leftArrow.png";
import { Navbar } from "react-bootstrap";
import {  useHistory } from "react-router-dom";
import loadingIcon from "../../../../assets/images/blue-Loading-spinner.gif"
import {  useSelector } from "react-redux";
import { student_path } from "../../../../Constants/paths/student";
import propTypes from 'prop-types';

const WorklogDetailsForUnitMobile = (props) => {
  const { newData } = props;
  const history = useHistory();
  
  const fetchWorkLogsData = useSelector((state) => state.worklog.fetchworklogs);
  const loading  = fetchWorkLogsData.loadingWorklogs
  const deleteStatus = useSelector(
    (state) => state.worklog.deleteWorklogReducer?.loadingWorklog
  );


  //To go back the Previous page
  const goToPreviousPath = () => {
    history.push(student_path.STUDENT_DASHBOARD_PATH);
  };

  return (
    <div className={`${styles.worklogContainer}`}>
    <div className={`${styles.Nav}`}>
      <Navbar bg="white" className={`${styles.nav} `}>
        <img
          src={left}
          className={`${styles.leftImg}`}
          onClick={goToPreviousPath}
          alt="Left arrow icon"
        />
        <div className={`${styles.activity}`}>Activities</div>
        <img src={logo} className={`${styles.logoImg}`} alt="CTI logo"/>
      </Navbar>
      <div
        className="subarea"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          backgroundColor: "#F3F3F3",
          borderTop: "1px solid #E1E1E1",
          borderBottom: "1px solid #E1E1E1",
          marginBottom: "10px",

          fontWeight: "500",
        }}
      >
      </div>
        </div>
      {loading || deleteStatus ? <div className={`${styles.loader}`}><img src={loadingIcon} alt="loading icon"></img></div> :
      <div className={`${styles.tubularContainer}`} onLoad={window.scrollTo(0, 0)}>
      {newData.worklogs.length > 0 ?  newData?.worklogs?.map((elem) => (
        <WorklogDetailsForUnitCard key={elem.id} data={elem} />
      )): <></>}
      </div>}
    </div>
  );
};

WorklogDetailsForUnitMobile.propTypes = {
  newData: propTypes.object,
};


export default WorklogDetailsForUnitMobile;
