import { createRequestTypes, action } from "../../../utils/redux";

export const REJECTION_SIGN = createRequestTypes("REJECTION_SIGN");

export const rejectionSign = {
  request: (data) => action(REJECTION_SIGN.REQUEST,data),
  success: (data) => action(REJECTION_SIGN.SUCCESS, data),
  failure: (error) => action(REJECTION_SIGN.FAILURE, error),
};
export const SHOW_SIGN = "SHOW_SIGN";

export const showSign = (data) => action(SHOW_SIGN, data);

export const REJECT_CLICK = "REJECT_CLICK";

export const rejectClick = (data) => action(REJECT_CLICK, data);