import React from "react";
import "./index.layout.scss";
import propTypes from 'prop-types';

// This is for authorised routes
const Auth = (props) => {

  
  return (
    <div
      className={`col-md-12 login-form-container no-gutters bg-image ${
        props.loading ? "disabled" : ""
      }`}
      disabled
    >
      {/* <button className='btn btn-danger' onClick={handleSignout}>Sign Out</button> */}
      {props.children}
    </div>
  );
};

Auth.propTypes = {
  children: propTypes.string,
  loading: propTypes.bool,
};

//export default requireAuthentication(Auth)
export default Auth;
