import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchWorklogTasks } from "../../store/actions/worklogs/fetchWorklogTasks.worklogs.action";
import { fetchWorklogLessons } from "../../store/actions/worklogs/fetchWorklogLessons.worklogs.action";
import { fetchLocally } from "../../utils/localStorage";
import StudentDasboard from "../../components/Dashboard/StudentDashboard";
import { Container } from "react-bootstrap";
import { fetchAllWorkshops } from "../../store/actions/worklogs/fetchAllWorkshops.action";
import { studentCurrentPage } from "../../store/actions/student/student.curerntPage.action";
import { studentTheoryAssessments } from "store/actions/student/studentTheoryAssessments.action";
const Dashboard = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();


  //setting the current page value
dispatch(studentCurrentPage.success("home"))

  //fetching and decoding the token from localstore
  const token = fetchLocally("login_credentials");
  const decoded = jwt_decode(token);
  const contactID = decoded.contact_id;

  //fetching the data of confirm lessons reducer
  const confimLessonStatus = useSelector(
    (state) => state.worklog.lessonConfimationReducer.lessonConfirmationStatus
  );

  //call the api when first render happens to this page
  useEffect(() => {
    dispatch(fetchWorklogTasks.request(contactID));
    dispatch(fetchWorklogLessons.request(contactID));
    dispatch(fetchAllWorkshops.request());
    dispatch(studentTheoryAssessments.request());
  }, []);

  useEffect(() => {
    //call this api when the status of lesson cofimation status changes
    dispatch(fetchWorklogLessons.request(contactID));
  }, [confimLessonStatus]);

  return (
    <div className={styles.mainContainer}>
      <Container className={`pt-3 ${styles.containerEmployer}`}>
        <StudentDasboard />
      </Container>
    </div>
  );
};

export default Dashboard;
