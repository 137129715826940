import React from "react";
import EmDashboardHeader from "../../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import EmFooter from "../../../components/EmployerDashboard/EmFooter";
import { trainer_path } from "../../../Constants/paths/trainer";
import TrainerStudents from "../../../components/Trainer/TrainerStudents/TrainerStudents";
import { useDispatch } from "react-redux";
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { isSupervisor } from "utils/helper/Student";
import { supervisor_path } from "Constants/paths/supervisor";


const TrainerApprenticePage  = () =>{
const dispatch = useDispatch();
//this action will set the current page to apprentice
dispatch(studentCurrentPage.success("apprentice"))


return (
    <>
{/* this is the header component of Trainer */}
    <EmDashboardHeader />

{/* this component will have the list of students available for trainer to sign */}
<TrainerStudents />

    {/* this is the footer for the trainer */}
<EmFooter
        homePath={isSupervisor()?supervisor_path.SUPERVISOR_DASHBOARD_PATH: trainer_path.TRAINER_DASHBOARD_PATH}
        apprenticePath={isSupervisor()?supervisor_path.SUPERVISOR_APPRENTICE_PATH:trainer_path.TRAINER_APPRENTICE_PATH}
        setting={isSupervisor()?supervisor_path.SUPERVISOR_VIEW_SETTING_PATH:trainer_path.TRAINER_VIEW_SETTING_PATH}
        active={true}
      />
    </>
    )
}

export default TrainerApprenticePage;