import { call, put, takeLatest } from "redux-saga/effects";
import { changePasswordApi } from "../../../services/profile/changePassword";
import { changePassword,CHANGE_PASSWORD } from "../../../store/actions/user/changePassword.user.action";


function* handleChangePassword( action ) {
  
  try {
    const { data} = yield call(changePasswordApi.changePassword, action.payload);
      yield put(changePassword.success({
        status:data?.status,
        statusCode:data?.status_code,
        data:data?.data,
        message:data?.message
      }));

  
  } catch (e) {
    const {data} = e;
    yield put(changePassword.failure({
      status:data?.status,
      statusCode:data?.status_code,
      data:data?.data,
      message:data?.message
    
}));
  }
}

function* changePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD.REQUEST, handleChangePassword);
}

export default changePasswordSaga;
