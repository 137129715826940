import React from 'react'
import styles from '../styles.module.scss';
import  { bool, func } from "prop-types";

const PrevUpButton = (props) => {
  //Reusable button component for the Home page
  return (
    <div className={styles.labelBox}>
    <div className={`${props.activestate ? styles.labelUpcomingactive : styles.labelUpcoming }`}     
     onClick={props.onUpcomingClick}>Upcoming Workshops
    </div>
    <div className={`${!props.activestate ? styles.labelPreviousactive : styles.labelPrevious}`}
      onClick={props.onPrevClick}>Previous Workshops
    </div>
  </div>
  )
}


PrevUpButton.propTypes = {
onUpcomingClick:func,
onPrevClick:func,
activestate:bool
  


};

export default PrevUpButton