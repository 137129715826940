export const dummyData = {
  worklogMainTable: {
    CourseName: "Certificate III in Carpentry",
    CourseCode: "CPC30211",
    data: [
      {
        UnitScope: "UC0001001-WL",
        StartDate: "05/01/2021",
        EndDate: "31/31/2021",
        status: "In Progress",
      },
      {
        UnitScope: "UC0001003-WL",
        StartDate: "06/01/2021",
        EndDate: "30/30/2021",
        status: "In Progress",
      },
      {
        UnitScope: "UC0001004-WL",
        StartDate: "07/01/2021",
        EndDate: "07/31/2021",
        status: "Completed",
      },
    ],
  },

  worklogDetailsForUnit: [
    {
      _id: 1,
      SrNo: 1,
      worklogDetails: "WL Details.... ....",
      status: "0",
      date: "15/3/2021",
    },
    {
      _id: 2,
      SrNo: 2,
      worklogDetails: "WL Details.... ....",
      status: "1",
      date: "16/3/2021",
    },
    {
      _id: 3,
      SrNo: 3,
      worklogDetails: "WL Details.... ....",
      status: "0",
      date: "14/3/2021",
    },
    {
      _id: 4,
      SrNo: 4,
      worklogDetails: "WL Details.... ....",
      status: "1",
      date: "18/3/2021",
    },
    {
      _id: 5,
      SrNo: 5,
      worklogDetails: "WL Details.... ....",
      status: "1",
      date: "20/3/2021",
    },
    {
      _id: 6,
      SrNo: 6,
      worklogDetails: "WL Details.... ....",
      status: "0",
      date: "22/3/2021",
    },
  ],

  radioButtonPicOptions: [
    {
      label: "Yes",
      name: "selectPic",
      value: "true",
      dataToggle: "collapse",
    },
    {
      label: "No",
      name: "selectPic",
      value: "false",
    },
  ],

  toolsData: [
    "Air compressors and hoses",
    "Allen keys",
    "Automatic level and levelling staff",
    "Bevels",
    "Bolt cutters",
    "Broad knives",
    "Brooms",
    "Bull floats",
  ],

  supervisorsData: [
    "John Smith",
    "John Howard",
    "Donald Trump",
    "My Trainer – Grant King",
    "Self Verify – No one else can",
  ],

  dataAccordion1: [
    "Identify asbestos",
    "Communicate and work safely with others ",
    "Respond to a hazard",
    "Use firefighting equipment ",
  ],

  dataAccordion2: [
    "Plan and prepare for tasks",
    "Identify workplace signage",
    "Respond to a hazard",
    "Direct on-site construction traffic",
  ],
};
