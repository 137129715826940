import React from "react";
import InputComponent from "../Input";
import "./checkbox.scss";
import { useState } from "react";
import { useEffect } from "react";
import propTypes from 'prop-types';
const Checkbox = (props) => {
/* eslint-disable */
const values = props?.moreEquipments?.value
  //Checkbox value is checked when the focus on input
  const [activeCheckBox, setactiveCheckBox] = useState(false);
  function focusIn() {
     setactiveCheckBox(true);
  }

  //If the value of the input is exist than checkbox checked otherwise unchecked
  function focusOut(){
    var input = document.getElementById(`${props.dataType}-${props.label}`);
    if(input.value.length > 0){
     setactiveCheckBox(true);
    }
    else{
      setactiveCheckBox(false);
    }
  }

  function clearInputField(){
    document.getElementById(`${props.dataType}-${props.label}`).value="";
    props.moreEquipments.setInputValue("")
  }

  //If the value is exist in the input value than no change in checkbox on render time
  useEffect(() => {
    props.value ? setactiveCheckBox(true) : setactiveCheckBox(false);
  }, []);

  //Change the input value if the file is save as draft
  function handleInternalClick(e) {
if(props.placeholder==="Add Equipment"){
  if(activeCheckBox){
    setactiveCheckBox(false);
    clearInputField()
    document.getElementById(props.dataType).value="";
  }else{
    document.getElementById(props.dataType).focus();
  }
}else

    if (props.array?.value) {
      let array = props.array.value;
      var index = array.indexOf(e.target.value);
      if (e.target.checked) {
        props.setInputValue([...props.array.value, e.target.value]);
      } else {
        index = array.indexOf(e.target.value);
        if (index !== -1) {
          props.setInputValue(array);
        }
      }
    }
  }
 
  //if the file is save as draft than help of this we add more checkbox value
  const handletoggleClick = (e) => {
    if (props.array) {
      const selectedCheckboxes = [...props.array];
      const isChecked = selectedCheckboxes.includes(e.target.value);
      if (!isChecked) {
        selectedCheckboxes.push(e.target.value);
      }
      else {
        selectedCheckboxes.splice(selectedCheckboxes.indexOf(e.target.value), 1);
      }
      props.setInputValue(selectedCheckboxes);
      props.setblankCheckbox(true);
    }
  }
  
  let inline;
  if (props.isInline) {
    inline = "d-inline-block";
  } else {
    inline = "d-flex";
  }
  return (
    <div className={`checkbox-class ${inline}
     ${props.label ==="" ? 'checkbox-margin' : "" }
     `}>
      {props.dataType === "tool" ?
      <>
        {props.label === "" ? <></> :
            <input
              type="checkbox"
              id={`${props.dataType}-${props.label}`}
              value={props.value}
              // onChange={props.onChange}
              onChange={handletoggleClick}
              checked={props.checked}
            />}
        </>
        :<>
          {props.label === "other" ?
          //this check box is for add more equipment
           <input
           className={props.className}
           name={props.name}
           type="checkbox"
           id={`${props.dataType}-${props.label}`}
           value={props.value}
           onChange={props.onChange}
           onClick={handleInternalClick}
           checked={activeCheckBox}
         /> :
            <input
              type="checkbox"
              id={`${props.dataType}-${props.label}`}
              value={props.value}
              onChange={props.onChange}
              onClick={props.dataType==="supervisor" ? props.onClick : handleInternalClick}
              checked={props.dataType==="supervisor" ? props.value === props.supervisor.value: props.checked}
            />}
        </>}
      {props.label === "other" ? (
        <label for={`${props.dataType}-${props.label}`}>
          <InputComponent
            // className="customInputComponent"
            name={props.name}
            for={`${props.dataType}-${props.label}`}
            onFocus={focusIn}
            onBlur={props.placeholder !=="Add a Equipment" && focusOut}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            type="text"
            id={props.dataType}
            className={props.class}
            values={values}
          />
        </label>
        
      ) : (
        <label htmlFor={`${props.dataType}-${props.label}`}>

          {props.label}

        </label>
      )}
      {props.duplicate && <span>This is duplicate equipment</span>}
    </div>
  );
};



Checkbox.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  key: propTypes.number,
  options: propTypes.string,
  value: propTypes.any,
  label: propTypes.string,
  dataType: propTypes.string,
  setInputValue: propTypes.func,
  array: propTypes.array,
  data: propTypes.array,
  checked: propTypes.bool,
  isInline: propTypes.bool,
  setblankCheckbox: propTypes.func,
  onChange: propTypes.func,
  onClick: propTypes.func,
  placeholder: propTypes.string,
}

export default Checkbox;
