import ProfileFooter from "../../../../components/Setting/ProfileFooter";
import ProfileInput from "../../../../components/Setting/ProfileInput";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../store/actions/user/changePassword.user.action";
import Overlay from "../../../../components/common/Overlay";
import { employer_path } from "../../../../Constants/paths/employer";
import { student_path } from "../../../../Constants/paths/student";
import { fetchProfile } from "../../../../store/actions/user/profile.user.actions";
import { resetChangePassword } from "../../../../store/actions/user/changePassword.user.action";
import { trainer_path } from "../../../../Constants/paths/trainer";
import { staff_path } from "../../../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";
import { isTempLogin } from "utils/helper/Student";
import HeaderLabel from "components/common/HeaderLabel";
import EmDashboardHeader from "components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";

const ChangePasswordPage = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const changePasswordData = useSelector((state) => state.user?.changePassword);
  const loadingsuccess = changePasswordData?.changePasswordSuccess;
  const errorcurrentpassword = changePasswordData?.message;
  const changePasswordFail = changePasswordData?.changePasswordFail;
  const dispatch = useDispatch();
  const history = useHistory();
  const initialValue = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const profileReducer = useSelector(
    (state) => state.user.fetchProfileUserReducer
  );
  const UserType = profileReducer?.profile?.type;
  const [values, setValues] = useState(initialValue);
  const [matchError, setmatchError] = useState("");
  const [gopath, setgopath] = useState(false);
  const [inactivematchError, setinactivematchError] = useState();
  const [allinput, setallinput] = useState(false);
  useEffect(() => {
    dispatch(resetChangePassword.success());
    dispatch(fetchProfile.request());
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setinactivematchError(false);
    dispatch(resetChangePassword.success());
  };
  const updateddata = {
    current_password: values.current_password,
    new_password: values.new_password,
    is_temp_login:isTempLogin() 
  };

  const handleSave = () => {
    if (values.new_password !== values.confirm_password) {
      setmatchError("New Password and Confirm Password does not match");
      setinactivematchError(true);
    } else {
      dispatch(changePassword.request(updateddata));
      setgopath(true);
    }
  };

  const handleDashboard = () => {
    if (UserType === "E") {
      history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
    } else if (UserType === "S") {
      history.push(student_path.STUDENT_DASHBOARD_PATH);
    } else if(UserType === "T"){
      history.push(trainer_path.TRAINER_DASHBOARD_PATH)
    }else if(UserType === "C"){
      history.push(staff_path.STAFF_DASHBOARD_PATH)
    } else if(UserType === "D"){
      history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
    }
    setgopath(false);
  };

  useEffect(() => {
    if (
     (isTempLogin() && values?.new_password.length >= 6 && values?.confirm_password.length >= 6) || 
     ( values?.confirm_password.length >= 6 && values?.new_password.length >= 6 && values?.current_password.length >= 6)
    ) {
      setallinput(true);
    } else {
      setallinput(false);
    }
  }, [values, loadingsuccess]);

  const handleCross = () => {
    setgopath(false);
    setValues(initialValue);
  };

  //css for header label
const style = { margin: "66px -16px 0px 0px", width: "120%", position: "static" };
  return (
    <>
      <div className={styles.mainContainer} >
      <EmDashboardHeader isImg={true} />
        <HeaderLabel text="Reset Password" style={style} />
        <div className={styles.main}>
          {loadingsuccess && gopath ? (
            <Overlay
              onClick={handleDashboard}
              text="Reset Password Successfully"
              title="Password Reset"
              handleCross={handleCross}
            />
          ) : (
            <></>
          )}
          {!isTempLogin() &&<ProfileInput
            label="Current Password"
            name="current_password"
            type="password"
            placeholder="Enter current Password"
            value={values.current_password}
            onChange={handleChange}
            id="current_password"
            errorMessage={
              errorcurrentpassword === "Incorrect password." &&
              changePasswordFail
                ? errorcurrentpassword
                : ""
            }
          />}
          <ProfileInput
            label="New Password"
            name="new_password"
            type="password"
            placeholder="Password must be at least 6 digit"
            value={values.new_password}
            onChange={handleChange}
            id="new_password"
          />
          <ProfileInput
            label="Confirm Password"
            name="confirm_password"
            type="password"
            placeholder="Password must be at least 6 digit"
            value={values.confirm_password}
            onChange={handleChange}
            id="confirm_password"
            errorMessage={
              inactivematchError && matchError !== "" ? matchError : ""
            }
          />
        </div>
        <ProfileFooter
          disable={false}
          onClick={handleSave}
          disabled={allinput}
        />
      </div>
    </>
  );
};

export default ChangePasswordPage;
