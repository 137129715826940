import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import {
    isAuthenticated, isAuthenticatedPin, isPinAuthSkip, isLoginPinActive, isStudent, isEmployer, isTrainer, isStaff, isSupervisor, isTempLogin
} from '../../utils/helper/Student/index'


const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        
       <Route
        {...rest}
        
        render={
            props => isAuthenticated() && (isAuthenticatedPin() || isPinAuthSkip() || isLoginPinActive() || isTempLogin()) && (isStudent() || isEmployer()|| isTrainer() || isStaff() || isSupervisor()) ? (
                <Component {...props} />
            ):(
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location}
                    }}
                />
            )
        }
       
       />
    )
}

export default ProtectedRoute;