import React, { useState, useRef } from "react";
import OtherPageHeader from "../../../../layouts/NewHeader/OtherPageHeader/index";
import SubHeader from "../../../../layouts/NewHeader/SubHeader/index";
import styles from "./styles.module.scss";
import NewWLStep1 from "../../../../components/NewWorklogComponents/Step1/index";
import NewWLStep2 from "../../../../components/NewWorklogComponents/Step2/index";
import NewWLStep3 from "../../../../components/NewWorklogComponents/Step3/index";
import NewWLStep4 from "../../../../components/NewWorklogComponents/Step4/index";
import { dummyData } from "../../../../utils/helper/common/dummyData";
import Checkbox from "../../../../components/common/Checkbox";
import { useHistory, useParams } from "react-router-dom";
import NewWLStep5 from "../../../../components/NewWorklogComponents/Step5/index.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchWorklogDetail } from "../../../../store/actions/worklogs/fetchWorklogDetail.worklogs.action";
import { createWorklogs } from "../../../../store/actions/worklogs/createWorklogs.worklogs.actions";
import { clearEquipment } from "../../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";

import compress from "compress-base64";
import { student_path } from "Constants/paths/student";
import heic2any from "heic2any";

function NewWorkLog(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  let { id } = useParams();

  const dispatch = useDispatch();

  //fetching the data from the reducer
  const fetchWorkLogdetailData = useSelector(
    (state) => id && state.worklog.fetchWorklogDetailReducer
  );

  //these constants will have the value of loadin and error for fetch worklog details
  const loadingWorklogDetail = fetchWorkLogdetailData?.loadingWorklog;
  const fetchWorklogError = fetchWorkLogdetailData?.worklogFetchError;

  const fetchWorkLogEquipmentData = useSelector(
    (state) => state.worklog.fetchWorklogEquipmentReducer
  );

  const user = useSelector((state) => state?.user?.loginWithPin?.user);
  const fetchWorkLogsData = useSelector((state) => state.worklog.fetchworklogs);

  useEffect(() => {
    id && dispatch(fetchWorklogDetail.request(id));
  }, []);
  const useFormInputs = (initialValue, field) => {
    //First we will do useState and define the value and setValue
    const [value, setValue] = useState(initialValue);

    //set the value directly according to the input
    function setInputValue(input) {
      setValue(input);
    }
    //This function will set the value of the state of the field email/password etc
    function handleChange(e) {
      setValue(e?.target?.value);

      if (!!errorMessage[field])
        setErrorMessage({
          ...errorMessage,
          [field]: "",
        });
    }

    //cler the value
    function clearInput() {
      setValue("");
    }

    //Finally we return the value(state's data) and the handleChange - the function that will setState of our property
    return {
      value,
      onChange: handleChange,
      clearInput: clearInput,
      setInputValue: setInputValue,
    };
  };

  function addMore(elem, value) {
    //   var currentDiv;
    if (value === "") {
      return;
    }

    let newTools, newSupervisors;
    if (elem === "tool") {
      //tools.indexOf(value) === -1 -- dont accept duplicate tool
      newTools = tools.concat(value);
      setTools(newTools);
      equipments.setInputValue([...equipments.value, value]);
      moreTools.clearInput();
    } else if (elem === "supervisor") {
      newSupervisors = supervisors.concat(value);
      setSupervisors(newSupervisors);
      profs.setInputValue([...profs.value, value]);
      moreSupervisors.clearInput();
    }

    var uncheck = document.getElementById(`${elem}-other`);
    uncheck.checked = false;
    setTimeout(function () {
      var check = document.getElementById(elem + "-" + value);
      check.checked = true;
    }, 50);

    // uncheck.checked = true;
  }

  const constructed = useFormInputs("", "constructed");
  const workPlace = useFormInputs("", "workPlace");
  const [step, setStep] = useState({
    num: 1,
    text: "Details",
  });
  const [errorMessage, setErrorMessage] = useState({
    workPlace: "", //"Please enter some value here",
    constructed: "", //"Please select atleast one option",
  });

  //these are the useState hooks constant
  const [showUploadImg, setUpload] = useState("");
  const [imageId, setImageId] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);
  const [supervisors, setSupervisors] = useState(dummyData.supervisorsData);
  const [tools, setTools] = useState(dummyData.toolsData);
  const [mergedPhotoArray, setMergedPhotoArray] = useState([]);
  const [mergedEquipments, setMergedEquipments] = useState([]);
  const [currentEquipments, setCurrentEquipments] = useState([]);
  const [selectEquip, setSelectEquip] = useState([]);
  const [blankCheckbox, setblankCheckbox] = useState(false);
  const [constructedSelectedValue, setConstructedSelectedValue] = useState({
    id: null,
  });
  const [overdueTask, setOverDueTask] = useState([]);
  const [currentTask, setCurretTask] = useState([]);
  const [futureTask, setFutureTask] = useState([]);
  const [completedTask, setCompletedTask] = useState([]);
  const [inputChecked, setinputChecked] = useState();

  //these are the useformInputs const
  const supervisor = useFormInputs("");
  const equipments = useFormInputs([]);
  const moreTools = useFormInputs("");
  const moreEquipments = useFormInputs([]);
  const moreSupervisors = useFormInputs("");
  const photoUpload = useFormInputs("");
  const photoHappening = useFormInputs("");
  const photoPart = useFormInputs("");
  const howLong = useFormInputs("");
  //this state will manage the search input of equipment
  const searchEquipment = useFormInputs("");
  const searchTasks = useFormInputs("")
  const profs = useFormInputs("");
  const otherComment = useFormInputs("");
  const overallComment = useFormInputs("");
  const dataAccordion1 = dummyData.dataAccordion1;
  const dataAccordion2 = dummyData.dataAccordion2;
  const task = useFormInputs("");
  const current = useFormInputs("");
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  const history = useHistory();

  const goToPreviousPath = () => {
    // history.goBack();
    setStep({
      num: 4,
      text: "Tasks",
    });
  };

  //for add more Equip buttons functaionlity
  const [addMoreEquipCount, setAddMoreEquipCount] = useState(0);
  function handleAddEquip() {
    setAddMoreEquipCount(addMoreEquipCount + 1);
  }
  const [addMoreEquipInput, setAddMoreEquipInput] = useState({});
  //save button when the data is fullfill
  useEffect(() => {}, [addMoreEquipInput]);
  var elem = document.getElementsByClassName("webcampics");
  var names = [];
  for (var i = 0; i < elem.length; i++) {
    if (typeof elem[i].value !== "undefined") {
      names.push(elem[i].value.trim());
    }
  }

  useEffect(() => {
    moreEquipments.setInputValue([]);
    moreEquipments.setInputValue(names);
  }, [addMoreEquipInput]);

  //refresh page error handler
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to refresh the page ? All worklog data will be lost.";
    e.returnValue = message;
  };
  //this effect will set the initial state value to the value which is coming from the reducer
  useEffect(() => {
    fetchWorkLogdetailData?.workingDetails === ""
      ? workPlace.setInputValue("")
      : workPlace.setInputValue(fetchWorkLogdetailData?.workingDetails);
    fetchWorkLogdetailData?.otherComments === ""
      ? otherComment.setInputValue("")
      : otherComment.setInputValue(fetchWorkLogdetailData?.otherComments);
    fetchWorkLogdetailData?.overallComments === ""
      ? overallComment.setInputValue("")
      : overallComment.setInputValue(fetchWorkLogdetailData?.overallComments);
    fetchWorkLogdetailData?.constructionDetails === ""
      ? constructed.setInputValue("")
      : constructed.setInputValue(fetchWorkLogdetailData?.constructionDetails);
    photoPart.setInputValue(
      fetchWorkLogdetailData?.worklog?.task[0]?.no_photo !== undefined
        ? fetchWorkLogdetailData?.worklog?.task[0]?.no_photo[0].part
        : ""
    );
    photoHappening.setInputValue(
      fetchWorkLogdetailData?.worklog?.task[0]?.photos !== undefined
        ? fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].photo_happening
        : ""
    );

    if (fetchWorkLogdetailData?.worklog?.task[0]?.photos !== undefined) {
      howLong.setInputValue(
        fetchWorkLogdetailData?.worklog?.task[0]
          ? fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].how_long
          : ""
      );
      supervisor.setInputValue(
        fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].supervisor_id
          ? fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].supervisor_id
          : fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].temp_emp_id
          ? fetchWorkLogdetailData?.worklog?.task[0]?.photos[0].temp_emp_id +
            "temp"
          : ""
      );
    } else {
      howLong.setInputValue(
        fetchWorkLogdetailData?.worklog?.task[0]?.no_photo !== undefined
          ? fetchWorkLogdetailData?.worklog?.task[0]?.no_photo[0].how_long
          : ""
      );
    }
  }, [fetchWorkLogdetailData?.worklog?.id, loadingWorklogDetail]);

  const convertHeicToJPEG = async (file) => {
    let blobURL = URL.createObjectURL(file);

    // convert "fetch" the new blob url
    let blobRes = await fetch(blobURL);

    // convert response to blob
    let blob = await blobRes.blob();

    // convert to PNG - response is blob
    let conversionResult = await heic2any({ blob });

    // convert to blob url
    var reader = new FileReader();
    reader.readAsDataURL(conversionResult);
    var base64data;
    reader.onloadend = function () {
      base64data = reader.result;
      var image = new Image();
      image.src = base64data;
      image.onload = function () {
        var kbLength =
          parseInt(image.src.replace(/=/g, "").length * 0.75) / 1024;
        //this will check if the image is greater than 500 then compress it otherwise donot compress
        if (kbLength > 500) {
          compress(reader.result, {
            width: 500,
            type: "image/png", // default
            max: 2000, // max size
            min: 2, // min size
            quality: 0.9,
          }).then((result) => {
            if(!photos.includes(result)){
              setPhotos((state) => {
                return [...state, result];
              });
            }

          });
        } else {
          //if the file size is less than 500kb
          if(!photos.includes(reader.result)){
          setPhotos((state) => {
            return [...state, reader.result];
          });
        }
        }
      };
    };
  };
  const normalImageUpload = async (file) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var kbLength =
          parseInt(image.src.replace(/=/g, "").length * 0.75) / 1024;
        //this will check if the image is greater than 500 then compress it otherwise donot compress
        if (kbLength > 500) {
          compress(reader.result, {
            width: 500,
            type: "image/png", // default
            max: 2000, // max size
            min: 2, // min size
            quality: 0.9,
          }).then((result) => {
            if(!photos.includes(result)){
              setPhotos((state) => {
                return [...state, result];
              });
            }

          });
        } else {
          //if the file size is less than 500kb
          if(!photos.includes(reader.result)){
            setPhotos((state) => {
              return [...state, reader.result];
            });
          }
        }
      };
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    files.forEach((file) => {
      if (file.name.includes(".heic")) {
        convertHeicToJPEG(file);
      } else {
        normalImageUpload(file);
      }
    });
  }, [files]);

  //this effect will set the task related array to its initial value whcih is coming from the reducer
  useEffect(() => {
    const uploadVal = fetchWorkLogdetailData?.worklog?.task[0]?.is_photo;
    setUpload(uploadVal === undefined ? "" : uploadVal ? true : false);
  }, [fetchWorkLogdetailData?.worklog?.task[0]]);

  useEffect(() => {
    const redEquip =
      fetchWorkLogdetailData?.worklog?.task[0]?.equipments !== undefined
        ? fetchWorkLogdetailData?.worklog?.task[0]?.equipments
        : [];
    setCurrentEquipments(redEquip);
    const modifiedEquip =
      redEquip.length > 0 && redEquip.map((item) => item.equipment_id);
    setMergedEquipments(modifiedEquip);
  }, [fetchWorkLogdetailData?.worklog?.task[0]?.equipments]);

  //this payload is used when user clicked on save as draft
  const submitForm = () => {
    // const equip=equipments?.value;
    let ids = [];

    let allImages = mergedPhotoArray;
    let imageData = [];

    allImages.forEach((file) => {
      imageData.push(
        supervisor?.value?.toString()?.includes("temp")
          ? {
              photo: file,
              photo_happening_desc: photoHappening?.value,
              photo_activity_time_spent: howLong?.value,
              temp_emp_id: +supervisor?.value.replace("temp", ""),
            }
          : {
              photo: file,
              photo_happening_desc: photoHappening?.value,
              photo_activity_time_spent: howLong?.value,
              supervisor_id: +supervisor?.value,
            }
      );
    });

    equipments.value.map((item) =>
      ids.push({
        equipment_id: item,
      })
    );

    let mergedEquipArray = [];
    mergedEquipments.map((item) => {
      return mergedEquipArray.push({
        equipment_id: item,
      });
    });

    //this is the payload object to the save worklog api
    const apiData = {
      step1: {
        working_details: workPlace?.value === undefined ? "" : workPlace?.value,
        construction_details:
          constructed?.value === undefined ? "" : constructed?.value.trim(),
        worklog_id:
          fetchWorkLogdetailData === undefined
            ? fetchWorkLogsData.id
            : fetchWorkLogdetailData.worklog.id,
      },

      step2: {
        task_id: task?.value?.task_id
          ? task?.value?.task_id
          : fetchWorkLogdetailData?.worklog?.task[0]
          ? fetchWorkLogdetailData?.worklog?.task[0].task_id
          : "",
        worklog_equipments: ids.length > 0 ? ids : mergedEquipArray,

        additional_equipments:
          moreEquipments?.value.length > 0
            ? moreEquipments.value
                ?.filter((item) => item !== "")
                ?.map((item) => {
                  const capitalizedTool = item
                    .split(" ")
                    .map((word) => {
                      const lower = word.toLowerCase();
                      return lower[0].toUpperCase() + lower.substring(1);
                    })
                    .join(" ");
                  return {
                    equipment_name: capitalizedTool,
                  };
                })
            : [],
      },

      step3: {
        photo_has: {
          photo: showUploadImg === true ? imageData : [],
        },

        photo_not: {
          nophoto_activity_details:
            showUploadImg === false
              ? photoPart?.value !== ""
                ? photoPart?.value
                : fetchWorkLogdetailData?.taskDetail[0]?.no_photo !== undefined
                ? fetchWorkLogdetailData?.taskDetail[0]?.no_photo[0]?.part
                : ""
              : "",
          nophoto_activity_time_spent:
            showUploadImg === false
              ? howLong?.value !== ""
                ? howLong?.value
                : fetchWorkLogdetailData?.taskDetail[0]?.no_photo !== undefined
                ? fetchWorkLogdetailData?.taskDetail[0]?.no_photo[0]?.how_long
                : ""
              : "",
        },
      },

      step4: {
        other_comments:
          otherComment?.value === undefined ? "" : otherComment?.value,
        overall_comments:
          overallComment?.value === undefined ? "" : overallComment?.value,
        status: 1,
      },
    };

    showUploadImg === true
      ? delete apiData.step3.photo_not
      : delete apiData.step3.photo_has;
    moreEquipments.value === "" && delete apiData.step2.additional_equipments;

    dispatch(createWorklogs.request(apiData));

    setTimeout(() => {
      dispatch(clearEquipment.request());
    }, 1000);
  };

  const saveLater = () => {
    let ids = [];

    let allImages = mergedPhotoArray;
    let imageData = [];

    allImages.forEach((file) => {
      imageData.push(
        supervisor?.value?.toString()?.includes("temp")
          ? {
              photo: file,
              photo_happening_desc: photoHappening?.value,
              photo_activity_time_spent: howLong?.value,
              temp_emp_id: +supervisor?.value.replace("temp", ""),
            }
          : {
              photo: file,
              photo_happening_desc: photoHappening?.value,
              photo_activity_time_spent: howLong?.value,
              supervisor_id: +supervisor?.value,
            }
      );
    });

    equipments.value.map((item) =>
      ids.push({
        equipment_id: item,
      })
    );

    let mergedEquipArray = [];
    mergedEquipments.length > 0 &&
      mergedEquipments.map((item) => {
        return mergedEquipArray.push({
          equipment_id: item,
        });
      });

    //this is the payload object to the save worklog api
    const apiData = {
      step1: {
        working_details: workPlace?.value === undefined ? "" : workPlace?.value,
        construction_details:
          constructed?.value === undefined ? "" : constructed?.value.trim(),
        worklog_id:
          fetchWorkLogdetailData === undefined
            ? fetchWorkLogsData.id
            : fetchWorkLogdetailData.worklog.id,
      },

      step2: {
        task_id: task?.value?.task_id
          ? task?.value?.task_id
          : fetchWorkLogdetailData?.worklog?.task[0]
          ? fetchWorkLogdetailData?.worklog?.task[0].task_id
          : "",
        worklog_equipments: ids.length > 0 ? ids : mergedEquipArray,

        additional_equipments:
          moreEquipments?.value.length > 0
            ? moreEquipments.value
                ?.filter((item) => item !== "")
                ?.map((item) => {
                  const capitalizedTool = item
                    .split(" ")
                    .map((word) => {
                      const lower = word.toLowerCase();
                      return lower[0].toUpperCase() + lower.substring(1);
                    })
                    .join(" ");
                  return {
                    equipment_name: capitalizedTool,
                  };
                })
            : [],
      },

      step3: {
        is_photo: showUploadImg,
        photo_has: {
          photo:
            showUploadImg === true && imageData.length > 0
              ? imageData
              : supervisor?.value?.toString().includes("temp")
              ? [
                  {
                    photo: "",
                    photo_happening_desc: photoHappening?.value,
                    photo_activity_time_spent: howLong?.value,
                    temp_emp_id: +supervisor?.value.replace("temp", ""),
                  },
                ]
              : {
                  photo: "",
                  photo_happening_desc: photoHappening?.value,
                  photo_activity_time_spent: howLong?.value,
                  supervisor_id: supervisor?.value,
                },
        },

        photo_not: {
          nophoto_activity_details:
            showUploadImg === false
              ? photoPart?.value !== ""
                ? photoPart?.value
                : fetchWorkLogdetailData?.taskDetail[0]?.no_photo !== undefined
                ? fetchWorkLogdetailData?.taskDetail[0]?.no_photo[0]?.part
                : ""
              : "",
          nophoto_activity_time_spent:
            showUploadImg === false
              ? howLong?.value !== ""
                ? howLong?.value
                : fetchWorkLogdetailData?.taskDetail[0]?.no_photo !== undefined
                ? fetchWorkLogdetailData?.taskDetail[0]?.no_photo[0]?.how_long
                : ""
              : "",
        },
      },

      step4: {
        other_comments:
          otherComment?.value === undefined ? "" : otherComment?.value,
        overall_comments:
          overallComment?.value === undefined ? "" : overallComment?.value,
        status: 0,
      },
    };

    showUploadImg === ""
      ? delete apiData.step3
      : showUploadImg === true
      ? delete apiData.step3.photo_not
      : delete apiData.step3.photo_has;

    moreEquipments.value === "" && delete apiData.step2.additional_equipments;
    dispatch(createWorklogs.request(apiData));
  };

  const handleErase = () => {
    workPlace.setInputValue("");
    constructed.setInputValue("");
    task.setInputValue("");
    equipments.setInputValue("");
    moreEquipments.setInputValue("");
    photoUpload.setInputValue("");

    photoHappening.setInputValue("");
    moreSupervisors.setInputValue("");
    howLong.setInputValue("");
    supervisor.setInputValue("");
    otherComment.setInputValue("");
    overallComment.setInputValue("");
    photoPart.setInputValue("");

    dispatch(clearEquipment.request());

    history.push(student_path.VIEW_WORKLOG_PATH);
  };

  const [customeHeight, setCustomeHeight] = useState();
  useEffect(() => {
    setCustomeHeight(window.innerHeight + "px");
  }, []);

  return fetchWorklogError ? (
    <div className={styles.notFound}>Can’t find that worklog!</div>
  ) : (
    <div
      className={styles.mainContainersteps}
      style={{ height: customeHeight }}
    >
      {loadingWorklogDetail === true ? (
        <></>
      ) : (
        <div className={`${styles.headerFix}`}>
          <OtherPageHeader
            heading={step.num === 5 ? `Summary` : `Create New Worklog`}
            navImage={step.num === 5 ? `nothing` : `cancel`}
            setStep={setStep}
            handleErase={handleErase}
          />

          {step.num !== 5 && (
            <SubHeader text1={step.text} text2={`Step ${step.num}/4`} />
          )}
        </div>
      )}
      {/* Depending on the step, we will bring out a new component */}

      {/* Step 1 -  */}
      {step.num === 1 && (
        <NewWLStep1
          loadingWorklogDetail={loadingWorklogDetail}
          constructed={constructed}
          errorMessage={errorMessage}
          setStep={setStep}
          workPlace={workPlace}
          data={fetchWorkLogdetailData}
          constructedSelectedValue={constructedSelectedValue}
          setConstructedSelectedValue={setConstructedSelectedValue}
          saveLater={saveLater}
          user={user}
          inputChecked={inputChecked}
          setinputChecked={setinputChecked}

          //errorMessage={errorMessage}
        />
      )}

      {/* // Step 2 -  */}
      {step.num === 2 && (
        <NewWLStep2
          setCurrentEquipments={setCurrentEquipments}
          constructed={constructed}
          errorMessage={errorMessage}
          setStep={setStep}
          data={fetchWorkLogdetailData}
          dataAccordion1={dataAccordion1}
          dataAccordion2={dataAccordion2}
          task={task}
          current={current}
          equipments={equipments}
          tools={tools}
          executeScroll={executeScroll}
          radioButtonPicOptions={dummyData.radioButtonPicOptions}
          myRef={myRef}
          supervisors={supervisors}
          moreEquipments={moreEquipments}
          addMore={addMore}
          AddMore={AddMore}
          profs={profs}
          mergedEquipments={mergedEquipments}
          setMergedEquipments={setMergedEquipments}
          searchTasks={searchTasks}
          overdueTask={overdueTask}
          setOverDueTask={setOverDueTask}
          currentTask={currentTask}
          setCurretTask={setCurretTask}
          futureTask={futureTask}
          setFutureTask={setFutureTask}
          completedTask={completedTask}
          setCompletedTask={setCompletedTask}
          saveLater={saveLater}
          setinputChecked={setinputChecked}
        />
      )}
      {/* // Step 3 -  */}
      {step.num === 3 && (
        <NewWLStep3
          is_photo
          searchEquipment={searchEquipment}
          currentEquipments={currentEquipments}
          setCurrentEquipments={setCurrentEquipments}
          selectEquip={selectEquip}
          setSelectEquip={setSelectEquip}
          addMoreEquipCount={addMoreEquipCount}
          setAddMoreEquipCount={setAddMoreEquipCount}
          handleAddEquip={handleAddEquip}
          addMoreEquipInput={addMoreEquipInput}
          setAddMoreEquipInput={setAddMoreEquipInput}
          constructed={constructed}
          errorMessage={errorMessage}
          setStep={setStep}
          data={fetchWorkLogdetailData}
          dataAccordion1={dataAccordion1}
          dataAccordion2={dataAccordion2}
          task={task}
          executeScroll={executeScroll}
          radioButtonPicOptions={dummyData.radioButtonPicOptions}
          myRef={myRef}
          showUploadImg={showUploadImg}
          howLong={howLong}
          photoPart={photoPart}
          photoUpload={photoUpload}
          photoHappening={photoHappening}
          setUpload={setUpload}
          supervisors={supervisors}
          supervisor={supervisor}
          moreSupervisors={moreSupervisors}
          addMore={addMore}
          AddMore={AddMore}
          profs={profs}
          files={files}
          setFiles={setFiles}
          imageId={imageId}
          setImageId={setImageId}
          photos={photos}
          setPhotos={setPhotos}
          mergedPhotoArray={mergedPhotoArray}
          setMergedPhotoArray={setMergedPhotoArray}
          mergedEquipments={mergedEquipments}
          setMergedEquipments={setMergedEquipments}
          equipments={equipments}
          moreEquipments={moreEquipments}
          saveLater={saveLater}
          blankCheckbox={blankCheckbox}
          setblankCheckbox={setblankCheckbox}
          setinputChecked={setinputChecked}
        />
      )}

      {step.num === 4 && (
        <NewWLStep4
          showUploadImg={showUploadImg}
          constructed={constructed}
          errorMessage={errorMessage}
          setStep={setStep}
          data={fetchWorkLogdetailData}
          task={task}
          executeScroll={executeScroll}
          otherComment={otherComment}
          overallComment={overallComment}
          saveLater={saveLater}
        />
      )}
      {step.num === 5 && (
        <NewWLStep5
          workPlace={workPlace}
          constructed={constructed}
          errorMessage={errorMessage}
          setStep={setStep}
          data={fetchWorkLogdetailData}
          fetchWorkLogEquipmentData={fetchWorkLogEquipmentData}
          task={task}
          current={current}
          equipments={equipments}
          currentEquipments={currentEquipments}
          moreEquipments={moreEquipments}
          photoUpload={photoUpload}
          photoHappening={photoHappening}
          photoPart={photoPart}
          howLong={howLong}
          supervisor={supervisor}
          otherComment={otherComment}
          overallComment={overallComment}
          executeScroll={executeScroll}
          navImage={goToPreviousPath}
          submitForm={submitForm}
          saveLater={saveLater}
          files={files}
          photos={photos}
          mergedPhotoArray={mergedPhotoArray}
          mergedEquipments={mergedEquipments}
          setMergedEquipments={setMergedEquipments}
          showUploadImg={showUploadImg}
          setUpload={setUpload}
        />
      )}
    </div>
  );
}

const AddMore = (props) => {
  return (
    <div className="addMore">
      <Checkbox
        className={styles.checkboxForAddaTool}
        name={props.name}
        dataType={props.call}
        options="other"
        label="other"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        moreEquipments={props.moreEquipments}
        class={props.class}
      />
    </div>
  );
};

export default NewWorkLog;
