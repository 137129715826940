import * as studentOntrackData from "../../actions/employer/StudentOnTrackStatus.action";

const initialState = {
    onTrackStatus: [],
    loading: false,
    success: false,
    onTrackStatusFailure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentOntrackData.STUDENT_ONTRACK_STATUS.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentOntrackData.STUDENT_ONTRACK_STATUS.SUCCESS:

    return {
      ...state,
      onTrackStatus: action.payload.data,
      loading: false,
      success: true,
      onTrackStatusFailure:false,
    };

  case studentOntrackData.STUDENT_ONTRACK_STATUS.FAILURE:
    return {
      ...state,
      onTrackStatus: [],
      loading: false,
      success: false,
      onTrackStatusFailure:true,
    };
      default: return state      
    }
}
