import React from "react";
import styles from "./styles.module.scss";
import logo from "../../../assets/images/logo.png";
import logout from "../../../assets/images/logout.svg";
import { signoutUserAction } from "../../../store/actions/user/signout.user.action";
import { useDispatch } from "react-redux";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { useState } from "react";
import backicon from "../../../assets/images/backicon.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmDashboardHeader = (props) => {
  const dispatch = useDispatch();

  //this function will signout the user
  const handleLogout = () => {
    dispatch(signoutUserAction.request());
  };
  const history = useHistory();
  const handleback = () => {
    history.goBack();
  }

  //these function will who and close the popup screen
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={`${styles.employHeader}`}>
        {props.isImg && <div className={styles.contactLabel}>
          <img src={backicon} alt="backButton" onClick={handleback} />
        </div>}
        <div className={`${styles.logoImg} `} style={props.isImg ? {paddingLeft: '0px', paddingRight: '22px'}: {paddingLeft: '30px'}}>
          <img src={logo} alt="CTI Logo" />{" "}
        </div>
        <div className={`${styles.logout} `}>
          <img
            src={logout}
            alt="logout"
            onClick={() => {
              handleShow();
            }}
          />
        </div>
      </div>

      {/* this custom modal will handle the popup for logout */}
      <CustomModal
        modalFor="logout"
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        handleLogout={() => handleLogout()}
      />
    </>
  );
};

export default EmDashboardHeader;
