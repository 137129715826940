import EmployerUnitSignPad from '../../../components/EmployerDashboard/EmployerUnitSignPad'
import React from 'react'
import ProfileHeader from '../../../components/Setting/ProfileHeader'
import styles from './styles.module.scss';
import EmFooter from '../../../components/EmployerDashboard/EmFooter';
import { employer_path } from '../../../Constants/paths/employer';


const EmUnitSignPad = () => {
  return (
    <div className={styles.containerUnitSignPad}>
        <ProfileHeader />
        <EmployerUnitSignPad />
        <EmFooter
        homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
        apprenticePath={employer_path.APPRENTICE_PATH}
        setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
        active={true}
      />
    </div>
  )
}

export default EmUnitSignPad