import { createRequestTypes, action } from "../../../utils/redux";
export const SIGNUP_LINK_VERIFICATION = createRequestTypes(
  "SIGNUP_LINK_VERIFICATION"
);

export const verifySignup = {
  request: (data) => action(SIGNUP_LINK_VERIFICATION.REQUEST, data),
  success: (data) => action(SIGNUP_LINK_VERIFICATION.SUCCESS, data),
  failure: (error) => action(SIGNUP_LINK_VERIFICATION.FAILURE, error),
};
