import * as upcomingStudentWorkshopdata from "../../actions/employer/UpcomingStudentWorkshop.action";

const initialState = {
    upcomingStudentworkshop: [],
    loading: false,
    success: false,
    workshopfailure:false,
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case upcomingStudentWorkshopdata.UPCOMING_STUDENT_WORKSHOP.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case upcomingStudentWorkshopdata.UPCOMING_STUDENT_WORKSHOP.SUCCESS:

    return {
      ...state,
      upcomingStudentworkshop: action.payload.data,
      loading: false,
      success: true,
      workshopfailure:false,
    };

  case upcomingStudentWorkshopdata.UPCOMING_STUDENT_WORKSHOP.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      workshopfailure:true,
    };
      default: return state      
    }
}