import * as ChangePinActions from "../../actions/user/changePin.user.action";

const initialState = {
loading:false,
changePinSuccess:false,
changePinFail:false,
status:"",
message:"",
};

export default function (state = initialState, action) {
    switch (action.type) {
            //Current Password
        case ChangePinActions.CHANGE_PIN.REQUEST:
            return {
                ...state,
                loading:true, 
                changePinSuccess:false,
                changePinFail:false,
            };
        case ChangePinActions.RESET_CHANGE_PIN.SUCCESS:
            return {
                ...state,
                loading:false,
                changePinSuccess:false,
                changePinFail:false,
                status:"",
                message:"",
            };
        case ChangePinActions.CHANGE_PIN.SUCCESS:
            return {
                ...state,
                loading:false,
                changePinSuccess:true,
                changePinFail:false,
                status:action.payload.status,
                message:action.payload.message
            
            };
        case ChangePinActions.CHANGE_PIN.FAILURE:
            return {
            ...state,
            loading:false,
            changePinSuccess:false,
            changePinFail:true,
            status:action.payload.status,
            message:action.payload.message
            };


        default:
            return state;
    }
}