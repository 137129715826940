import { createRequestTypes, action } from "../../../utils/redux";
export const FETCH_ALL_WORKLOGS = createRequestTypes("FETCH_ALL_WORKLOGS");
export const FETCH_WORKLOGS_FOR_UNIT = createRequestTypes(
  "FETCH_WORKLOGS_FOR_UNIT"
);

//As we are using the saga, we dont need the api call here, that we have done in the saga itself
//dispatch the action

export const fetchAllWorklogs = {
  request: (data) => action(FETCH_ALL_WORKLOGS.REQUEST, data),
  success: (data) => action(FETCH_ALL_WORKLOGS.SUCCESS, data),
  failure: (error) => action(FETCH_ALL_WORKLOGS.FAILURE, error),
};

export const fetchWorklogsForUnit = {
  request: (data) => action(FETCH_WORKLOGS_FOR_UNIT.REQUEST, data),
  success: (data) => action(FETCH_WORKLOGS_FOR_UNIT.SUCCESS, data),
  failure: (error) => action(FETCH_WORKLOGS_FOR_UNIT.FAILURE, error),
};
