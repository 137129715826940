import React from "react";
import styles from "./styles.module.scss";
import ProfileInput from "../../../../components/Setting/ProfileInput";
import ProfileFooter from "../../../../components/Setting/ProfileFooter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  resetUpdateEmail,
  updateEmail,
} from "../../../../store/actions/user/updateProfile.user.action";
import {
  resetUpdatePhone,
  updatePhone,
} from "../../../../store/actions/user/updateProfile.user.action";
import { useSelector } from "react-redux";
import Overlay from "../../../../components/common/Overlay";
import { fetchProfile } from "../../../../store/actions/user/profile.user.actions";
import { useHistory } from "react-router-dom";
import { employer_path } from "../../../../Constants/paths/employer";
import { student_path } from "../../../../Constants/paths/student";
import { studentCurrentPage } from "../../../../store/actions/student/student.curerntPage.action";
import { decodeToken } from "../../../../utils/helper/common/decodeJSONToken";
import { updateTrainerInfo } from "../../../../store/actions/trainer/updateTrainerInfo.action";
import { trainer_path } from "../../../../Constants/paths/trainer";
import { resetTrainerInfo } from "../../../../store/actions/trainer/updateTrainerInfo.action";
import { staff_path } from "../../../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";
import EmDashboardHeader from "components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import HeaderLabel from "components/common/HeaderLabel";
const ProfileUpdatePage = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();
  const history = useHistory();
  const [customeHeight, setCustomeHeight] = useState(window.innerHeight+'px')
  //setting the current page by calling an action to set page
  dispatch(studentCurrentPage.success("settings"))


  const [overlay, setOverlay] = useState(false);
  //fetch user locally by the token
  const tokenData = decodeToken();
  const tokenUsertype = tokenData?.user_type
  //reducer
  const emailUpdateReducer = useSelector(
    (state) => state.user.updateEmailUserReducer
  );
  const phoneUpdateReducer = useSelector(
    (state) => state.user.updatePhoneUserReducer
  );

  const profileReducer = useSelector(
    (state) => state.user.fetchProfileUserReducer
  );
  const trainerSuccess = useSelector((state)=>state.trainerReducers?.updateTrainerInfoReducer?.success)

  const UserType = profileReducer?.profile?.type;

  const [values, setValues] = useState({
    emailErrorMessage: "",
    phoneErrorMessage: "",
    emailSuccess: false,
    phoneSuccess: false,
  });

  useEffect(() => {
    dispatch(resetUpdatePhone.request());
    dispatch(resetUpdateEmail.request());
    //this action will reset the trainer reducer for update profile
    dispatch(resetTrainerInfo());
    dispatch(fetchProfile.request());
  }, []);

  useEffect(() => {
    if (profileReducer?.profile) {
      const profile = profileReducer?.profile;
      phoneNumber.setInputValue(profile.phone);
      emailData.setInputValue(profile.email);
    }
  }, [profileReducer?.profile]);

  //effect related to state Changes
  useEffect(() => {
    if (emailUpdateReducer.emailStatus === "SUCCESS") {
    } else {
      setValues({
        emailErrorMessage: emailUpdateReducer.emailMessage,
      });
    }
  }, [emailUpdateReducer]);

  useEffect(() => {
    if (phoneUpdateReducer.phoneStatus === "SUCCESS") {
    } else {
      setValues({
        phoneErrorMessage: phoneUpdateReducer.phoneMessage,
      });
    }
  }, [phoneUpdateReducer]);

  useEffect(() => {
    if (
      phoneUpdateReducer.phoneStatus === "SUCCESS" &&
      emailUpdateReducer.emailStatus === "SUCCESS"
    ) {
      setOverlay(true);
      dispatch(fetchProfile.request());
    } else {
      setOverlay(false);
    }
  }, [emailUpdateReducer.emailStatus, phoneUpdateReducer.phoneStatus]);

  //functions to update the user email
  function handleSave() {
    dispatch(updateEmail.request(emailData.value));
    dispatch(updatePhone.request(phoneNumber.value));
  }
  function handleTrainerSave() {
    const data = {
        email: emailData?.value,
        phone: phoneNumber.value
      }
    if(tokenUsertype==="Trainer"){
      dispatch(updateTrainerInfo.request(data))
    } 
  }
  function handleCloseClick() {
    if (UserType === "E") {
      history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
    } else if (UserType === "S") {
      history.push(student_path.STUDENT_DASHBOARD_PATH);
    } else if (UserType === "T") {
      history.push(trainer_path.TRAINER_DASHBOARD_PATH);
    }else if (UserType === "C") {
      history.push(staff_path.STAFF_DASHBOARD_PATH);
    } else if (UserType === "D") {
      history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH);
    }
  }
  function handleCrossClick() {
    setOverlay(false);
  }


  //state for the disable button if the input value is empty
  const [buttonDisable, setbuttonDisable] = useState(false);

  //useForm Inputs
  const useFormInputs = (initialValue, field) => {
    const [value, setValue] = useState(initialValue);
    const [typed, setTyped] = useState(false);

    //disable button if the input value is empty
    useEffect(() => {
      if (emailData?.value?.length > 0 && phoneNumber?.value.length > 0) {
        setbuttonDisable(true)
      } else {
        setbuttonDisable(false)
      }

    }, [value]);

    function setInputValue(input) {
      setValue(input);
      setTyped(false);
    }

    function handleChange(e) {
      setValue(e.target.value);
      setTyped(true);
    }

    function clearInput() {
      setValue("");
    }

    return {
      value,
      typed,
      onChange: handleChange,
      clearInput: clearInput,
      setInputValue: setInputValue,
    };
  };

  //form inputs variable
  const phoneNumber = useFormInputs("");
  const emailData = useFormInputs("");

//css for header label
const style = { margin: "66px -16px 0px 0px", width: "120%", position: "static" };

  return (
    <>
      <div className={styles.mainContainer} style={{ height: customeHeight }}>
      <EmDashboardHeader isImg={true} />
      <HeaderLabel text="Edit Profile" style={style} />
        <div className={styles.main}>
          <ProfileInput
            label="Name"
            label2={profileReducer?.profile?.name}
            placeholder="Name"
          />
          <ProfileInput
            label="Phone"
            type="number"
            onKeyDown={(e) => ["e", "E", "=", "'", '"', "."].includes(e.key) && e.preventDefault()}
            placeholder="Phone"
            value={phoneNumber.value}
            onChange={phoneNumber.onChange}
            errorMessage={values.phoneErrorMessage}
          />
          <ProfileInput
            label="Email"
            type="text"
            placeholder="Enter Email Id"
            value={emailData.value}
            onChange={emailData.onChange}
            errorMessage={values.emailErrorMessage}
          />
        </div>
        {tokenUsertype === "Trainer" ? 
        <ProfileFooter onClick={handleTrainerSave} disabled={buttonDisable} /> :
        <ProfileFooter onClick={handleSave} disabled={buttonDisable} /> }
      </div>
      {(overlay || trainerSuccess) && (
        <Overlay
          title="Profile Update"
          text="Profile Updated Successfully"
          handleCross={handleCrossClick}
          onClick={handleCloseClick}
        />
      )}
    </>
  );
};

export default ProfileUpdatePage;
