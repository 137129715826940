
export function getJsonFromLocalStorage(key) {
  const value = fetchLocally(key);
  if (value) {
    return value;
  
  }
}


export const storeInLocalStorage = {
  storeFirstName: (name) => {
    localStorage.setItem("user_first_name",name);
  },
  storeLastName: (name) => {
    localStorage.setItem("user_last_name",name);
  },
  storeEmail: (email) => {
    localStorage.setItem("user_email",email);
  },

  storePhone: (phone) => {
    localStorage.setItem("user_phone",phone);
  },


  storePin : (pin) => {
    localStorage.setItem("login_credentials_pin", pin)
  },
  storeloginPinActivated : (val) =>{
    localStorage.setItem("login_pin_activated", val)
  },

  storeloginCredential : (loginData) =>{
    localStorage.setItem("login_credentials", loginData)
  },

  storeResetPin : (rpin) => {
    localStorage.setItem("resetPin",rpin)
  },

  storeSkipPin : (skip) =>{
    localStorage.setItem("skip_pin_auth", skip)
  },

  trusted : (remVal) =>{
    localStorage.setItem("trusted", remVal)
  },

  setTokenVerifySignup : (token)=>{
    localStorage.setItem("login_credentials", token)
  },

  setOnBehalfToken : (onBehalfToekn) =>{
    localStorage.setItem("onbehalf_token",onBehalfToekn )
  },
  setInternalError : (internalError) =>{
    localStorage.setItem("internal_error", internalError)
  },
  setTempLogin : (tempLogin) =>{
    localStorage.setItem("temp_login", tempLogin)
  }

} 

export const removeFromLocalStorage = {
  signout: () => {
    clearLocalStorage();
  },
  forgetMe : () => {
    removeDataFromLocalStorage("is_pin_active");
  },
  loginCredential:()=>{
   removeDataFromLocalStorage("login_credentials")
  },
  loginCredentialPin : () =>{
    removeDataFromLocalStorage("login_credentials_pin")
  },
  resetPin : () =>{
    removeDataFromLocalStorage("resetPin")
  }, 
  skipPinAuth : () => {
    removeDataFromLocalStorage("skip_pin_auth")
  }, 
  
  loginPinActivated : () =>{
    removeDataFromLocalStorage("login_pin_activated")
  },
  setOnBehalfToken: () =>{
    removeDataFromLocalStorage("onbehalf_token")
  },
  setInternalError : () =>{
    removeDataFromLocalStorage("internal_error")
  }
}



function removeDataFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export const saveAuthToken = (token) => {
storeInLocalStorage.storeloginCredential(token);  
};

export const getAuthToken = (isRemembered = false) => {
  if (isRemembered) return fetchLocally("login_credentials");
  else return fetchLocally("login_credentials");
};

export const getOnBehalfToken = ()=>{
if(fetchLocally("onbehalf_token")){
  return fetchLocally("onbehalf_token")
}
else return 
}
const clearLocalStorage = () => {
  removeDataFromLocalStorage("login_credentials");
  removeDataFromLocalStorage("login_credentials_pin");
  removeDataFromLocalStorage("login_pin_activated");
  removeDataFromLocalStorage("skip_pin_auth");
  removeDataFromLocalStorage("user_first_name");
  removeDataFromLocalStorage("login_pin_activated");
  removeDataFromLocalStorage("trusted")

}


export function fetchLocally(itemName) {
  return localStorage.getItem(itemName);
}