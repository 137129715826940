import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as confirmWorkshop from "../../actions/employer/confirmUpcomingWorkshop.action";



function *confirmUpcomingWorkshop(action){
    try{
        const {data} = yield call(EmployerApi.confirmUpcomingWorkshop, action.payload)
        //extract the data here
        
        yield put(confirmWorkshop.confirmUpcomingWorkshop.success(data));

    } catch (e) {
        yield put(confirmWorkshop.confirmUpcomingWorkshop.failure(e));
    }
}

function *confirmWorkshopSaga(){
    yield (
      
        takeLatest(confirmWorkshop.CONFIRM_UPCOMINGWORKSHOP.REQUEST, confirmUpcomingWorkshop)
    );
}

export default confirmWorkshopSaga;