import * as rejectionSign from "../../actions/employer/RejectionSign.action"

const initialState = {
  loading: false,
  rejectionsuccess: false,
  rejectionFailure: false,
  signSelected: false,
  status: "",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case rejectionSign.SHOW_SIGN:
      return {
        ...state,
        signSelected: true,
      }
      case rejectionSign.REJECT_CLICK:
      return {
        ...state,
        rejectionsuccess: false,
      }
    case rejectionSign.REJECTION_SIGN.REQUEST:
      return {
        ...state,
        loading: true,
        rejectionsuccess: false,
        message: action.payload.message,
      };

    case rejectionSign.REJECTION_SIGN.SUCCESS:
      return {
        ...state,
        loading: false,
        rejectionsuccess: true,
        signSelected: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    case rejectionSign.REJECTION_SIGN.FAILURE:
      return {
        ...state,
        loading: false,
        rejectionsuccess: false,
        rejectionFailure: true,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
