import { createRequestTypes, action } from "../../../utils/redux";

export const GET_ALL_STUDENTS_OF_TRAINER = createRequestTypes("GET_ALL_STUDENTS_OF_TRAINER");

//this functional object will fetch the worklogs for trainer so they can sign
export const getAllStudentsOfTrainer = {
  request: (data) => action(GET_ALL_STUDENTS_OF_TRAINER.REQUEST,data),
  success: (data) => action(GET_ALL_STUDENTS_OF_TRAINER.SUCCESS, data),
  failure: (error) => action(GET_ALL_STUDENTS_OF_TRAINER.FAILURE, error),
};
