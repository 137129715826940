import React from 'react'
import SupervisorInput from "../AddNewSupervisorModal/SupervisorInput/index";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Overlay from "../../../components/common/Overlay";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { student_path } from '../../../Constants/paths/student';
import { resetAddSupervisor } from '../../../store/actions/student/addSupervisor.action';

const AddNewSupervisorContact = (props) => {
   // this comment for disable useeffect dependency
  /* eslint-disable */

  //these are the props passed from parent component
  const {
    handleSupervisorSubmit,
    values,
    setValues,
    initialValue,
  } = props;

  const dispatch = useDispatch();
  const supervisorData = useSelector(
    (state) => state.studentReducers?.addNewSupervisorReducer
  );
  const supervisorerror = supervisorData?.error;

  const supervisorsucess = supervisorData?.success;

  const supervisormessage = supervisorData?.message;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setError(false);
  };
  const history = useHistory();
  const [isError, setError] = useState(false);
  const [isSuccess, setSucsess] = useState(false);

  useEffect(() => {
    if (supervisorerror) {
      setError(true);
      setSucsess(false);
    }
    if (supervisorsucess) {
      setSucsess(true);
      setError(false);
    }else{
      setSucsess(false)
    }
  }, [supervisorerror, supervisorsucess]);

  const handleCloseOverlay = () => {
    dispatch(resetAddSupervisor.success());
    history.push(student_path.VIEW_SETTINGS_PATH)
  };
  const handleCross=()=>{
    dispatch(resetAddSupervisor.success());
    setValues(initialValue)
  }

  return (
    <div className = {styles.NewSuperVisorpage}>
        <SupervisorInput
            label = "First name"
            placeholder="Enter first name"
            name="first_name"
            type="text"
            value={values.first_name}
            onChange={handleChange}
          />
          <SupervisorInput
            label = "Last name"
            placeholder="Enter last name"
            name="last_name"
            type="text"
            value={values.last_name}
            onChange={handleChange}
          />
          <SupervisorInput
            label = "Phone Number"
            placeholder="Enter phone Number"
            name="phone"
            type="number"
            value={values.phone}
            onChange={handleChange}
            onKeyDown={(e) =>
              ["e", "E", "=", "'", '"', "."].includes(e.key) &&
              e.preventDefault()
            }
          />
          <span className={styles.error}>
            {isError ? supervisormessage : ""}
          </span>
        <div className={styles.submitSupervisor}>
        <button
          onClick={() => {
            handleSupervisorSubmit();
          }}
          class={`mb-3 btn btn-info btn-active ${styles.btnsupervisor}`}
        >
          Submit
        </button>
        </div>
        {isSuccess && (
        <Overlay
          title="Add new supervisor"
          onClick={handleCloseOverlay}
          text="Supervisor added successfully"
          handleCross={handleCross}
        />
      )}
    </div>
    
  )
}

export default AddNewSupervisorContact