import * as TrainerActions from "../../actions/trainer/getAllStudentsOfTrainer.action"

const initialState = {
  //profile
  loading: false,
  success: false,
  status: "",
  message: "",
  students:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
  //profile
    case TrainerActions.GET_ALL_STUDENTS_OF_TRAINER.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        message: action.payload.message,
      };

    case TrainerActions.GET_ALL_STUDENTS_OF_TRAINER.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        message: action.payload.message,
        students:action.payload.data
      };

    case TrainerActions.GET_ALL_STUDENTS_OF_TRAINER.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
