import { createRequestTypes, action } from "../../../utils/redux";

export const EMPLOYER_DATA = createRequestTypes("EMPLOYER_DATA");

export const employerData = {
  request: (data) => action(EMPLOYER_DATA.REQUEST,data),
  success: (data) => action(EMPLOYER_DATA.SUCCESS, data),
  failure: (error) => action(EMPLOYER_DATA.FAILURE, error),
};

export const EMPLOYER_SIGN = createRequestTypes("EMPLOYER_SIGN");

export const employerSign = {
  request: (data) => action(EMPLOYER_SIGN.REQUEST,data),
  success: (data) => action(EMPLOYER_SIGN.SUCCESS, data),
  failure: (error) => action(EMPLOYER_SIGN.FAILURE, error),
};

export const SHOW_SIGN = "SHOW_SIGN";

export const showSign = (data) => action(SHOW_SIGN, data);


export const APPROVE_CLICK = "APPROVE_CLICK";

export const approveClick = (data) => action(APPROVE_CLICK, data);