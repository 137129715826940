import React from 'react'
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import Emfooter from "../../../components/EmployerDashboard/EmFooter/index"
import { employer_path } from '../../../Constants/paths/employer';
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";
import EmContactLogo from "../../../components/EmployerDashboard/EmContactLogo";
import EmDashboardHeader from "../../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";

const EmployerContactUs = () => {
  const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("contactus"))

  const style = { position: 'relative', margin: '75px 0px' }

  return (
    <div className={styles.mainContainer}>
      <Container className={`pt-3 ${styles.container}`}>
        <EmDashboardHeader isImg={true} />
        <EmContactLogo style={style} isImg={true} />
        <Emfooter
          homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
          apprenticePath={employer_path.APPRENTICE_PATH}
          setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
          active={"contact"}
        />
      </Container>
    </div>
  )
}

export default EmployerContactUs