import {call , put , takeLatest} from "@redux-saga/core/effects";
import { TrainerApi } from "../../../services/trainer/trainer.services";
import * as updateTrainerActions from "../../actions/trainer/updateTrainerInfo.action"


function *updateTrainer(action){

try {
    //this will fetch the data Object from the Api which is used
    const {data:resData} = yield call(TrainerApi.updateTrainerInfo, action.payload)
 
    yield put(updateTrainerActions.updateTrainerInfo.success({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }));
} catch (e) {
    //this will catch an error when there is an error in the fetching api
    const {data:resData} = e
    yield put(updateTrainerActions.updateTrainerInfo.failure({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }))
}
}

function *updateTrainerSaga(){
    yield takeLatest(updateTrainerActions.UPDATE_TRAINER_INFO.REQUEST, updateTrainer)
}

export default updateTrainerSaga;