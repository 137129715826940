import axios from "axios";
import { BASE_API_URL } from "../Constants/api/index";
import { fetchLocally,getAuthToken, getOnBehalfToken } from "../utils/localStorage";
import { storeInLocalStorage } from "../utils/localStorage";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

const requestHandler = (request) => {
  const token = getAuthToken(false);
  const onBehalfToken = getOnBehalfToken();
  if (token) {
    //if the token exist we will assign the token in auth Header
    request.headers.Authorization = `Bearer ${token}`;
    //if on behalf token exists in the localStorage then we will modify the header and pass the obBehalf token
    if(onBehalfToken){
      request.headers.OnBehalfToken=`Bearer ${onBehalfToken}`
    } 
    request.headers["Accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
  }
  //   request.headers["Content-Security-Policy"] =
  //     "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'";
  //   request.headers["X-Content-Type-Options"] = "nosniff";
  //   request.headers["X-XSS-Protection"] = 1;
  //   request.headers["Strict-Transport-Security"] = "max-age=31536000";
  return request;
};

const errorHandler = async (error) => {
  if (error.response.status === 401) {
    if(fetchLocally("trusted")==="true"){
      window.location.href = "/sign/pin";
    }else{
      localStorage.clear();
      window.location.href = "/login";
    }
  }
  if (error.response.status === 403) {
    window.location.href = "/403";
  }
  if (error.response.status === 404) {
    window.location.href = "/404";
  }

  if (error.response.status === 500) {
storeInLocalStorage.setInternalError("500");
window.dispatchEvent(new StorageEvent('storage'));
  }

  if (error.response.status >= 400) {
    await Promise.reject(error.response);
  }
};

const successHandler = (response) => {
  if(response.headers["x-status"]){
    //do something when we have x-status code
  }
  return response;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
