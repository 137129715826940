import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import CrossIcon from "../../../assets/images/cancel.png";
const ViewImageModal = (props) => {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  //these are the props passed from parent component
  const { show, values, handleClose, setShow,setValue } = props;

  //this will close the view image modal
  const handleModalClose = () =>{
    setShow(false);
    setValue("");
  }

  return (
    <div>
   
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-vcenter"
        centered
        className="p-4"
      >
        <Modal.Header>
        <div class={styles.buttonClose} >
        <img src={CrossIcon}  onClick={()=>handleModalClose()} />
        </div>
      </Modal.Header>


        <Modal.Body>
            <div className={styles.modalImage}>
              <img src={values}></img>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewImageModal;
