import { call, put, takeLatest } from "redux-saga/effects";
import { loginWithPinApi } from "../../../services/anonymous/loginWithPin.service";
import {
  LOGIN_WITH_PIN,
  loginWithPin,
} from "../../../store/actions/user/loginWithPin.user.actions";


function* handleLoginWithPin({ payload }) {
  try {
    const { data } = yield call(loginWithPinApi.login, payload);
    yield put(loginWithPin.success(data));
    
  } catch (e) {
    yield put(loginWithPin.failure(e.data));
  }
}

function* loginWithPinSaga() {
  yield takeLatest(LOGIN_WITH_PIN.REQUEST, handleLoginWithPin);
}

export default loginWithPinSaga;
