import WorklogDetailsForSignSaga from "./getWorklogDetails.employer.saga";
import getEmployerSaga from "./getEmployerData.saga";
import employerStudentSignSaga from "./employerStudentSign.saga";
import getUpcomingWorkshopSaga from "./upcomingWorkshop.saga";
import getStudentDetailsDataSaga from "./employerApprentices.saga";
import upcomingStudentWorkshop from "./UpcomingStudentWorkshop.saga";
import rejectionsignSaga from "./rejectionSign.saga";
import confirmWorkshopSaga from "./confirmUpcomingWorkshop.saga";
import getEmployerStudentSaga from "./apprenticeStudent.saga";
import getEmployerStudentTodoSaga from "./apprenticeStudentTodo.saga";
import getWorklogSubmissionSaga from "./worklogSubmission.saga";
import getStudentProfileDataSaga from "./employerStudentProfile.saga"
import getStudentProgressReportDataSaga from "./StudentProgressReport.saga"
import getStudentOntrackData from "./StudentOnTrackStatus.saga"
import unitSignEmployerSaga from "./unitSign.saga";


const employerSagas = [
    WorklogDetailsForSignSaga(),
    getEmployerSaga(),
    employerStudentSignSaga(),
    getUpcomingWorkshopSaga(),
    getStudentDetailsDataSaga(),
    upcomingStudentWorkshop(),
    rejectionsignSaga(),
    confirmWorkshopSaga(),
    getEmployerStudentSaga(),
    getEmployerStudentTodoSaga(),
    getWorklogSubmissionSaga(),
    getStudentProfileDataSaga(),
    getStudentProgressReportDataSaga(),
    getStudentOntrackData(),
    unitSignEmployerSaga()
];

export default employerSagas;
