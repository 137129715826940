import { call, put, takeLatest } from "redux-saga/effects";
import { updateProfileInfoApi } from "../../../services/profile/updateProfileInfo.service";
import { updateEmail,updatePhone,UPDATE_EMAIL,UPDATE_PHONE } from "../../../store/actions/user/updateProfile.user.action";


function* handleUpdateEmail(action) {

  try {
    const { data} = yield call(updateProfileInfoApi.Email, action.payload);
      yield put(updateEmail.success({
        status:data?.status,
        statusCode:data?.status_code,
        data:data?.data,
        message:data?.message
      }));

  } catch (e) {
    const {data} = e;
    yield put(updateEmail.failure({
            status:data?.status,
            statusCode:data?.status_code,
            data:data?.data,
            message:data?.message
          
    }));
  }
}

function* handleUpdatePhone( action) {

    try {
        const { data } = yield call(updateProfileInfoApi.Phone, action.payload);
       
          yield put(updatePhone.success({
            status:data?.status,
            statusCode:data?.status_code,
            data:data?.data,
            message:data?.message
          }));
 
    
      } catch (e) {
        const {data} = e;
        yield put(updatePhone.failure({
            status:data?.status,
            statusCode:data?.status_code,
            data:data?.data,
            message:data?.message
          }));
      }
}

function* profileUpdateSaga() {
  yield takeLatest(UPDATE_EMAIL.REQUEST, handleUpdateEmail);
  yield takeLatest(UPDATE_PHONE.REQUEST, handleUpdatePhone);
}

export default profileUpdateSaga;
