import * as FetchWorklogActions from "../../../store/actions/worklogs/fetchworklogs.worklogs.actions"

const initialState = {
    worklogs: [],
    id:"",
    loadingWorklogs: false,
    worklogFetchError:false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FetchWorklogActions.FETCH_WORKLOGS.REQUEST:
            return {
                ...state,
                loadingWorklogs: true,
            };
        case FetchWorklogActions.FETCH_WORKLOGS.SUCCESS:
            return {
                ...state,
                worklogs: action.payload.worklogs,
                id:action.payload.worklogs.id,
                loadingWorklogs: false,
                worklogFetchError:false,
            };
        case FetchWorklogActions.FETCH_WORKLOGS.FAILURE:
            return {
            ...state,
            worklogs: action.payload,
            loadingWorklogs: false,
            worklogFetchError:true,
            };
        default:
            return state;
    }
}