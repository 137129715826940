import { call, put, takeLatest } from "redux-saga/effects";
import { SetPinAPI } from "../../../services/profile/setpin.service";
import { SET_PIN, setPin } from "../../../store/actions/user/setpin.user.action";
import { storeInLocalStorage } from "../../../utils/localStorage";

function* handleSetPin({ payload }) {
  try {
    const { data } = yield call(SetPinAPI.set_pin, payload);

    if (data.status === "SUCCESS") {
      storeInLocalStorage.storeloginPinActivated(true);
      yield put(
        setPin.success({
          success: data.status === "SUCCESS",
          message: data.message,
        })
      );
    } else {
      yield put(
        setPin.success({
          success: data.status === "SUCCESS",
          message: data.message,
        })
      );
    }
  } catch (e) {
    yield put(setPin.failure(e.data));
  }
}

function* setPinSaga() {
  yield takeLatest(SET_PIN.REQUEST, handleSetPin);
}

export default setPinSaga;
