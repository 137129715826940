import { createRequestTypes, action } from "../../../utils/redux";
export const ADD_SUPERVISOR = createRequestTypes("ADD_SUPERVISOR");
export const addSupervisor = {
  request: (data) => action(ADD_SUPERVISOR.REQUEST, data),
  success: (data) => action(ADD_SUPERVISOR.SUCCESS, data),
  failure: (error) => action(ADD_SUPERVISOR.FAILURE, error),
};


export const RESET_ADD_SUPERVISOR = createRequestTypes("RESET_ADD_SUPERVISOR");
export const resetAddSupervisor = {
  success: () => action(RESET_ADD_SUPERVISOR.SUCCESS)
};
