import React from "react";
// this comment for disable the 'Router' is defined but never used
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { paths } from "../../Constants/paths/common";
import { employer_path } from "../../Constants/paths/employer";
import StudentRoute from "./StudentRoute";
import EmployerRoute from "./EmployerRoute";

import Login from "../Auth/Login";
import VerifyPin from "../Auth/VerifyPin";
import Signup from "../Auth/SignUp";
import SetPin from "../Auth/SetPin";
import ForgotPassword from "../Auth/ForgotPassword";
import GenerateLoginLink from "pages/Auth/GenerateLoginLink";
import EmployerDashboard from "../Employer/Dashboard";
import ErrorPage from "../Error";
import ResetPassword from "../Auth/ResetPassword";
import Dashboard from "../../pages/Dashboard/index";
import ViewWorklogDetails from "../../pages/Worklog/ViewWorklogDetails/index";
import NewWorkLog from "../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/index";
import AccessDenied from "../../pages/Auth/AccessDenied/AccessDenied";
import EmSignature from "../Employer/EmSignature";
import HomePageRoute from "./HomePageRoute";
import SemiProtectedRoute from "./SemiProtectedRoute";
import ProtectedRoute from "./ProtectedRoute";
import ProfileUpdatePage from "../Dashboard/SettingsPage/ProfileUpdatePage/ProfileUpdatePage";
import ChangePasswordPage from "../../pages/Dashboard/SettingsPage/ChangePassword/ChangePasswordPage";
import ChangePinPage from "../../pages/Dashboard/SettingsPage/ChangePin/ChangePinPage";
import WorklogSubmission from "../../pages/Employer/WorklogSubmission/index";
import SubmittedWorklogDetailsPage from "../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/SubmittedWorklogDetailsPage/SubmittedWorklogDetailsPage";
import AddNewSupervisor from "../../pages/ContactUs/AddNewSupervisor";
import EmployerApprentices from "../../pages/Employer/EmployerApprentices";
import StudentLayout from "../../pages/Employer/StudentLayout";
import { student_path } from "../../Constants/paths/student";
import { anonymous_path } from "../../Constants/paths/anonymous";
import EmployerContactUs from "../Employer/EmployerContactUs/index";
import EmUnitSignPad from "../../pages/Employer/EmUnitSignPad";

//trainer related imports 
import TrainerRoute from "./TrainerRoute";
import { trainer_path } from "../../Constants/paths/trainer";
import TrainerDashboardPage from "../../pages/Trainer/TrainerDashboard/TrainerDashboardPage";
import TrainerApprenticePage from "../../pages/Trainer/TrainerApprentice/TrainerApprencticePage";
import TrainerStudentPage from "../../pages/Trainer/TrainerApprentice/TrainerStudentPage/TrainerStudentPage";

//SUPERVISOR RELATED IMPORTS
import SupervisorRoute from "./SupervisorRoute";
import { supervisor_path } from "../../Constants/paths/supervisor";


//Staff Related imports
import StaffRoute from "./StaffRoute";
import { staff_path } from "../../Constants/paths/staff";
import StaffDashboardPage from "../../pages/Staff/StaffDashboard/StaffDashboardPage";
import ErrorHandlePage from "../../components/common/ErrorHandlePage/ErrorHandlePage";
import LoginWithCode from "pages/Auth/LoginWithCode";
import SettingsPage from "pages/Dashboard/SettingsPage/SettingsPage";
import ProgressPage from "pages/Dashboard/ProgressPage/ProgressPage";


export default function AppRoutes() {
  return (
    <>
      <Switch>
        {/* this is the hompage route will decide the landing page for the user */}
        <HomePageRoute
          exact
          path={anonymous_path.HOME_PATH}
          component={VerifyPin}
        />
        <Route exact path={paths.PAGE_NOT_FOUND} component={ErrorHandlePage} />
        {/* Public Routes */}
        <Route exact path={paths.UI_TEST_PATH} component={NewWorkLog} />
        <Route exact path={anonymous_path.LOGIN_PATH} component={Login} />
        <Route
          exact
          path={anonymous_path.FORGOT_PASSWORD_PATH}
          component={ForgotPassword}
        />
          <Route
          exact
          path={anonymous_path.GENERATE_LOGIN_LINK_PATH}
          component={GenerateLoginLink}
        />
        <Route exact path={paths.UNAUTHORIZED} component={AccessDenied} />
        {/* <Route exact path={paths.PAGE_NOT_FOUND} component={PageNotFound} /> */}

        <Route exact path={anonymous_path.USER_FORGOT_PATH} component={Login} />
        <Route exact path={anonymous_path.SIGNUP_PATH} component={Signup} />
        <Route exact path={anonymous_path.LOGIN_WITH_CODE_PATH} component={LoginWithCode} />
        <Route
          exact
          path={anonymous_path.RESET_PASSWORD_PATH}
          component={ResetPassword}
        />
        {/* these are the common protected routes can be accessed by authenticated user */}

        <ProtectedRoute
          exact
          path={paths.CHANGE_PASSWORD}
          component={ChangePasswordPage}
        />
        <ProtectedRoute
          exact
          path={paths.CHANGE_PIN}
          component={ChangePinPage}
        />
        {/* these are the semiProtected routes can be access by expired token */}
        <SemiProtectedRoute
          exact
          path={anonymous_path.VERIFY_PIN_PATH}
          component={VerifyPin}
        />
        <SemiProtectedRoute
          exact
          path={anonymous_path.SET_PIN_PATH}
          component={SetPin}
        />

        {/* Student only protected routes */}
        <StudentRoute
          exact
          path={student_path.STUDENT_DASHBOARD_PATH}
          component={Dashboard}
        />
        <StudentRoute
          exact
          path={`${student_path.SUBMITTED_WORKLOG_DETAILS}/:id`}
          component={SubmittedWorklogDetailsPage}
        />
        <StudentRoute
          exact
          path={student_path.VIEW_WORKLOG_PATH}
          component={ViewWorklogDetails}
        />

        <StudentRoute
          exact
          path={student_path.CREATE_WORKLOG_PATH}
          component={NewWorkLog}
        />
        <StudentRoute
          exact
          path={`${student_path.EDIT_WORKLOG_PATH}/:id`}
          component={NewWorkLog}
        />
        <StudentRoute
          exact
          path={student_path.DASHBOARD_CONTACTUS}
          component={ProgressPage}
        />
        <StudentRoute
          exact
          path={student_path.NEWSUPERVISOR_CONTACTUS}
          component={AddNewSupervisor}
        />
        <StudentRoute
          exact
          path={student_path.VIEW_SETTINGS_PATH}
          component={SettingsPage}
        />
        <StudentRoute
          exact
          path={student_path.PROFILE_UPDATE_PAGE}
          component={ProfileUpdatePage}
        />

        {/* these are the employer routes can be accessed by the employer only */}
        <EmployerRoute
          exact
          path={employer_path.EMPLOYER_DASHBOARD_PATH}
          component={EmployerDashboard}
        />
        <EmployerRoute
          exact
          path={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
          component={ProfileUpdatePage}
        />
        <EmployerRoute
          exact
          path={employer_path.WORKLOG_SUBMISSION}
          component={WorklogSubmission}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPLOYER_CONTACT_US}
          component={EmployerContactUs}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPLOYER_DASHBOARD_UNITSIGN}
          component={EmUnitSignPad}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPOYLER_APPRENTICES}
          component={EmployerApprentices}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPOYLER_STUDENTS_ID}
          component={StudentLayout}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPLOYER_DASHBOARD_SIGN}
          component={EmSignature}
        />
        <EmployerRoute
          exact
          path={employer_path.EMPLOYER_STUDENT_WORKLOG_SIGN}
          component={EmSignature}
        />


        {/* Trainer redirection related routes are defined below */}
      <TrainerRoute exact path={trainer_path.TRAINER_DASHBOARD_PATH} component={TrainerDashboardPage}/>
      <TrainerRoute exact path={trainer_path.TRAINER_APPRENTICE_PATH} component={TrainerApprenticePage}/>
      <TrainerRoute exact path={trainer_path.TRAINER_VIEW_SETTING_PATH} component={ProfileUpdatePage}/>
      {/* this route will show the worklog details to be sign by the trainer (same as employer) */}
      <TrainerRoute exact path={trainer_path.TRAINER_WORKLOG_SIGN} component={EmSignature}/>
      <TrainerRoute exact path={trainer_path.TRAINER_STUDENTS_ID} component={TrainerStudentPage}/>


        {/* Supervisor redirection related routes are defined below */}
      <SupervisorRoute exact path={supervisor_path.SUPERVISOR_DASHBOARD_PATH} component={TrainerDashboardPage}/>
      <SupervisorRoute exact path={supervisor_path.SUPERVISOR_APPRENTICE_PATH} component={TrainerApprenticePage}/>
      <SupervisorRoute exact path={supervisor_path.SUPERVISOR_VIEW_SETTING_PATH} component={ProfileUpdatePage}/>
      {/* this route will show the worklog details to be sign by the supervisor (same as employer) */}
      <SupervisorRoute exact path={supervisor_path.SUPERVISOR_WORKLOG_SIGN} component={EmSignature}/>
      <SupervisorRoute exact path={supervisor_path.SUPERVISOR_STUDENTS_ID} component={TrainerStudentPage}/>

      
      
      {/* Staff redirection related routes are defined below */}
        <StaffRoute exact path={staff_path.STAFF_DASHBOARD_PATH} component={StaffDashboardPage} />
        <StaffRoute exact path={staff_path.TRAINER_VIEW_SETTING_PATH} component={ProfileUpdatePage}/>

        <Route default path={anonymous_path.HOME_PATH} component={ErrorPage} />
      </Switch>
    </>
  );
}
