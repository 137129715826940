import * as SignoutActions from "../../actions/user/signout.user.action";

const initialState = {
  loading: false,
  signoutSuccess: false,
  signoutFailed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SignoutActions.SIGNOUT_USER.REQUEST:
      return {
        ...state,
        loading: true,
        signoutSuccess: false,
        signoutFailed: false,
      };

    case SignoutActions.SIGNOUT_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        signoutSuccess: true,
        signoutFailed: false,
      };

    case SignoutActions.SIGNOUT_USER.FAILURE:
      return {
        ...state,
        loading: false,
        signoutSuccess: false,
        signoutFailed: true,
      };

    default:
      return state;
  }
}
