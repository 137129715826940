import * as LoginWithCodeActions from "../../actions/user/loginWithCode.user.action";

const initialState = {
  loading: false,
  loginWithCodeSuccess: false,
  status: "",
  message: "",
  token: "",
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LoginWithCodeActions.LOGIN_WITH_CODE.REQUEST:
      return {
        ...state,
        loading: true,
        loginWithCodeSuccess: false,
        message: action.payload.message,
      };

    case LoginWithCodeActions.LOGIN_WITH_CODE.SUCCESS:
      return {
        ...state,
        loading: false,
        loginWithCodeSuccess: true,
        status: action.payload.status,
        token: action.payload.data ? action.payload.data.token : null,
        message: action.payload.message,
        user: action.payload.user,
      };

    case LoginWithCodeActions.LOGIN_WITH_CODE.FAILURE:
      return {
        ...state,
        loading: false,
        loginWithCodeSuccess: false,
        status: action.payload.status,
        message: action.payload.message,
        user: null,
      };

    default:
      return state;
  }
}