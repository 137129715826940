import React, { useEffect } from 'react'
import ProgressReportTable from './ProgressReportTable'
import styles from './style.module.scss'
import propTypes from 'prop-types';
import { studentProgressReportData } from "../../../store/actions/employer/StudentProgressReport.action";
import { useDispatch, useSelector } from 'react-redux'

const ProgressReport = (props) => {
    // this comment for disable useeffect dependency
    /* eslint-disable */
    const dispatch = useDispatch();
    //To request the student progress report data
    useEffect(() => {
        dispatch(studentProgressReportData.request(props.id))
    }, [])

    //To fetch the student progress report data from the reducer
    const studentProgressReportDetails = useSelector(
        (state) => state.employerReducers?.StudentProgressReport
    );
    const { progressReport } = studentProgressReportDetails

    return (
        <div className={`${styles.ProgressReport}`}>
            {progressReport.length> 0 && <div className={`${styles.ProgressReportHeading}`}>Progress Report</div> }
            <ProgressReportTable progressReport={progressReport}/>

        </div>
    )
}
ProgressReport.propTypes = {
    id: propTypes.string
}

export default ProgressReport