import { FETCH_PROFILE,
     FETCH_PROFILE_FAILED,
     FETCH_PROFILE_SUCCESS,
     FORGOT_PASSWORD,
     FORGOT_PASSWORD_FAILED,
     FORGOT_PASSWORD_SUCCESS,
     INITIAL_LOAD_USER_DONE,
     LOAD_USER_FROM_LOCAL_STORAGE,
     RESET_PASSWORD,
     RESET_PASSWORD_FAILED,
     RESET_PASSWORD_SUCCESS,
     LOGIN,
     LOGIN_FAILED,
     LOGIN_SUCCESS,
     SIGNOUT,
     SIGNUP,
     SIGNUP_FAILED,
     SIGNUP_RESET,
     SIGNUP_SUCCESS,
     UPDATE_PROFILE,
     UPDATE_PROFILE_FAILED,
     UPDATE_PROFILE_SUCCESS,
     VERIFY_EMAIL,
     VERIFY_EMAIL_FAILED,
     VERIFY_EMAIL_SUCCESS,
     VERIFY_MOBILE,
     VERIFY_MOBILE_FAILED,
     VERIFY_MOBILE_SUCCESS,
     VERIFY_OTP,
     VERIFY_OTP_FAILED,
     VERIFY_OTP_SUCCESS, 
     LOGIN_WITH_PIN,
     LOGIN_WITH_PIN_FAILED,
     LOGIN_WITH_PIN_SUCCESS,
     SET_PIN,
     SET_PIN_SUCCESS,
     SET_PIN_FAILED,
     VERIFY_SIGNUP_LINK,
     VERIFY_SIGNUP_LINK_SUCCESS,
     VERIFY_SIGNUP_LINK_FAILED,
     VERIFY_RESET_PASSWORD_LINK,
     VERIFY_RESET_PASSWORD_LINK_SUCCESS,
     VERIFY_RESET_PASSWORD_LINK_FAILED} from "../types";

export function signout() {
  return { type: SIGNOUT };
}

export function loadUserFromLocalStorage() {
  return { type: LOAD_USER_FROM_LOCAL_STORAGE };
}

export function initialLoadUserDone(user) {
  return { type: INITIAL_LOAD_USER_DONE, user };
}

// LOGIN Actions
export function login({login_id, password, remember_me}) {
  return {
    type: LOGIN,
    payload: {
        login_id: login_id,
        password: password,
        remember_me: remember_me
    },
  };
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
}

export function loginFailed(err) {
  return {
    type: LOGIN_FAILED,
    err,
  };
}
// Set login Pin
export function setPin(pin) {
  return {
    type: SET_PIN,
    payload: {
      pin
    },
  };
}

export function setPinSuccess(user) {
  return {
    type: SET_PIN_SUCCESS,
    user,
  };
}

export function setPinFailed(err) {
  return {
    type: SET_PIN_FAILED,
    err,
  };
}
// LOGIN PIN
export function loginWithPin(pin) {
  return {
    type: LOGIN_WITH_PIN,
    payload: {
      pin
    },
  };
}

export function loginWithPinSuccess(user) {
  return {
    type: LOGIN_WITH_PIN_SUCCESS,
    user,
  };
}

export function loginWithPinFailed(err) {
  return {
    type: LOGIN_WITH_PIN_FAILED,
    err,
  };
}

// Signup Actions
export function signupReset() {
  return {
    type: SIGNUP_RESET,
  };
}

export function signup(userDetails, token) {
  return {
    type: SIGNUP,
    payload: {
      userDetails, token
    },
  };
}

export function signupSuccess(user) {
  return {
    type: SIGNUP_SUCCESS,
    user,
  };
}

export function signupFailed(error) {
  return {
    type: SIGNUP_FAILED,
    error,
  };
}

export function verifySignupLink(token) {
  return {
    type: VERIFY_SIGNUP_LINK,
    payload: { token },
  };
}

export function verifySignupLinkSuccess(user) {
  return {
    type: VERIFY_SIGNUP_LINK_SUCCESS,
    user,
  };
}

export function verifySignupLinkFailed(error) {
  return {
    type: VERIFY_SIGNUP_LINK_FAILED,
    error,
  };
}

// Verify email

export function verifyEmail(token) {
  return {
    type: VERIFY_EMAIL,
    token,
  };
}

export function verifyEmailSuccess(user) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    user,
  };
}

export function verifyEmailFailed() {
  return {
    type: VERIFY_EMAIL_FAILED,
  };
}



export function verifyMobile(userId, mobile) {
  return {
    type: VERIFY_MOBILE,
    userId,
    mobile,
  };
}

export function verifyMobileSuccess(user) {
  return {
    type: VERIFY_MOBILE_SUCCESS,
    user,
  };
}

export function verifyMobileFailed() {
  return {
    type: VERIFY_MOBILE_FAILED,
  };
}

export function verifyOTP(userId, otp) {
  return {
    type: VERIFY_OTP,
    userId,
    otp,
  };
}

export function verifyOTPSuccess(user) {
  return {
    type: VERIFY_OTP_SUCCESS,
    user,
  };
}

export function verifyOTPFailed() {
  return {
    type: VERIFY_OTP_FAILED,
  };
}

export function fetchProfile(userId) {
  return {
    type: FETCH_PROFILE,
    userId,
  };
}

export function fetchProfileSuccess(userProfile) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    userProfile,
  };
}

export function fetchProfileFailed(error) {
  return {
    type: FETCH_PROFILE_FAILED,
    error,
  };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD,
    email,
  };
}

export function forgotPasswordSuccess(user) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    user,
    message: 'Mail sent',
  };
}

export function forgotPasswordFailed(err) {
  return {
    type: FORGOT_PASSWORD_FAILED,
    err,
    message: 'Invalid email!',
  };
}

export function verifyResetPasswordLink(token) {
  return {
    type: VERIFY_RESET_PASSWORD_LINK,
    payload: {
      token
    },
  };
}

export function verifyResetPasswordLinkSuccess(user) {
  return {
    type: VERIFY_RESET_PASSWORD_LINK_SUCCESS,
    user,
  };
}

export function verifyResetPasswordLinkFailed(err) {
  return {
    type: VERIFY_RESET_PASSWORD_LINK_FAILED,
    err
  };
}

export function resetPassword(password, token) {
  return {
    type: RESET_PASSWORD,
    payload: {
      password, token
    }
  };
}

export function resetPasswordSuccess(user) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user,
  };
}

export function resetPasswordFailed(err) {
  return {
    type: RESET_PASSWORD_FAILED,
    err
  };
}

export function updateProfile(userId, userDetails) {
  return {
    type: UPDATE_PROFILE,
    userDetails,
    userId,
  };
}

export function updateProfileSuccess(user) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    user,
  };
}

export function updateProfileFailed() {
  return {
    type: UPDATE_PROFILE_FAILED,
  };
}
