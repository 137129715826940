import { createRequestTypes, action } from "../../../utils/redux";

export const ONBEHALF_SIGN_IN = createRequestTypes("ONBEHALF_SIGN_IN");

export const onBehalfSignIn = {
  request: (data) => action(ONBEHALF_SIGN_IN.REQUEST,data),
  success: (data) => action(ONBEHALF_SIGN_IN.SUCCESS, data),
  failure: (error) => action(ONBEHALF_SIGN_IN.FAILURE, error),
};

export const RESET_ONBEHALF_SIGN_IN = createRequestTypes("RESET_ONBEHALF_SIGN_IN");

export const resetOnBehalfSignIn = {
  success: () => action(RESET_ONBEHALF_SIGN_IN.SUCCESS)
};