import * as upcomingWorkshopdata from "../../actions/employer/UpcomingWorkshop";

const initialState = {
    upcomingworkshop: [],
    loading: false,
    success: false,
    workshopfailure:false,
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case upcomingWorkshopdata.UPCOMING_WORKSHOP.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case upcomingWorkshopdata.UPCOMING_WORKSHOP.SUCCESS:

    return {
      ...state,
      upcomingworkshop: action.payload.data,
      loading: false,
      success: true,
      workshopfailure:false,
    };

  case upcomingWorkshopdata.UPCOMING_WORKSHOP.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      workshopfailure:true,
    };
      default: return state      
    }
}