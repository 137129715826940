import React from 'react'
import styles from './style.module.scss'
import propTypes from 'prop-types';

const ProgressReportTable = (props) => {
  const { progressReport } = props
  
  return (
    <div className={`${styles.ProgressReportTable}`}>
      <table>
        {progressReport.map((data, key) => {
          return (
            <tr key={key}>
              <td>{data.unit} - {data.name}</td>
              <td>{data.status}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

ProgressReportTable.propTypes = {
  progressReport: propTypes.object
}

export default ProgressReportTable