import { createRequestTypes, action } from "../../../utils/redux";
export const FORGOT_PASSWORD = createRequestTypes("FORGOT_PASSWORD");


export const forgotPassword = {
  request: (data) => action(FORGOT_PASSWORD.REQUEST, data),
  success: (data) => action(FORGOT_PASSWORD.SUCCESS, data),
  failure: (error) => action(FORGOT_PASSWORD.FAILURE, error),
};

export const RESET_STATE_FORGOT_PASSWORD = createRequestTypes("RESET_STATE_FORGOT_PASSWORD");

export const resetStateForgotPassword = {
  success: () => action(RESET_STATE_FORGOT_PASSWORD.SUCCESS),
};