import React from "react";
import styles from "./styles.module.scss";
import ContactsList from "./ContactsList";
import { useDispatch } from "react-redux";
import { studentowncontacts } from "../../../../store/actions/student/studentOwnContact.action";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderLabel from "../../../../components/common/HeaderLabel";
// import { useState } from "react";
// import { addSupervisor } from "../../../../store/actions/student/addSupervisor.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { student_path } from "../../../../Constants/paths/student";
import mail from "../../../../assets/images/mailicon.svg";
import phone from "../../../../assets/images/phone.png";
import editIcon from "../../../../assets/images/editIcon.svg";
import passwordKeyIcon from "../../../../assets/images/passwordKeyIcon.svg";
import downArrow from "../../../../assets/images/downArrow.png";
import upArrow from "../../../../assets/images/upArrow.png";
import { fetchProfile } from "store/actions/user/profile.user.actions";
import { paths } from "Constants/paths/common";
import loadinWheel from "../../../../assets/images/blue-Loading-spinner.gif"
// import EmContactLogo from "components/EmployerDashboard/EmContactLogo";

const StudentOwnContacts = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();
  const history = useHistory();
  const supervisorSuccess = useSelector(
    (state) => state.studentReducers?.addNewSupervisorReducer?.success
  );

  //profile data of a user
  const profileReducer = useSelector(
    (state) => state.user.fetchProfileUserReducer
  );

  useEffect(() => {
    dispatch(studentowncontacts.request());
  }, [supervisorSuccess]);

  const contactsReducer = useSelector(
    (state) => state.studentReducers?.studentOwnContactReducer)
  const ContactsData = contactsReducer?.contacts

  const employer = ContactsData?.employers;
  const supervisor = ContactsData?.supervisors;
  const trainer = ContactsData?.trainers;
  const ctiOffice = ContactsData?.trading_name;
  const style = { margin: "5px -16px", width: "120%", position: "static" };
  const styleForMyProfile = {
    margin: "54px -16px 5px -16px",
    width: "120%",
    position: "static",
  };

  //function to move to the new supervisor creation page
  const handleShow = () => {
    history.push(student_path.NEWSUPERVISOR_CONTACTUS);
  };

  useEffect(() => {
    //will fetch the profile of the user
    dispatch(fetchProfile.request());
  }, []);

  //handle the up and down arrow state
  const [arrow, setArrow] = useState(false);

  //hadle the drowpdown arrow of supervisor
  const handleArrowClick = () => {
    setArrow(!arrow);
  };

  //handle the profile edit icon click user will navigate to prifile update page
  const handleProfileEditClick = () => {
    history.push(student_path.PROFILE_UPDATE_PAGE);
  };

  //handle the password update click , user will be navigated to password update page.
  const handlePasswordUpdateClick = () => {
    history.push(paths.CHANGE_PASSWORD);
  };

  return (
    <>
      {(profileReducer.loading || contactsReducer.loadingContacts)? (
        <div className={styles.loadingWheel}>
          <img src={loadinWheel} alt="loadingWheel" />
        </div>
      ) : (
        <>
        <div>
        
          <HeaderLabel text="My Profile" style={styleForMyProfile} />
          <div className={styles.myProfileBox}>
            <div>
              <div className={styles.userName}>
                {profileReducer.profile.name}
              </div>
              <div>
                <div className={styles.phoneIcon}>
                  <img src={phone} />
                  <span>{profileReducer.profile.phone}</span>
                </div>
              </div>
              <div>
                <div className={styles.phoneIcon}>
                  <img src={mail} /><span>{profileReducer.profile.email}</span>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.editIcon}>
                <img src={editIcon} onClick={handleProfileEditClick} />
              </div>
              <div className={styles.passwordKeyIcon}>
                <img
                  src={passwordKeyIcon}
                  onClick={handlePasswordUpdateClick}
                />
              </div>
            </div>
          </div>
          <HeaderLabel text="Company" style={style} />
          <div>
            <div className={styles.companyName}>{ctiOffice}</div>
            <div className={styles.supervisorSection}>
              <div className={styles.SupervisorLabel}>
                <div>Supervisors</div>
                <div>
                  <img
                    src={arrow ? upArrow : downArrow}
                    onClick={handleArrowClick}
                  />
                </div>
              </div>
            </div>

            {arrow &&
              employer?.map((elem) => {
                return (
                  <>
                    <ContactsList data={elem} />
                  </>
                );
              })}
            {arrow && (
              <button
                className={`mb-3 btn btn-info btn-active ${styles.btnworklog}`}
                onClick={handleShow}
              >
                Add Supervisor
              </button>
            )}
          </div>
          {trainer?.length > 0 && <HeaderLabel text="Trainers" style={style} />}
          {trainer?.map((elem) => {
            return (
              <>
                <ContactsList data={elem} trainers={true} />
              </>
            );
          })}
                  
        </div>
        {/* <EmContactLogo  /> */}
        </>
      )}
    </>
  );
};

export default StudentOwnContacts;
