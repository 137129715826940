import React from "react";
import { useSelector } from "react-redux";
import { student_path } from "../../../Constants/paths/student";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import styles from "./styles.module.scss";
import { Container } from "react-bootstrap";
import StaffDashboard from "../../../components/Staff/StaffDashboard/StaffDashboard";
import { useDispatch } from "react-redux";
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { employer_path } from "../../../Constants/paths/employer";
import { trainer_path } from "../../../Constants/paths/trainer";
import { supervisor_path } from "Constants/paths/supervisor";


const StaffDashboardPage = () => {
  /* eslint-disable */
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(studentCurrentPage.success("home"))

  //reducer data for onBehalfLogged In user
  const onBehalfLoggedInUserReducer = useSelector(
    (state) => state.staffReducers.OnBehalfSignIn
  );
  const onBehalfUserData = onBehalfLoggedInUserReducer.onBehalfData;

  //after successfull onBehalf Login we will be redirected to student dashboard
  useEffect(() => {
    if (onBehalfUserData) {
      switch (onBehalfUserData?.user?.type.toLowerCase()) {
          case "s":
            history.push(student_path.STUDENT_DASHBOARD_PATH);
          break;
          case "e":
            history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
          break;
          case "t":
            history.push(trainer_path.TRAINER_DASHBOARD_PATH);
          break;
          case "d":
            history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH);
          break;
      }
    }
  }, [onBehalfLoggedInUserReducer?.loading]);

  return (
    <>
      <div className={styles.mainContainer}>
        <Container className={`pt-3 ${styles.containerEmployer}`}>
            <StaffDashboard />
        </Container>
      </div>
    </>
  );
};

export default StaffDashboardPage;
