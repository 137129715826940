import React from "react";
import styles from "./styles.module.scss";
import logo from "../../../assets/images/logo.png";
import mail from "../../../assets/images/mailicon.svg";
import phone from "../../../assets/images/phone.png";
import internet from "../../../assets/images/InternetIcon.svg";
import propTypes from "prop-types";

const EmContactLogo = (props) => {
  return (
    <div className={styles.ContactLogo} style={props.style}>
      {props.isImg ? <></> : <img src={logo} alt="logo" />}
      <div className={styles.ConatactBox}>
      <div className={styles.mail}>
        <span><img src={mail} alt="mail" /></span>
        <a href="mailto: hello@cti.edu.au">hello@cti.edu.au</a>
      </div>
      <div className={styles.phoneNumber}>
      <span><img src={phone} alt="phone" /></span>
        <a href="tel:1300 859 091">1300 859 091</a>
      </div>
      <div className={styles.sms}>
      <span><img src={internet} alt="internet" /></span>
        <a href="https://www.cti.edu.au" target="_blank"  rel="noopener noreferrer">https://www.cti.edu.au</a>
      </div>
      </div>
    </div>
  );
};

EmContactLogo.propTypes = {
  style: propTypes.object,
  isImg: propTypes.bool
};

export default EmContactLogo;
