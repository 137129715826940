import React from 'react';
import styles from './styles.module.scss';
import SignatureCanvas from "react-signature-canvas";
import { useState } from 'react';
import Cancel from "../../../assets/images/cancel.png"
import { useDispatch } from 'react-redux';
import * as SiteActions from '../../../store/actions/employer/Employer.action';
import propTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const SignPad = (props) => {
    const [signPadRef, setSignPadRef] = useState({});
    const [values, setValues] = useState({
      show: "block",
      text: "text",
      dataURI: null,
      signPadRef: "",
      didRedirect: false,
    });

 //finding the worklog id using params '
const {search} = useLocation();
const queryData=search?.split("&");
//this const is having the data of worklog id fetched from the query
const worklog_id=queryData[1]?.split("=")[1]



    const WorklogId = props.studentData?.signUnit.worklog_id ? props.studentData?.signUnit.worklog_id : +worklog_id
    //if the signature box is empty submitbutton is disable
    const [empty, setEmpty] = useState(true);
    const signEnable=()=>{
        setEmpty(false)
      }

    const setRef = (reference) => {
        setSignPadRef(reference);
      };

    const dispatch = useDispatch();

    // to trim out signatures
    const trimSignatures = () => {
      setValues({
        ...values,
        dataURI: signPadRef.getTrimmedCanvas().toDataURL("image/png"),
      });
      var payload = {
        signature:signPadRef.getTrimmedCanvas().toDataURL("image/png"),
        worklog_id:WorklogId,
      }
      dispatch(SiteActions.employerSign.request(payload));
    };

    // To Clear Canvas
    const clearCanvas = () => {
      setSignPadRef(signPadRef.clear());
      setEmpty(true)
  };
  
  return (
    <div className={styles.SignPadFull}>
    <div className={styles.signPad}>
        <div className={styles.signpadHeader}>
            <div className={styles.heading}>Approval Signature</div>
            <div onClick={props.onCancel} className={styles.cancelIcon}><img src={Cancel} alt="cancel Icon" /></div>
        </div>
        <div className="modal-body text-center">
           <SignatureCanvas
             penColor="black"
             canvasProps={{ className: "signCanvas" }}
             ref={setRef}
             onBegin={signEnable}
           />
         </div>
        
         <div className="col-md pb-4 mx-auto row">
            <div className="centerWidth">
              <div className={`centerBtn ${styles.submitButton}`} >
                <button
                  className="btn btn-active halfBtn btn-info"
                  onClick={trimSignatures}
                  disabled={empty}>
                  Submit
                </button>
              </div>

              <div className="centerBtn">
                <button
                  className="btn btn-danger halfBtn bg-danger"
                  onClick={clearCanvas}>
                  Clear
                </button>
              </div>
            </div>
        </div>
    </div>
    </div>
  )
}


SignPad.propTypes = {
  onCancel: propTypes.func, //for close sign pad model
  studentData: propTypes.object, //add sign for particular worklog student
  
}

export default SignPad