import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import worklogs from "../../../assets/images/worklogs.svg"
import progressIcon from "../../../assets/images/progressIcon.svg"
import homeIcon from "../../../assets/images/Home.png"
import settingIcon from "../../../assets/images/Setting.svg"
import { useDispatch, useSelector } from "react-redux";
import { student_path } from "../../../Constants/paths/student";
import { fetchLocally, removeFromLocalStorage } from "../../../utils/localStorage";
import { history } from "../../../store";
import { staff_path } from "../../../Constants/paths/staff";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";

const StudentFooter = () => {
  const dispatch = useDispatch();
const currentPage = useSelector(state=>state.studentReducers.studentCurrentPageReducer.currentPage)
const onBehalfToken = fetchLocally("onbehalf_token")
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}
  return (
    <>
    
    <div className={onBehalfToken ? styles.onBehalfFooter :styles.employerFooter}>
    {onBehalfToken&&<div className={styles.onbehalfLogOut}onClick={handleOnbehalfLogOut}>Close</div>}
      {/* we will navigate to the student dashboard which is a home page for student */}
      <Link to={student_path.STUDENT_DASHBOARD_PATH} className={styles.linkColor} style={{color: currentPage==="home" ? '#0098C8' : '#888'}}>
        <div className={styles.homeButton}>
          <div className={styles.homeIcon}>
            <div className={styles.iconimg}>
            <img src={homeIcon} alt="Home"/>
            </div>
            Home
          </div>
        </div>
      </Link>
      {/* using this link we can navigate to the student worklog page */}
      <Link to={student_path.VIEW_WORKLOG_PATH} className={styles.linkColor} style={{color: currentPage==="worklogs" ? '#0098C8' : '#888'}}>
        <div className={styles.worklogButton}>
          <div className={styles.apprenticesIcon}>
            <div className={styles.iconimg}>
              <img src={worklogs} alt="worklog"/>
            </div>
            Worklogs
          </div>
        </div>
      </Link>
      {/* this link will navigate to contact us page */}
      <Link to={student_path.DASHBOARD_CONTACTUS} className={styles.linkColor} style={{color: currentPage==="progress" ? '#0098C8' : '#888'}}>
        <div className={styles.contactSupervisor}>
          <div className={styles.apprenticesIcon}>
            <div className={styles.iconimg}>
            <img src={progressIcon} alt="Progress Icon" />
            </div>
            Progress
          </div>
        </div>
      </Link>
      {/* this link will navigate to dashboard settings page where user can change their profile */}
      <Link to={student_path.VIEW_SETTINGS_PATH} className={styles.linkColor} style={{color: currentPage==="settings" ? '#0098C8' : '#888'}}>
        <div className={styles.settingbutton}>
          <div className={styles.settingIcon}>
            <div className={styles.iconimg}>
            <img src={settingIcon} alt="Settings"/>
            </div>
            Settings
          </div>
        </div>
      </Link>
    </div>
    </>
  );
};

export default StudentFooter;
