import * as ForgotPasswordActions from "../../actions/user/forgotPassword.user.actions";

const initialState = {
  loading: false,
  forgotPasswordSuccess: null,
  status: "",
  message: "",
  token: "",
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ForgotPasswordActions.FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        forgotPasswordSuccess: null,
      };

    case ForgotPasswordActions.FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordSuccess: true,
        status: action.payload.status,
        token: action.payload.token,
        message: action.payload.message,
        data: action.payload.data,
      };

    case ForgotPasswordActions.FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        forgotPasswordSuccess: false,
        status: action.payload.status,
        message: action.payload.message,
      };

      case ForgotPasswordActions.RESET_STATE_FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordSuccess: null,
        status: "",
        message: "",
        token: "",
        data: {},
      };


    default:
      return state;
  }
}
