import { all } from "redux-saga/effects";

// import all sagas here
import worklogSaga from "./worklogs";
import employerSagas from "./employer";
import userSagas from "./user";
import studentSagas from "./student";
import trainerSagas from "./trainer";
import staffSagas from "./staff"


export default function* watchAll() {
  yield all([
    ...worklogSaga,
    ...employerSagas,
    ...userSagas,
    ...studentSagas,
    ...trainerSagas,
    ...staffSagas
  ]);
}
