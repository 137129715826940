
import { push } from "connected-react-router";
import { all, put, takeLatest } from "redux-saga/effects";
import { initialLoadUserDone } from "../../../store/actions";
import { INITIAL_LOAD_USER_DONE, LOAD_USER_FROM_LOCAL_STORAGE } from "../../../store/types";
import { isPinAuthSkip } from "../../../utils/helper/Student";
import { getJsonFromLocalStorage, removeFromLocalStorage } from "../../../utils/localStorage";


function* loadUserFromLocalStorage() {

  try {
    //const { user } = JSON.parse(getJsonFromLocalStorage("login_credentials"));
    const localUser = getJsonFromLocalStorage("login_credentials");
  
    // Flow for ask pin verification every day.
    const today = new Date().getDate();
    const checkPin = getJsonFromLocalStorage("login_credentials_pin");
    if (checkPin && !isPinAuthSkip()) {
      const pinLastCheckDate = new Date(checkPin.created_at).getDate();
      if (today !== pinLastCheckDate) {
        removeFromLocalStorage.loginCredentialPin();
      }
    }

    yield put(initialLoadUserDone(localUser));
  } catch (error) {
  }
    
  }


  
function* checkUser(action) {


  try {
    if (action.user===undefined) {
    
      yield put(push('/login'));

    }else{
      
      yield put(push("/student/dashboard"));
      
    }
  } catch (error) {
  }     
}

function* localStorageSaga() {
    yield all([takeLatest(LOAD_USER_FROM_LOCAL_STORAGE, loadUserFromLocalStorage),
     takeLatest(INITIAL_LOAD_USER_DONE, checkUser)
]
    )
}

export default localStorageSaga;

  
