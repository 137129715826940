const HOME_PATH = "/";
const LOGIN_PATH = "/login";
const FORGOT_PASSWORD_PATH = "/forgot/password";
const GENERATE_LOGIN_LINK_PATH ="/generate/login/link"
const USER_FORGOT_PATH = "/user/forgot";
const RESET_PASSWORD_PATH = "/reset/password/:token";
const VERIFY_PIN_PATH = "/sign/pin";
const SET_PIN_PATH = "/set/pin";
const SIGNUP_PATH = "/signup/:token";
const LOGIN_WITH_CODE_PATH= "/l/:code"


export const anonymous_path = {
    HOME_PATH,
    LOGIN_PATH,
    FORGOT_PASSWORD_PATH,  
    USER_FORGOT_PATH,
    RESET_PASSWORD_PATH,    
    VERIFY_PIN_PATH,
    SET_PIN_PATH,
    SIGNUP_PATH,
    GENERATE_LOGIN_LINK_PATH,
    LOGIN_WITH_CODE_PATH
}