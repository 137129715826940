import React, { useState, useEffect, useRef } from "react";
import { Accordion, Card } from "react-bootstrap";
import CustomRadioButton from "../../../components/common/CustomRadioButton/index";
import styles from "../Accordian/styles.module.scss"
import ReactTooltip from "react-tooltip";
import camera from "../../../assets/images/camera.svg";
import photos from "../../../assets/images/photos.svg";
import propTypes from "prop-types";
import downArrow from "../../../assets/images/downArrow.png"
import upArrow from "../../../assets/images/upArrow.png"

const AccordionComponent = (props) => {
  //Name initialize by the props
  const { data, title, value, setinputChecked, defaultKey } = props;

  const ref = useRef(0);
  const [active, setActiveKey] = useState("0");
  function toggleActive() {
    if (active === "0") {
      setActiveKey("1");
    } else {
      setActiveKey("0");
    }
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  //To get the innerwidth of the window
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });



  return (
    <Accordion
      defaultActiveKey={props.activeAccordionKey}
      onSelect={() => toggleActive()}
      className={styles.accordianMain}
    >
      <div>
        <Accordion.Toggle
          ref={ref}
          eventKey="0"
          as={Card.Header}
          className="p-3 pt-4 pb-4"
          style={{
            backgroundColor: "white",
            borderBottom: "none", 
            marginBottom: "-22px"
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="removeExtraBotMargin heading">{title}</p>
            <img
              src={defaultKey? (active ===  "0" && downArrow) || (active === "1" && upArrow) : (active ===  "1" && downArrow) || (active === "0" && upArrow)}
              style={{ width: "20px", height: "20px" }}
             alt="updownimage"/>
            
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" >
          <Card.Body className="p-3">
            {data.map((elem, index) => {
              return (
                <div className={styles.overduTaskName} key={index}>
                  <div className={styles.radiobutton}>
                    <CustomRadioButton
                      key={index}
                      id={`${props.id}-${elem.task_id}`}
                      value={elem}
                      label={elem.name}
                      name="tasks"
                      onChange={() => {
                        props.onChange();
                        // ref.current.click();
                        // toggleActive();
                      }}
                      isInline={false}
                      setInputValue={props.setInputValue}
                      checked={value?.task_id === elem.task_id}
                      setinputChecked={setinputChecked}
                    />
                  </div>
                  <div className={styles.photosRequired}>
                    <span data-tip data-for="photoRequired" className={`${styles.iconphoto}`}><img src={camera} alt="photoRequired" /></span>
                    <span className={`${styles.textGreen}`}>{elem.photos_uploaded}</span> | {elem.photos_required}</div>
                  <div className={styles.numberPerformed}>
                    <span data-tip data-for="photoPerformed" className={`${styles.iconphoto}`}><img src={photos} alt="No.of timesPerformed" /></span>
                    <span className={`${styles.textGreen}`} >{elem.number_times_performed}</span> | {elem.number_of_times}</div>
                  <ReactTooltip id="photoRequired" place="top" effect="solid" event={isDesktop ? "click" : ""}>
                    Photos Required
                  </ReactTooltip>
                  <ReactTooltip id="photoPerformed" place="top" effect="solid" event={isDesktop ? "click" : ""}>
                    Number of times performed
                  </ReactTooltip>
                </div>

              );
            })}
          </Card.Body>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};


AccordionComponent.propTypes={
  data:propTypes.array,
  title:propTypes.string,
  id:propTypes.string,
  name:propTypes.string,
  value:propTypes.object,
  onChange:propTypes.func,
  setInputValue:propTypes.func,
  setinputChecked:propTypes.func,
}

export default AccordionComponent;