import { call, put, takeLatest } from "redux-saga/effects";
import { fetchWorklogForStudentAPI } from "../../../services/worklogs/fetchworklogs.service";
import {
  FETCH_WORKLOGS,
  fetchWorklogs,
} from "../../../store/actions/worklogs/fetchworklogs.worklogs.actions";

function* handleFetchWorklogs({ payload }) {
  try {
    
    const { data:resData } = yield call(fetchWorklogForStudentAPI.getWorklogs, payload);

    const data = resData?.data
    yield put(fetchWorklogs.success(data));
  } catch (e) {
    yield put(fetchWorklogs.failure(e.data));
  }
}

function* fetchWorklogsSaga() {
  yield takeLatest(FETCH_WORKLOGS.REQUEST, handleFetchWorklogs);
}

export default fetchWorklogsSaga;
