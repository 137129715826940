import * as FetchProfileActions from "../../actions/user/profile.user.actions"

const initialState = {
    userProfile: [],
    loadingProfile: false,
    errorFetchProfile: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FetchProfileActions.FETCH_PROFILE.REQUEST:
            return {
                ...state,
                loadingProfile: true,
            };
        case FetchProfileActions.FETCH_PROFILE.SUCCESS:
            return {
                ...state,
                userProfile: action.payload.value,
                loadingProfile: false,
            };
        case FetchProfileActions.FETCH_PROFILE.FAILURE:
            return {
            ...state,
            userProfile: action.payload,
            loadingProfile: false,
            };
        default:
            return state;
    }
}