import * as LessonConfirmationActions from "../../../store/actions/worklogs/lessonConfirmation.worklogs.action";

const initialState = {
    contact_id:"",
    loadingLessonConfirmation: false,
    lessonConfirmationError:false,
    lessonConfirmationStatus:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LessonConfirmationActions.LESSON_CONFIRMATION.REQUEST:
            return {
                ...state,
                loadingLessonConfirmation: true,
                lessonConfirmationStatus:false
            };
        case LessonConfirmationActions.LESSON_CONFIRMATION.SUCCESS:
            return {
                ...state,
                loadingLessonConfirmation: false,
                lessonConfirmationError:false,
                lessonConfirmationStatus:true
            };
           
        case LessonConfirmationActions.LESSON_CONFIRMATION.FAILURE:
            return {
            ...state,
            
            loadingLessonConfirmation: false,
            lessonConfirmationError:true,
            lessonConfirmationStatus:false
            };
        default:
            return state;
    }
}