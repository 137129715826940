import React from "react";
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import EmDashboardHeader from "components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import StudentOwnContacts from "components/Dashboard/DashboardContactus/StudentOwnContacts";
import StudentFooter from "components/Dashboard/StudentFooter/StudentFooter";
import { studentCurrentPage } from "store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";


const SettingsPage = () => {
  const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("settings"));
  return (
    <>

<div className={styles.mainContainer}>
      <Container className={`pt-3 ${styles.container}`} onLoad={window.scrollTo(0, 0)}>
        <EmDashboardHeader isImg={false} />
        <StudentOwnContacts />
        <StudentFooter disableContact={false} />
      </Container>
    </div>
    </>
  );
};

export default SettingsPage;
