import * as FetchWorklogSupervisorActions from "../../../store/actions/worklogs/fetchWorklogSupervisor.worklogs.action";

const initialState = {
    SupervisorId:"",
    SupervisorName:"",
    loadingSupervisor: false,
    worklogFetchError:false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FetchWorklogSupervisorActions.FETCH_WORKLOG_SUPERVISOR.REQUEST:
            return {
                ...state,
                loadingSupervisor: true,
            };
        case FetchWorklogSupervisorActions.FETCH_WORKLOG_SUPERVISOR.SUCCESS:
            return {
                ...state,
                supervisors:action.payload.supervisors,
                loadingSupervisor: false,
                worklogFetchError:false,
            };
           
        case FetchWorklogSupervisorActions.FETCH_WORKLOG_SUPERVISOR.FAILURE:
            return {
            ...state,
             Supervisor_id: action.payload,
            Supervisor_name:action.payload,
            loadingSupervisor: false,
            worklogFetchError:true,
            };
        default:
            return state;
    }
}