import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../../layouts/Auth";
import FormCard from "../../../components/auth/FormCard";
import { loginWithPin } from "../../../store/actions/user/loginWithPin.user.actions";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
import { set_authenticate_pin } from "../../../utils/helper/Student/index";
import "./styles.module.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwtDecode from "jwt-decode";
import { removeFromLocalStorage, saveAuthToken } from "../../../utils/localStorage";

import { fetchLocally } from "../../../utils/localStorage";
import { employer_path } from "../../../Constants/paths/employer";
import { student_path } from "../../../Constants/paths/student";
import { anonymous_path } from "../../../Constants/paths/anonymous";
import { trainer_path } from "../../../Constants/paths/trainer";
import { staff_path } from "../../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";

// Old name - PinForm
const VerifyPin = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  const dispatch = useDispatch();
  const UserFirstName = fetchLocally("user_first_name");
  const history = useHistory();
  const [values, setValues] = useState({
    pin: "",
    isPinActive: false,
    isPinCorrect: "",
    showPin: false,
    signinError: false,
    signinErrorMessage: "",
    loading: false,
  });
  const pinState = useSelector((state) => state.user.loginWithPin);
  const stateOfPin = pinState.loginWithPinSuccess;

  const token = fetchLocally("login_credentials")
  const decodedToken = jwtDecode(token)


  useEffect(() => {

    if (stateOfPin === true) {
      saveAuthToken(pinState.token)
      if (decodedToken.user_type === "Student" || decodedToken.contact_type === "S") {
        history.push(student_path.STUDENT_DASHBOARD_PATH)
      } else if((decodedToken.user_type === "Employer" || decodedToken.contact_type === "E")){
        history.push(employer_path.EMPLOYER_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Trainer" || decodedToken.contact_type === "T")){
        history.push(trainer_path.TRAINER_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Staff" || decodedToken.contact_type === "C")){
        history.push(staff_path.STAFF_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Supervisor" || decodedToken.contact_type === "D")){
        history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
      }

    }



  }, [stateOfPin])



  useEffect(() => {
    if (pinState.loading) {
      setValues({
        ...values,
        loading: true,
      });
    } else {
      setValues({
        ...values,
        loading: false,
      });
    }
    if (pinState.loginWithPinSuccess === false) {
      setValues({
        ...values,
        signinError: true,
        signinErrorMessage: pinState.message,
        loading: false,
      });
    } else {
      set_authenticate_pin(values.pin);
    }
  }, [pinState]);


  //all handler function
  const inputChangeHandler = (name) => (event) => {
    setValues({
      ...values,
      signinErrorMessage: "",
      [name]: event.target.value,
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if(values.pin === ""){
      return;
    }
    setValues({
      ...values,
      loading: true,
    });
    let pin = values.pin;
    dispatch(loginWithPin.request(pin));

  };
  const handleNotMe = () => {
    removeFromLocalStorage.signout();
    history.push(anonymous_path.LOGIN_PATH);
  }

  const loginPinForm = () => {
    return (
      <form className="form-width-inherit">
        <Input
          label="Verify Pin"
          type="number"
          onKeyDown={(e) => ["e", "E", "+", "-", "=", "'", '"', "."].includes(e.key) && e.preventDefault()}
          id="pin"
          name="password"
          placeholder="Pin"
          aria-describedby="pinHelp"
          value={values.pin}
          onChange={inputChangeHandler("pin")}
          error={values.signinErrorMessage}
          required
        />
        <Button
          type={values.loading ? "button" : "submit"}
          disable={values.pin.length <= 0}
          onClick={handleSubmit}
          loading={values.loading}
        >
          {values.loading ? "Verifying.." : "Verify Pin"}
        </Button>
        <div class="row mt-4 col-md-12 pl-2 pr-0 d-flex justify-content-between mb-2" >
          {/* <span className="btn text-primary" onClick={handleResetPin}>
          Click here to Reset pin.
        </span> */}
          <span className="btn text-primary " onClick={handleNotMe}>
            Not me
          </span>
        </div>
      </form>
    );
  };

  return (
    <Auth>
      <FormCard formFor="verifyPin" title={`Hi ${UserFirstName}, please sign in with your pin`}>
        {loginPinForm()}
      </FormCard>
    </Auth>
  );
};

export default VerifyPin;
