import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import "./style.scss";
import propTypes from "prop-types";
import DisclaimerImg  from "../../../assets/images/Disclaimer.svg"
import RedCrossIcon from "../../../assets/images/RedCross.svg"

const UploadImage = (props) => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const [Label, setLabel] = useState("Files will appear here");
  const {
    setFiles,
    files,
    data,
    photos,
    setPhotos,
    mergedPhotoArray,
    setMergedPhotoArray,
  } = props;

  let formData = new FormData();

  //if the data is exist than create array of images
  const redImages =
    data !== undefined
      ? data?.taskDetail[0].photos !== undefined &&
        data?.taskDetail[0].photos.length > 0
        ? data.taskDetail[0].photos.filter((item) => item.photo!=="").map(item=>item?.photo)
        : []
      : [];

  const [reducerPhoto, setReducerPhoto] = useState(redImages);
  const [uploadError, setUploadError] = useState(false);

  //concating the photo array
  useEffect(() => {
    setMergedPhotoArray(reducerPhoto.concat(photos));
  }, [reducerPhoto, photos]);

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const handleFileInput = (e) => {
    //To do - when user uploads multiple files, do the validation
    if (e.target.files[0]?.size > 8500000) {
      e.target.value = null;
      setUploadError(true);
    } else {
      setUploadError(false);
      if (files.length > 0) {
        setFiles([...files, ...e.target.files]);
      } else {
        setFiles([...e.target.files]);
        formData.append("file", e.target.files[0]);
      }
    }
  };

  //this function will close down the error box while uploading a larger size image
  const handleUploadErrorClose = ()=>{
    setUploadError(false);
    clearInputTextOfImage();
  }

//this function will erase the placeholder value inside upload input box
const clearInputTextOfImage = () =>{
  document.getElementById('custom-file').value = "";
  if(Label === "Files will appear here"){
    setLabel("")
  }
  if(Label === ""){
    setLabel(" ")
  }
  if(Label === " "){
    setLabel("")
  }
}
 
  //Image deleted on the croos icon clicked on merged Photo
  const handleMergedPhotoRemove = (index) => {
    const dataValue = mergedPhotoArray[index];
    if (photos.find((item) => item === dataValue)) {
      const idx = photos.indexOf(dataValue);
      setPhotos([...photos.slice(idx - idx, idx), ...photos.slice(idx + 1)]);
      setFiles([...files.slice(idx - idx, idx), ...files.slice(idx + 1)]);
    } else {
      const idxToRemove = reducerPhoto.indexOf(dataValue);
      setReducerPhoto([
        ...reducerPhoto.slice(idxToRemove - idxToRemove, idxToRemove),
        ...reducerPhoto.slice(idxToRemove + 1),
      ]);
    }
    setMergedPhotoArray([
      [
        ...mergedPhotoArray.slice(index - index, index),
        ...mergedPhotoArray.slice(index + 1),
      ],
    ]);
    clearInputTextOfImage();
  };

const lastElement = files[files.length - 1];
  return (
    <Form.Group>
      <Form.File
        id="custom-file"
        label={lastElement?.name ? lastElement?.name : Label}
        custom
        accept="image/* , .heic"
        onChange={handleFileInput}
      />
{uploadError && <div className="UploadError">
<img className="disclaimerImg" src={DisclaimerImg} alt = "DisclaimerImg"/>
<span>The file size limit is 8mb.</span>
<img className="crossIconImg" src={RedCrossIcon} alt = "Cross Icon" onClick={handleUploadErrorClose}/> 
</div>}
      <div className="collectionThumbnail">
        {mergedPhotoArray?.length > 0 &&
          mergedPhotoArray.map((file, index) => (
            file==="" ? <></> :
            <div className="ImgBox">
              <img className="thumbnail" src={file} alt="thumbnail" />
              <i
                class="fa fa-times"
                aria-hidden="true"
                onClick={() => {
                  handleMergedPhotoRemove(index);
                }}
              ></i>
            </div>
          ))}
      </div>
    </Form.Group>
  );
};

UploadImage.propTypes = {
  setImageId: propTypes.number,
  imageId: propTypes.number,
  files: propTypes.array,
  setFiles: propTypes.func,
  data: propTypes.object,
  photos: propTypes.array,
  setPhotos: propTypes.func,
  mergedPhotoArray: propTypes.array,
  setMergedPhotoArray: propTypes.func,
};

export default UploadImage;
