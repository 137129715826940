import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import propTypes from 'prop-types';
import moment from 'moment'
const StageNavigation = (props) => {

    const totalStages = 4
    const pageNumbers = [...Array(totalStages + 1).keys()].slice(1)
    const [step, setStep] = useState(0);

    // difference between started date to today date
    const start_date = moment(props.date, 'DD-MM-YYYY')
    const date1 = new Date(start_date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // set stages 
    useEffect(() => {
        if (diffDays < 365) {
            setStep(1);
        } else if (diffDays < 730) {
            setStep(2);
        } else if (diffDays < 1095) {
            setStep(3);
        } else if (diffDays >= 1095) {
            setStep(4);
        }
    }, [diffDays])



    return (
        <div className={`${styles.stages}`}>
            <ul>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} className={`${step >= pgNumber ? styles.selected : ''}`}>
                        {pgNumber}
                    </li>
                ))}
            </ul>
        </div>
    )
}
StageNavigation.propTypes = {
    date: propTypes.string, 
    id: propTypes.string
}

export default StageNavigation