import React, { useEffect } from "react";
import StudentFooter from "../StudentFooter/StudentFooter";
import EmDashboardHeader from "components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader";
import styles from "./styles.module.scss"
import { useSelector } from "react-redux";
import { studentCourseProgress } from "store/actions/student/studentCourseProgress.action";
import { useDispatch } from "react-redux";
import ProgressReportTable from "components/EmployerDashboard/ProgressReport/ProgressReportTable";
import ProgressConstants from "components/EmployerDashboard/SingleStudentLayout/ProgressConstants/ProgressConstants";
import HeaderLabel from "components/common/HeaderLabel";
import { fetchAllWorkshops } from "store/actions/worklogs/fetchAllWorkshops.action";
import { studentCurrentPage } from "store/actions/student/student.curerntPage.action";
import loadinWheel from "../../../assets/images/blue-Loading-spinner.gif"


const Progress = () => {
 /* eslint-disable */
  const dispatch = useDispatch();

  //set current page is progress page
  dispatch(studentCurrentPage.success("progress"));
   //reducer data for workshops
   const workshopsReducer = useSelector(
    (state) => state?.worklog?.fetchAllWorkshopsReducer
  );
  const allWorkshops = workshopsReducer?.workshops;
  
  //fetch the cousre progress 
  const studentCourseProgresReducer = useSelector(
    (state) => state.studentReducers?.studentCourseProgressReducer
);
  const studentProgressReportDetails = studentCourseProgresReducer?.progressReport

  const totalWorkshops =  allWorkshops?.filter(item=> (item.status==="Future" || item.status === "Attended" || item.status === "Not Booked")).length
  const attendedWorkshops = allWorkshops?.filter(item=> (item.status === "Attended")).length

//call api when user navigate to this page
useEffect(()=>{
  //fetch the workshops
  dispatch(fetchAllWorkshops.request());
  //fetch the unit status
  dispatch(studentCourseProgress.request())
},[])


// styles for header label
const styleForWorkshops = { margin: "65px -16px 5px -16px", width: "120%", position: "static" };
const styleForUnits = { margin: "25px -16px 5px -16px", width: "120%", position: "static" };
  return (
    <>
      <EmDashboardHeader />
      {(workshopsReducer.loading || studentCourseProgresReducer.loading) ?         <div className={styles.loadingWheel}>
          <img src={loadinWheel} alt="loadingWheel" />
        </div>:
      <>
            <HeaderLabel text="Workshops" style={styleForWorkshops}/>
        {/* this container contains the progress bar of workshops */}
        {
          <div className={styles.workshopsCompleted}>
            <div className={styles.workshopCompletedLabel}>
              <div className={`${styles.details}`}>Workshops Completed</div>
              <div className={`${styles.details}`}>{attendedWorkshops || 0 } / <span className={styles.totalWorkshops}>{totalWorkshops || 0}</span></div>
            </div>

            <div className={styles.statusField}>
                  <div className={styles.activeStatus}>
                    <div
                      className={styles.statusBar}
                      style={{ width: (attendedWorkshops/totalWorkshops)*100 + "%" }}
                    ></div>
                  </div>              
            </div>
          </div>
        }
            <div className={`${styles.ProgressReport}`}>
            {studentProgressReportDetails.length> 0 && <>
              <HeaderLabel text="Units" style={styleForUnits} />
              <ProgressReportTable progressReport={studentProgressReportDetails}/>
            <ProgressConstants />
              </> }


        </div>
      </>}

      <StudentFooter />
    </>
  );
};

export default Progress;
