export const ApiEndpoints = Object.freeze({
    fetchWorklogForStudent: {
        url: `/worklog/fetchallworklogs`,
        method: "GET",
      },
      CreateWorkLog: {
        url: `/worklog/createworklog`,
        method: "POST",
      },
      FetchWorkLogDetail: {
        url: `/worklog/detail/`,
        method: "GET",
      },
      FetchTasksOfStudent: {
        url: `/worklog/tasks`,
        method: "GET",
      },
      FetchWorkLogSupervisor: {
        url: `/worklog/supervisors`,
        method: "GET",
      },
      FetchWorkLogEquipment: {
        url: `/worklog/equipments/`,
        method: "POST",
      },
      FetchFrequentEquipment: {
        url: `/worklog/frequent_equipments/`,
        method: "POST",
      },
      DeleteWorklog:{
        url:`/worklog/deleteworklog/`,
        method:"DEL"
      },
      FetchOverdueTasks:{
        url:`/worklog/fetchoverduetasks`,
        method:"GET",
      },
      FetchLessons:{
        url:`/worklog/lessons`,
        method:"GET"
      },
      LessonConfirmation:{
        url:`/worklog/confirmlesson/`
      },
      FetchAllWorkshop:{
        url:`/student/workshops`,
        method:"GET"
      },
      StudentOwnContact:{
        url:`/student/about`,
        method:"GET"
      },
      AddSupervisor:{
        url:`student/supervisor`,    //url for adding the supervisor for student by student
        method:"POST"
      },
      FetchStudentCourseProgress:{
        url:`/student/progress`,
        method:"GET"
      },
      FetchStudentTheoryAssessments:{
        url:`/student/assessments`,
        method:"GET"
      }

});