import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as UpcomingStudentWorkshop from "../../actions/employer/UpcomingStudentWorkshop.action";

function *getUpcomingWorkshopData(action){
   
    try{
        const {data} = yield call(EmployerApi.getUpcomingStudentWorkshop, action.payload)
        //extract the data here     
        yield put(UpcomingStudentWorkshop.upcomingStudentWorkshopdata.success(data));
    } catch (e) {
        yield put(UpcomingStudentWorkshop.upcomingStudentWorkshopdata.failure(e));
    }
}
function *getUpcomingStudentWorkshopSaga(){
    yield (
        takeLatest(UpcomingStudentWorkshop.UPCOMING_STUDENT_WORKSHOP.REQUEST, getUpcomingWorkshopData)
    );
}

export default getUpcomingStudentWorkshopSaga;