import {call , put , takeLatest} from "@redux-saga/core/effects";
import { TrainerApi } from "../../../services/trainer/trainer.services";
import * as TrainerActions from "../../actions/trainer/getAllWorklogsOfStudentForTrainer.action"


function *getAllWorklogsOfStudentForTrainer(action){

try {
    //this will fetch the data Object from the Api which is used
    const {data:resData} = yield call(TrainerApi.getAllWorklogsOfStudentForTrainer, action.payload)
    yield put(TrainerActions.getAllWorklogsOfStudentForTrainer.success({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }));
} catch (e) {
    //this will catch an error when there is an error in the fetching api
    const {data:resData} = e
    yield put(TrainerActions.getAllWorklogsOfStudentForTrainer.failure({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }))
}
}

function *getAllWorklogsOfStudentForTrainerSaga(){
    yield takeLatest(TrainerActions.GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER.REQUEST, getAllWorklogsOfStudentForTrainer)
}

export default getAllWorklogsOfStudentForTrainerSaga;