import { combineReducers } from "redux";
import fetchworklogs from "../../../store/reducers/worklogs/fetchWorklogs.worklogs.reducer";
import createWorklogsWorklogsReducer from "./createWorklogs.worklogs.reducer";
import fetchWorklogDetailReducer from "./fetchworklogdetail.reducer";
import fetchWorklogEquipmentReducer from "./fetchworklogequipment.reducer";
import fetchWorklogSupervisorReducer from "./fetchworklogsupervisor.reducer";
import fetchWorklogTasksReducer from "./fetchWorklogTasks.reducer";
import fetchLessonsReducer from "./fetchLessons.reducer";
import deleteWorklogReducer from "./deleteWorklog.reducer";
import lessonConfimationReducer from "./lessonConfimation.reducer";
import fetchAllWorkshopsReducer from "./fetchAllWorkshops.reducer";
import fetchFrequentEquipmentsReducer from "./fetchFrequentEquipments.reducer";
const worklogReducer = combineReducers({
  fetchworklogs,
  fetchWorklogDetailReducer,
  createWorklogsWorklogsReducer,
  fetchWorklogEquipmentReducer,
  fetchWorklogSupervisorReducer,
  fetchWorklogTasksReducer,
  fetchLessonsReducer,
  deleteWorklogReducer,
  lessonConfimationReducer,
  fetchAllWorkshopsReducer,
  frequentEquipments:fetchFrequentEquipmentsReducer
});
export default worklogReducer;
