import getWorklogsForTrainerToSignSaga from "./getWorklogsForTrainerToSign.saga";
import getAllStudentsOfTrainerSaga from "./getAllStudentsOfTrainer.saga";
import getAllWorklogsOfStudentForTrainerSaga from "./getAllWorklogsOfStudentForTrainer.saga";
import updateTrainerSaga from "./updateTrainerInfo.saga";

const trainerSagas= [
    getWorklogsForTrainerToSignSaga(),
    getAllStudentsOfTrainerSaga(),
    getAllWorklogsOfStudentForTrainerSaga(),
    updateTrainerSaga()
]


export default trainerSagas;