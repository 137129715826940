import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as EmployerActions from "../../actions/employer/Employer.action";

function *getEmployersData(action){
   
    try{
        const {data} = yield call(EmployerApi.getEmployers, action.payload)
        //extract the data here     
        yield put(EmployerActions.employerData.success(data));
    } catch (e) {
        yield put(EmployerActions.employerData.failure(e));
    }
}
function *getEmployerSaga(){
    yield (
        takeLatest(EmployerActions.EMPLOYER_DATA.REQUEST, getEmployersData)
    );
}

export default getEmployerSaga;