export const validateEmail = (mail = '') => {
    if (!mail|| mail === undefined) {
        return false;
    }
    else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) !== true) {
        return false
    }

    return true
}
export const validateMobile = (number = 0) => {
    if (!number) { return false};

   if (/^\d{10}$/.test(number)) {
        return false
    }
    return true
}