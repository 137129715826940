import axiosInstance from "../../services/interceptor";
import { ApiEndpoints } from "../../Constants/api/trainer";


export const TrainerApi = {
    getWorklogsForTrainerToSign:  () =>{
        return axiosInstance.get(ApiEndpoints.getWorklogsForTrainerToSign.url)
    },
    getAllStudentsOfTrainer:()=>{
        return axiosInstance.get(ApiEndpoints.getAllStudentsOfTrainer.url)
    },
    getAllWorklogsOfStudentForTrainer:(student_id)=>{
        return axiosInstance.get(ApiEndpoints.getAllWorklogsOfStudentForTrainer.url.replace("student_id", student_id));
    },
    updateTrainerInfo:(info)=>{
        return axiosInstance.put(ApiEndpoints.updateTrainerInfo.url,info);
    }
}