import {  put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { removeFromLocalStorage } from "../../../utils/localStorage";

import {
  SIGNOUT_USER,
  signoutUserAction,
} from "../../../store/actions/user/signout.user.action";
import {
  remove_login,
} from "../../../store/actions/user/login.user.actions";
import { anonymous_path } from "../../../Constants/paths/anonymous";

function* handleSignout() {
  try {
    
    removeFromLocalStorage.signout();

    yield put(remove_login.request());

    yield put(signoutUserAction.success());
    yield put(remove_login.success());
    yield put(push(anonymous_path.LOGIN_PATH));
  } catch (e) {
    yield put(
      signoutUserAction.failure({
        signoutSuccess: false,
        signoutFailed: true,
      })
    );
    yield put(remove_login.failure());
  }
}

function* signoutSaga() {
  yield takeLatest(SIGNOUT_USER.REQUEST, handleSignout);
}

export default signoutSaga;
