import * as createWorklogsActions from "../../../store/actions/worklogs/createWorklogs.worklogs.actions"

const initialState = {
    worklogs: [],
    loadingWorklogs: false,
    worklogFetchError:false,
    worklogCreated:false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case createWorklogsActions.CREATE_WORKLOGS.REQUEST:
            return {
                ...state,
                loadingWorklogs: true,
            };
        case createWorklogsActions.CREATE_WORKLOGS.SUCCESS:
            return {
                ...state,
                worklogs: action.payload.value,
                loadingWorklogs: false,
                worklogFetchError:false,
                worklogCreated:true,
            };
        case createWorklogsActions.CREATE_WORKLOGS.FAILURE:
            return {
            ...state,
            worklogs: action.payload,
            loadingWorklogs: false,
            worklogFetchError:true,
            };

        case createWorklogsActions.CLEAR_WORKLOG_STATE:
                return {
                  state:initialState
                }
          
        default:
            return state;
    }
}