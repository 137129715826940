import React from 'react'
import SingleStudentLayout from '../../../components/EmployerDashboard/SingleStudentLayout'
import EmFooter from '../../../components/EmployerDashboard/EmFooter'
import styles from './style.module.scss'
import { employer_path } from '../../../Constants/paths/employer'
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";

const StudentLayout = () => {
    const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("apprentice"))
    return (
        <div className={styles.mainContainer}>
            <SingleStudentLayout />
            <EmFooter
                homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
                apprenticePath={employer_path.APPRENTICE_PATH}
                setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
            />
        </div>
    )
}

export default StudentLayout