import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as RejectionSign from "../../actions/employer/RejectionSign.action";



function *rejectionSign(action){
    try{
        const {data} = yield call(EmployerApi.rejection, action.payload.student_id, action.payload.worklog_id, action.payload.comment)
        //extract the data here
        
        yield put(RejectionSign.rejectionSign.success(data));

    } catch (e) {
        yield put(RejectionSign.rejectionSign.failure(e));
    }
}

function *rejectionsignSaga(){
    yield (
      
        takeLatest(RejectionSign.REJECTION_SIGN.REQUEST, rejectionSign)
    );
}

export default rejectionsignSaga;