import * as FetchProfileActions from "../../actions/user/fetchProfile.user.action"

const initialState = {
  //profile
  loading: false,
  success: false,
  status: "",
  message: "",
  profile:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
  //profile
    case FetchProfileActions.FETCH_PROFILE.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        message: action.payload.message,
      };

    case FetchProfileActions.FETCH_PROFILE.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        message: action.payload.message,
        profile:action.payload.data
      };

    case FetchProfileActions.FETCH_PROFILE.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
