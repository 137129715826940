import React, { useState, useEffect } from "react";
import WorklogDetailsForUnitMobile from "./WorklogDetailsForUnitMobile/index";
import { decideResolution } from "../../../utils/helper/common/decideResolution";
import { Link } from "react-router-dom";
import "../../../styles/global.module.scss";
import styles from "./styles.module.scss";
import { fetchWorklogs } from "../../../store/actions/worklogs/fetchworklogs.worklogs.actions";
import { useDispatch, useSelector } from "react-redux";
import { clearEquipment } from "../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";
import jwt_decode from "jwt-decode";
import { clearWorklogState } from "../../../store/actions/worklogs/createWorklogs.worklogs.actions";
import { fetchLocally } from "../../../utils/localStorage";
import { student_path } from "../../../Constants/paths/student";

const WorklogDetailsForUnit = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();
  //To get the Worklog data for student Dashboard and request for new worklog
  const fetchWorkLogsData = useSelector((state) => state.worklog.fetchworklogs);
  const deleteStatus = useSelector(
    (state) => state.worklog.deleteWorklogReducer.worklogDeleteStatus
  );

  const token = fetchLocally("login_credentials");
  const decoded = jwt_decode(token);
  const [isMobile, setMobile] = useState(false);
  const contactID = decoded.contact_id;

  useEffect(() => {
    dispatch(fetchWorklogs.request(contactID));
    //We use this function to add to the resize event of our event listener and pass in custom resolution
    let checkMobile = decideResolution(setMobile, 780);
    //call the checkMobile function to set the state acc to current resolution
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [deleteStatus === true]);

  //To create a new Worklog
  function handleClick() {
    dispatch(clearEquipment.request());
    dispatch(clearWorklogState.request());
  }

  return (
    <div className={`${styles.btnContainer}`}>
      <WorklogDetailsForUnitMobile newData={fetchWorkLogsData} />
      <Link to={student_path.CREATE_WORKLOG_PATH}>
        <div className={`${styles.containerworklog}`}>
          <button
            className={`mb-3 btn btn-info btn-active ${styles.btnworklog}`}
            onClick={handleClick()}
          >
            Create New Worklog
          </button>
        </div>
      </Link>
    </div>
  );
};

export default WorklogDetailsForUnit;
