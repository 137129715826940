import { createRequestTypes, action } from "../../../utils/redux";

export const UPDATE_TRAINER_INFO = createRequestTypes("UPDATE_TRAINER_INFO");

//this functional object will fetch the worklogs for trainer so they can sign


export const RESET_TRAINER_INFO = "RESET_TRAINER_INFO";

export const resetTrainerInfo = (data) => action(RESET_TRAINER_INFO, data);



export const updateTrainerInfo = {
  request: (data) => action(UPDATE_TRAINER_INFO.REQUEST,data),
  success: (data) => action(UPDATE_TRAINER_INFO.SUCCESS, data),
  failure: (error) => action(UPDATE_TRAINER_INFO.FAILURE, error),
};
