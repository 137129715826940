import { call, put, takeLatest } from "redux-saga/effects";
import { FetchWorkLogEquipment } from "../../../services/worklogs/fetchworklogequipment.service";
import { fetchWorklogEquipment, FETCH_WORKLOG_EQUIPMENT } from "../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";




function* handleFetchWorklogEquipment({ payload }) {
  try {
    
    const { data:resData } = yield call(FetchWorkLogEquipment.getWorklogs, payload);
    const data = resData?.data
    yield put(fetchWorklogEquipment.success(data));
  } catch (e) {
    yield put(fetchWorklogEquipment.failure(e.data));
  }
}

function* fetchWorklogEquipmentSaga() {
  yield takeLatest(FETCH_WORKLOG_EQUIPMENT.REQUEST, handleFetchWorklogEquipment);
}

export default fetchWorklogEquipmentSaga;
