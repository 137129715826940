import React, { useState } from 'react'
import { Container } from "react-bootstrap";
import AddNewSupervisorContact from '../../../components/Dashboard/AddNewSupervisorContact'
import { useDispatch } from 'react-redux';
import { addSupervisor } from '../../../store/actions/student/addSupervisor.action';
import styles from "../styles.module.scss";
import EmDashboardHeader from '../../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader';
import StudentFooter from '../../../components/Dashboard/StudentFooter/StudentFooter';

const AddNewSupervisor = () => {
  
  //Addnew supervisor Modal
  // const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  //these function will who and close the popup screen
  // const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  // };

  const initialValue = {
    first_name: "",
    last_name: "",
    phone: "",
  };

  const [values, setValues] = useState(initialValue);

  const handleSupervisorSubmit = () => {
    dispatch(addSupervisor.request(values))
  };

  return (
    <div>
      <Container className={`pt-3 ${styles.container}`} onLoad={window.scrollTo(0, 0)}>
        <EmDashboardHeader isImg={true} />
        <AddNewSupervisorContact
         handleSupervisorSubmit={handleSupervisorSubmit}
         values={values}
         setValues={setValues}
         initialValue={initialValue}
        />
        <StudentFooter disableContact={false} />
      </Container>
    </div>
  )
}

export default AddNewSupervisor