import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss"
import mainStyles from "../../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/styles.module.scss";
import BlueDot from "../../../components/common/BlueDot";
import { useState, useEffect } from "react";
import ViewImageModal from "../ViewImageModal/ViewImageModal";
const SubmittedWorklogDetails = () =>{
  // this comment for disable useeffect dependency
    /* eslint-disable */
const worklogData = useSelector(state=>state.worklog?.fetchWorklogDetailReducer.worklog);
const taskData = worklogData?.task ? worklogData?.task[0] : [];
const [showUploadImg, setshowUploadImg] = useState(worklogData?.task
    ? worklogData?.task[0].photos
      ? true
      : false
    : "");

    useEffect(() => {
        setshowUploadImg(worklogData?.task
          ? worklogData?.task[0].photos
            ? true
            : false
          : "");
      }, [worklogData?.id]) 


  //this function will open the modal which will handle the popup of the image
  const [viewImage, setViewImage] = useState(false);
  const [imgData,setImgData] = useState("")
 const handleViewImage= (img) =>{
   setViewImage(true);
   setImgData(img);
 }      
  
return (
<div style={{marginTop: '60px'}}>
      <div
        className={`newContainer ${mainStyles.addExtraPaddingtop}`}
        // style={isScroll ? { marginTop: "2em", position: "fixed" } : { marginTop: "2em" }}
        onLoad={window.scrollTo(0, 0)}
      >
        {/* Details Div step 1*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Details -</span>{" "}
            <span className={`${styles.header2}`}>Step 1/4</span>
          </p>

          {/* Where have you being working Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Where have you been working?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.working_details}
            </p>
          </div>

          {/* What was being constructed Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                What was being constructed?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.construction_details}
            </p>
          </div>
        </div>

        {/* Tasks Div step 2*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Tasks -</span>
            <span className={`${styles.header2}`}>Step 2/4</span>
          </p>

          {/* Overdue Tasks Container */}
          {worklogData?.task && (
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                {worklogData?.task[0].name ? <BlueDot /> : <></>}
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightcurrent}`}
                >
                  {worklogData?.task[0].task_name}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* photo Tasks Container  step-3*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Photo -</span>{" "}
            <span className={`${styles.header2}`}>Step 3/4</span>
          </p>

          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                {/* {`Have you got a photo of the ${
                task?.value === "" ? "task name" : task?.value?.name
              }?`} */}{" "}
                Have you got a photo of the task name
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.task
                ? worklogData?.task[0].photos
                  ? "Yes"
                  : "No"
                : ""}
            </p>
          </div>
          {showUploadImg ? <>
            {/* If the photo answer is YES than show this div */}
            <div className="mb-4">
              {taskData?.photos?.length > 0 &&
                taskData?.photos?.map((item) => (
                  <>
                    <div className="thumbnailImage">
                      <img src={item.photo} alt="thumbnails" onClick={() => handleViewImage(item.photo)}/>
                    </div>
                  </>
                ))}
            </div>
            <ViewImageModal
                  show={viewImage}
                  setShow={setViewImage}
                  values={imgData}
                  setValue ={setImgData}
                />
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  What's happening in this photo?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? taskData?.photos[0].photo_happening
                  : ""}
              </p>
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  How long did it take?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? taskData?.photos[0].how_long
                  : ""}
              </p>
            </div>

            {/* Equipment Tools Container */}
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">Equipment</p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {/* this is the equipments */}
                {taskData?.equipments?.length > 0 &&
                  taskData?.equipments?.map((item, index) => (
                    <span>
                      {item.name}
                      {index < taskData?.equipments.length - 1 ? ", " : ""}
                    </span>
                  ))}
              </p>
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  Which supervisor can verify your activity and photo?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? (taskData?.photos[0].supervisor_name.trim()?taskData?.photos[0].supervisor_name :taskData?.photos[0].temp_emp_name)
                  : ""}
              </p>
            </div>
          </> :
            <>
              {/* If the photo answer is NO than show this div */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What was your part in the activity?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {taskData?.no_photo?.length > 0
                  ? taskData?.no_photo[0].part
                  : ""}
                </p>

              </div>
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {taskData?.no_photo?.length > 0
                  ? taskData?.no_photo[0].how_long
                  : ""}
                </p>

              </div>
              {/* Equipment Tools Container */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    Equipment
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {/* this is the equipments */}
                  {taskData?.equipments?.length > 0 &&
                    taskData?.equipments?.map((item, index) => (
                      <span>
                        {item.name}
                        {index < taskData?.equipments.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </p>

              </div>
            </>


          }
        </div>

        {/* Comments Div step-4*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Comments -</span>{" "}
            <span className={`${styles.header2}`}>Step 4/4</span>
          </p>

          {/* Other Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other comments you want to say about your activity?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.other_comments}
            </p>
          </div>

          {/* Overall Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other overall comments?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.overall_comments}
            </p>
          </div>
        </div>
      </div>      
    </div> 
)
}





export default SubmittedWorklogDetails;
