import * as UpdateProfileActions from "../../actions/user/updateProfile.user.action";

const initialState = {
  //email
  loadingEmail: false,
  emailUpdateSuccess: false,
  emailStatus: "",
  emailMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    //email
    case UpdateProfileActions.UPDATE_EMAIL.REQUEST:
      return {
        ...state,
        loadingEmail: true,
        emailUpdateSuccess: false,
        emailMessage: action.payload.message,
      };

    case UpdateProfileActions.UPDATE_EMAIL.SUCCESS:
      return {
        ...state,
        loadingEmail: false,
        emailUpdateSuccess: true,
        emailStatus: action.payload.status,
        emailMessage: action.payload.message,
      };

    case UpdateProfileActions.UPDATE_EMAIL.FAILURE:
      return {
        ...state,
        loadingEmail: false,
        emailUpdateSuccess: false,
        emailStatus: action.payload.status,
        emailMessage: action.payload.message,
      };

   //reset
   case UpdateProfileActions.RESET_UPDATE_EMAIL.REQUEST:
    return {
      ...state,
      loadingEmail: false,
      emailUpdateSuccess: false,
      emailStatus: "",
      emailMessage: "",
    };
 

    default:
      return state;
  }
}
