import * as StudentActions from "../../actions/studentSign/student.sign.action"

const initialState = {
    students: {},
    loadingStudents:true,
    studentsError:undefined,
    signSelected:false,
    signUnit:{},
    loadingSign:false,
    signSuccess:false,
    invalidId:false
}


export default function returnstate(state = initialState, action) {
    switch (action.type) {
      case StudentActions.SHOW_SIGN:
        return {
          ...state,
          signSelected: action.payload.signSelected,
          signUnit: action.payload.signUnit
        }
      case StudentActions.STUDENT_DATA.REQUEST:
        return {
          ...state,
          loadingStudents: true,
        };
      case StudentActions.STUDENT_DATA.SUCCESS:

        return {
          ...state,
          students: action.payload,
          studentsError: undefined,
          loadingStudents: false,
        };
  
      case StudentActions.STUDENT_DATA.FAILURE:
        return {
          ...state,
          studentsError: action.payload,
          loadingStudents: true,
          invalidId:true,
        };
      case StudentActions.STUDENT_SIGN.REQUEST:
        return {
          ...state,
          loadingSign: true,
        };
      case StudentActions.STUDENT_SIGN.SUCCESS:

        return {
          ...state,
          signSelected:false,
          signSuccess: true,
          loadingSign: false,
        };
  
      case StudentActions.STUDENT_SIGN.FAILURE:
        return {
          ...state,
          signSelected:false,
          signSuccess: false,
          loadingSign: false,
        };
      default: return state      
    }
}