import { combineReducers } from "redux";
import getWorklogDetailForSign from "./worklogDetailsForSign.reducer"
import employerStudent from './employerStudent.reducer';
import upcomimgWorkshop from './upcomigWorkshop.reducer';
import employerApprentices from './employerApprentices.reducer'
import upcomingStudentWorkshop from './upcomingStudentWorkshop.reducer'
import rejectionSign from './rejectionSign.reducer'
import confirmUpcomingWorkshopReducer from "./confirmUpcomingWorkshop.reducer";
import apprenticeStudent from './apprenticeStudent.reducer';
import apprenticeStudentTodo from "./apprenticeStudentTodo.reducer"
import worklogSubmissions from "./worklogSubmissions.reducer"
import employerStudentProfile from './employerStudentProfile.reducer'
import StudentProgressReport from './StudentProgressReport.reducer'
import StudentOnTrackStatus from './StudentOnTrackStatus.reducer'
import unitSign from './unitSign.reducer';


const employerReducers = combineReducers({
    getWorklogDetailForSign,
    employerStudent,
    upcomimgWorkshop,
    employerApprentices,
    upcomingStudentWorkshop,
    rejectionSign,
    confirmUpcomingWorkshopReducer,
    apprenticeStudent,
    apprenticeStudentTodo,
    worklogSubmissions,
    employerStudentProfile,
    StudentProgressReport,
    StudentOnTrackStatus,
    unitSign,
    
});

export default employerReducers;
