import * as FetchWorklogTasksActions from "../../../store/actions/worklogs/fetchWorklogTasks.worklogs.action";

const initialState = {
  taskId: 0,
  taskName: "",
  unitiId: "",
  dueDate: new Date(),
  timesPerformed: 0,
  totalNumberOfTimes: 1,
  photoRequired: 0,
  photosUploaded: 0,
  loadingWorklogTask: false,
  worklogFetchError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FetchWorklogTasksActions.FETCH_WORKLOG_TASKS.REQUEST:
      return {
        ...state,
        tasks: [],
        loadingWorklogTask: true,
      };
    case FetchWorklogTasksActions.FETCH_WORKLOG_TASKS.SUCCESS:
      return {
        ...state,
        // taskId: action.payload.task_id,
        // taskName: action.payload.name,
        loadingWorklogTask: false,
        // worklogFetchError: false,
        tasks: action.payload.tasks,
      };

    case FetchWorklogTasksActions.FETCH_WORKLOG_TASKS.FAILURE:
      return {
        ...state,
        Task_id: action.payload,
        Task_name: action.payload,
        loadingWorklogTask: true,
        worklogFetchError: true,
      };
    default:
      return state;
  }
}