import { call, put, takeLatest } from "redux-saga/effects";
import { CreateWorkLogAPI } from "../../../services/worklogs/createworklogs.service";
import {
  CREATE_WORKLOGS,
  createWorklogs,
} from "../../../store/actions/worklogs/createWorklogs.worklogs.actions";

function* handlecreateWorklogs({ payload }) {
  try {
    const { data } = yield call(CreateWorkLogAPI.getWorklogs, payload);
    yield put(createWorklogs.success(data));
  } catch (e) {
    yield put(createWorklogs.failure(e.data));
  }
}

function* createWorklogsSaga() {
  yield takeLatest(CREATE_WORKLOGS.REQUEST, handlecreateWorklogs);
}

export default createWorklogsSaga;
