import * as FetchAllWorkshops from "../../actions/worklogs/fetchAllWorkshops.action";

const initialState = {
  loading: false,
  changePasswordSuccess: false,
  changePasswordFail: false,
  status: "",
  message: "",
  workshops: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    //All Workshops
    case FetchAllWorkshops.FETCH_ALL_WORKSHOPS.REQUEST:
      return {
        ...state,
        loading: true,
        changePasswordSuccess: false,
        changePasswordFail: false,
      };
    case FetchAllWorkshops.FETCH_ALL_WORKSHOPS.SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordSuccess: true,
        changePasswordFail: false,
        status: action.payload.status,
        message: action.payload.message,
        workshops: action.payload.data,
      };
    case FetchAllWorkshops.FETCH_ALL_WORKSHOPS.FAILURE:
      return {
        ...state,
        loading: false,
        changePasswordSuccess: false,
        changePasswordFail: true,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
