import { call, put, takeLatest } from "redux-saga/effects";
import { verifySignupApi } from "../../../services/anonymous/verifySignup.service";
import {
  SIGNUP_LINK_VERIFICATION,
  verifySignup,
} from "../../../store/actions/user/verifySignup.user.action";

function* handleverifySignup({ payload }) {
  try {

    const { data:resData } = yield call(verifySignupApi.signup, payload);
    const data = resData?.data
  
    yield put(
      verifySignup.success({
        status: resData?.status,
        statusCode:resData?.status_code,
        message: resData?.message,
        users: data?.users,
        contact_id: data?.users?.contact_id,
        contact_type: data?.users?.contact_type,
      })
      
    );

    // saveAuthToken(data.token, false);
  } catch (e) {
    yield put(verifySignup.failure(e.data));
  }
}

function* verifySignupSaga() {
  yield takeLatest(SIGNUP_LINK_VERIFICATION.REQUEST, handleverifySignup);
}

export default verifySignupSaga;
