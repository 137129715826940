import { createRequestTypes, action } from "../../../utils/redux";

export const GET_WORKLOGS_FOR_TRAINER_TO_SIGN = createRequestTypes("GET_WORKLOGS_FOR_TRAINER_TO_SIGN");

//this functional object will fetch the worklogs for trainer so they can sign
export const getWorklogsForTrainerToSign = {
  request: (data) => action(GET_WORKLOGS_FOR_TRAINER_TO_SIGN.REQUEST,data),
  success: (data) => action(GET_WORKLOGS_FOR_TRAINER_TO_SIGN.SUCCESS, data),
  failure: (error) => action(GET_WORKLOGS_FOR_TRAINER_TO_SIGN.FAILURE, error),
};
