export const ApiEndpoints = Object.freeze({
    getEmployers: {
        url: `/employer/worklog/signature`,
        method: "GET",
      },
      sign:{
        url:`/employer/worklog/signature`,
        method:"POST"
      },
      rejection:{
        url:"/employer/students/student_id/worklogs/worklog_id/reject",
        method:"POST"
      },
      confirmUpcomingWorkshop:{
        url:"employer/students/student_id/workshop/lesson_id/confirm",
        method:"PATCH"
      },
      getWorklogDetailsForSign:{
        url:"/employer/students/student_id/worklogs/worklog_id",
        method:"GET"
      },
      getUpcomingWorkshop:{
        url:"/employer/todo",
        method:"GET"
      },
      getStudentDetails:{
        url:"/employer/students",
        method:"GET"
      },
      getUpcomingStudentWorkshop:{
        url:"/employer/students/workshops",
        method:"GET"
      },
      unitSign:{
        url:"/employer/todo/unit/signature",
        method:"PATCH"
      },

      //for the Employer student (apprentices)

      getStudentData:{
        url:"employer/students/student_id/workshops",
        method:"GET"
      },
      getStudentTodo:{
        url:"/employer/students/student_id/todo",
        method:"GET"
      },
      getWorklogSubmission:{
        url:"/employer/students/student_id/worklogs",
        method:"GET"
      },
      getStudentProfile:{
        url:"/employer/students/student_id/profile",
        method:"GET"
      },
      getStudentProgressReport:{
        url:"/employer/students/student_id/progress",
        method:"GET"
      },
      getStudentOntrackStatus:{
        url:"/employer/students/student_id/track",
        method:"GET"
      },


});