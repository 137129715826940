import React, { useEffect, useState } from "react";
import "./radio.scss";
import InputComponent from "../Input";
import propTypes from "prop-types";
function CustomRadioButton(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  //Change in Onchange according the props value
  const [valueUpdated, setValueUpdated] = useState("");
  useEffect(() => {
    if (props.name !== "const") {
      props.onChange && props.onChange(valueUpdated);
    }
  }, [valueUpdated]);

  //Checked the Rdio button on clicked
  function otherClick(e) {
    document.getElementById("otherWorkPlace").focus();
    var input = document.getElementById(props.id);
    input.checked = true;
    props.setinputChecked(true);
    props.setInputValue(e?.target?.value? e?.target?.value : " ");

    if (props?.clearInput) {
    }
  }

  //when click on input than radio button is checked
  function handleInternalClick(value) {
    props.setInputValue(value);
    props?.onChange && setValueUpdated(value);

    if (props?.onClick) {
      props.onClick();
    }
  }

  //Style
  let inline;
  if (props.isInline) {
    inline = "d-inline-block";
  } else {
    inline = "d-flex align-items-start";
  }

  return (
    <div className={`radio-item ${inline}`}>
      <input
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value}
        checked={props?.checked}
        onClick={
          props.setInputValue
            ? props.id === "Other"
              ? otherClick
              : () => handleInternalClick(props.value)
            : props.onChange
        }
        readOnly
      />
      {props?.otherInputBox === true ? (
        <label htmlFor={props.id} className="labelInput">
          <InputComponent
            className="customInputComponent"
            for={props.id}
            id="otherWorkPlace"
            onClick={otherClick}
            onChange={props?.onChange}
            placeholder="Other"
            type="text"
            value={props.value}
          />
        </label>
      ) : (
        <>
          <label htmlFor={props.id} className="labelWidth" />
          <label>
            <span className={`clickable`}
              value={props.value}
              onClick={
                props.setInputValue
                  ? () => handleInternalClick(props.value)
                  : props.onChange
              }
            >
              {props.label}
            </span>
          </label>
        </>
      )}
    </div>
  );
}

CustomRadioButton.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  isInline: propTypes.bool,
  onClick: propTypes.func,
  onChange: propTypes.func,
};

export default CustomRadioButton;
