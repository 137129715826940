import { call, put, takeLatest } from "redux-saga/effects";
import { resetPasswordApi } from "../../../services/anonymous/resetPassword.service";
import {resetPasswordUserAction, RESET_PASSWORD_USER} from "../../actions/user/resetPassword.user.action"

function* handleResetPassword({ payload }) {
  //onsole.log("iNNN Signup sAGAAAAAA");
  try {


    const { data } = yield call(resetPasswordApi.resetPassword, payload);
    yield put(
        resetPasswordUserAction.success({
        status: data.status,
        message: data.message,
        user: data.user,
      })
    );

    // saveAuthToken(data.token, false);
  } catch (e) {
    yield put(resetPasswordUserAction.failure(e.data));
  }
}

function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_USER.REQUEST, handleResetPassword);
}

export default resetPasswordSaga;
