import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as apprenticeStudent from "../../actions/employer/apprenticeStudent.action";

function *getApprenticeStudentData(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getStudentData, action.payload)
        //extract the data here    
        yield put(apprenticeStudent.apprenticeStudentData.success(data));
    } catch (e) {
        yield put(apprenticeStudent.apprenticeStudentData.failure(e));
    }
}
function *getEmployerStudentSaga(){
    yield (
        takeLatest(apprenticeStudent.APPRENTICE_STUDENT.REQUEST, getApprenticeStudentData)
    );
}

export default getEmployerStudentSaga;