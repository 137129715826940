import * as ChangePasswordActions from "../../actions/user/changePassword.user.action";

const initialState = {
loading:false,
changePasswordSuccess:false,
changePasswordFail:false,
status:"",
message:"",
};

export default function (state = initialState, action) {
    switch (action.type) {
            //Current Password
        case ChangePasswordActions.CHANGE_PASSWORD.REQUEST:
            return {
                ...state,
                loading:true, 
                changePasswordSuccess:false,
                changePasswordFail:false,
            };
            case ChangePasswordActions.RESET_CHANGE_PASSWORD.SUCCESS:
                return {
                    ...state,
                    loading:false,
                    changePasswordSuccess:false,
                    changePasswordFail:false,
                    status:"",
                    message:"",
                };
        case ChangePasswordActions.CHANGE_PASSWORD.SUCCESS:
            return {
                ...state,
                loading:false,
                changePasswordSuccess:true,
                changePasswordFail:false,
                status:action.payload.status,
                message:action.payload.message
            
            };
        case ChangePasswordActions.CHANGE_PASSWORD.FAILURE:
            return {
            ...state,
            loading:false,
            changePasswordSuccess:false,
            changePasswordFail:true,
            status:action.payload.status,
            message:action.payload.message
            };


        default:
            return state;
    }
}