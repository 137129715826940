import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const loginWithPinApi = {
  login: (data) => {
    return axiosInstance.post(ApiEndpoints.loginWithPin.url, {
      pin: data,
    });
  },
};
