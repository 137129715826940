import { call, put, takeLatest } from "redux-saga/effects";
import { FetchWorkLogSupervisor } from "../../../services/worklogs/fetchworklogsupervisor.service";
import { fetchWorklogSupervisor, FETCH_WORKLOG_SUPERVISOR } from "../../../store/actions/worklogs/fetchWorklogSupervisor.worklogs.action";


function* handleFetchWorklogSupervisor({ payload }) {
  try {
    const { data : resData} = yield call(FetchWorkLogSupervisor.getWorklogs, payload);
    const data = resData?.data
    yield put(fetchWorklogSupervisor.success(data));
  } catch (e) {
    yield put(fetchWorklogSupervisor.failure(e.data));
  }
}

function* fetchWorklogSupervisorSaga() {
  yield takeLatest(FETCH_WORKLOG_SUPERVISOR.REQUEST, handleFetchWorklogSupervisor);
}

export default fetchWorklogSupervisorSaga;
