import { createSelector } from 'reselect';

export const selectUser = state => state.user;

export const selectInitialLoadUserDone = createSelector(
  selectUser,
  userState => userState.initialLoadUserDone,
);

export const selectUserToken = createSelector(
  selectUser,
  userState => userState.authToken,
);

export const selectIsAuthenticated = createSelector(
  selectUser,
  userState => !!userState.authToken,
);

export const selectBasicUser = createSelector(
  selectUser,
  userState => ({
    token: userState.token ? userState.token : (userState.user ? userState.user.token : undefined),
    type: userState.user ? userState.user.type : undefined,
    email: userState.user ? userState.user.email : undefined,
    phone: userState.user ? userState.user.phone : undefined,
    contact_id: userState.user ? userState.user.contact_id : undefined,
    authentication_id: userState.user ? userState.user.authentication_id : undefined,
    userStatus: userState.user ? userState.userStatus : undefined,
    pin_active: userState.user ? userState.user.pin_active : undefined,
  }),
);

export const selectlogin = createSelector(
  selectUser,
  userState => ({
    login: userState.login.login,
    loginSuccess: userState.login.loginSuccess,
    loginFailed: userState.login.loginFailed,
    statusText: userState.login.statusText,
  }),
);

export const selectPin = createSelector(
  selectUser,
  userState => ({
    loginWithPin: userState.loginWithPin,
    loginWithPinSuccess: userState.loginWithPinSuccess,
    loginWithPinFailed: userState.loginWithPinFailed,
    setPin: userState.setPin,
    setPinSuccess: userState.setPinSuccess,
    setPinFailed: userState.setPinFailed,
    statusText: userState.statusText,
  }),
);

export const selectSignup = createSelector(
  selectUser,
  userState => ({
    verifySignupLink: userState.verifySignupLink,
    verifySignupLinkSuccess: userState.verifySignupLinkSuccess,
    verifySignupLinkFailed: userState.verifySignupLinkFailed,
    signup: userState.signup,
    signupSuccess: userState.signupSuccess,
    signupFailed: userState.signupFailed,
    statusText: userState.statusText,
    error: userState.err,
    user: userState.signupUser
  }),
);

export const selectResetPassword = createSelector(
  selectUser,
  userState => ({
    resetPassword: userState.resetPassword,
    resetPasswordSuccess: userState.resetPasswordSuccess,
    resetPasswordFailed: userState.resetPasswordFailed,
    verifyResetPasswordLink: userState.verifyResetPasswordLink,
    verifyResetPasswordLinkSuccess: userState.verifyResetPasswordLinkSuccess,
    verifyResetPasswordLinkFailed: userState.verifyResetPasswordLinkFailed,

    statusText: userState.statusText,
    error: userState.err,
    user: userState.signupUser
  }),
);

export const selectForgot = createSelector(
  selectUser,
  userState => ({
    submitingForgot: userState.submitingForgot,
    resettingPassword: userState.resettingPassword,
    message: userState.message,
  }),
);

export const selectVerifyEmail = createSelector(
  selectUser,
  userState => ({
    verifyingEmail: userState.verifyingEmail,
    verifiedEmail: userState.verifiedEmail,
    verifyEmailFailed: userState.verifyEmailFailed,
    statusText: userState.statusText,
  }),
);


