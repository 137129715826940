import { call, put, takeLatest } from "@redux-saga/core/effects";
import { StudentApi } from "services/student/Student.services";
import * as studentCourseProgress from "../../actions/student/studentCourseProgress.action";

function *getStudentCourseProgressData(action){
   
    try{
        const {data} = yield call(StudentApi.fetchStudentCourseProgress, action.payload)
        //extract the data here     
        yield put(studentCourseProgress.studentCourseProgress.success(data));
    } catch (e) {
        yield put(studentCourseProgress.studentCourseProgress.failure(e));
    }
}
function *getStudentCourseProgressSaga(){
    yield (
        takeLatest(studentCourseProgress.STUDENT_COURSE_PROGRESS.REQUEST, getStudentCourseProgressData)
    );
}

export default getStudentCourseProgressSaga;