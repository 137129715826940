import React from "react";
import styles from "./styles.module.scss";
import "../../../styles/global.module.scss";
import propTypes from "prop-types";

function CustomButton(props) {

  return (<>
    {props.showbtn ?
      <button
        className={`btn btn-info ${styles.btnworklog}`}
        style={{
          width: props.width,
          margin: props.margin,
        }}
        onClick={props.onClick}
        disabled={props.disabled === true ? true : false}
      >
        <span className={props.disabled ? `${styles.textUpdark}` : `${styles.textUp}`}>{props.text}</span>
      </button> :
      <button
        className={`${styles.btn}`}
        style={{
          width: props.width,
          margin: props.margin,
        }}
        onClick={props.onClick}
        disabled={props.disabled === true ? true : false}
      >
        {props.blank ? <></> : <img src={props.disabled ? props.disableimg : props.image} alt="Next" />}<br />
        <span className={props.disabled ? `${styles.textUpdark}` : `${styles.textUp}`}>{props.text}</span>
      </button>
    }
  </>
  );
}
CustomButton.propTypes = {
  text: propTypes.string,
  showbtn: propTypes.string,
  type: propTypes.any,
  width: propTypes.string,
  margin: propTypes.string,
  onClick: propTypes.func,
  btn: propTypes.string,
  blank: propTypes.string,
  image: propTypes.string,
  disableimg: propTypes.string,
  disabled: propTypes.any,
}

export default CustomButton;
