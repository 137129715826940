import React, { useEffect, useState } from "react";
import "./styles.module.scss";
import InputComponent from "../../../components/common/Input/index";
import CustomButton from "../../../components/common/CustomButton";
import BlueDot from "../../../components/common/BlueDot/index";
import backIcon from "../../../assets/images/Back.svg";
import nextIcon from "../../../assets/images/next.svg";
import saveletter from "../../../assets/images/saveDraft.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import disableNextIcon from "../../../assets/images/DisabledNext.svg";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { student_path } from "../../../Constants/paths/student";
import propTypes from "prop-types";
import { decodeOnBehalfToken } from "../../../utils/helper/common/decodeJSONToken";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../../utils/localStorage";
import { useDispatch } from "react-redux";
import { staff_path } from "../../../Constants/paths/staff";

function NewWLStep4(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const history = useHistory();
  const dispatch = useDispatch();
  //props passed from parent component
  const { data, otherComment, overallComment } = props;

  //fetching the data of worklog creation status
  const worklogCreationReducer = useSelector(
    (state) => state?.worklog?.createWorklogsWorklogsReducer
  );
  const createWorklogStatus = worklogCreationReducer?.worklogCreated;

  //these const and function is used to enable and disbale the buttons
  const saveDisable = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  //this effect will push the user the view all worklogs page
  useEffect(() => {
    if (createWorklogStatus === true) {
      history.push(student_path.VIEW_WORKLOG_PATH);
    }
  }, [createWorklogStatus]);

  //handlign the signout of onbehalf login
  const handleOnbehalfLogOut =() =>{
    dispatch(resetOnBehalfSignIn.success());
    removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
    history.push(staff_path.STAFF_DASHBOARD_PATH);
  }

  return (
    <div>
      {(saveDisable === true ||
        worklogCreationReducer.loadingWorklogs === true) && (
        <div className="loader">
          <img src={loadingIcon} alt="loading icon"></img>
        </div>
      )}
      <div className={`newContainer`} style={{ paddingTop: "7em" }}>
        {/* Overdue tasks and Current Tasks */}
        <div>
          <div className="d-flex align-items-baseline mb-3">
            <BlueDot />
            <p className="removeExtraBotMargin heading">
              Any other comments you want to say about your activity?
            </p>
          </div>

          <InputComponent
            className="customInputComponent"
            placeholder="Enter comments about the activity"
            type="text"
            as="textarea"
            value={otherComment?.value}
            onChange={otherComment.onChange}
          />

          <div className="d-flex align-items-center mb-3">
            <BlueDot />
            <p className="removeExtraBotMargin heading">
              Any other overall comments?
            </p>
          </div>

          <InputComponent
            placeholder="Enter any other overall comments"
            type="text"
            className="customInputComponent"
            as="textarea"
            value={overallComment?.value}
            onChange={overallComment.onChange}
          />
        </div>
      </div>

      <div
        className={
          decodeOnBehalfToken()
            ? `d-flex justify-content-center align-items-center fixedBottomContainer newContainer OnBehalfButtonPadding`
            : `d-flex justify-content-center align-items-center fixedBottomContainer newContainer ButtonPadding`
        }
      >
        {decodeOnBehalfToken() && (
          <div className={"cancel"} onClick={handleOnbehalfLogOut}>
            Close
          </div>
        )}
        <CustomButton
          image={backIcon}
          text="Back"
          type="transparent"
          width="50%"
          margin="0px 5px 0px 0px"
          onClick={() =>
            props.setStep({
              num: 3,
              text: "Photo",
            })
          }
        />
        <CustomButton
          text="Save as Draft"
          disabled={data?.status === 1}
          type={data?.status === 1 ? "disabled" : "blue"}
          width="50%"
          image={saveletter}
          disableimg={saveletter}
          margin="0px 5px 0px 0px"
          onClick={() => {
            handleShow();
          }}
        />

        <CustomModal
          modalFor="saveLater"
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          handleErase={props.saveLater}
        />

        <CustomButton
          image={nextIcon}
          disableimg={disableNextIcon}
          text="Next"
          type="blue"
          width="50%"
          onClick={() =>
            props.setStep({
              num: 5,
              text: "Summary",
            })
          }
        />
      </div>
    </div>
  );
}

NewWLStep4.propTypes = {
  showUploadImg: propTypes.bool,
  constructed: propTypes.object,
  errorMessage: propTypes.object,
  setStep: propTypes.func,
  data: propTypes.object,
  task: propTypes.object,
  executeScroll: propTypes.func,
  otherComment: propTypes.object,
  overallComment: propTypes.object,
  saveLater: propTypes.func,
};

export default NewWLStep4;
