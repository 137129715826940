import * as studentProfileData from "../../actions/employer/employerStudentProfile.action";

const initialState = {
    studentProfile: [],
    loading: false,
    success: false,
    studentProfileFailure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentProfileData.STUDENT_PROFILE.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentProfileData.STUDENT_PROFILE.SUCCESS:

    return {
      ...state,
      studentProfile: action.payload.data,
      loading: false,
      success: true,
      studentProfileFailure:false,
    };

  case studentProfileData.STUDENT_PROFILE.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      studentProfileFailure:true,
    };
      default: return state      
    }
}
