import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as EmployerActions from "../../actions/employer/Employer.action";



function *signEmployer(action){
    try{
        const {data} = yield call(EmployerApi.sign, action.payload.signature, action.payload.worklog_id)
        //extract the data here
        
        yield put(EmployerActions.employerSign.success(data));

    } catch (e) {
        yield put(EmployerActions.employerSign.failure(e));
    }
}

function *employerStudentSignSaga(){
    yield (
      
        takeLatest(EmployerActions.EMPLOYER_SIGN.REQUEST, signEmployer)
    );
}

export default employerStudentSignSaga;