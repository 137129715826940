import {
  getJsonFromLocalStorage,
  removeFromLocalStorage,
  storeInLocalStorage, fetchLocally
} from "../../localStorage";

import { decodeToken } from "../common/decodeJSONToken";


export const set_authenticate_pin = (data, next) => {
  if (typeof window !== "undefined") {
    storeInLocalStorage.storePin(JSON.stringify(data));
  }
};



export const signout = async () => {
  if (typeof window !== "undefined") {
    removeFromLocalStorage.loginCredential();
    removeFromLocalStorage.skipPinAuth();
    removeFromLocalStorage.loginCredentialPin();
  }
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    storeInLocalStorage.storeloginCredential(JSON.stringify(data));
    next();
  }
};

export const skipPinAuthenticate = () => {
  if (typeof window !== "undefined") {
    storeInLocalStorage.storeSkipPin(true);
    removeFromLocalStorage.resetPin();
  }
};

export const isPinAuthSkip = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (fetchLocally("skip_pin_auth")) {
    const skip = fetchLocally("skip_pin_auth");
    return skip;
  } else {
    return false;
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }

  try {
    if (fetchLocally("login_credentials")) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    removeFromLocalStorage.loginCredential();
  }
};

export const isAuthenticatedPin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  try {
    if (fetchLocally("login_credentials_pin")) {
      const jwt = JSON.parse(fetchLocally("login_credentials_pin"));
      if (jwt) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    removeFromLocalStorage.loginCredentialPin();
    return false;
  }
};

export const removeThePinAndLogout = () => {
  if (typeof window !== "undefined") {
    removeFromLocalStorage.loginCredential();
    removeFromLocalStorage.skipPinAuth();
    removeFromLocalStorage.loginCredentialPin();
    removeFromLocalStorage.loginPinActivated();
    storeInLocalStorage.storeResetPin(true);
  }
};
export const isLoginPinActive = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (getJsonFromLocalStorage("login_pin_activated")) {
    return true;
  } else {
    return false;
  }
};


//this is used for the temporary login using the code
export const isTempLogin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (fetchLocally("temp_login")) {
    return true;
  }
   else {
    return false;
  }
};

//this will return that the user is Employer or not
export const isStudent = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const token = decodeToken();
  const{user_type} = token
  if (user_type==="Student") {
    return true;
  } else {
    return false;
  }
};


//this will return that the user is employer or not
export const isEmployer = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const token = decodeToken();
  const{user_type} = token
  if (user_type==="Employer") {
    return true;
  } else {
    return false;
  }
};

//this will return that the user is Trainer or not
export const isTrainer = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const token = decodeToken();
  const{user_type} = token
  if (user_type==="Trainer") {
    return true;
  } else {
    return false;
  }
};

//this will return that the user is Trainer or not
export const isSupervisor = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const token = decodeToken();
  const{user_type} = token
  if (user_type==="Supervisor") {
    return true;
  } else {
    return false;
  }
};

//this will return that the user is Trainer or not
export const isStaff = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const token = decodeToken();
  const{user_type} = token
  if (user_type==="Staff") {
    return true;
  } else {
    return false;
  }
};



//To set the login pin active status.
export const setLoginPinActive = () => {
  if (typeof window !== "undefined") {
    storeInLocalStorage.storeloginPinActivated(JSON.stringify(true));
  }
};


export const isTrusted = () => {
  if (typeof window == "undefined") {
    return false;
  }

  const trusted = fetchLocally("trusted")


  if (trusted === "true") {

    return true;
  } else {
    return false
  }

}