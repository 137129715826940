import * as GetWorklogDetailsForSign from "../../actions/employer/getWorklogDetailsForSign.action"

const initialState = {
  //worklogData
  loading: false,
  success: false,
  status: "",
  message: "",
  worklogData:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
  //worklogData
    case GetWorklogDetailsForSign.GET_WORKLOG_DETAILS_FOR_SIGN.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        message: action.payload.message,
      };

    case GetWorklogDetailsForSign.GET_WORKLOG_DETAILS_FOR_SIGN.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        message: action.payload.message,
        worklogData:action.payload.data
      };

    case GetWorklogDetailsForSign.GET_WORKLOG_DETAILS_FOR_SIGN.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
