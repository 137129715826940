import React from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";
import propTypes from "prop-types";


const InputComponent = (props) => {
  /* eslint-disable */
  const { values } = props

  var index = []
 
  const res = (values !== undefined && values.length > 0) ? values?.map(item => item.toLowerCase()) : []

  for (let i = 0; i < res.length; i++) {
    const v = res[i]
    for (let j = i + 1; j < res.length; j++) {
      if (v !== "" && res[j] !== '' && v === res[j]) {
        index.push(j + 1)
      }
    }
  }



  return (
    <Form.Group>
      {props.label && <Form.Label> {props.label}</Form.Label>}
      <Form.Control
        style={{ border: index?.includes(props.name) ? '1px solid red' : '' }}
        id={props?.id}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        as={props?.as}
        className={props?.className}
        isInvalid={props.errorMessage === "" ? true : false}
        {...props}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      {index?.includes(props.name) && <span style={{ color: 'red', fontSize: '14px' }}>Duplicate tool can't be added</span>}
      {/* Some Error */}
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

InputComponent.propTypes = {
  className: propTypes.string,
  for: propTypes.string,
  onFocus: propTypes.func,
  onBlur: propTypes.func,
  value: propTypes.string,
  onChange: propTypes.func,
  placeholder: propTypes.string,
  type: propTypes.string,
  id: propTypes.string,
  onClick: propTypes.func,
  style: propTypes.object,
  as: propTypes.string,
  label: propTypes.string,
  defaultValue: propTypes.string,
  isInvalid: propTypes.string,
  errorMessage: propTypes.string,

}

export default InputComponent;
