import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Auth from "../../../layouts/Auth";
import FormCard from "../../../components/auth/FormCard";
import { useHistory } from "react-router-dom";
import { setPin } from "../../../store/actions/user/setpin.user.action";
import { selectPin } from "../../../store/selectors/user.selector";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
import { fetchLocally, storeInLocalStorage } from "../../../utils/localStorage";
import { resetSetPin } from "../../../store/actions/user/setpin.user.action";
import jwtDecode from "jwt-decode";
import { employer_path } from "../../../Constants/paths/employer";
import { student_path } from "../../../Constants/paths/student";
import { trainer_path } from "../../../Constants/paths/trainer";
import { staff_path } from "../../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";

export default function SetPin() {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  let history = useHistory();
  const token = fetchLocally("login_credentials")
  const decoded = jwtDecode(token)
  const [values, setValues] = useState({
    logo: useSelector((state) => state.configuration.logo, shallowEqual),
    pin: "",
    confirmPin: "",
    showPin: false,
    showConfirmPin: false,
    error: false,
    errorMessage: "",
    loading: false,
  });
  const dispatch = useDispatch();
  const pinState = useSelector(selectPin);
  const setPinState = useSelector((state) => state.user.setpin);

  useEffect(() => {
    if (pinState.setPin) {
      setValues({
        ...values,
        loading: true,
      });
    } else {
      setValues({
        ...values,
        loading: false,
      });
    }
    if (pinState.setPinFailed) {
      setValues({
        ...values,
        error: true,
        errorMessage: pinState.statusText,
        loading: false,
      });
    }
  }, [pinState]);

  
  const inputChangeHandler = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
      error: false,
      errorMessage: "",
      loading: false,
    });
  };
  const arePinsSameCheck = () => {
    const check =
      values.pin === values.confirmPin &&
      values.pin.length > 0 &&
      values.confirmPin.length > 0;
    return check;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!arePinsSameCheck()) {
      return setValues({
        ...values,
        arePinsSame: false,
        error: true,
        errorMessage: "Pin and Confirm Pin does not match.",
      });
    } else {
      setValues({
        ...values,
        arePinsSame: true,
        error: false,
        errorMessage: "",
      });
      dispatch(resetSetPin.success());
      dispatch(setPin.request(values.pin));
    }
  };

  useEffect(() => {

    //if pins are successfull, then route to the homepage
    if (setPinState.setPinSuccess === true) {
      //   history.push("/student/dashboard");
      //   set_authenticate_pin(values.pin);
      storeInLocalStorage.storePin( JSON.stringify(values.pin));
      if(decoded.user_type ==="Employer"){
        history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
      }else if(decoded.user_type==='Student'){
        history.push(student_path.STUDENT_DASHBOARD_PATH);
      }else if(decoded.user_type==='Trainer'){
        history.push(trainer_path.TRAINER_DASHBOARD_PATH);
      }else if(decoded.user_type==='Staff'){
        history.push(staff_path.STAFF_DASHBOARD_PATH);
      }else if(decoded.user_type==='Supervisor'){
        history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH);
      }
     
    }
    //
    //else display message - error setting pins
    else {
      setValues({
      ...values,
      error: true,
      errorMessage: setPinState.message,
    });
  }
  }, [setPinState]);

  

  const setPinForm = () => {
    return (
      <>
        <div className="col-md-12 verify">
          <div className="verifyHeader"><h5 className="mb-3">For faster logon next time</h5></div>
  
        </div>
        <form className="form-width-inherit">
          <Input
            label="Enter Pin"
            type="number"
            onKeyDown={(e) =>["e", "E", "+", "-","=","'",'"',"."].includes(e.key) && e.preventDefault()}
            inputmode="numeric"
            id="pin"
            name="password"
            placeholder="Enter Pin"
            aria-describedby="pinHelp"
            value={values.pin}
            onChange={inputChangeHandler("pin")}
            //errorPin={values.pinError || values.errorMessage}
            required
          />
          <Input
            label="Confirm Pin"
            type="number"
            onKeyDown={(e) =>["e", "E", "+", "-","=","'",'"',"."].includes(e.key) && e.preventDefault()}
            inputmode="numeric"
            id="confirmPin"
            name="confirmPin"
            placeholder="Confirm Pin"
            aria-describedby="confirmPinHelp"
            value={values.confirmPin}
            onChange={inputChangeHandler("confirmPin")}
            required
            errorPin={values.pinError || values.errorMessage}
            autoComplete="off"
          />
          <Button
            type={values.loading ? "button" : "submit"}
            disabled={values.pin.length < 1 || values.confirmPin.length < 1}
            onClick={handleSubmit}
            loading={values.loading}
          >
            {values.loading ? " Saving..." : "Enter Pin"}
          </Button>
        </form>
        {/* <div className="skip">
        <a
          className="btn ml-auto mr-auto text-gray text-muted mt-4"
          onClick={skipPin}
        >
          <u>Skip</u>
        </a>
        </div> */}
      </>
    );
  };

  return (
    <Auth>
      <FormCard  title="Enter Pin">{setPinForm()}</FormCard>
    </Auth>
  );
}
