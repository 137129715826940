import * as LoginActions from "../../actions/user/login.user.actions";

const initialState = {
  login: false,
  loginSuccess: false,
  loginFailed: false,
  statusText: null,
  loadingLogin: false,
  errorLogin: null,
  rememberMe: false,
  user: null,
  removeLogin: null,
  status: null,
  message: "",
  contact_id: "",
  resetLogin: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LoginActions.LOGIN.REQUEST:
      return {
        ...state,
        login: false,
        loadingLogin: true,
      };

    case LoginActions.LOGIN.SUCCESS:
      return {
        ...state,
        login: true,
        loginSuccess: true,
        loginFailed: false,
        loadingLogin: false,
        user: action.payload.user,
        contact_id: action.payload.contact_id,
        status: action.payload.status,
        message: action.payload.message,
      };

    case LoginActions.LOGIN.FAILURE:
      return {
        ...state,
        login: true,
        loginSuccess: false,
        loginFailed: true,
        loadingLogin: false,
        user: action.payload.user,
        status: action.payload.status,
        message: action.payload.message,
      };

    case LoginActions.REMEMBER_ME.SUCCESS:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case LoginActions.REMEMBER_ME.FAILURE:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case LoginActions.REMOVE_LOGIN.REQUEST:
      return {
        ...state,
        removeLogin: true,
      };

    case LoginActions.REMOVE_LOGIN.SUCCESS:
      return {
        ...state,
        login: false,
        loginSuccess: false,
        loginFailed: false,
        statusText: null,
        loadingLogin: false,
        errorLogin: null,
        rememberMe: false,
        user: null,
        removeLogin: false,
      };

    case LoginActions.REMOVE_LOGIN.FAILURE:
      return {
        ...state,
        removeLogin: false,
      };

    case LoginActions.RESET_LOGIN.REQUEST:
      return {
        ...state,
        resetLogin: true,
      };

    case LoginActions.RESET_LOGIN.SUCCESS:
      return {
        ...state,
        login: false,
        loginSuccess: false,
        loginFailed: false,
        statusText: null,
        loadingLogin: false,
        errorLogin: null,
        rememberMe: false,
        user: null,
        removeLogin: null,
        status: null,
        message: "",
        resetLogin: false,
      };

    case LoginActions.RESET_LOGIN.FAILURE:
      return {
        ...state,
        resetLogin: false,
      };

    default:
      return state;
  }
}
