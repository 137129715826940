const STUDENT_DASHBOARD_PATH = "/student/dashboard";
const STUDENT_DESIGN_PATH = "/student/design";
const CREATE_WORKLOG_PATH = "/student/dashboard/worklog/create";
const EDIT_WORKLOG_PATH = "/student/dashboard/worklog/edit";
const VIEW_WORKLOG_PATH = "/student/dashboard/worklog";
const VIEW_SETTINGS_PATH = "/student/dashboard/settings";
const DASHBOARD_CONTACTUS = "/student/dashboard/progress"
const NEWSUPERVISOR_CONTACTUS = "/student/dashboard/contactus/supervisor";
const SUBMITTED_WORKLOG_DETAILS = "/student/worklog/details";
const PROFILE_UPDATE_PAGE = "/student/profile/update" 

export const student_path = {
    STUDENT_DASHBOARD_PATH,    
    STUDENT_DESIGN_PATH,
    CREATE_WORKLOG_PATH,
    EDIT_WORKLOG_PATH,
    VIEW_WORKLOG_PATH,  
    VIEW_SETTINGS_PATH,
    DASHBOARD_CONTACTUS,
    SUBMITTED_WORKLOG_DETAILS,
    NEWSUPERVISOR_CONTACTUS,
    PROFILE_UPDATE_PAGE
}