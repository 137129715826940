import * as UpdateProfileActions from "../../actions/user/updateProfile.user.action";

const initialState = {
  //phone
  loadingPhone: false,
  phoneUpdateSuccess: false,
  phoneStatus: "",
  phoneMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    //phone

    case UpdateProfileActions.UPDATE_PHONE.REQUEST:
      return {
        ...state,
        loadingPhone: true,
        phoneUpdateSuccess: false,
        phoneMessage: action.payload.message,
      };

    case UpdateProfileActions.UPDATE_PHONE.SUCCESS:
      return {
        ...state,
        loadingPhone: false,
        phoneUpdateSuccess: true,
        phoneStatus: action.payload.status,
        phoneMessage: action.payload.message,
      };

    case UpdateProfileActions.UPDATE_PHONE.FAILURE:
      return {
        ...state,
        loadingPhone: false,
        phoneUpdateSuccess: false,
        phoneStatus: action.payload.status,
        phoneMessage: action.payload.message,
      };
      case UpdateProfileActions.RESET_UPDATE_PHONE.REQUEST:
        return {
          ...state,
          loadingPhone: false,
          phoneUpdateSuccess: false,
          phoneStatus: "",
          phoneMessage: "",
        };


    default:
      return state;
  }
}
