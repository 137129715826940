import React, { useState } from "react";
import styles from "./styles.module.scss";
import logo from "../../../assets/images/logo.png";
import cancel from "../../../assets/images/cancel.png";
import leftArrow from "../../../assets/images/leftArrow.png";
import propTypes from 'prop-types';
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { useHistory } from "react-router-dom";
import { student_path } from "../../../Constants/paths/student";

function OtherPageHeader(props) {

  //this will fethc the history from the router
  const history = useHistory();

  //this will handle the opening and closure of popup boxes before performing any action related to that button
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const goToPreviousPath = () => {
    //if the back button is clicked from the sumbmitted worklog summary page it will navigate to student worklogs page.
    if(props.navHeaderFor==="Summary"){
      history.replace(student_path.VIEW_WORKLOG_PATH);
      return
    }

    //this will set the steps to step 4 and heading to comment
    props.setStep({
      num: 4,
      text: "Comments",
    });
  };
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${styles.navDiv} newContainer`}
    >
      <div className={`d-flex align-items-center`}>
        { props.navImage === "nothing" ? <></> : <img
          className={
            props.navImage === "cancel"
              ? styles.navIconCancel
              : styles.navIconBack
          }
          onClick={props.navImage === "cancel" ? handleShow : goToPreviousPath}
          src={props.navImage === "cancel" ? cancel : leftArrow}
          alt="left arrow icon"
        ></img> }

        <CustomModal
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          handleErase={props.handleErase}
        />
        <h6 className={`heading removeExtraBotMargin`}>{props.heading}</h6>
      </div>

      <img className={styles.logoImg} src={logo} alt="CTI Logo"></img>
    </div>
  );
}

OtherPageHeader.propTypes = {
  heading: propTypes.string, //this will show the text of the heading
  navImage: propTypes.string, //this is the imgage of the header
  setStep: propTypes.func,  //this will  set the steps 
  handleErase: propTypes.func, //this will erase the the worklog data while creating it
  navHeaderFor:propTypes.string, //this is the props passed from the submitted worklog summary page
};



export default OtherPageHeader;