import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as UpcomingWorkshop from "../../actions/employer/UpcomingWorkshop";

function *getUpcomingWorkshopData(action){
   
    try{
        const {data} = yield call(EmployerApi.getUpcomingWorkshop, action.payload)
        //extract the data here     
        yield put(UpcomingWorkshop.upcomingWorkshopdata.success(data));
    } catch (e) {
        yield put(UpcomingWorkshop.upcomingWorkshopdata.failure(e));
    }
}
function *getUpcomingWorkshopSaga(){
    yield (
        takeLatest(UpcomingWorkshop.UPCOMING_WORKSHOP.REQUEST, getUpcomingWorkshopData)
    );
}

export default getUpcomingWorkshopSaga;