import { call, put, takeLatest } from "redux-saga/effects";
import { lessonConfirmationApi } from "../../../services/worklogs/lessonConfirmation.service";
import {
    LESSON_CONFIRMATION,
    lessonConfirmation,
} from "../../../store/actions/worklogs/lessonConfirmation.worklogs.action";

function* handleLessonConfirmation({ payload }) {
  try {
    
    const { data } = yield call(lessonConfirmationApi.lessonConfirmation, payload);
    yield put(lessonConfirmation.success(data));
  } catch (e) {
    yield put(lessonConfirmation.failure(e.data));
  }
}

function* lessonConfirmationSaga() {
  yield takeLatest(LESSON_CONFIRMATION.REQUEST, handleLessonConfirmation);
}

export default lessonConfirmationSaga;