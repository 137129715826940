import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const forgotPasswordApi = {
  forgotPassword: (data) => {
    return axiosInstance.patch(ApiEndpoints.forgotPassword.url, {
    email:data
    });
  },
};
