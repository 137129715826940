import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated, isAuthenticatedPin, isPinAuthSkip , isLoginPinActive, isStaff, isTempLogin} from '../../utils/helper/Student/index'
import StaffLayout from '../../layouts/Staff/StaffLayout';
import { anonymous_path } from '../../Constants/paths/anonymous';


const StaffRoute = ({component: Component, ...rest}) => {
    return (
        <Route
        {...rest}
        render={(props) =>
          isAuthenticated() && (isAuthenticatedPin() || isPinAuthSkip() || isLoginPinActive() || isTempLogin()) && isStaff() ? (
            <StaffLayout>
              <Component {...props} />
            </StaffLayout>
          ) : (
            <Redirect
              to={{
                pathname: anonymous_path.LOGIN_PATH,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
    
}

export default StaffRoute;