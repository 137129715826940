import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllStudentsOfTrainer } from "../../../store/actions/trainer/getAllStudentsOfTrainer.action";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import styles from "./styles.module.scss"
import loadingWheel  from "../../../assets/images/blue-Loading-spinner.gif"
import { isSupervisor } from "utils/helper/Student";

const TrainerStudents = () =>{
const dispatch = useDispatch();
useEffect(()=>{
dispatch(getAllStudentsOfTrainer.request());
},[dispatch])

const allStudentsDataReducer = useSelector((state)=>state.trainerReducers.getAllStudentsOfTrainerReducer);
const {students:studentsData, loading} = allStudentsDataReducer

function handleClickForTrainer (name){
  localStorage.setItem("worklogsOf",name)
}
return (
    <>
    {loading ? <img className={styles.loadingWheel} src={loadingWheel} alt="loading" /> :
        <div className={styles.apprenticesLink}>

      {(studentsData==null)? (
      
        <div className={styles.allSetLabel}>You don't have any apprentice.</div>
            ) : (
        <></>
      )}
            {studentsData?.map((data) =>
                    <Link to={`/${isSupervisor()?"supervisor":"trainer"}/students/${data.student_id}`} style={{ textDecoration: "none" }} onClick={() =>handleClickForTrainer(data?.name)}>
                    <Card className={`${styles.EmpApprenticesLink}`} >
                      <Card.Body className={`${styles.EmpApprenticesName}`}>{data.name}</Card.Body >
                    </Card>
                  </Link>
            )}

        </div>}
</>
)
}


export default TrainerStudents;