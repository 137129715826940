import React, { useEffect, useState } from "react";
import "./index.layout.scss";
import { useSelector } from "react-redux";
import { userType } from "../../Constants/enums";
import { Redirect } from "react-router";
import { setLoginPinActive } from "../../utils/helper/Student/index";
import { employer_path } from "../../Constants/paths/employer";
import { student_path } from "../../Constants/paths/student";
import { anonymous_path } from "../../Constants/paths/anonymous";
import { trainer_path } from "../../Constants/paths/trainer";
import propTypes from "prop-types";
import { useLocation } from "react-router";
import { staff_path } from "../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";

const Anonymous = (props) => {
  const { user } = useSelector((state) => state.user.login);
  const [goToAuthRoute, setGoToAuthRoute] = useState(false);
  const {
    state: {
      from: { pathname = "", search =""} = "",
    } = "",
  } = useLocation();


  const selectAuthRoute = () => {
    if (user.pin_active) {

      //this is for student navigation 
      if (user.type === userType.student) {
        if (pathname && pathname !=="/" && search) {
          return pathname + search;
        } else if (pathname && pathname !=="/" &&  pathname.startsWith("/student")) {
          return pathname;
        } else {
          return student_path.STUDENT_DASHBOARD_PATH;
        }
      }
      
      //this is for employer navigation
      else if (user.type === userType.employer) {
        if (pathname && pathname !=="/" && search) {
          return pathname + search;
        } else if (pathname && pathname !=="/" && pathname.startsWith("/employer")) {
          return pathname;
        } else {
          return employer_path.EMPLOYER_DASHBOARD_PATH;
        }
      } 
      
      //this is for trainer navigation
      else if (user.type === userType.trainer) {
        if (pathname && pathname !=="/" && search) {
          return pathname + search;
        } else if (pathname && pathname !=="/" && pathname.startsWith("/trainer")) {
          return pathname;
        } else {
          return trainer_path.TRAINER_DASHBOARD_PATH;
        }
      }
      //this for the staff naviagation
      else if (user.type === userType.staff) {
        if (pathname && pathname !=="/" && search) {
          return pathname + search;
        } else if (pathname && pathname !=="/") {
          return pathname;
        } else {
          return staff_path.STAFF_DASHBOARD_PATH;
        }
      }

      //this is for supervisor navigation
      else if (user.type === userType.supervisor) {
        if (pathname && pathname !=="/" && search) {
          return pathname + search;
        } else if (pathname && pathname !=="/") {
          return pathname;
        } else {
          return supervisor_path.SUPERVISOR_DASHBOARD_PATH;
        }
      }

    } else {
      return anonymous_path.SET_PIN_PATH;
    }
  };

  useEffect(() => {
    if (user) {
      if (user.pin_active === true) {
        setLoginPinActive();
      }
      setGoToAuthRoute(true);
    }
  }, [user]);

  if (goToAuthRoute) {
    return <Redirect to={selectAuthRoute()}></Redirect>;
  } else {
    return (
      <div
        className={`col-md-12 login-form-container no-gutters bg-image ${
          props.loading ? "disabled" : ""
        }`}
        disabled
      >
        {props.children}
      </div>
    );
  }
};

Anonymous.propTypes = {
  children: propTypes.object,
  loading: propTypes.bool,
};

//export default checkAuthentication(Anonymous)
export default Anonymous;
