import React from "react";
import styles from "./styles.module.scss";
import propTypes from 'prop-types';

function SubHeader(props) {
  return (
    <div
      className={`d-flex align-items-center justify-content-between newContainer ${styles.secDiv}`}
    >
      <p className={`${styles.secDivTitle1} removeExtraBotMargin`}>
        {props.text1}
      </p>
      <p className={`${styles.secDivTitle2} removeExtraBotMargin`}>
        {props.text2}
      </p>
    </div>
  );
}

SubHeader.propTypes = {
  text1: propTypes.string,
  text2: propTypes.string,
};


export default SubHeader;
