import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as studentProfile from "../../actions/employer/employerStudentProfile.action";

function *getStudentProfileData(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getStudentProfile, action.payload)
        //extract the data here     
        yield put(studentProfile.studentProfileData.success(data));
    } catch (e) {
        yield put(studentProfile.studentProfileData.failure(e));
    }
}
function *getStudentProfileDataSaga(){
    yield (
        takeLatest(studentProfile.STUDENT_PROFILE.REQUEST, getStudentProfileData)
    );
}

export default getStudentProfileDataSaga;