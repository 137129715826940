import * as unitSignature from "../../actions/employer/unitSignature.action";

const initialState = {
    unitSign: [],
    loadingSign:false,
    signSelected:false,
    signSuccess:false,
    signError:false,
    signUnit:{},
    loadPendingSignatures: true,
}


export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
      case unitSignature.SHOW_SIGN:
        return {
          ...state,
          signSelected: action.payload.signSelected,
          signUnit: action.payload.signUnit,
          signSuccess: action.payload.signSuccess
        }
        case unitSignature.SIGN_SUCCESS:
          return {
            ...state,
            signSuccess:false,
          }
  case unitSignature.UNIT_SIGN.REQUEST:
    return {
      ...state,
      loadingSign: true,
      loadPendingSignatures: true,
    };
  case unitSignature.UNIT_SIGN.SUCCESS:

    return {
      ...state,
      signSelected:false,
      signSuccess: true,
      loadingSign: false,
      loadPendingSignatures: true,
    };

  case unitSignature.UNIT_SIGN.FAILURE:
    return {
      ...state,
      signSelected:false,
      signSuccess: false,
      loadingSign: false,
      signError:true,
      loadPendingSignatures: true,
    };
 
    default: return state      
}
}