import React from 'react'
import './styles.modules.scss'
import close from '../../../assets/images/cancel.png'
import success from '../../../assets/images/success.png'
import Button from 'react-bootstrap/Button';
import global from "../../../styles/global.module.scss";
import propTypes from "prop-types";

const Overlay = (props) => {

    return (
        <div className='top_overlay'>
        <div className='overlay-modal'>
            <div className='overlay-header'>
                <h3>{props.title}</h3>
                {props.crossicon === true ?  <></> : <div className='overlay-close-icon'  onClick={props.handleCross}>
                <img src={close} alt="close" className='overlay-close' />
                </div>}
            </div>
            <div className='overlay-body'>
                <img src={success} alt="sucess" className='overlay-success' />
                <div className='overlay-content'>
                    <strong>{props.text}</strong>
                </div>
                <div className='overlay-footer'>
                    <Button className={`${global.blueButton}`} onClick={props.onClick}>
                        Close
                    </Button>
                </div>

            </div>
            {props.children}
        </div>
        </div>
    )

}

Overlay.propTypes = {
    children: propTypes.string,
    title: propTypes.string,
    crossicon: propTypes.bool,
    handleCross: propTypes.func,
    text: propTypes.string,
    onClick: propTypes.func,
}


export default Overlay