import * as studentProgressReportData from "../../actions/employer/StudentProgressReport.action";

const initialState = {
    progressReport: [],
    loading: false,
    success: false,
    progressReportFailure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentProgressReportData.STUDENT_PROGRESS_REPORT.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentProgressReportData.STUDENT_PROGRESS_REPORT.SUCCESS:

    return {
      ...state,
      progressReport: action.payload.data,
      loading: false,
      success: true,
      progressReportFailure:false,
    };

  case studentProgressReportData.STUDENT_PROGRESS_REPORT.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      progressReportFailure:true,
    };
      default: return state      
    }
}
