import * as studentCourseProgress from "../../actions/student/studentCourseProgress.action";

const initialState = {
    progressReport: [],
    loading: false,
    success: false,
    progressReportFailure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentCourseProgress.STUDENT_COURSE_PROGRESS.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentCourseProgress.STUDENT_COURSE_PROGRESS.SUCCESS:

    return {
      ...state,
      progressReport: action.payload.data,
      loading: false,
      success: true,
      progressReportFailure:false,
    };

  case studentCourseProgress.STUDENT_COURSE_PROGRESS.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      progressReportFailure:true,
    };
      default: return state      
    }
}
