import * as VerifySignupActions from "../../actions/user/verifySignup.user.action";

const initialState = {
  loading: null,
  verifySuccess: false,
  verifyFailure: false,
  user: {},
  contact_id: "",
  contact_type: "",
  status: "",
  statusCode:"",
};

export default function (state = initialState, action) {
  switch (action.type) {
  
    case VerifySignupActions.SIGNUP_LINK_VERIFICATION.REQUEST:
      return {
        ...state,
        loading: true,
        verifySuccess: false,
        verifyFailure: false,
        user: {},
        contact_id: "",
        contact_type: "",
      };

    case VerifySignupActions.SIGNUP_LINK_VERIFICATION.SUCCESS:
     
      return {
        ...state,
        loading: false,
        verifySuccess: true,
        verifyFailure: false,
        user: action.payload.users,
        contact_id: action.payload.contact_id,
        contact_type: action.payload.contact_type,
        status: action.payload.status,
        statusCode: action.payload.statusCode,
        message: action.payload.message,
      };

    case VerifySignupActions.SIGNUP_LINK_VERIFICATION.FAILURE:
      return {
        ...state,
        loading: false,
        verifySuccess: false,
        verifyFailure: true,
        user: null,
        contact_id: "",
        contact_type: "",
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
