import { createRequestTypes, action } from "../../../utils/redux";
export const FETCH_FREQUENT_EQUIPMENTS = createRequestTypes("FETCH_FREQUENT_EQUIPMENTS");
export const fetchFrequentEquipments= {
  request: (data) => action(FETCH_FREQUENT_EQUIPMENTS.REQUEST, data),
  success: (data) => action(FETCH_FREQUENT_EQUIPMENTS.SUCCESS, data),
  failure: (error) => action(FETCH_FREQUENT_EQUIPMENTS.FAILURE, error),
};


export const CLEAR_FREQUENT_EQUIPMENT = "CLEAR_FREQUENT_EQUIPMENTS";
export const clearFrequentEquipments = {
  request : () => action(CLEAR_FREQUENT_EQUIPMENT)
}
