
const logo = require('../../assets/images/logo.png')
const defaultConfig = {
    logo: logo
}


const configReducer = (state=defaultConfig, action) => {
    switch(action.type){
        case 'GET_LOGO':
            return state.configuration.logo
        default:
            return state
    }
}

export default configReducer;