import { createRequestTypes, action } from "../../../utils/redux";
export const LOGIN_WITH_PIN = createRequestTypes("LOGIN_WITH_PIN");
//export const REMEMBER_ME = createRequestTypes("REMEMBER_ME")

export const loginWithPin = {
  request: (data) => action(LOGIN_WITH_PIN.REQUEST, data),
  success: (data) => action(LOGIN_WITH_PIN.SUCCESS, data),
  failure: (error) => action(LOGIN_WITH_PIN.FAILURE, error),
};

//   export const remember_me = {
//     request: (data) => action(REMEMBER_ME.REQUEST, data),
//     success: (data) => action(REMEMBER_ME.SUCCESS, data),
//     failure: (error) => action(REMEMBER_ME.FAILURE, error),
//   };
