import React from "react";
import { ConnectedRouter } from "connected-react-router";
import "./App.scss";
import "./styles/global.scss";
import AppRoutes from "./pages/App/index";

function App({ history, context }) {
  //removing all the errors from the browser console
  console.warn = console.error = () => {};
  return (
    <ConnectedRouter history={history}>
      <AppRoutes history={history} context={context} />
    </ConnectedRouter>
  );
}

export default App;
