import { call, put, takeLatest } from "redux-saga/effects";
import { StudentApi } from "../../../services/student/Student.services";
import {ADD_SUPERVISOR, addSupervisor} from "../../actions/student/addSupervisor.action"

function* handleAddSupervisor({ payload }) {
  try {
    const { data } = yield call(StudentApi.addSupervisor, payload);
    yield put(addSupervisor.success({
        status:data?.status,
        statusCode:data?.status_code,
        supervisorData:data?.data,
        message:data?.message
    }));
  } catch (e) {
    const data = e.data
    yield put(addSupervisor.failure({
        status:data?.status,
        statusCode:data?.status_code,
        supervisorData:data?.data,
        message:data?.message,
        error:data?.error
    }));
  }
}

function* addSupervisorSaga() {
  yield takeLatest(ADD_SUPERVISOR.REQUEST, handleAddSupervisor);
}

export default addSupervisorSaga;