import * as SetPinActions from "../../actions/user/setpin.user.action";

const initialState = {
  loading: false,
  setPinSuccess: false,
  error: "",
  payload: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SetPinActions.SET_PIN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SetPinActions.SET_PIN.SUCCESS:
      return {
        ...state,
        setPinSuccess: action.payload.success,
        loading: false,
        error: "",
        message: action.payload.message,
      };
    case SetPinActions.SET_PIN.FAILURE:
      return {
        ...state,
        loading: false,
        message:action.payload.message,
        status:action.payload.status
      };

      case SetPinActions.RESET_SET_PIN.SUCCESS:
        return {
          ...state,
          setPinSuccess: false,
          loading: false,
          error: "",
          message: "",
        };
   


    default:
      return state;
  }
}
