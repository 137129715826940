import React from 'react'
import EmployerSignature from '../../../components/EmployerSignature/EmployerSignature'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getWorklogDetailsForSign } from '../../../store/actions/employer/getWorklogDetailsForSign.action';

const EmSignature = () => {



  //this search have the value of the query passed in params
  const {search} = useLocation()
  const dispatch=useDispatch();
  useEffect(()=>{
   if(search!==""){
    const queryData=search.split("&");
    //this const is having the data of worklog id fetched from the query
    const worklog_id=queryData[1].split("=")[1]
    //this const is having the studen id of whom the worklogs belong and fetched from teh query params
    const student_id=queryData[0].split("=")[1]
  if(worklog_id && student_id){
    //this action will dispatch when there is worklog id and student id both exists
    dispatch(getWorklogDetailsForSign.request({worklog_id:worklog_id , student_id:student_id}));
  }
   } 
  },[dispatch, search])


  const studentData = useSelector(state => state.employerReducers.employerStudent)
  return (
    <div>
      <EmployerSignature studentData={studentData} />
    </div>
  )
}

export default EmSignature