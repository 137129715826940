import React, { useEffect, useState } from "react";
import "./styles.module.scss";
import mainStyles from "../../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/styles.module.scss";
import CustomButton from "../../../components/common/CustomButton";
import AccordionComponent from "../../../components/common/Accordian";
import { fetchWorklogTasks } from "../../../store/actions/worklogs/fetchWorklogTasks.worklogs.action";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchWorklogEquipment,
  clearEquipment,
} from "../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";
import { fetchWorklogSupervisor } from "../../../store/actions/worklogs/fetchWorklogSupervisor.worklogs.action";
import jwt_decode from "jwt-decode";
import NextIcon from "../../../assets/images/next.svg";
import backIcon from "../../../assets/images/Back.svg";
import saveletter from "../../../assets/images/saveDraft.svg";
import { useHistory } from "react-router-dom";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import disableNextIcon from "../../../assets/images/DisabledNext.svg";
import { fetchLocally } from "../../../utils/localStorage";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { student_path } from "../../../Constants/paths/student";
import propTypes from "prop-types";
import { fetchFrequentEquipments } from "../../../store/actions/worklogs/fetchFrequentWorklogEquiment.action";
import { decodeOnBehalfToken } from "../../../utils/helper/common/decodeJSONToken";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../../utils/localStorage";
import { staff_path } from "../../../Constants/paths/staff";
import searchIcon from "../../../assets/images/searchIcon.svg";
import clearSearchIcon from "../../../assets/images/crossSearchIcon.svg";
import style from "./styles.module.scss";

function NewWLStep2(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const history = useHistory();
  const dispatch = useDispatch();

  //these are the props from parent components
  const {
    overdueTask,
    setOverDueTask,
    currentTask,
    setCurretTask,
    futureTask,
    setFutureTask,
    completedTask,
    setCompletedTask,
    task,
    data,
    setinputChecked,
    setCurrentEquipments,
    searchTasks,
  } = props;

  //fetching the create worklog status from createworklog reducer
  const worklogCreationReducer = useSelector(
    (state) => state?.worklog?.createWorklogsWorklogsReducer
  );
  const createWorklogStatus = worklogCreationReducer?.worklogCreated;

  const fetchWorkLogTasksData = useSelector(
    (state) => state.worklog.fetchWorklogTasksReducer
  );

  //these constants and function will enable and disable the button
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  //these are teh constants
  const loadingTask = fetchWorkLogTasksData.loadingWorklogTask;
  const token = fetchLocally("login_credentials");
  const decoded = jwt_decode(token);
  const contactID = decoded.contact_id;

  //useEffect to render page
  useEffect(() => {}, []);

  //these effects will move the user to the view all worklogs page
  useEffect(() => {
    if (createWorklogStatus === true) {
      history.push(student_path.VIEW_WORKLOG_PATH);
    }
  }, [createWorklogStatus]);

  //this effect will set the diffrentiate and assign all the tasks to 4 types of tasks
  useEffect(() => {
    setOverDueTask([]);
    setCurretTask([]);
    setFutureTask([]);
    setCompletedTask([]);
    if (fetchWorkLogTasksData?.tasks) {
      fetchWorkLogTasksData.tasks
        .filter((item) => {
          if (searchTasks.value === "") {
            return item;
          } else {
            const splittedSearchValue = searchTasks.value
              .toLowerCase()
              .split(" ");
            let isFound = true;
            for (let i of splittedSearchValue) {
              if (item.name.toLowerCase().includes(i)) {
              } else {
                isFound = false;
              }
            }
            if (isFound === true) {
              return item;
            }
          }
        })
        .forEach((item, index) => {
          let currDate = new Date();
          let dueDate = new Date(item.due_date);
          let fourMonths = new Date(
            new Date().getTime() + 4 * 30 * 24 * 60 * 60 * 1000
          );

          if (
            item.photos_required <= item.photos_uploaded &&
            item.number_of_times <= item.number_times_performed
          ) {
            setCompletedTask((state) => {
              return [...state, item];
            });
          } else {
            if (currDate > dueDate) {
              setOverDueTask((state) => {
                return [...state, item];
              });
            } else if (dueDate < fourMonths) {
              setCurretTask((state) => {
                return [...state, item];
              });
            } else if (dueDate > fourMonths) {
              setFutureTask((state) => {
                return [...state, item];
              });
            }
          }
        });
    }
  }, [fetchWorkLogTasksData, searchTasks.value]);

  //this effect will fetch the task for the logged in user
  useEffect(() => {
    dispatch(fetchWorklogTasks.request(contactID));
  }, []);

  //these function will clear the equipment when there is task id exist
  const getEquipments = () => {
    //nothing is happening now
  };

  // this effect will run when there is a change in the task id and it will fetch the equipments for that task id
  useEffect(() => {
    const task_id =
      task.value?.task_id?.toString() || props?.data?.taskDetail[0].task_id;
    task_id && dispatch(clearEquipment.request());
    setCurrentEquipments([]);
    // task_id &&
    //   dispatch(
    //     fetchWorklogEquipment.request({
    //       task_id,
    //       search_string:"",
    //       skip_count: 0

    //     })
    //   );
    task_id &&
      dispatch(
        fetchFrequentEquipments.request({
          task_id,
        })
      );
  }, [task?.value?.task_id]);

  //handlign the signout of onbehalf login
  const handleOnbehalfLogOut = () => {
    dispatch(resetOnBehalfSignIn.success());
    removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
    history.push(staff_path.STAFF_DASHBOARD_PATH);
  };

  //this function will handle the search of the tasks
  const handleSearchClick = () => {
    const input = document.getElementById("searchInput");
    if (input) {
      input.focus();
    }
  };
  //this function will clear the search input of the tasks
  const handleClearSearchInput = () => {
    searchTasks.setInputValue("");
  };

  return loadingTask === true ||
    worklogCreationReducer.loadingWorklogs === true ? (
    <div className="loader">
      <img src={loadingIcon} alt="loading icon"></img>
    </div>
  ) : (
    <div>
      <div
        id="searchBox"
        className={
          searchTasks.value !== ""
            ? style.searchContainerActive
            : style.searchContainer
        }
      >
        <div className={style.searchIcon}>
          <img src={searchIcon} alt="Search Icon" />
        </div>
        <div className={style.searchInput} onClick={handleSearchClick}>
          <input
            className={style.searchInput}
            id="searchInput"
            type="text"
            placeholder="Search Equipment"
            value={searchTasks.value}
            onChange={searchTasks.onChange}
          />
        </div>
        <div className={style.crossIcon}>
          <img
            src={clearSearchIcon}
            alt="Cross Icon"
            onClick={handleClearSearchInput}
          />
        </div>
      </div>
      <div className={style.addExtraPadding}>
        {/* Overdue tasks and Current Tasks */}
        <div>
          {overdueTask?.length > 0 && (
            <AccordionComponent
              data={overdueTask}
              title={`Overdue Tasks (${overdueTask?.length})`}
              name="tasks"
              onChange={() => getEquipments()}
              // value={task.value ? task.value : { task_id: 6 }}
              value={
                task.value
                  ? task.value
                  : { task_id: props?.data?.taskDetail[0].task_id }
              }
              setInputValue={task.setInputValue}
              id="overdue"
              setinputChecked={setinputChecked}
              activeAccordionKey="0"
              defaultKey={true}
            />
          )}

          {currentTask?.length > 0 && (
            <AccordionComponent
              data={currentTask}
              title={`Current Tasks (${currentTask?.length})`}
              name="tasks"
              onChange={() => getEquipments()}
              value={
                task.value
                  ? task.value
                  : { task_id: props?.data?.taskDetail[0].task_id }
              }
              id="current"
              setInputValue={task.setInputValue}
              setinputChecked={setinputChecked}
              activeAccordionKey={overdueTask?.length <= 0 ? "0" : "1"}
              defaultKey={overdueTask?.length <= 0 ? true : false}
            />
          )}

          {futureTask?.length > 0 && (
            <AccordionComponent
              data={futureTask}
              title={`Future Tasks (${futureTask?.length})`}
              name="tasks"
              onChange={() => getEquipments()}
              value={
                task.value
                  ? task.value
                  : { task_id: props?.data?.taskDetail[0].task_id }
              }
              id="current"
              setInputValue={task.setInputValue}
              setinputChecked={setinputChecked}
              activeAccordionKey={
                overdueTask?.length <= 0 && currentTask?.length <= 0 ? "0" : "1"
              }
              defaultKey={
                overdueTask?.length <= 0 && currentTask?.length <= 0
                  ? true
                  : false
              }
            />
          )}

          {completedTask?.length > 0 && (
            <AccordionComponent
              data={completedTask}
              title={`Completed Tasks (${completedTask?.length})`}
              name="tasks"
              onChange={() => getEquipments()}
              value={
                task.value
                  ? task.value
                  : { task_id: props?.data?.taskDetail[0].task_id }
              }
              id="current"
              setInputValue={task.setInputValue}
              setinputChecked={setinputChecked}
              activeAccordionKey={
                overdueTask?.length <= 0 &&
                currentTask?.length <= 0 &&
                futureTask?.length <= 0
                  ? "0"
                  : "1"
              }
              defaultKey={
                overdueTask?.length <= 0 &&
                currentTask?.length <= 0 &&
                futureTask?.length <= 0
                  ? true
                  : false
              }
            />
          )}
        </div>

        <div
          className={
            decodeOnBehalfToken()
              ? `d-flex justify-content-center align-items-center fixedBottomContainer newContainer mt-4 OnBehalfButtonPadding`
              : `d-flex justify-content-center align-items-center fixedBottomContainer newContainer mt-4 ButtonPadding`
          }
        >
          {decodeOnBehalfToken() && (
            <div className={"cancel"} onClick={handleOnbehalfLogOut}>
              Close
            </div>
          )}
          <CustomButton
            image={backIcon}
            text="Back"
            type="transparent"
            width="50%"
            margin="0px 5px 0px 0px"
            onClick={() =>
              props.setStep({
                num: 1,
                text: "Details",
              })
            }
          />
          <CustomButton
            text="Save as Draft"
            disabled={data?.status === 1}
            type={data?.status === 1 ? "disabled" : "blue"}
            width="50%"
            image={saveletter}
            disableimg={saveletter}
            margin="0px 5px 0px 0px"
            onClick={() => {
              handleShow();
            }}
          />
          <CustomModal
            modalFor="saveLater"
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            handleErase={props.saveLater}
          />

          <CustomButton
            disabled={
              data !== undefined
                ? task.value !== "" || data.taskDetail[0].task_id
                  ? false
                  : true
                : task.value !== ""
                ? false
                : true
            }
            text="Next"
            image={NextIcon}
            disableimg={disableNextIcon}
            type={
              data !== undefined
                ? task.value !== "" || data.taskDetail[0].task_id
                  ? "blue"
                  : "disabled"
                : task.value !== ""
                ? "blue"
                : "disabled"
            }
            width="50%"
            onClick={() => {
              dispatch(fetchWorklogSupervisor.request(contactID));
              props.setStep({
                num: 3,
                text: "Photo",
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

NewWLStep2.propTypes = {
  constructed: propTypes.object,
  errorMessage: propTypes.object,
  setStep: propTypes.func,
  data: propTypes.object,
  dataAccordion1: propTypes.array,
  dataAccordion2: propTypes.array,
  task: propTypes.object,
  current: propTypes.object,
  equipments: propTypes.object,
  tools: propTypes.array,
  executeScroll: propTypes.func,
  radioButtonPicOptions: propTypes.array,
  myRef: propTypes.object,
  supervisors: propTypes.array,
  moreEquipments: propTypes.object,
  addMore: propTypes.func,
  AddMore: propTypes.func,
  profs: propTypes.object,
  mergedEquipment: propTypes.array,
  setMergedEquipments: propTypes.func,
  overdueTask: propTypes.array,
  setOverDueTask: propTypes.func,
  currentTask: propTypes.array,
  setCurretTask: propTypes.func,
  futureTask: propTypes.array,
  setFutureTask: propTypes.func,
  completedTask: propTypes.array,
  setCompletedTask: propTypes.func,
  saveLater: propTypes.func,
  setinputChecked: propTypes.func,
  searchTasks: propTypes.string,
};

export default NewWLStep2;
