import { call, put, takeLatest } from "redux-saga/effects";
import { deleteWorklogAPI } from "../../../services/worklogs/deleteworklog.service";
import {
    DELETE_WORKLOG,
  deleteWorklog,
} from "../../../store/actions/worklogs/deleteWorklog.worklogs.action";

function* handleDeleteWorklog({ payload }) {
  try {
    
    const { data } = yield call(deleteWorklogAPI.deleteWorklog, payload);
    yield put(deleteWorklog.success(data));
  } catch (e) {
    yield put(deleteWorklog.failure(e.data));
  }
}

function* deleteWorklogSaga() {
  yield takeLatest(DELETE_WORKLOG.REQUEST, handleDeleteWorklog);
}

export default deleteWorklogSaga;