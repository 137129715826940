import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import BlueDot from "../../../components/common/BlueDot/index";
import Checkbox from "../../../components/common/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorklogEquipment,
  clearEquipment,
} from "../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";
import propTypes from "prop-types";
import style from "./style.module.scss";
import searchIcon from "../../../assets/images/searchIcon.svg";
import clearSearchIcon from "../../../assets/images/crossSearchIcon.svg";
import addMoreToolIcon from "../../../assets/images/addMoreToolIcon.svg";
import showMoreToolIcon from "../../../assets/images/showMoreToolIcon.svg";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";

function Equipments(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  //these are the props which are passed
  const {
    task,
    equipments,
    mergedEquipments,
    equipMap,
    AddMore,
    addMore,
    moreEquipments,
    data,
    setblankCheckbox,
    blankCheckbox,
    addMoreEquipCount,
    handleAddEquip,
    addMoreEquipInput,
    setAddMoreEquipInput,
    searchEquipment,
    currentEquipments,
    setCurrentEquipments,
    selectEquip, setSelectEquip
  } = props;

  //fetching the value of reducer from Equipment data
  const fetchWorkLogEquipmentData = useSelector(
    (state) => state.worklog.fetchWorklogEquipmentReducer
  );
  //frequent equipments used in tasks
  const frequentEquipments = useSelector(
    (state) => state.worklog.frequentEquipments
  );

  //assigning the equiment to a constant
  const worklogEquip =
    data?.worklog?.task != undefined ? data?.worklog?.task[0].equipments : [];

  //modifying the array key of equip
  // const modifiedWorklogEquip =
  //   worklogEquip != undefined &&
  //   worklogEquip.map((item) => {
  //     return {
  //       equipment_id: item.equipment_id,
  //       name: item.name,
  //     };
  //   });

  //merging the two array having three parameter passed in a funtion which will merge the two array of equipments
  //   *************** need to remove this code /******************* */
  // const mergeArrayOfObjects = (original, newdata, selector = "key") => {
  //   newdata.forEach((dat) => {
  //     const foundIndex =
  //       original.length > 0 &&
  //       original.findIndex((ori) => ori[selector] == dat[selector]);
  //     if (foundIndex >= 0 && original.length > 0)
  //       original.splice(foundIndex, 1, dat);
  //     else original.length > 0 && original.push(dat);
  //   });

  //   return original;
  // };
  const FilteredEquipArray = fetchWorkLogEquipmentData?.equipments
  //these function will fetch the next 10 equipments from the database
  const showMore = () => {
    const task_id =
      task.value?.task_id?.toString() || data?.taskDetail[0].task_id;
    task_id &&
      dispatch(
        fetchWorklogEquipment.request({
          task_id,
          search_string: searchEquipment.value,
          skip_count: count * 10,
        })
      );
    setCount(count + 1);
  };

  //add more button ///////////////////////////

  useEffect(() => {}, [addMoreEquipCount]);

  const numberVariable = addMoreEquipCount;
  let arr = [];
  for (let i = 0; i < numberVariable; i++) {
    arr.push(i);
  }
  let initialState = {};
  arr.forEach((element) => {
    initialState[element] = "";
  });

  let inputName = 0;

  const handleUserInputChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setAddMoreEquipInput({ ...addMoreEquipInput, [name]: newValue });
  };

  //searching the equipment functionality
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearEquipment.request());
      const task_id =
        task.value?.task_id?.toString() || data?.taskDetail[0].task_id;
     if(searchEquipment.value !=="" && task_id){
      dispatch(
        fetchWorklogEquipment.request({
          task_id,
          skip_count: 0,
          search_string: searchEquipment.value,
        })
      );
     } 
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchEquipment.value]);

  // const [searchResultBox, setsearchResultBox] = useState(false);
  const handleSearchClick = () => {
    const input= document.getElementById("searchInput")
    if(input){
      input.focus();
    }
    // setsearchResultBox(!searchResultBox);
  };

  //this function will set the current equipment outside the search box

  useEffect(() => {
    if (mergedEquipments.length > 0) {
      mergedEquipments?.map((item) => {
        //these two var will filter out the unique eqipment
        var ids = new Set(
          fetchWorkLogEquipmentData?.equipments.length > 0 ?fetchWorkLogEquipmentData?.equipments.map((d) => d.equipment_id) : []
        );
        var merged = worklogEquip !==undefined  ? [
          ...fetchWorkLogEquipmentData?.equipments,
          ...worklogEquip?.filter((d) => !ids.has(d.equipment_id)),
        ] : [...fetchWorkLogEquipmentData?.equipments];
        return merged.find((equip) => {
          if (equip.equipment_id == item) {
            var contains = selectEquip.some((elem) => {
              return (
                JSON.stringify(equip.equipment_id) ===
                JSON.stringify(elem.equipment_id)
              );
            });
            if (contains) {
            } else {
              setSelectEquip((selectEquip) => [...selectEquip, equip]);
            }
          }
        });
      });
    }
  }, [mergedEquipments, fetchWorkLogEquipmentData?.equipments]);

  const handleClearSearchInput = () => {
    searchEquipment.setInputValue("");
  };


  // useEffect(()=>{
  //   if(searchEquipment.value !==""){
  //     const input= document.getElementById("searchInput")
  //     if(input){
  //       input.focus();
  //     }
  //   }
    
  // },[searchEquipment.value])
//this effect will set the current selected equipment so it can be utilized on other page.. this is the final page
  useEffect(() => {
    setCurrentEquipments([]);
    const filterEquip = selectEquip.filter((item) => {
      return mergedEquipments.find((equip) => {
        if (equip == item.equipment_id) {
          return item;
        }
      });
    });
    const finalEquipArray = frequentEquipments?.equipments.concat(filterEquip)
    const key = 'equipment_id'
    const arrayUniqueByKey = [...new Map(finalEquipArray.map(item =>
      [item[key], item])).values()];
    setCurrentEquipments(arrayUniqueByKey);
  }, [selectEquip, mergedEquipments]);
 const showMoreButton =   fetchWorkLogEquipmentData.total < 10 ? false : fetchWorkLogEquipmentData.total/((count)*10) >=1 ? true :false
  return (
    <Collapse in={true} className="mt-4">
      <div className="mb-4 ml-3" id="supervisorDiv">
        <div className="d-flex align-items-center mb-3">
          <BlueDot />
          <p className="removeExtraBotMargin heading">
            Equipment {/* {task.value.name}? */}
          </p>
        </div>
        <div
        id="searchBox"
          className={
            searchEquipment.value !==""
              ? style.searchContainerActive
              : style.searchContainer
          }
        >
          <div className={style.searchIcon}>
            <img src={searchIcon} alt="Search Icon"/>
          </div>
          <div className={style.searchInput} onClick={handleSearchClick}>
            <input
              className={style.searchInput}
              id="searchInput"
              type="text"
              placeholder="Search Equipment"
              value={searchEquipment.value}
              onChange={searchEquipment.onChange}
            />
          </div>
          <div className={style.crossIcon}>
            <img
              src={clearSearchIcon}
              alt="Cross Icon"
              onClick={handleClearSearchInput}
            />
          </div>
        </div>
        {searchEquipment.value !=="" && (
          <div className={style.searchResulContainer}>
            <div className={style.toolBox}>
              { fetchWorkLogEquipmentData.loadingWorklog ? 
                  <div className={style.loaderIcon}>
                  <img src={loadingIcon} alt="loading icon"></img>
                </div>
              :FilteredEquipArray != false
                ? FilteredEquipArray?.map((elem) => (
                    <Checkbox
                      options={elem.name}
                      value={elem.equipment_id}
                      label={elem.name}
                      dataType="tool"
                      setInputValue={equipments.setInputValue}
                      array={mergedEquipments}
                      data={
                        equipMap
                          ? equipMap.map((item) => item.equipment_id)
                          : []
                      }
                      checked={
                        equipments?.value?.length || blankCheckbox
                          ? equipments.value.includes(
                              elem.equipment_id.toString()
                            )
                          : equipMap?.find((item) => {
                              if (item.equipment_id == elem.equipment_id) {
                                return true;
                              }
                            })
                      }
                      setblankCheckbox={setblankCheckbox}
                    />
                  ))
                : fetchWorkLogEquipmentData?.equipments?.map((elem) => (
                    <Checkbox
                      options={elem.name}
                      value={elem.equipment_id}
                      label={elem.name}
                      dataType="tool"
                      setInputValue={equipments.setInputValue}
                      array={mergedEquipments}
                      data={
                        equipMap
                          ? equipMap.map((item) => item.equipment_id)
                          : []
                      }
                      checked={
                        equipments?.value?.length || blankCheckbox
                          ? equipments.value.includes(
                              elem.equipment_id.toString()
                            )
                          : equipMap?.find((item) => {
                              if (item.equipment_id == elem.equipment_id) {
                                return true;
                              }
                            })
                      }
                      setblankCheckbox={setblankCheckbox}
                      // activeCheck={ActiveCheckbox}
                    />
                  ))}
            </div>
          {showMoreButton && <div className={style.showMoreTool} >
              <img src={showMoreToolIcon} alt="Show More Tool Icon" onClick={showMore}/>
              <span onClick={showMore}>Show more tools</span>
            </div>}  
            <div
              className={style.addaTool}
            >
              <img src={addMoreToolIcon} alt="Add Tool Icon" onClick={() => {
                handleAddEquip();
                handleClearSearchInput();
              }}/>
              <span onClick={() => {
                handleAddEquip();
                handleClearSearchInput();
              }}>Add a tool</span>
            </div>
          </div>
        )}

        {searchEquipment.value === "" &&
          currentEquipments.length > 0 &&
          currentEquipments?.map((elem) => (
            <Checkbox
              options={elem.name}
              value={elem.equipment_id}
              label={elem.name}
              dataType="tool"
              setInputValue={equipments.setInputValue}
              array={mergedEquipments}
              data={equipMap ? equipMap.map((item) => item.equipment_id) : []}
              checked={
                equipments?.value?.length || blankCheckbox
                  ? equipments.value.includes(elem.equipment_id.toString())
                  : equipMap?.find((item) => {
                      if (item.equipment_id == elem.equipment_id) {
                        return true;
                      }
                    })
              }
              setblankCheckbox={setblankCheckbox}
            />
          ))}
        {searchEquipment.value ==="" && arr.map((number, idx) => {
          inputName++;
          return (
            <>
              <AddMore
                name={inputName}
                call={`Equipment${idx}`}
                placeholder="Add Equipment"
                addMore={addMore}
                value={addMoreEquipInput[inputName]}
                onChange={handleUserInputChange}
                moreEquipments={moreEquipments}
                class="webcampics"
              />
            </>
          );
        })}
      </div>
    </Collapse>
  );
}

Equipments.propTypes = {
  data: propTypes.object,
  task: propTypes.object,
  equipments: propTypes.array,
  mergedEquipments: propTypes.array,
  equipMap: propTypes.array,
  AddMore: propTypes.func,
  addMore: propTypes.func,
  moreEquipments: propTypes.object,
  setblankCheckbox: propTypes.func,
  blankCheckbox: propTypes.bool,
  setCurrentEquipments:propTypes.func
};

export default Equipments;
