import { combineReducers } from "redux";
import login from "./login.user.reducer";
import profile from "./profile.user.reducer";
import worklog from "./worklog.user.reducer";
import setpin from "./setpin.user.reducer";
import loginWithPin from "./loginWithPin.user.reducer";
import forgotPassword from "./forgotPassword.user.reducer";
import verifySignup from "./verifySignup.user.reducer";
import signup from "./signup.user.reducer";
import signout from "./signout.user.reducer";
import resetPassword from "./resetPassword.user.reducer";

import changePassword from "./changePassword.user.reducer";
import changePasswordUserReducer from "./changePassword.user.reducer";
import updateEmailUserReducer from "./updateEmail.user.reducer";
import updatePhoneUserReducer from "./updatePhone.user.reducer";

import fetchProfileUserReducer from "./fetchProfile.user.reducer";

import changePinUserReducer from "./changePin.user.reducer";
import generateLoginLinkUserReducer from "./generateLoginLink.user.reducer";
import loginWithCodeUserReducer from "./loginWithCode.user.reducer";


const userReducer = combineReducers({
  login,
  profile,
  setpin,
  worklog,
  loginWithPin,
  forgotPassword,
  verifySignup,
  signup,
  signout,
  resetPassword,
  changePassword,
  changePasswordUserReducer,
  updateEmailUserReducer,
  updatePhoneUserReducer,
  fetchProfileUserReducer,
  changePinUserReducer,
  generateLoginLinkUserReducer,
  loginWithCodeUserReducer


});

export default userReducer;
