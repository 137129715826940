// Login
export const PERFORM_LOGIN = "PERFORM_LOGIN";
export const PERFORM_LOGIN_RESPONSE = "PERFORM_LOGIN_RESPONSE";

export const VALIDATE_LOGIN_CREDENTIALS = "VALIDATE_LOGIN_CREDENTIALS";
export const IS_LOGIN = "IS_LOGIN";
export const SAVE_LOGIN_CREDENTIALS = "SAVE_LOGIN_CREDENTIALS";

// PIN
export const SET_PIN = "userAuthActions/SET_PIN";
export const SET_PIN_SUCCESS = "userAuthActions/SET_PIN_SUCCESS";
export const SET_PIN_FAILED = "userAuthActions/SET_PIN_FAILED";
export const SKIP_PIN = "userAuthActions/SKIP_PIN";

// PIN
export const LOGIN_WITH_PIN = "userAuthActions/LOGIN_WITH_PIN";
export const LOGIN_WITH_PIN_SUCCESS = "userAuthActions/LOGIN_WITH_PIN_SUCCESS";
export const LOGIN_WITH_PIN_FAILED = "userAuthActions/LOGIN_WITH_PIN_FAILED";

// LOGIN
export const LOGIN = 'userAuthActions/LOGIN';
export const LOGIN_SUCCESS = 'userAuthActions/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'userAuthActions/LOGIN_FAILURE';

export const SIGNOUT = 'userAuthActions/SIGNOUT';
export const LOAD_USER_FROM_LOCAL_STORAGE =
  'userAuthActions/LOAD_USER_FROM_LOCAL_STORAGE';
export const INITIAL_LOAD_USER_DONE = 'userAuthActions/INITIAL_LOAD_USER_DONE';

// Signup
export const SIGNUP = 'userAuthActions/SIGNUP';
export const SIGNUP_RESET = 'userAuthActions/SIGNUP_RESET';
export const SIGNUP_SUCCESS = 'userAuthActions/SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'userAuthActions/SIGNUP_FAILED';

export const VERIFY_SIGNUP_LINK = 'userAuthActions/VERIFY_SIGNUP_LINK';
export const VERIFY_SIGNUP_LINK_SUCCESS = 'userAuthActions/VERIFY_SIGNUP_LINK_SUCCESS';
export const VERIFY_SIGNUP_LINK_FAILED = 'userAuthActions/VERIFY_SIGNUP_LINK_FAILED';

// Verify Email
export const VERIFY_EMAIL = 'userAuthActions/VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'userAuthActions/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'userAuthActions/VERIFY_EMAIL_FAILED';

// Verify Mobile
export const VERIFY_MOBILE = 'userAuthActions/VERIFY_MOBILE';
export const VERIFY_MOBILE_SUCCESS = 'userAuthActions/VERIFY_MOBILE_SUCCESS';
export const VERIFY_MOBILE_FAILED = 'userAuthActions/VERIFY_MOBILE_FAILED';

// Verify OTP
export const VERIFY_OTP = 'userAuthActions/VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'userAuthActions/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'userAuthActions/VERIFY_OTP_FAILED';

//forgot password
export const FORGOT_PASSWORD = 'userAuthActions/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'userAuthActions/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'userAuthActions/FORGOT_PASSWORD_FAILED';

//Reset passwordLink Verify
export const VERIFY_RESET_PASSWORD_LINK = 'userAuthActions/VERIFY_RESET_PASSWORD_LINK';
export const VERIFY_RESET_PASSWORD_LINK_SUCCESS = 'userAuthActions/VERIFY_RESET_PASSWORD_LINK_SUCCESS';
export const VERIFY_RESET_PASSWORD_LINK_FAILED = 'userAuthActions/VERIFY_RESET_PASSWORD_LINK_FAILED';
//Reset password
export const RESET_PASSWORD = 'userAuthActions/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'userAuthActions/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'userAuthActions/RESET_PASSWORD_FAILED';

//forgot password
export const UPDATE_PROFILE = 'userAuthActions/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'userAuthActions/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'userAuthActions/UPDATE_PROFILE_FAILED';

// User (Party Profile fetch)
export const FETCH_PROFILE = 'userAuthActions/FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'userAuthActions/FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILED = 'userAuthActions/FETCH_PROFILE_FAILED';

