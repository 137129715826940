import * as GenerateLoginLink from "../../actions/user/generateLoginLink.user.actions";

const initialState = {
  loading: false,
  generateLoginLinkSuccess: null,
  status: "",
  message: "",
  token: "",
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GenerateLoginLink.GENERATE_LOGIN_LINK.REQUEST:
      return {
        ...state,
        loading: true,
        generateLoginLinkSuccess: null,
      };

    case GenerateLoginLink.GENERATE_LOGIN_LINK.SUCCESS:
      return {
        ...state,
        loading: false,
        generateLoginLinkSuccess: true,
        status: action.payload.status,
        token: action.payload.token,
        message: action.payload.message,
        data: action.payload.data,
      };

    case GenerateLoginLink.GENERATE_LOGIN_LINK.FAILURE:
      return {
        ...state,
        loading: false,
        generateLoginLinkSuccess: false,
        status: action.payload.status,
        message: action.payload.message,
      };

      case GenerateLoginLink.RESET_STATE_GENERATE_LOGIN_LINK.SUCCESS:
      return {
        ...state,
        loading: false,
        generateLoginLinkSuccess: null,
        status: "",
        message: "",
        token: "",
        data: {},
      };


    default:
      return state;
  }
}
