import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as worklogSumissions from "../../actions/employer/worklogSubmissions.action";

function *getWorklogSubmission(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getWorklogSubmission, action.payload)

        //extract the data here    
        yield put(worklogSumissions.worklogSumissionsData.success(data));
    } catch (e) {
        yield put(worklogSumissions.worklogSumissionsData.failure(e));
    }
}
function *getWorklogSubmissionSaga(){
    yield (
        takeLatest(worklogSumissions.WORKLOG_SUBMISSION.REQUEST, getWorklogSubmission)
    );
}

export default getWorklogSubmissionSaga;