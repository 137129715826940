import * as DeleteWorklogActions from "../../../store/actions/worklogs/deleteWorklog.worklogs.action";

const initialState = {
    WorklogId:"",
    loadingWorklog: false,
    loadingDeleteWorklog: false,
    worklogDeleteError:false,
    worklogDeleteStatus:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DeleteWorklogActions.DELETE_WORKLOG.REQUEST:
            return {
                ...state,
                loadingWorklog: true,
                worklogDeleteStatus:false
            };
        case DeleteWorklogActions.DELETE_WORKLOG.SUCCESS:
            return {
                ...state,
                // supervisors:action.payload.supervisors,
                loadingWorklog: false,
                worklogFetchError:false,
                worklogDeleteStatus:true
            };
           
        case DeleteWorklogActions.DELETE_WORKLOG.FAILURE:
            return {
            ...state,
            // Supervisor_id: action.payload,
            loadingWorklog: false,
            worklogFetchError:true,
            worklogDeleteStatus:false
            };
        default:
            return state;
    }
}