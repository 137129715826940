import * as FetchWorklogDetailActions from "../../../store/actions/worklogs/fetchWorklogDetail.worklogs.action"

const initialState = {
    loadingWorklog: false,
    worklog:{
        
    id:0,
        contact_id:1,
        workingDetails:"",
        constructionDetails:"",
        otherComments:"",
        overallComments:"",
        status:0,
        task:{
            id:1,
            photo:{
                photo:"",
                photoHappening:"",
                supervisorId:1,
                howLong:""
            },
            no_photo:{
                part:"",
                how_long:""
            },
            equipment:{
                id:1
            }
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FetchWorklogDetailActions.FETCH_WORKLOG_DETAIL.REQUEST:
            return {
                ...state,
                loadingWorklog: true,  
            };
        case FetchWorklogDetailActions.FETCH_WORKLOG_DETAIL.SUCCESS:
            return {
                ...state,
                loadingWorklog: false,
               worklog: action.payload.worklog,
               taskDetail:action.payload.worklog.task,
                workingDetails:action.payload.worklog.working_details,
                constructionDetails:action.payload.worklog.construction_details,
                photo:"",
                photoHappening:"",
                supervisorId:action.payload.worklog.supervisor_id,
                equipment:action.payload.equipment_id,
                 otherComments:action.payload.worklog.other_comments,
                 overallComments:action.payload.worklog.overall_comments,
                 status:action.payload.worklog.status,
                //loadingWorklog: false,
                worklogFetchError:false,
            };
           
        case FetchWorklogDetailActions.FETCH_WORKLOG_DETAIL.FAILURE:
            return {
            ...state,
            worklog: initialState.worklog,
            loadingWorklog: false,
            worklogFetchError:true,
            };
        default:
            return state;
    }
}