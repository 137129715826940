import React from 'react'
import styles from "./styles.module.scss";
import BlueDot from '../../../components/common/BlueDot';
import { useState } from 'react';
import { useEffect } from 'react';
import propTypes from 'prop-types';

const ProfileInput = (props) => {

  const [value, setValue] = useState(props.value);
  const [isActive, setIsActive] = useState(false)

    //To change the type password to text
  const togglePasswordField = (passwordID) => {
    let passwordField = document.getElementById(passwordID);
    const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
    passwordField.setAttribute("type", type);
    setIsActive(!isActive)
  };

  useEffect(() => {
    setValue(props.value);
  },[props.value])

  return (
    <div className={styles.ProfileInput}>
        <div className={styles.profileLabel}>
            <div className={styles.blueDot}><BlueDot /></div>
        <label htmlFor="">
            {props.label}
        </label>
        </div>
        {props.label2 ? 
        <p className={styles.plaintext}>{props.label2}</p> : 
        <div className={styles.profileInput}>
        <input
         type={props.type}
         onKeyDown = {props.onKeyDown}
         name={props.name}
         onChange={props.onChange}
         value={value}
         placeholder={props.placeholder} 
         id={props.id}
         disabled={props.inputDisable}
         autocomplete="off"
         {...props}
         />   
         {/*Only those input field which type are password*/}
      {((props.id === "currentPassword" && props.value) ||
        (props.id === "newPin" && props.value) ||
        (props.id === "confirmPin" && props.value) ||
        (props.id === "current_password" && props.value) ||
        (props.id === "new_password" && props.value) ||
        (props.id === "confirm_password" && props.value) ||
       (props.id === "currentPin" && props.value)) && <span className={styles.eyeIcon} ><i className={isActive ? "fas fa-eye-slash eye-icon" : "fas fa-eye eye-icon"}
                onClick={() => togglePasswordField(props.id)}
              ></i></span> }
        {props.errorMessage && <span className="form-text text-danger">{props.errorMessage}</span>}
        </div>}
    </div>
  )
}

ProfileInput.propTypes = {
  id: propTypes.string,
  label: propTypes.string,
  label2: propTypes.string,
  name: propTypes.string,
  type: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  errorMessage: propTypes.string,
  
}


export default ProfileInput