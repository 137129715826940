import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { login, remove_login, reset_login } from "../../../store/actions/user/login.user.actions";
import "./index.scss";
import Anonymous from "../../../layouts/Anonymous";
import FormCard from "../../../components/auth/FormCard";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
// import { validateEmail } from "utils/helper/common/validation";
import Checkbox from "../../../components/common/Checkbox/index";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import { anonymous_path } from "../../../Constants/paths/anonymous";
import { resetStateForgotPassword } from "../../../store/actions/user/forgotPassword.user.actions";
import { resetGenerateLoginLink } from "store/actions/user/generateLoginLink.user.actions";

const Login = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  localStorage.clear();
  const [values, setValues] = useState({
    logo: useSelector((state) => state.configuration.logo, shallowEqual),
    email: "",
    password: "",
    remember_me: true,
    isEmailActive: false,
    isPasswordActive: false,
    showPassword: false,
    isEmailCorrect: true,
    emailErrorMessage: "",
    passwordErrorMessage: "",
    error: "",
  });

  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  
  //this will reset the forgot password state reducer to initial value
  dispatch(resetStateForgotPassword.success());
  dispatch(resetGenerateLoginLink.success());

  const loginState = useSelector((state) => state.user.login);

  //   const setPinState = useSelector((state) => state.user.setpin);
  useEffect(() => {}, [loginState.loadingLogin]);

  useEffect(() => {
    if (loginState.loginFailed === true && loginState.loginSuccess === false) {
      setLoading(false);
      // this is for browser back button
      dispatch(remove_login.success())

      if (loginState.message == "No record found") {
        setValues({
          ...values,
          emailErrorMessage: "You have entered an incorrect username",
        });
      } else {
        setValues({
          ...values,
          passwordErrorMessage: loginState.message,
        });
      }
    }
  }, [loginState]);

  const inputChangeHandler = (name) => (event) => {
    name === "remember_me"
      ? setValues({
          ...values,
          error: "",
          remember_me: !values.remember_me,
        })
      : setValues({
          ...values,
          error: "",
          emailErrorMessage: "",
          passwordErrorMessage: "",
          [name]: event.target.value,
        });
  };
  const handleSubmit = (name) => (event) => {
    if (values.email.length > 0) {
      setValues({ ...values, email: "", [name]: event.target.value });
    }
  };
  // const [emailvalue,setEmailvalue] = useState(values.email)
  // const clearEmailField= ()=>{
  //   setEmailvalue(values.email="")
  // }

  const formSubmitHandler = (e) => {
    e.preventDefault();

    // if (!validateEmail(values.email)) {
    //   setValues({
    //     ...values,
    //     isEmailCorrect: false,
    //     emailErrorMessage: "You have entered an incorrect username",
    //     // passwordErrorMessage: "You have entered incorrect Password",
    //   });
    // } else
     if (values.password === "") {
      setValues({
        ...values,
        passwordErrorMessage: "Password field cannot be empty",
      });
    } else {
      setValues({
        ...values,
        isEmailCorrect: true,
        emailErrorMessage: "",
        passwordErrorMessage: "",
      });
      let { email, password, remember_me } = values;
      setLoading(true);
      dispatch(reset_login.request());
      dispatch(login.request({ login_id: email, password, remember_me }));
    }
  };

  const loginForm = () => {
    return(
      <FormCard title="Login">
        <form className="form-width-inherit position-relative minHeight">
        {loginState.loadingLogin == true ? 
      <div className="loader">
        <img src={loadingIcon} alt="loading icon" ></img>
      </div> : <>
          <Input
            label="Email ID / Username"
            icon="fas fa-envelope"
            type="text"
            id="email"
            aria-describedby="emailHelp"
            value={values.email}
            name="email"
            error={values.emailErrorMessage ? values.emailErrorMessage : "false"}
            placeholder="Email ID"
            onChange={inputChangeHandler("email")}
            Click={handleSubmit()}
            required
          />
           {/* <span>
                <i
                  className={`fas fa-times-circle icon ${isactive ?"color-active":""}`}  aria-hidden="true"
                  onClick={clearEmailField}
                ></i>
              </span> */}
         
          <Input
            label="Password"
            icon="fas fa-lock"
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={inputChangeHandler("password")}
            error={
              values.passwordErrorMessage ? values.passwordErrorMessage : "false"
            }
          />
          <div className="ml-auto row mt-4">
            <div className="col-md-12 p-0 d-flex justify-content-between mb-2">
              {/* <div className="form-group form-check"> */}
              <Checkbox
                id="remember_me"
                name="remember_me"
                label="Remember Me"
                value={values.remember_me}
                onChange={inputChangeHandler("remember_me")}
                onClick={inputChangeHandler("remember_me")}
                checked={values.remember_me}
              />
              <div className="mr-3">
                <Link to={anonymous_path.FORGOT_PASSWORD_PATH}>Forgot Password?</Link>
              </div>

            </div>
            <div className="generateLoginLink">
                <Link to={anonymous_path.GENERATE_LOGIN_LINK_PATH}>Generate Login Link</Link>
              </div>
          </div>
          {/* {values.error && (
            <small className="form-text text-danger">{values.error}</small>
          )} */}
          {isLoading ? (
            <Button type="button" disabled>
              Please wait...
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={values.email.length < 1 || values.password.length < 1}
              onClick={formSubmitHandler}
            >
              Login
            </Button>
          )}
          </>}</form>
      </FormCard>
    );
  };

  return <Anonymous loading={isLoading}>{loginForm()}</Anonymous>;
};

export default Login;
