import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import home from '../../../assets/images/Home.png';
import Apprentice from '../../../assets/images/apprentice.svg'
import setting from '../../../assets/images/Setting.svg';
import propTypes from "prop-types";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchLocally } from '../../../utils/localStorage';
import { removeFromLocalStorage } from '../../../utils/localStorage';
import { resetOnBehalfSignIn } from '../../../store/actions/staff/onBehalfSignIn.action';
import { staff_path } from '../../../Constants/paths/staff';

const EmFooter = (props) => {
const dispatch = useDispatch();
const history = useHistory();
const currentPage = useSelector(state=>state.studentReducers.studentCurrentPageReducer.currentPage)
const onBehalfToken = fetchLocally("onbehalf_token")
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}
  return (
    <div className={onBehalfToken ? styles.onBehalfFooter :styles.employerFooter}>
            {onBehalfToken&&<div className={styles.onbehalfLogOut}onClick={handleOnbehalfLogOut}>Close</div>}
        <Link to = {props.homePath} className={styles.homeIcon} style={{color: currentPage==="home" ? '#0098C8' : '#888'}}>
        <div className={styles.homebutton} >
            <div>
            <div className={styles.IconColor} >
                <img src={home} alt="Home" /></div>
                Home
            </div>
        </div>
        </Link>
        <Link to = {props.apprenticePath} className={styles.apprenticesIcon} style={{color: currentPage==="apprentice" ? '#0098C8' : '#888'}}>
        <div className={styles.apprenticesbutton}>
            <div>
            <div className={styles.IconColor} >
            <img src={Apprentice} alt="apprentice" /></div>
                Apprentices
            </div>
        </div>
        </Link>
        <Link to = {props.setting} className={styles.settingIcon}>
        <div className={styles.settingbutton} >
            <div >
            <div className={styles.IconColor}>
            <img src={setting} alt="setting" /></div>
                Settings
            </div>
        </div>
        </Link>
    </div>
  )
}

EmFooter.propTypes = {
    homePath: propTypes.string,
    apprenticePath: propTypes.string,
    setting: propTypes.string,
    active: propTypes.bool,

}




export default EmFooter