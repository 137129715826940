import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const SetPinAPI = {
  set_pin: (pin) => {
    return axiosInstance.post(ApiEndpoints.setPin.url, {
      pin: pin
    });
  },
};

// export default SetPinAPI;
