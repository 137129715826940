import { call, put, takeLatest } from "redux-saga/effects";
import { FetchWorkLogDetail } from "../../../services/worklogs/fetchworklogdetail.service";
import { fetchWorklogDetail, FETCH_WORKLOG_DETAIL } from "../../../store/actions/worklogs/fetchWorklogDetail.worklogs.action";


function* handleFetchWorklogDetail({ payload }) {
  try {
    const { data:resData } = yield call(FetchWorkLogDetail.getWorklogs, payload);
    const data = resData?.data
  
    yield put(fetchWorklogDetail.success(data));
  } catch (e) {
    yield put(fetchWorklogDetail.failure(e.data));
  }
}

function* fetchWorklogDetailSaga() {
  yield takeLatest(FETCH_WORKLOG_DETAIL.REQUEST, handleFetchWorklogDetail);
}

export default fetchWorklogDetailSaga;
