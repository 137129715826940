import React from 'react'
import propTypes from "prop-types";

const Button = (props) => {
   return <button
            type={props.type}
            className={`btn bg-active text-white login-btn 
                ${props.disable ? "btn-disabled disabled" :''}
                ${props.loading ? "btn-able" :''}
                `
            }
            onClick={props.onClick}
            {...props}
        >
            {props.children}
        </button>
}

Button.propTypes={
    children: propTypes.string,
    type:propTypes.string,
    onClick:propTypes.func,
    disable:propTypes.bool,
    loading:propTypes.bool
}

export default Button