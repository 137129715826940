import React from 'react'
import styles from "./styles.module.scss";
import propTypes from 'prop-types';
import BlueDot from '../../../../components/common/BlueDot';

const SupervisorInput = (props) => {

  return (
    <div className={styles.ProfileInput}>
      {props.label && <div className={styles.profileLabel}>
            <div className={styles.blueDot}><BlueDot /></div>
        <label htmlFor="">
            {props.label}
        </label>
        </div>}
        <div className={styles.profileInput}>
        <input
         type={props.type}
         onKeyDown = {props.onKeyDown}
         name={props.name}
         onChange={props.onChange}
         value={props.value}
         placeholder={props.placeholder} 
         id={props.id}
         disabled={props.inputDisable}
         autocomplete="off"
         {...props}
         />   
        </div>
    </div>
  )
}

SupervisorInput.propTypes = {
  id: propTypes.string,
  label: propTypes.string,
  label2: propTypes.string,
  name: propTypes.string,
  type: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  errorMessage: propTypes.string,
  
}


export default SupervisorInput