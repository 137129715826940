import { call, put, takeLatest } from "redux-saga/effects";
import { generateLoginLinkApi } from "services/anonymous/generateLoginLink.services";
import { GENERATE_LOGIN_LINK,generateLoginLink } from "store/actions/user/generateLoginLink.user.actions";

function* handlegenerateLoginLink({ payload }) {
  try {
    const { data } = yield call(generateLoginLinkApi.generateLoginLink, payload);
    yield put(generateLoginLink.success(data));
  } catch (e) {
    yield put(generateLoginLink.failure(e.data));
  }
}

function* generateLoginLinkSaga() {
  yield takeLatest(GENERATE_LOGIN_LINK.REQUEST, handlegenerateLoginLink);
}

export default generateLoginLinkSaga;
