import React from 'react'
import styles from "./styles.module.scss"
import errorHandleIcon from '../../../assets/images/pageNotFound.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { anonymous_path } from '../../../Constants/paths/anonymous'

const ErrorHandlePage = () => {
    const history = useHistory()

    const handleReload = () => {
        history.push(anonymous_path.HOME_PATH)
    }
 
    return (
        <div className={styles.errorHandlePageContainer}>
            <div className={styles.errorPageBox}>
                <div className={styles.errorImg}>
                    <img src={errorHandleIcon} alt="" className={styles.errorHandleIcon} />
                </div>
                <div className={styles.errorMsgBox}>
                    <span className={styles.errorMsg1}>Sorry. Page Not Found</span>
                    <span className={styles.errorMsg2}>The page you Requested could not be found.</span>
                </div>
                <div className={styles.btn} onClick={handleReload}>
                    <button className={styles.reloadBtn}>Reload</button>
                </div>
            </div>
        </div>
    )
}

export default ErrorHandlePage