import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import configReducers from './config.reducer';
import userReducer from "./user"
import worklogReducer from "./worklogs";
import StudentSign from "./studentSign/student.sign.reducer";
import employerReducers from "./employer";
import studentReducers from "./student";
import trainerReducers from "./trainer";
import staffReducers from "./staff"

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    configuration: configReducers,
    worklog: worklogReducer,
    studentSign: StudentSign,
    employerReducers:employerReducers,
    studentReducers:studentReducers,
    trainerReducers:trainerReducers,
    staffReducers:staffReducers,
  })

export default createRootReducer;