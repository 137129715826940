import { combineReducers } from "redux";
import getWorklogsForTrainerToSignReducer from "./getWorklogsForTrainerToSign.reducer";
import getAllStudentsOfTrainerReducer from "./getAllStudentsOfTrainer.reducer";
import getAllWorklogOfStudentForTrainerReducer from "./getAllWorklogOfStudentForTrainer.reducer";
import updateTrainerInfoReducer from "./updateTrainerInfo.reducer";

const trainerReducers = combineReducers({
    getWorklogsForTrainerToSignReducer,
    getAllStudentsOfTrainerReducer,
    getAllWorklogOfStudentForTrainerReducer,
    updateTrainerInfoReducer
})

export default trainerReducers;