import React from "react";
import requireAuthentication from "../../../hoc/requireAuthentication";
import { Container } from "react-bootstrap";
import TrainerDashboard from "../../../components/Trainer/TrainerDashboard";
import styles from "./styles.module.scss"
import { useDispatch } from "react-redux";
import { getWorklogsForTrainerToSign } from "../../../store/actions/trainer/getWorklogsForTrainerToSign.action";
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";

const TrainerDashboardPage = () =>{
//defining the dispatch function
const dispatch = useDispatch();

//this action will set the current page to home
dispatch(studentCurrentPage.success("home"));
//this dispatch function will fetch all the worklogs that an employer need to sign
dispatch(getWorklogsForTrainerToSign.request())

    
return(
    <div className={styles.mainContainer}>
    <Container className={`pt-3 ${styles.containerEmployer}`}>
      <TrainerDashboard />
    </Container>
  </div>
)

}

export default requireAuthentication(TrainerDashboardPage);