import * as studentTheoryAssessments from "../../actions/student/studentTheoryAssessments.action";

const initialState = {
    theoryAssessments: [],
    loading: false,
    success: false,
    failure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentTheoryAssessments.STUDENT_THEORY_ASSESSMENTS.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentTheoryAssessments.STUDENT_THEORY_ASSESSMENTS.SUCCESS:

    return {
      ...state,
      theoryAssessments: action.payload.data,
      loading: false,
      success: true,
      failure:false,
    };

  case studentTheoryAssessments.STUDENT_THEORY_ASSESSMENTS.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      failure:true,
    };
      default: return state      
    }
}
