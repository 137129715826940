import * as FetchWorklogEquipmentActions from "../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";

const initialState = {
  equipments: [],
  total:0,
  loadingWorklog: false,
  worklogFetchError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FetchWorklogEquipmentActions.FETCH_WORKLOG_EQUIPMENT.REQUEST:
      return {
        ...state,
        loadingWorklog: true,
      };
    case FetchWorklogEquipmentActions.FETCH_WORKLOG_EQUIPMENT.SUCCESS:
      return {
        ...state,
        equipments: [...state.equipments, ...action.payload.equipments],
        total:action.payload.total,
        loadingWorklog: false,
        worklogFetchError: false,
      };

    case FetchWorklogEquipmentActions.FETCH_WORKLOG_EQUIPMENT.FAILURE:
      return {
        ...state,
        equipments: [],
        total:0,
        loadingWorklog: false,
        worklogFetchError: true,
      };

    case FetchWorklogEquipmentActions.CLEAR_WORKLOG_EQUIPMENT:
      return {
        equipments:[],
        total:0
      }


    default:
      return state;
  }
}
