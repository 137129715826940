import jwtDecode from "jwt-decode"; 
import { fetchLocally } from "../../../utils/localStorage";


export const decodeToken = () => {
    if (typeof window == "undefined") {
      return false;
    }
const token = fetchLocally("login_credentials");
const decode = jwtDecode(token);
return decode;

  };

  
export const decodeOnBehalfToken = () => {
  if (typeof window == "undefined") {
    return false;
  }
const token = fetchLocally("onbehalf_token");
const decode = token ? jwtDecode(token) :'';
return decode;

};