import { call, put, takeLatest } from "redux-saga/effects";
import { FetchOverdueTasksAPI } from "../../../services/worklogs/fetchOverdueTasks.services";

import {fetchOverdueTasks, FETCH_OVERDUE_TASKS} from "../../../store/actions/worklogs/fetchOverdueTasks.worklogs.action";


function* handleFetchOverdueTasks({ payload }) {
  try {
    const { data } = yield call(FetchOverdueTasksAPI.getOverdueTasks, payload);
    yield put(fetchOverdueTasks.success(data));
  } catch (e) {
    yield put(fetchOverdueTasks.failure(e.data));
  }
}

function* fetchWorklogsOverDueTasksSaga() {
  yield takeLatest(FETCH_OVERDUE_TASKS.REQUEST, handleFetchOverdueTasks);
}

export default fetchWorklogsOverDueTasksSaga;
