import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const signupApi = {
  signup: (data) => {
    return axiosInstance.post(ApiEndpoints.signup.url, {
      email: data.email,
      phone: data.phone,
      password: data.password,
      type: data.type
    });
  },
};
