import * as studentowncontacts from "../../../store/actions/student/studentOwnContact.action"

const initialState = {
    contacts: [],
    loadingContacts: false,
    errorContacts:false,
    successContacts:false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case studentowncontacts.STUDENT_OWN_CONTACTS.REQUEST:
            return {
                ...state,
                loadingContacts: true,
            };
        case studentowncontacts.STUDENT_OWN_CONTACTS.SUCCESS:
            return {
                ...state,
                contacts: action.payload.data,
                loadingContacts: false,
                errorContacts:false,
                successContacts:true,
            };
        case studentowncontacts.STUDENT_OWN_CONTACTS.FAILURE:
            return {
            ...state,
            contacts: action.payload,
            loadingContacts: false,
            errorContacts:true,
            successContacts:false,
            };
          
        default:
            return state;
    }
}