import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const updateProfileInfoApi = {
  Email: (data) => {
    return axiosInstance.put(ApiEndpoints.updateEmail.url, {
      info: data,
      type:"email"
    });
  },
  Phone: (data) => {
    return axiosInstance.put(ApiEndpoints.updatePhone.url, {
      info: data,
      type:"phone"
    });
  },
};
