import {call , put , takeLatest} from "@redux-saga/core/effects";
import { TrainerApi } from "../../../services/trainer/trainer.services";
import * as TrainerActions from "../../actions/trainer/getAllStudentsOfTrainer.action"


function *getAllStudentsOfTrainer(action){

try {
    //this will fetch the data Object from the Api which is used
    const {data:resData} = yield call(TrainerApi.getAllStudentsOfTrainer, action.payload)
    yield put(TrainerActions.getAllStudentsOfTrainer.success({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }));
} catch (e) {
    //this will catch an error when there is an error in the fetching api
    const {data:resData} = e
    yield put(TrainerActions.getAllStudentsOfTrainer.failure({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }))
}
}

function *getAllStudentsOfTrainerSaga(){
    yield takeLatest(TrainerActions.GET_ALL_STUDENTS_OF_TRAINER.REQUEST, getAllStudentsOfTrainer)
}

export default getAllStudentsOfTrainerSaga;