import { createRequestTypes, action } from "../../../utils/redux";
export const CREATE_WORKLOGS = createRequestTypes("CREATE_WORKLOGS");
export const createWorklogs = {
  request: (data) => action(CREATE_WORKLOGS.REQUEST, data),
  success: (data) => action(CREATE_WORKLOGS.SUCCESS, data),
  failure: (error) => action(CREATE_WORKLOGS.FAILURE, error),
};

export const CLEAR_WORKLOG_STATE = "CLEAR_WORKLOG_STATE";
export const clearWorklogState = {
  request : () => action(CLEAR_WORKLOG_STATE)
}
