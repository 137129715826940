import * as EmployerActions from "../../actions/employer/Employer.action";

const initialState = {
    employers: [],
    loadingEmployer:true,
    employersError:undefined,
    loadingSign:false,
    invalidId:false,
    loadingWheel:false,
    signSelected:false,
    signSuccess:false,
    signUnit:{},
    loadPendingSignatures: true,
}


export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
      case EmployerActions.SHOW_SIGN:
        return {
          ...state,
          signSelected: action.payload.signSelected,
          signUnit: action.payload.signUnit 
        }
        case EmployerActions.APPROVE_CLICK:
        return {
          ...state,
          signSuccess : false
        }
  case EmployerActions.EMPLOYER_SIGN.REQUEST:
    return {
      ...state,
      loadingSign: true,
      loadPendingSignatures: true,
    };
  case EmployerActions.EMPLOYER_SIGN.SUCCESS:

    return {
      ...state,
      signSelected:false,
      signSuccess: true,
      loadingSign: false,
      loadPendingSignatures: true,
    };

  case EmployerActions.EMPLOYER_SIGN.FAILURE:
    return {
      ...state,
      signSelected:false,
      signSuccess: false,
      loadingSign: false,
      loadPendingSignatures: true,
    };
      case EmployerActions.EMPLOYER_DATA.REQUEST:
        return {
          ...state,
          loadingEmployer: true,
          loadPendingSignatures: true,
        };
      case EmployerActions.EMPLOYER_DATA.SUCCESS:

        return {
          ...state,
          employers: action.payload.data,
          employersError: undefined,
          loadingEmployer: false,
          loadingWheel: true,
          loadingSign: true,
          loadPendingSignatures: false,
        };
  
      case EmployerActions.EMPLOYER_DATA.FAILURE:
        return {
          ...state,
          employersError: action.payload,
          loadingEmployer: true,
          invalidId:true,
          loadPendingSignatures: true,
        };
      default: return state      
    }
}