import { createRequestTypes, action } from "../../../utils/redux";
export const UPDATE_EMAIL = createRequestTypes("EMAIL");
export const UPDATE_PHONE = createRequestTypes("PHONE");
export const RESET_UPDATE_EMAIL=createRequestTypes("RESET_UPDATE_EMAIL")
export const RESET_UPDATE_PHONE=createRequestTypes("RESET_UPDATE_PHONE")



export const resetUpdateEmail = {
  request: () => action(RESET_UPDATE_EMAIL.REQUEST)
};

export const updateEmail = {
  request: (data) => action(UPDATE_EMAIL.REQUEST, data),
  success: (data) => action(UPDATE_EMAIL.SUCCESS, data),
  failure: (error) => action(UPDATE_EMAIL.FAILURE, error),
};


export const resetUpdatePhone = {
  request: () => action(RESET_UPDATE_PHONE.REQUEST),
};

export const updatePhone = {
  request: (data) => action(UPDATE_PHONE.REQUEST, data),
  success: (data) => action(UPDATE_PHONE.SUCCESS, data),
  failure: (error) => action(UPDATE_PHONE.FAILURE, error),
};

