import { createRequestTypes, action } from "../../../utils/redux";

export const APPRENTICE_STUDENT_TODO = createRequestTypes("APPRENTICE_STUDENT_TODO");

export const apprenticeStudentTodoData = {
  request: (data) => action(APPRENTICE_STUDENT_TODO.REQUEST,data),
  success: (data) => action(APPRENTICE_STUDENT_TODO.SUCCESS, data),
  failure: (error) => action(APPRENTICE_STUDENT_TODO.FAILURE, error),
};


export const SAVE_ID = "SAVE_ID";

export const saveId = (data) => action(SAVE_ID, data);

