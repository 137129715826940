import {call , put , takeLatest} from "@redux-saga/core/effects";
import { TrainerApi } from "../../../services/trainer/trainer.services";
import * as TrainerActions from "../../actions/trainer/getWorklogsForTrainerToSign.action"


function *getWorklogsForTrainerToSign(action){

try {
    //this will fetch the data Object from the Api which is used
    const {data:resData} = yield call(TrainerApi.getWorklogsForTrainerToSign, action.payload)
    yield put(TrainerActions.getWorklogsForTrainerToSign.success({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }));
} catch (e) {
    //this will catch an error when there is an error in the fetching api
    const {data:resData} = e
    yield put(TrainerActions.getWorklogsForTrainerToSign.failure({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }))
}
}

function *getWorklogsForTrainerToSignSaga(){
    yield takeLatest(TrainerActions.GET_WORKLOGS_FOR_TRAINER_TO_SIGN.REQUEST, getWorklogsForTrainerToSign)
}

export default getWorklogsForTrainerToSignSaga;