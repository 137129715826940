import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loginWithCode } from "store/actions/user/loginWithCode.user.action";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { student_path } from "Constants/paths/student";
import { employer_path } from "Constants/paths/employer";
import { trainer_path } from "Constants/paths/trainer";
import { staff_path } from "Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";
import { saveAuthToken } from "utils/localStorage";
import FormCard from "components/auth/FormCard";
import Anonymous from "layouts/Anonymous";

const LoginWithCode = () => {
//fetchting the code from parameters passed in url
  /* eslint-disable */
const {code} = useParams();

const history = useHistory();
const dispatch = useDispatch();

const loginWithCodeReducer = useSelector((state)=>state?.user.loginWithCodeUserReducer)





useEffect(()=>{
if(loginWithCodeReducer?.token){
    //token received from the login code api
 const token = loginWithCodeReducer?.token
 saveAuthToken(token)
 const decodedToken = jwtDecode(token)
    if (decodedToken.user_type === "Student" || decodedToken.contact_type === "S") {
        history.push(student_path.STUDENT_DASHBOARD_PATH)
      } else if((decodedToken.user_type === "Employer" || decodedToken.contact_type === "E")){
        history.push(employer_path.EMPLOYER_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Trainer" || decodedToken.contact_type === "T")){
        history.push(trainer_path.TRAINER_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Staff" || decodedToken.contact_type === "C")){
        history.push(staff_path.STAFF_DASHBOARD_PATH)
      }else if((decodedToken.user_type === "Supervisor" || decodedToken.contact_type === "D")){
        history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
      }
}

},[loginWithCodeReducer?.token])


useEffect(()=>{
if(code){
    dispatch(loginWithCode.request(code))
}
},[code])

return <>{loginWithCodeReducer.message==="Invalid Link." &&       <>
    <Anonymous loading={loginWithCodeReducer.loading}>
{/* <h2>Huuu</h2> */}
<FormCard title="Login with Code">
  <div className="col-md-10">
    <h5 className="text-center text-gray mb-4">
    Invalid Link !
    </h5>
    <h5 className="text-center text-gray mb-4">
     Please re-generate the login link.
    </h5>
  </div> 
</FormCard>
    </Anonymous>

</>}</>
};

export default LoginWithCode;
