import { call, put, takeLatest } from "redux-saga/effects";
import { loginApi } from "../../../services/anonymous/login.service";
import {
  LOGIN,
  login,
  RESET_LOGIN,
  reset_login,
} from "../../../store/actions/user/login.user.actions";
import { removeFromLocalStorage, saveAuthToken } from "../../../utils/localStorage";
import { storeInLocalStorage } from "../../../utils/localStorage";

function* handleLogin({ payload }) {
  try {
    const { data: resData } = yield call(loginApi.login, payload);
    const data = resData?.data;
    if (resData.status === "SUCCESS") {
      removeFromLocalStorage.loginPinActivated();
      yield put(login.success(data));
      //storing the data in localstore after login apin success
      storeInLocalStorage.trusted(payload.remember_me);
      storeInLocalStorage.storeFirstName(data.user.first_name);
      storeInLocalStorage.storeLastName(data.user.last_name);
      storeInLocalStorage.storeEmail(data.user.email);
      storeInLocalStorage.storePhone(data.user.phone);
      saveAuthToken(data.token);
    } else {
      yield put(login.failure(data));
    }
  } catch (e) {
    yield put(login.failure(e.data));
  }
}

function* handleResetLogin({ payload }) {
  try {
    //   const { data } = yield call(loginApi.login, payload);
    yield put(reset_login.success());
    //   saveAuthToken(data.token, false);
  } catch (e) {
    yield put(reset_login.failure(e.data));
  }
}

function* loginSaga() {
  yield takeLatest(LOGIN.REQUEST, handleLogin);
  yield takeLatest(RESET_LOGIN.REQUEST, handleResetLogin);
}

export default loginSaga;
