import React from 'react'
import EmployerApprenticesList from '../../../components/EmployerDashboard/EmployerApprenticesList'
import EmDashboardHeader from '../../../components/EmployerDashboard/EmDashboardHeader/EmDashboardHeader';
import EmFooter from '../../../components/EmployerDashboard/EmFooter'
import { employer_path } from '../../../Constants/paths/employer';
import { studentCurrentPage } from "../../../store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";
const EmployerApprentices = () => {
  const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("apprentice"))
  return (
    <div  >
      <EmDashboardHeader/>
      <EmployerApprenticesList />
      <EmFooter
                        homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
                        apprenticePath={employer_path.APPRENTICE_PATH}
                        setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
                    />
    </div>
  )
}

export default EmployerApprentices