import axiosInstance from "../../services/interceptor";
import { ApiEndpoints } from "../../Constants/api/student";

export const StudentApi = {
    //this services will add the new supervisor 
    addSupervisor: (supervisorData) => {
        return axiosInstance.post(ApiEndpoints.AddSupervisor.url,supervisorData);
    },

    //this services will fetch the progress of the units of the student 
    fetchStudentCourseProgress: (courseProgress) => {
            return axiosInstance.get(ApiEndpoints.FetchStudentCourseProgress.url,courseProgress);
    },

        //this services will fetch the theoryy assessments of the student 
    fetchStudentTheoryAssessments: (theoryAssessments) => {
            return axiosInstance.get(ApiEndpoints.FetchStudentTheoryAssessments.url,theoryAssessments);
    },

}