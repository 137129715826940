import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from "../styles.module.scss";
import { getWorklogDetailsForSign } from '../../../../store/actions/employer/getWorklogDetailsForSign.action';
import * as rejectionAction from "../../../../store/actions/employer/RejectionSign.action"
import * as SiteActions from "../../../../store/actions/employer/Employer.action"
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { employer_path } from '../../../../Constants/paths/employer';
import propTypes from 'prop-types';
import moment from 'moment';

const SubmissionDetail = (props) => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
    const data = props.status
    const history = useHistory();
    const dispatch = useDispatch();

    //To set boolean value for the buttons if the supervisor id is match the employerid
    const [supervisor, setSupervisor] = useState(true);
    useEffect(()=>{
      if(data.supervisor_id===props.supervisorId){
        setSupervisor(false);
      }else{
        setSupervisor(true);
      }
    },[])

    //created payload
    const customizedData= {
      student_id: data?.contact_id,
      name: data?.name,
      worklog_id: data?.worklog_id,
      SupervisorId: supervisor,
      Submitted: props.submitted
    }

    //To send the payload on click of worklog details box
    const showWorklogDetails = (worklog_id,student_id) => {
      dispatch(getWorklogDetailsForSign.request({worklog_id:worklog_id , student_id:student_id}));
      var payload = {
        signSelected: true,
        signUnit: customizedData
      }
      dispatch(rejectionAction.showSign());
      dispatch(SiteActions.showSign(payload));
      history.push(employer_path.EMPLOYER_DASHBOARD_SIGN);
    }

  return (
    <div className={styles.workshopbox} onClick={()=>showWorklogDetails(data.worklog_id, data.contact_id)}>


    <div className={styles.workshopLabel}>
    <ul>
    <li className={`${styles.details}`}>{data.task_name}</li>
      <li className={`${styles.dateinfo}`}>
      {moment.utc(data?.date).local().format("DD/MM/YYYY")}{" "}
      </li>
    </ul>
    </div>

    <div className={styles.workshopApprove}>
        <ul>
            <li style={props.style_1}>{data.status}</li>
        </ul>
    </div>

    </div>
  )
}

SubmissionDetail.propTypes = {
  status: propTypes.object, //get data waiting_approval,approved_data,rejected_data and submitted_data for worklog submission
  style_1: propTypes.string, //change status style 
  submitted: propTypes.bool,

}

export default SubmissionDetail