import { call, put, takeLatest } from "redux-saga/effects";
import { FetchWorkLogLessons } from "../../../services/worklogs/fetchlessons.service";
import { fetchWorklogLessons, FETCH_WORKLOG_LESSONS } from "../../../store/actions/worklogs/fetchWorklogLessons.worklogs.action"


function* handleFetchWorklogLessons({ payload }) {
  try {
    const { data:resData } = yield call(FetchWorkLogLessons.FetchLessons, payload);
  if(resData.status ==="SUCCESS"){
    const lesson = resData?.data?.lessons
    yield put(fetchWorklogLessons.success({
      status:resData?.status,
      message:resData?.message,
      lsnDate: lesson !==null ?lesson[0]?.lsn_date:"",
      lesStudId:lesson !==null ?lesson[0]?.les_stud_id:"",
      studentConfirmed:lesson!==null ? lesson[0].student_confirmed ? true : false :"",
      worklogSubmitted:resData?.data?.worklog_submitted,
      overdueTheoryAssessmentsCount:resData?.data?.overdue_theory_assessments
     }));
  }else{
    yield put(fetchWorklogLessons.success({
      status:resData?.status,
      message:resData?.message,
      lsnDate: "",
      lesStudId:"",
      studentConfirmed: ""
     }));
  }
   
  } catch (e) {
    yield put(fetchWorklogLessons.failure(e.data));
  }
}

function* fetchWorklogLessonsSaga() {
  yield takeLatest(FETCH_WORKLOG_LESSONS.REQUEST, handleFetchWorklogLessons);
}

export default fetchWorklogLessonsSaga;
