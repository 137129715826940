import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import SignatureCanvas from "react-signature-canvas";
import { useState } from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as unitSignature from "../../../store/actions/employer/unitSignature.action";
import Overlay from "../../../components/common/Overlay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { decodeToken ,decodeOnBehalfToken} from "../../../utils/helper/common/decodeJSONToken";
import { student_path } from "../../../Constants/paths/student";
import { employer_path } from "../../../Constants/paths/employer";
import { trainer_path } from "../../../Constants/paths/trainer";
import { upcomingWorkshopdata } from "../../../store/actions/employer/UpcomingWorkshop";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import disclaimar from "../../../assets/images/Disclaimer.svg"

const EmployerUnitSignPad = (props) => {
  //this search have the value of the query passed in params
  const { search } = useLocation();
  const [signPadRef, setSignPadRef] = useState({});
  const [values, setValues] = useState({
    show: "block",
    text: "text",
    dataURI: null,
    signPadRef: "",
    didRedirect: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const employerUnitData = useSelector(
    (state) => state.employerReducers?.unitSign
  );
  const employerUnitSign = employerUnitData?.signUnit;
  const studentId = employerUnitSign?.student_id;
  const enrolledUnitId = employerUnitSign?.enrolled_unit_id;
  const signSuccess = employerUnitData?.signSuccess;

  //fetch user locally by the token
  const tokenData = decodeToken();
  const tokenUsertype = tokenData?.user_type;

  //if the signature box is empty submitbutton is disable
  const [empty, setEmpty] = useState(true);

  const signEnable = () => {
    setEmpty(false);
  };

  const setRef = (reference) => {
    setSignPadRef(reference);
  };

  //this will split the query with &
  const queryData = search?.split("&");
  //this const is having the data of worklog id fetched from the query
  const enrolledUnit_id = queryData[1]?.split("=")[1];
  //this const is having the studen id of whom the worklogs belong and fetched from teh query params
  const student_id = queryData[0]?.split("=")[1];

  useEffect(() => {
    if (student_id && enrolledUnit_id) {
      dispatch(upcomingWorkshopdata.request());
    }
  }, [dispatch, student_id, enrolledUnit_id]);

  const unitReducer = useSelector(
    (state) => state?.employerReducers?.upcomimgWorkshop
  );

  const allUnitsData = unitReducer.upcomingworkshop.units

//this const will filter out the unit data which is passed from the params  
const filteredUnitData = allUnitsData?.filter(item=>item.enrolled_unit_id?.toString()===enrolledUnit_id?.toString())
const unitData=  filteredUnitData !==undefined ? filteredUnitData[0] :[]


  // to trim out signatures
  const trimSignatures = () => {
    setValues({
      ...values,
      dataURI: signPadRef.getTrimmedCanvas().toDataURL("image/png"),
    });
    var payload = {
      student_id: student_id ? student_id : studentId,
      enrolled_unit_id: enrolledUnit_id ? enrolledUnit_id : enrolledUnitId,
      signature: signPadRef.getTrimmedCanvas().toDataURL("image/png"),
    };
    dispatch(unitSignature.unitSign.request(payload));
  };

//these constant will have the unit data to be shown on sign pad 
//this is the employer first name who gonaa sign the Unit
const employerName = localStorage.getItem("user_first_name")
const studentName=employerUnitSign?.student_name ? employerUnitSign?.student_name : unitData?.student_name
const unitName = employerUnitSign?.unit ? employerUnitSign?.unit: unitData?.unit
const tradingName = employerUnitSign?.trading_name ? employerUnitSign?.trading_name : unitData?.trading_name


  // To Clear Canvas
  const clearCanvas = () => {
    setSignPadRef(signPadRef.clear());
    setEmpty(true);
  };

  //this functionw will redirect the user to their corresponding dashboard
  const handleCloseOverlay = () => {
    //if the user is signed onbehalf then it will redirected against the onbehalf token
    const onBehalfToken =  decodeOnBehalfToken()
    if(onBehalfToken){
      if (onBehalfToken.user_type === "Student") {
        history.push(student_path.STUDENT_DASHBOARD_PATH);
      } else if (onBehalfToken.user_type === "Employer") {
        history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
      } else if (onBehalfToken.user_type === "Trainer") {
        history.push(trainer_path.TRAINER_DASHBOARD_PATH);
      }

    }else{
      //if the user is not signed on behalf
      if (tokenUsertype === "Student") {
        history.push(student_path.STUDENT_DASHBOARD_PATH);
      } else if (tokenUsertype === "Employer") {
        history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
      } else if (tokenUsertype === "Trainer") {
        history.push(trainer_path.TRAINER_DASHBOARD_PATH);
      }
    }

    dispatch(unitSignature.signSuccess());
  };


  return (
    <>
      {signSuccess ? (
        <Overlay
          title="Unit Sign"
          onClick={handleCloseOverlay}
          text="Sign successfully submitted"
          crossicon={true}
        />
      ) : 
      (unitReducer.loading===true) ?
      <div className="loader">
      <img src={loadingIcon} alt="loading icon"></img>
    </div>
      :
      (student_id && enrolledUnit_id && allUnitsData ===null)?
      (<div className={styles.notAvailableUnit}>
           <div className={styles.disclaimarImg}> <img src={disclaimar} alt="disclaimar"/></div>
           <div className={styles.disclaimarText}>Unit is unavailable to sign</div> 
      </div>)
      :(
        <div className={styles.signPad}>
          <div className={styles.heading}>{studentName}</div>
          <div className={styles.subHeading}>{unitName}</div>
          <p className={styles.mainText}>Carry out general demolition of minor building structures</p>
          <p className={styles.subText}>I {employerName} of {tradingName} support that {studentName} is competent in the workplace for this unit of competency to industry and company standards. I agree for this signature to be placed on the training plan for {studentName} for this {unitName}</p>
          <div className="modal-body text-center">
            <SignatureCanvas
              penColor="black"
              canvasProps={{ className: "signCanvas" }}
              ref={setRef}
              onBegin={signEnable}
            />
          </div>

          <div className="col-md pb-4 mx-auto row">
            <div className="centerWidth">
              <div className={`centerBtn ${styles.submitButton}`}>
                <button
                  className="btn btn-active halfBtn btn-info"
                  onClick={trimSignatures}
                  disabled={empty}
                >
                  Submit
                </button>
              </div>

              <div className="centerBtn">
                <button
                  className="btn btn-danger halfBtn bg-danger"
                  onClick={clearCanvas}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};

EmployerUnitSignPad.propTypes = {
  onCancel: propTypes.func, //for close sign pad model
  studentData: propTypes.object, //add sign for particular worklog student
};

export default EmployerUnitSignPad;
