import { call, put, takeLatest } from "redux-saga/effects";
import {forgotPasswordApi} from "../../../services/anonymous/forgotPassword.service"

import {
  FORGOT_PASSWORD,
  forgotPassword,
} from "../../../store/actions/user/forgotPassword.user.actions";
import { saveAuthToken } from "../../../utils/localStorage";

function* handleforgotPassword({ payload }) {
  try {
    const { data } = yield call(forgotPasswordApi.forgotPassword, payload);
    yield put(forgotPassword.success(data));
    saveAuthToken(data.token, false);
  } catch (e) {
    yield put(forgotPassword.failure(e.data));
  }
}

function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD.REQUEST, handleforgotPassword);
}

export default forgotPasswordSaga;
