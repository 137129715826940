import * as studentDetailsData from "../../actions/employer/employerApprentices.action";

const initialState = {
    studentDetails: [],
    loading: false,
    success: false,
    studentdetailfailure: false
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case studentDetailsData.STUDENT_DETAILS.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case studentDetailsData.STUDENT_DETAILS.SUCCESS:

    return {
      ...state,
      studentDetails: action.payload.data,
      loading: false,
      success: true,
      studentdetailfailure:false,
    };

  case studentDetailsData.STUDENT_DETAILS.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      studentdetailfailure:true,
    };
      default: return state      
    }
}
