import * as apprenticeStudentTodoData from "../../actions/employer/apprenticeStudentTodo.action";

const initialState = {
    studenttodo: [],
    loading: false,
    success: false,
    apprenticeStudentfailure:false,
    loadPendingSignatures:false,
    SignId:[]
}

export default function returnstate(state = initialState, action) {
  
    switch (action.type) {
  case apprenticeStudentTodoData.SAVE_ID:
    return {
      ...state,
      SignId:action.payload
    }
  case apprenticeStudentTodoData.APPRENTICE_STUDENT_TODO.REQUEST:
    return {
      ...state,
      loading: true,
    };
  case apprenticeStudentTodoData.APPRENTICE_STUDENT_TODO.SUCCESS:

    return {
      ...state,
      studenttodo: action.payload.data,
      loading: false,
      success: true,
      apprenticeStudentfailure:false,
    };

  case apprenticeStudentTodoData.APPRENTICE_STUDENT_TODO.FAILURE:
    return {
      ...state,
      loading: false,
      success: false,
      apprenticeStudentfailure:true,
    };
      default: return state      
    }
}