import fetchWorklogsSaga from "./fetchworklogs.worklogs.saga";
import fetchWorklogDetailSaga from "./fetchworklogdetail.saga";
import fetchWorklogTasksSaga from "./fetchWorklogTasks.saga";
import fetchWorklogSupervisorSaga from "./fetchworklogsupervisor.saga";
import deleteWorklogSaga from "./deleteworklog.saga";
import fetchWorklogsOverDueTasksSaga from "./fetchWorklogOverdueTasks.saga";
import fetchWorklogLessonsSaga from "./fetchworkloglessons.worklog.saga";

import fetchWorklogEquipmentSaga from "./fetchworklogequipment.saga";

import createWorklogsSaga from "./createworklogs.worklogs.saga";
import lessonConfirmationSaga from "./lessonConfirmation.saga";
import fetchAllWorkshopsSaga from "./fetchAllWorkshops.saga";
import getStudentOwnContactsSaga from "./studentOwnContact.saga";
import fetchFrequentEquipmentsSaga from "./fetchFrequentEquipments.saga";

const worklogSaga = [
  fetchWorklogsSaga(),
  createWorklogsSaga(),
  fetchWorklogDetailSaga(),
  fetchWorklogTasksSaga(),
  fetchWorklogSupervisorSaga(),
  fetchWorklogEquipmentSaga(),
  deleteWorklogSaga(),
  fetchWorklogsOverDueTasksSaga(),
  fetchWorklogLessonsSaga(),
  lessonConfirmationSaga(),
  fetchAllWorkshopsSaga(),
  getStudentOwnContactsSaga(),
  fetchFrequentEquipmentsSaga()
];

export default worklogSaga;
