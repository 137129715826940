import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerApi } from "../../../services/employer/Employer.services";
import * as unitSignature from "../../actions/employer/unitSignature.action";



function *unitSignEmployer(action){
    try{
        const {data} = yield call(EmployerApi.unitSign, action.payload.student_id, action.payload.enrolled_unit_id, action.payload.signature)
        //extract the data here
        
        yield put(unitSignature.unitSign.success(data));

    } catch (e) {
        yield put(unitSignature.unitSign.failure(e));
    }
}

function *unitSignEmployerSaga(){
    yield (
        
      
        takeLatest(unitSignature.UNIT_SIGN.REQUEST, unitSignEmployer)
    );
}

export default unitSignEmployerSaga;