import React from "react";
import { Modal } from "react-bootstrap";
import SupervisorInput from "./SupervisorInput";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Overlay from "../../../components/common/Overlay";
import { resetAddSupervisor } from "../../../store/actions/student/addSupervisor.action";
import { useDispatch } from "react-redux";
const AddNewSupervisorModal = (props) => {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  const dispatch = useDispatch();

  //these are the props passed from parent component
  const {
    show,
    handleSupervisorSubmit,
    values,
    setValues,
    handleClose,
    setShow,
  } = props;

  //these are the data of the add new supervisor reducer
  const supervisorData = useSelector(
    (state) => state.studentReducers?.addNewSupervisorReducer
  );
  const supervisorerror = supervisorData?.error;
  const supervisorsucess = supervisorData?.success;
  const supervisormessage = supervisorData?.message;

  //this function will set the value of each input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setError(false);
  };

  //these state handles the eror and success rendering
  const [isError, setError] = useState(false);
  const [isSuccess, setSucsess] = useState(false);

  //when there is supervisor error or success changes will call this effect
  useEffect(() => {
    if (supervisorerror) {
      setError(true);
    }
    if (supervisorsucess) {
      setShow(false);
      setSucsess(true);
      setError(false);
    }
  }, [supervisorerror, supervisorsucess]);

  //this function will triger when we click on the close button of the overlay
  const handleCloseOverlay = () => {
    //this action will clear the reducer of add supervisor
    dispatch(resetAddSupervisor.success());
    setSucsess(false);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        // {...props}
        size="lg"
        aria-labelledby="contained-modal-vcenter"
        centered
        className="p-4"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new supervisor</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginTop: "20px" }}>
          <SupervisorInput
            placeholder="First name"
            name="first_name"
            type="text"
            onChange={handleChange}
          />
          <SupervisorInput
            placeholder="Last name"
            name="last_name"
            type="text"
            onChange={handleChange}
          />
          <SupervisorInput
            placeholder="Phone Number"
            name="phone"
            type="number"
            onChange={handleChange}
            onKeyDown={(e) =>
              ["e", "E", "=", "'", '"', "."].includes(e.key) &&
              e.preventDefault()
            }
          />
          <span className={styles.error}>
            {isError ? supervisormessage : ""}
          </span>
        </Modal.Body>
        <div className={styles.submitSupervisor}></div>
        <button
          onClick={() => {
            handleSupervisorSubmit();
          }}
          class={`mb-3 btn btn-info btn-active ${styles.btnsupervisor}`}
        >
          Submit
        </button>
      </Modal>
      {isSuccess && (
        <Overlay
          title="Add new supervisor"
          onClick={handleCloseOverlay}
          text="Supervisor added successfully"
          crossicon={true}
        />
      )}
    </div>
  );
};

export default AddNewSupervisorModal;
