import React from "react";
import { Route, Redirect } from "react-router-dom";
import StudentLayout from "../../layouts/Student";
import {
  isAuthenticated,
  isPinAuthSkip,
  isAuthenticatedPin,
  isLoginPinActive,
  isStudent,
  isStaff,
  isTempLogin
} from "../../utils/helper/Student/index";
import { anonymous_path } from "../../Constants/paths/anonymous";
import OnBehalfLayout from "../../layouts/OnBehalf/OnBehalfLayout";

const StudentRoute = ({ component: Component, ...rest }) => {
  //   const isPinAuth = isAuthenticatedPin();
  //?.status === "success"
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && (isAuthenticatedPin() || isPinAuthSkip() || isLoginPinActive() || isTempLogin()) && (isStudent() || isStaff()) ? (
          isStaff() ? 
          <OnBehalfLayout>
            <Component {...props} />
          </OnBehalfLayout>
          :
          <StudentLayout>
            <Component {...props} />
          </StudentLayout>
        ) : (
          <Redirect
            to={{
              pathname: anonymous_path.LOGIN_PATH,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default StudentRoute;
