export const ApiEndpoints = Object.freeze({
    changePassword: {
        url: "/auth/password/change",
        method: "PUT",
      },
      changePin: {
        url: "/auth/pin/change",
        method: "PUT",
      },


});