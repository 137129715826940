import * as SignupActions from "../../actions/user/signup.user.action";

const initialState = {
  loading: false,
  signupSuccess: false,
  signupFailed: false,
  status: null,
  message: "",
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SignupActions.SIGNUP_USER.REQUEST:
      return {
        ...state,
        loading: true,
        signupSuccess: false,
        signupFailed: false,
        status: null,
        message: "",
        user: {},
      };

    case SignupActions.SIGNUP_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        signupSuccess: true,
        signupFailed: false,
        status: action.payload.status,
        message: action.payload.message,
        user: action.payload.user,
      };

    case SignupActions.SIGNUP_USER.FAILURE:
      return {
        ...state,

        loading: false,
        signupSuccess: false,
        signupFailed: true,
        status: action.payload.status,
        message: action.payload.message,
        user: {},
      };

    default:
      return state;
  }
}
