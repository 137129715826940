import { call, put, takeLatest } from "redux-saga/effects";
import { FetchAllWorkshopsApi } from "../../../services/worklogs/fetchAllWorkshops.service";
import {
  fetchAllWorkshops,
  FETCH_ALL_WORKSHOPS,
} from "../../../store/actions/worklogs/fetchAllWorkshops.action";

function* handleAllWorkshops() {
  try {
    const { data } = yield call(FetchAllWorkshopsApi.fetchAllWorkshops);
    yield put(
      fetchAllWorkshops.success({
        status: data?.status,
        statusCode: data?.status_code,
        data: data?.data,
        message: data?.message,
      })
    );
  } catch (e) {
    const { data } = e;
    yield put(
      fetchAllWorkshops.failure({
        status: data?.status,
        statusCode: data?.status_code,
        data: data?.data,
        message: data?.message,
      })
    );
  }
}

function* fetchAllWorkshopsSaga() {
  yield takeLatest(FETCH_ALL_WORKSHOPS.REQUEST, handleAllWorkshops);
}

export default fetchAllWorkshopsSaga;
