import {call , put , takeLatest} from "@redux-saga/core/effects";
import { StaffApi } from "../../../services/staff/staff.services";
import * as StaffActions from "../../actions/staff/onBehalfSignIn.action"
import { storeInLocalStorage } from "../../../utils/localStorage";


function *onBehalfSignIn(action){

try {
    //this will fetch the data Object from the Api which is used
    const {data:resData} = yield call(StaffApi.onBehalfSignIn, action.payload)
    yield put(StaffActions.onBehalfSignIn.success({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }));
    storeInLocalStorage.setOnBehalfToken(resData?.data?.token)
} catch (e) {
    //this will catch an error when there is an error in the fetching api
    const {data:resData} = e
    yield put(StaffActions.onBehalfSignIn.failure({
        status:resData?.status,
        status_code:resData?.status_code,
        message:resData?.message,
        data:resData?.data
    }))
}
}

function *onBehalfSignInSaga(){
    yield takeLatest(StaffActions.ONBEHALF_SIGN_IN.REQUEST, onBehalfSignIn)
}

export default onBehalfSignInSaga;