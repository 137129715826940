import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const resetPasswordApi = {
  resetPassword: (data) => {
    return axiosInstance.put(ApiEndpoints.resetPassword.url, {
    password:data
    });
  },
};
