import React from "react";
import EmFooter from "../EmFooter";
import styles from "./styles.module.scss";
import SubmissionDetail from "./SubmissionDetail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { worklogSumissionsData } from "../../../store/actions/employer/worklogSubmissions.action";
import { useSelector } from "react-redux";
import EmployerStudentHeader from "../EmployerStudentHeader";
import HeaderLabel from '../../common/HeaderLabel/index'
import { employerData } from "../../../store/actions/employer/Employer.action";
import { employer_path } from "../../../Constants/paths/employer";

const WorklogSubmissiondetails = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const { id } = useParams()
  const dispatch = useDispatch();
  

  //To get the boolean value in reducer for showing submit and reject buttons
  const supervisorId = useSelector(
    (state) => state.employerReducers?.employerStudent?.employers?.employeer_id
  );

  useEffect(() => {
    dispatch(worklogSumissionsData.request(id));
    dispatch(employerData.request())
  }, []);

  //To get the data in reducer for worklog submission
  const WorklogSubmissiondata = useSelector(
    (state) => state.employerReducers?.worklogSubmissions?.worklogs
  )

  //data filter for the worklog submission by the status
  const submitted_data = WorklogSubmissiondata?.filter((elem) => {
    return elem.status === "Submitted";
  });
  const waiting_approval = WorklogSubmissiondata?.filter((elem) => {
    return elem.status === "Awaiting Approval";
  });
  const rejected_data = WorklogSubmissiondata?.filter((elem) => {
    return elem.status === "Rejected";
  });
  const approved_data = WorklogSubmissiondata?.filter((elem) => {
    return elem.status === "Approved";
  });

  const style = { top: '65px', position: 'fixed', zIndex: '200'  }
  return (
    <div>
      <EmployerStudentHeader id={id} />
      <HeaderLabel text="Worklog Submission" style={style} />
      <div className={styles.mainSubmission} onLoad={window.scrollTo(0, 0)}>
        {waiting_approval?.map((elem) =>
          <SubmissionDetail status={elem} style_1={{ color: "#FEC20E" }} supervisorId={supervisorId} />
        )}
        {approved_data?.map((elem) =>
          <SubmissionDetail status={elem} style_1={{ color: "#80BE41" }} submitted={true} />
        )}
        {rejected_data?.map((elem) =>
          <SubmissionDetail status={elem} style_1={{ color: "#ED2024" }} submitted={true}/>
        )}
        {submitted_data?.map((elem) =>
          <SubmissionDetail status={elem} style_1={{ color: "#0098C8" }} submitted={true}/>
        )}
      </div>
      <EmFooter
        homePath={employer_path.EMPLOYER_DASHBOARD_PATH}
        apprenticePath={employer_path.APPRENTICE_PATH}
        setting={employer_path.VIEW_EMPLOYER_SETTINGS_PATH}
      />
    </div>
  );
};

export default WorklogSubmissiondetails;
