import React from 'react'
import style from "./style.module.scss";
import backButton from "../../../assets/images/backicon.png";
import profileHeaderLogo from "../../../assets/images/logo.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const ProfileHeader = () => {
  //Header and go back from the setting page
  const history = useHistory();
  const handlegoBack=()=>{
    history.goBack()
  }
  return (
    <div className={style.profileHeader}>
        <div className={style.button}><img src={backButton} alt="backButton" onClick={handlegoBack} /></div>
        <div className={style.profileHeaderLogo}><img src={profileHeaderLogo} alt="construction Header Logo" /></div>
    </div>
  )
}

export default ProfileHeader