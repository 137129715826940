import React from "react";
import styles from "../styles.module.scss";

const ContactsList = (props) => {
  const { data, trainers } = props;
  return (
    <div className={styles.workshopbox}>
      <div className={styles.workshopLabel}>
        <ul>
          <li className={`${styles.details}`}>{data?.name}</li>
          {trainers && (
            <li className={`${styles.draftList}`}>
              <span className={`${styles.draft}`}>{data?.email}</span>
            </li>
          )}
          <li className={`${styles.dateinfo}`}>{data?.phone}</li>
        </ul>
      </div>
    </div>
  );
};

export default ContactsList;
