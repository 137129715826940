import { call, put, takeLatest } from "redux-saga/effects";
import { FetchWorklogTasks } from "../../../services/worklogs/fetchworklogtasks.service";

import {fetchWorklogTasks, FETCH_WORKLOG_TASKS} from "../../../store/actions/worklogs/fetchWorklogTasks.worklogs.action";


function* handleFetchWorklogTasks({ payload }) {
  try {
    const { data:resData } = yield call(FetchWorklogTasks.getWorklogs, payload);
    const data = resData?.data
    yield put(fetchWorklogTasks.success(data));
  } catch (e) {
    yield put(fetchWorklogTasks.failure(e.data));
  }
}

function* fetchWorklogTasksSaga() {
  yield takeLatest(FETCH_WORKLOG_TASKS.REQUEST, handleFetchWorklogTasks);
}

export default fetchWorklogTasksSaga;
