import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import BlueDot from "../../components/common/BlueDot/index";
import mainStyles from "../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/styles.module.scss";
import ProfileHeader from "../../components/Setting/ProfileHeader";
import SignPad from "./SignPad";
import RejectionComment from "./RejectionComment";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../components/common/Overlay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { approveClick } from "../../store/actions/employer/Employer.action";
import { rejectClick } from "../../store/actions/employer/RejectionSign.action"
import propTypes from 'prop-types';
import Approve from "../../assets/images/Submit.svg";
import Delete from "../../assets/images/Delete.svg";
import ViewImageModal from "../../components/NewWorklogComponents/ViewImageModal/ViewImageModal";
import { decodeToken,decodeOnBehalfToken } from "../../utils/helper/common/decodeJSONToken";
import { employer_path } from "../../Constants/paths/employer";
import { trainer_path } from "../../Constants/paths/trainer";
import { resetOnBehalfSignIn } from "../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../utils/localStorage";
import { staff_path } from "../../Constants/paths/staff";
import { supervisor_path } from "Constants/paths/supervisor";
function EmployerSignature(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const [cancelSign, setCancelSign] = useState(false);
  const [cancelRejection, setcancelrejection] = useState(false);
  const [isScroll, setIsSrcoll] = useState(false)
  const history = useHistory();
  const dispatch = useDispatch();

  //fetch user locally by the token
  const tokenData = decodeToken();
  const tokenUsertype = tokenData?.user_type

  //To cancel the sign pad when the sign modal is open
  const onCancelSignPad = () => {
    setCancelSign(false);
    setIsSrcoll(false);
  };
  //To click on Approve button 
  const handleApprove = () => {
    setCancelSign(true);
    setIsSrcoll(true);
    dispatch(approveClick());
  };
  //To click on cancel button
  const handleRejection = () => {
    setcancelrejection(true);
    setIsSrcoll(true);
    dispatch(rejectClick());
  };

  //To click on rejection button 
  const onCancelRejection = () => {
    setIsSrcoll(false);
    setcancelrejection(false);
  };

  //If the response is success than modal is open and button to get back
  const handleSuccess = () => {
 const onBehalfToken =  decodeOnBehalfToken()
 if(onBehalfToken){
  //if onbehalf token exists
  if(onBehalfToken?.user_type==="Employer"){
    history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
    }else if(onBehalfToken?.user_type==="Trainer"){
    history.push(trainer_path.TRAINER_DASHBOARD_PATH)
    }
    else if(onBehalfToken?.user_type==="Supervisor"){
      history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
      }

 }else{
  if(tokenUsertype==="Employer"){
    history.push(employer_path.EMPLOYER_DASHBOARD_PATH);
    }else if(tokenUsertype==="Trainer"){
    history.push(trainer_path.TRAINER_DASHBOARD_PATH)
    }
    else if(tokenUsertype==="Supervisor"){
      history.push(supervisor_path.SUPERVISOR_DASHBOARD_PATH)
      }
 }  
  }

  //For Sign
  const worklogDetails = useSelector(
    (state) => state.employerReducers.getWorklogDetailForSign
  );
  const employerStudentDetails = useSelector(
    (state) => state.employerReducers.employerStudent
  )
  const rejection = useSelector(
    (state) => state.employerReducers.rejectionSign
  )
  const rejectSubmitSuccess = rejection?.rejectionsuccess
  const rejectSignSelected = rejection?.signSelected
  const signSelected = employerStudentDetails?.signSelected
  const signSuccess = employerStudentDetails?.signSuccess
  const worklogData = worklogDetails?.worklogData.worklog;
  const taskData = worklogData?.task ? worklogData?.task[0] : [];
  const SupervisorId = employerStudentDetails?.signUnit?.SupervisorId
  const submitted = employerStudentDetails?.signUnit?.Submitted
  const [showUploadImg, setshowUploadImg] = useState(worklogData?.task
    ? worklogData?.task[0].photos
      ? true
      : false
    : "");

  //For rejection Comment
  const rejectionData = useSelector(state => state.employerReducers.rejectionSign)
  const rejectionSucess = rejectionData.rejectionsuccess
  useEffect(() => {
    setshowUploadImg(worklogData?.task
      ? worklogData?.task[0].photos
        ? true
        : false
      : "");
  }, [worklogData?.id])


  //this function will open the modal which will handle the popup of the image
  const [viewImage, setViewImage] = useState(false);
  const [imgData,setImgData] = useState("")
 const handleViewImage= (img) =>{
   setViewImage(true);
   setImgData(img);
 }      
  
//handlign the signout of onbehalf login
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}

  return (
    <div>
      <ProfileHeader />
      <div
        className={`newContainer ${mainStyles.addExtraPaddingtop}`}
        style={isScroll ? { marginTop: "2em", position: "fixed" } : { marginTop: "2em" }}
        onLoad={window.scrollTo(0, 0)}
      >
        {/* Details Div step 1*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Details -</span>{" "}
            <span className={`${styles.header2}`}>Step 1/4</span>
          </p>

          {/* Where have you being working Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Where have you been working?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.working_details}
            </p>
          </div>

          {/* What was being constructed Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                What was being constructed?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.construction_details}
            </p>
          </div>
        </div>

        {/* Tasks Div step 2*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Tasks -</span>
            <span className={`${styles.header2}`}>Step 2/4</span>
          </p>

          {/* Overdue Tasks Container */}
          {worklogData?.task && (
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                {worklogData?.task[0].name ? <BlueDot /> : <></>}
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightcurrent}`}
                >
                  {worklogData?.task[0].name}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* photo Tasks Container  step-3*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Photo -</span>{" "}
            <span className={`${styles.header2}`}>Step 3/4</span>
          </p>

          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                {/* {`Have you got a photo of the ${
                task?.value === "" ? "task name" : task?.value?.name
              }?`} */}{" "}
                Have you got a photo of the task name
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.task
                ? worklogData?.task[0].photos
                  ? "Yes"
                  : "No"
                : ""}
            </p>
          </div>
          {showUploadImg ? <>
            {/* If the photo answer is YES than show this div */}
            <div className="mb-4">
              {taskData?.photos?.length > 0 &&
                taskData?.photos?.map((item) => (
                  <>
                    <div className="thumbnailImage">
                      <img src={item.photo} alt="thumbnails" onClick={() => handleViewImage(item.photo)}/>
                    </div>
                  </>
                ))}
            </div>
            <ViewImageModal
                  show={viewImage}
                  setShow={setViewImage}
                  values={imgData}
                  setValue ={setImgData}
                />

            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  What's happening in this photo?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? taskData?.photos[0].photo_happening
                  : ""}
              </p>
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  How long did it take?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? taskData?.photos[0].how_long
                  : ""}
              </p>
            </div>

            {/* Equipment Tools Container */}
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">Equipment</p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {/* this is the equipments */}
                {taskData?.equipments?.length > 0 &&
                  taskData?.equipments?.map((item, index) => (
                    <span>
                      {item.equipment_name}
                      {index < taskData?.equipments.length - 1 ? ", " : ""}
                    </span>
                  ))}
              </p>
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <BlueDot />
                <p className="removeExtraBotMargin heading">
                  Which supervisor can verify your activity and photo?
                </p>
              </div>
              <p
                className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
              >
                {taskData?.photos?.length > 0
                  ? taskData?.photos[0].supervisor_name
                  : ""}
              </p>
            </div>
          </> :
            <>
              {/* If the photo answer is NO than show this div */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What was your part in the activity?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {taskData?.no_photo?.length > 0
                  ? taskData?.no_photo[0].part
                  : ""}
                </p>

              </div>
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                   {taskData?.no_photo?.length > 0
                  ? taskData?.no_photo[0].how_long
                  : ""}
                </p>

              </div>
              {/* Equipment Tools Container */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    Equipment
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {/* this is the equipments */}
                  {taskData?.equipments?.length > 0 &&
                    taskData?.equipments?.map((item, index) => (
                      <span>
                        {item.equipment_name}
                        {index < taskData?.equipments.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </p>

              </div>
            </>


          }
        </div>

        {/* Comments Div step-4*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Comments -</span>{" "}
            <span className={`${styles.header2}`}>Step 4/4</span>
          </p>

          {/* Other Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other comments you want to say about your activity?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.other_comments}
            </p>
          </div>

          {/* Overall Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other overall comments?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {worklogData?.overall_comments}
            </p>
          </div>
        </div>
      </div>
      {!SupervisorId && !submitted ? <div className={decodeOnBehalfToken() ? styles.OnBehalfFooter : styles.footerButton}>
       {decodeOnBehalfToken() && <div className={styles.cancel} onClick={handleOnbehalfLogOut}>Close</div>} 
        <div className={styles.approveIcon}  onClick={handleRejection}> 
        <img src={Delete} alt="button" />
        <button className={styles.rejectButton}>
          Reject
        </button>
        </div>
        <div className={styles.rejectIcon} onClick={handleApprove}>
        <img src={Approve} alt="button" />
        <button className={styles.approveButton} >
          Approve
        </button>
        </div>
      </div> : <></>}
      {cancelSign && !signSuccess ? (
        <SignPad onCancel={onCancelSignPad} studentData={props.studentData} />
      ) : (
        <></>
      )}
      {cancelRejection && !rejectSubmitSuccess ? (
        <RejectionComment onCancel={onCancelRejection} studentData={props.studentData} />
      ) : (
        <></>
      )}
      {signSelected ? <></> : signSuccess ?
        <Overlay
          title="Pending Signature"
          text="Sign submitted successfully"
          crossicon={true}
          onClick={handleSuccess} />
        : <></>}
      {rejectSignSelected ? <></> : rejectionSucess &&
        <Overlay
          title="Pending Signature"
          crossicon={true}
          text="Reject comment save successfully"
          onClick={handleSuccess} />
      }
    </div>
  );
}

EmployerSignature.propTypes = {
  studentData: propTypes.object //get student worklog data for sign 
};

export default EmployerSignature;
