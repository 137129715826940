import * as AddSupervisor from "../../actions/student/addSupervisor.action"

const initialState = {
  //supervisorData
  loading: false,
  success: false,
  error: false,
  status: "",
  message: "",
  supervisorData:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
  //supervisorData
    case AddSupervisor.ADD_SUPERVISOR.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        message: action.payload.message,
      };

    case AddSupervisor.ADD_SUPERVISOR.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        status: action.payload.status,
        message: action.payload.message,
        supervisorData:action.payload.data
      };

    case AddSupervisor.ADD_SUPERVISOR.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        status: action.payload.status,
        message: action.payload.message,
      };
      case AddSupervisor.RESET_ADD_SUPERVISOR.SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          error: false,
          status: "",
          message: "",
          supervisorData:[]
        };
    default:
      return state;
  }
}
