import React, { useEffect, useState } from "react";
import Anonymous from "../../../layouts/Anonymous";
import FormCard from "../../../components/auth/FormCard";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
import { validateEmail } from "../../../utils/helper/common/validation";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../store/actions/user/forgotPassword.user.actions";
import "./styles.css";

export default () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  const [values, setValues] = useState({
    email: "",
    emailErrorMessage: "",
    isEmailCorrect: true,
    isEmailActive: false,
    signinError: false,
    success: false,
    signinErrorMsg: "",
    didRedirect: false,
    loading: false,

  });
  const dispatch = useDispatch();
  const forgotPasswordState = useSelector((state) => state.user.forgotPassword);

  useEffect(() => {


    if (forgotPasswordState.forgotPasswordSuccess === true) {
      setValues({
        ...values,
        loading: false,
        emailErrorMessage: "",
        success: true,
        isEmailCorrect: true,
      });
    } else if (forgotPasswordState.forgotPasswordSuccess === false) {
      setValues({
        ...values,
        success: false,
        loading: false,
        emailErrorMessage: "Please enter valid email",
      });
    }
  }, [forgotPasswordState]);


  const inputChangeHandler = (name) => (event) => {
    setValues({
      ...values,
      loading: false,
      emailErrorMessage: "",
      [name]: event.target.value,

      signinError: false,
      success: false,
      signinErrorMsg: "",
      didRedirect: false,
      init: "init",
    });
  };

  const handleSubmit = (name) => (event) => {
    if (values.email.length > 0) {
      setValues({ ...values, email: "", [name]: event.target.value });
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      loading: true,
      isEmailCorrect: true,
      emailErrorMessage: "",
    });

    if (!validateEmail(values.email)) {
      return setValues({
        ...values,
        isEmailCorrect: false,
        emailErrorMessage: "Invalid Email Address!",
      });
    }

    var email = values.email;

    dispatch(forgotPassword.request(email));
  };

  const form = () => {
    
    return (
      <FormCard title="Forgot Password?">
        {values.signinError && (
          <div class="alert alert-danger d-block w-100" role="alert">
            {values.signinErrorMsg}
          </div>
        )}
        {values.success ? (
            <div class=" d-block w-800 notification" style={{color:"green", size:"30px"}}>
            Password reset link sent. Check your Email  
          </div>
        ) : 
        
        <form className="form-width-inherit">
           <div className= "getReset">
            <span className="notification">Get a password reset link</span>
           <p className="subnotification">CTI will send a password reset link on the below entered email
            address</p>
          </div>
     
          
          <Input
            label="Enter Email"
            icon="fas fa-envelope"
            type="text"
            id="email"
            aria-describedby="emailHelp"
            value={values.email}
            name="email"
            error={values.emailErrorMessage}
            placeholder="Email ID"
            onChange={inputChangeHandler("email")}
            Click={handleSubmit()}
            required
          />
          {values.loading ? (
            <Button type="button" disable={true} loading={values.loading}>
              Please wait...
            </Button>
          ) : (
            <Button type="submit" 
            disabled={
              values.email.length < 1
            }
            onClick={formSubmitHandler}>
              Reset Password
            </Button>
          )}
        </form>
        }

        
      
      </FormCard>
    );
  };

  

  return (
    <Anonymous>
      {form()}
      {/* {loader()} */}
    </Anonymous>
  );
};
