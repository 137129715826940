import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { verifySignup } from "../../../store/actions/user/verifySignup.user.action";
import Anonymous from "../../../layouts/Anonymous";
import Input from "../../../components/auth/Input";
import Button from "../../../components/auth/Button";
import FormCard from "../../../components/auth/FormCard";
import { signupUserAction } from "../../../store/actions/user/signup.user.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import { storeInLocalStorage } from "../../../utils/localStorage";
import { login } from "../../../store/actions/user/login.user.actions";

import "./index.scss";

const Signup = () => {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const { token } = useParams();
  const dispatch = useDispatch();
  const history= useHistory();
  const verifySignupState = useSelector((state) => state.user.verifySignup);
  const signupState = useSelector((state) => state.user.signup);
  const loginState = useSelector(state=>  state.user.login);

  const decodedJwt= jwt_decode(token);
  

  const [values, setValues] = useState({
    token: "",
    contact_id: "",
    contact_type: "",
    first_name: "",
    email: "",
    phone: "",

    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    isPasswordActive: false,
    isConfirmPasswordActive: false,
    arePasswordsSame: false,
    passwordError: "Confirm Password and Password should be same.",
    errMsg: "",
    isError: false,
    isVerifying: false, //check this
    isVerified: false,
    alreadySignedUp: null,
    otherMsg: null,
    verificationErrorMsg: "",
    isSignupSuccess: false,
    signupMsg: "",
    didRedirect: false,

    initiateSignup: null,
    signupSuccessfull: null,
    loading: true,
  });
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState({ status: false, message: "" });

  //First useEffect for dispatching the verify signup
  useEffect(() => {
    storeInLocalStorage.setTokenVerifySignup(token);
    dispatch(verifySignup.request(token));

  }, []);

  //second useEffect for checking the status of verify signup and setting the state for signup
  useEffect(() => {
    //handle the signup as verification is successfull
    if (
      verifySignupState.verifySuccess === true &&
      verifySignupState.verifyFailure === false
    ) {


      //case 1 - when the verificaion is succes ans link is not expired
      if (verifySignupState.message === "Already Signed Up.") {
        setValues({
          ...values,
          alreadySignedUp: true,
          initiateSignup: false,
        });
        setLoading(false);
      } else if (verifySignupState.status_code >= 400) {
        setValues({
          ...values,
          otherMsg: true,
          initiateSignup: false,
        });
        setLoading(false);
      } else {
        setValues({
          ...values,
          initiateSignup: true,
          alreadySignedUp: false,
        });
        setLoading(false);
      }
    } else if (
      verifySignupState.verifySuccess === false &&
      verifySignupState.verifyFailure === true
    ) {
      //handle the cases when there are some errors
    
      setValues({
        ...values,
        otherMsg: true,
        initiateSignup: false,
      });
      setLoading(false);
      // setError({ status: true, message: verifySignupState.message });
    }

    //as verification is done, set loading to false...
    // setLoading(false);
  }, [verifySignupState]);

  //third useEffect to initiate signup
  useEffect(() => {
    if (values.initiateSignup === true) {
    }
  }, [values.initiateSignup]);

  //fourth useEffect for checking the status of signup process
  useEffect(() => {
    //Case 1 - when status is 1.
    const {password} = values;
    const remember_me = true;
    if (signupState.status === "SUCCESS") {
      dispatch(login.request({
        login_id: verifySignupState.user.email,
        password,
        remember_me}));
        storeInLocalStorage.trusted(remember_me);
    }
    else if(signupState.signupFailed===true){
      setError({
        status:true,
        message:signupState.message
      });
      setLoading(false);
    } 
    
    
  }, [signupState]);


  //login state
  useEffect(()=>{
if(loginState.loginSuccess===true){
  history.replace("/set/pin")
}
  },[loginState])

  const inputChangeHandler = (name) => (event) => {
    setError({ status: false, message: "" });
    setValues({
      ...values,
      [name]: event.target.value,
      // arePasswordsSame: arePasswordsSameCheck()
    });
  };

  const arePasswordsSameCheck = () => {
    const check =
      values.password === values.confirmPassword &&
      values.password.length > 0 &&
      values.confirmPassword.length > 0;

    return check;
  };

  const handleSubmit = (event) => {
    //set loading to true
    setLoading(true);
    event.preventDefault();
    setError({ status: false, message: "" });
    if (!arePasswordsSameCheck()) {
      setError({
        status: true,
        message: "Password and Confirm Password does not match.",
      });
      setValues({
        ...values,
        arePasswordsSame: false,
      });
      setLoading(false);
      return;
    }

    dispatch(
      signupUserAction.request({
        email: verifySignupState.user.email,
        phone: verifySignupState.user.phone,
        password: values.password,
        contact_id: verifySignupState.contact_id,
        type: decodedJwt.contact_type,
        token: token,
      })
    );
  };

  const loading = () => {
    if (isLoading === true) {
      return (
        <div className="spinner-border text-warning" role="status">
          <span className="visually-hidden"></span>
          {/* <div
            className="spinner-border text-secondary loader enabled"
            role="status"
          >
            <span className="sr-only">Verifying...</span>
          </div> */}
        </div>
      );
    }
  };




  const containerForm = () => {
    return (
      <>
        {/* <h2>Huuu</h2> */}
        <FormCard title="Sign Up">
          <div className="col-md-12">
            <h5 className="text-center text-gray mb-4">
              {values.signupSuccessfull &&
                `Welcome, ${verifySignupState?.user?.first_name} You have signed up successfully!`}
              {values.initiateSignup &&
                `Welcome, ${verifySignupState?.user?.first_name}`}
              {values.alreadySignedUp && `Hi ${verifySignupState?.user?.first_name}, you have already signed up`}
              {values.otherMsg && `There is something wrong, please contact CTI.`}
            </h5>
          </div>

          {isLoading === true && (
            <div className="d-flex col-md-12 justify-content-center">
              {loading()}
            </div>
          )}
          {/* {error.status === true && error.message} */}
          {values.initiateSignup && (
            <form className="form-width-inherit">
              <lable style={{marginBottom:"5px" }}>{`Email : ${verifySignupState?.user?.email}`}</lable>
              <Input
                icon="fas fa-lock"
                label="Password"
                type="password"
                id="password"
                name="password"
                placeholder="Enter Password"
                value={values.password}
                onChange={inputChangeHandler("password")}
                required
              />
              <Input
                icon="fas fa-lock"
                label="Password"
                type="Password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                aria-describedby="confirmPasswordHelp"
                value={values.confirmPassword}
                onChange={inputChangeHandler("confirmPassword")}
                required
              />

              {error.status && (
                <small className="form-text text-danger">{error.message}</small>
              )}

              <Button
                type="submit"
                disabled={
                  values.password.length < 1 ||
                  values.confirmPassword.length <1
                }
                onClick={handleSubmit}
                loading={isLoading}
              >
                {isLoading ? "Submitting..." : "Sign Up"}
              </Button>
            </form>
          )}

          {/* {(values.alreadySignedUp || values.signupSuccessfull) && ( */}
          <div className="col-md-12 d-flex justify-content-center">
            {/* <Link to={paths.LOGIN_PATH}>Go back to Login Page</Link> */}
          </div>
          {/* )} */}
        </FormCard>
      </>
    );
  };

  return (
    <Anonymous loading={values.isVerifying}>
      {containerForm()}
    </Anonymous>
  );
};

export default Signup;
