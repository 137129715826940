import { call, put, takeLatest } from "redux-saga/effects";
import { changePinApi } from "../../../services/profile/changePin";
import { changePin,CHANGE_PIN } from "../../../store/actions/user/changePin.user.action";


function* handleChangePin( action ) {
  
  try {
    const { data} = yield call(changePinApi.changePin, action.payload);
      yield put(changePin.success({
        status:data?.status,
        statusCode:data?.status_code,
        data:data?.data,
        message:data?.message
      }));

  
  } catch (e) {
    const {data} = e;
    yield put(changePin.failure({
      status:data?.status,
      statusCode:data?.status_code,
      data:data?.data,
      message:data?.message
    
}));
  }
}

function* changePinSaga() {
  yield takeLatest(CHANGE_PIN.REQUEST, handleChangePin);
}

export default changePinSaga;
