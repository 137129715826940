import WorklogSubmissiondetails from '../../../components/EmployerDashboard/WorklogSubmissiondetails'
import React from 'react'

const index = () => {
  return (
    <div>
        <WorklogSubmissiondetails />
    </div>
  )
}

export default index