import React from 'react'
import SingleStudentForTrainer from '../../../../components/Trainer/TrainerStudents/SingleStudentForTrainer/SigleStudentForTrainer'
import EmFooter from '../../../../components/EmployerDashboard/EmFooter'
import styles from './styles.module.scss'
import { trainer_path } from '../../../../Constants/paths/trainer'
import { studentCurrentPage } from "../../../../store/actions/student/student.curerntPage.action";
import { useDispatch } from "react-redux";
import { isSupervisor } from 'utils/helper/Student'
import { supervisor_path } from 'Constants/paths/supervisor'

const TrainerStudentPage = () => {
    const dispatch = useDispatch();
  dispatch(studentCurrentPage.success("apprentice"))
    return (
        <div className={styles.mainContainer}>
            <SingleStudentForTrainer />
            <EmFooter
        homePath={isSupervisor()?supervisor_path.SUPERVISOR_DASHBOARD_PATH: trainer_path.TRAINER_DASHBOARD_PATH}
        apprenticePath={isSupervisor()?supervisor_path.SUPERVISOR_APPRENTICE_PATH:trainer_path.TRAINER_APPRENTICE_PATH}
        setting={isSupervisor()?supervisor_path.SUPERVISOR_VIEW_SETTING_PATH:trainer_path.TRAINER_VIEW_SETTING_PATH}
      />
        </div>
    )
}

export default TrainerStudentPage