import { createRequestTypes, action } from "../../../utils/redux";

export const GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER = createRequestTypes("GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER");

//this functional object will fetch the worklogs of a student for trainer
export const getAllWorklogsOfStudentForTrainer = {
  request: (data) => action(GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER.REQUEST,data),
  success: (data) => action(GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER.SUCCESS, data),
  failure: (error) => action(GET_ALL_WORKLOGS_OF_STUDENT_FOR_TRAINER.FAILURE, error),
};
