import axiosInstance from "../interceptor";
import { ApiEndpoints } from "../../Constants/api/anonymous";

export const loginApi = {
  login: (data) => {
    return axiosInstance.post(ApiEndpoints.login.url, {
      login_id: data.login_id,
      password: data.password,
      remember_me: data.remember_me
    });
  },
};
