//common
const CHANGE_PASSWORD = "/change/password";
const CHANGE_PIN = "/change/pin";
const UNAUTHORIZED = '/403';
const PAGE_NOT_FOUND = '/404';
const UI_TEST_PATH = "/ui/test";

export const paths = {
  CHANGE_PASSWORD,
  CHANGE_PIN,
  UI_TEST_PATH,
  UNAUTHORIZED,
  PAGE_NOT_FOUND,
};
