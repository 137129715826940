import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import homeIcon from "../../../../assets/images/Home.png"
import settingIcon from "../../../../assets/images/Setting.svg"
import { useSelector } from "react-redux";
import { staff_path } from "../../../../Constants/paths/staff";


const StaffFooter = () => {
const currentPage = useSelector(state=>state.studentReducers.studentCurrentPageReducer.currentPage)

  return (
    <>
    
    <div className={styles.employerFooter}>
      {/* we will navigate to the student dashboard which is a home page for student */}
      <Link to={staff_path.STUDENT_DASHBOARD_PATH} className={styles.linkColor} style={{color: currentPage==="home" ? '#0098C8' : '#888'}}>
        <div className={styles.homeButton}>
          <div className={styles.homeIcon}>
            <div className={styles.iconimg}>
            <img src={homeIcon} alt="Home"/>
            </div>
            Home
          </div>
        </div>
      </Link>
      {/* this link will navigate to dashboard settings page where user can change their profile */}
      <Link to={staff_path.VIEW_SETTINGS_PATH} className={styles.linkColor} style={{color: currentPage==="settings" ? '#0098C8' : '#888'}}>
        <div className={styles.settingbutton}>
          <div className={styles.settingIcon}>
            <div className={styles.iconimg}>
            <img src={settingIcon} alt="Settings"/>
            </div>
            Settings
          </div>
        </div>
      </Link>
    </div>
    </>
  );
};

export default StaffFooter;
