export const ApiEndpoints = Object.freeze({
  login: {
    url: `/auth/signin/n`,
    method: "POST",
  },
  verifySigupLink: {
    url: `/auth/verify/signup/`,
    method: "GET",
  },
  signup: {
    url: `/auth/signup`,
    method: "POST",
  },
  setPin: {
    url: `/auth/set/pin`,
    method: "POST",
  },
  loginWithPin: {
    url: `/auth/signin/pin`,
    method: "POST",
  },
  verifyLink: {
    url: `/verify/link/`,
    method: "GET",
  },
  verifyResetPasswordLink: {
    url: `/auth/verify/reset-password`,
    method: "GET",
  },
  resetPassword: {
    url: `auth/password/reset`,
    method: "PUT",
  },
  fetchWorklogForUnit: {
    url: `/worklog/fetchworklogforcontactandunitscope`,
    method: "POST",
  },
  forgotPassword:{
    url:`auth/password/reset/link`,
    method:"PATCH"
  },

  updateEmail:{
    url:`auth/info`,
    method:"PUT"
  },
  updatePhone:{
    url:`auth/info`,
    method:"PUT"
  },
  fetchProfile:{
    url:`/profile`,
    method:"GET"
  },
  generateLoginLink:{
    url:`/link/create/login/code`,
    method:"POST"
  },
  loginWithCode:{
    url:`/auth/signin/code?login_code=CODE`,
    method:"POST"
  }
});
