import axiosInstance from "../../services/interceptor";
import { ApiEndpoints } from "../../Constants/api/employer";

export const EmployerStudentApi = {
    getStudentData: (student_id) => {
        return axiosInstance.get(ApiEndpoints.getStudentData.url.replace("student_id", student_id));
    },
    getStudentTodo: (student_id) => {
        return axiosInstance.get(ApiEndpoints.getStudentTodo.url.replace("student_id", student_id));
    },
    getWorklogSubmission: (student_id) => {
        return axiosInstance.get(ApiEndpoints.getWorklogSubmission.url.replace("student_id", student_id));
    },
    getStudentProfile: (student_id) => {
        return axiosInstance.get(ApiEndpoints.getStudentProfile.url.replace("student_id", student_id));
    },
    getStudentProgressReport: (student_id)=>{
        return axiosInstance.get(ApiEndpoints.getStudentProgressReport.url.replace("student_id", student_id));
    },
    getStudentOntrackStatus: (student_id) => {
        return axiosInstance.get(ApiEndpoints.getStudentOntrackStatus.url.replace("student_id", student_id));
    },

}