import React, { useState } from "react";
import styles from "./styles.module.scss";
import pen from "../../../../assets/images/pen.png";
import right from "../../../../assets/images/right.png";
import { Link } from "react-router-dom";
import "../../../../styles/global.module.scss";
import { useDispatch } from "react-redux";
import moment from "moment";
import deleteIcon from "../../../../assets/images/Delete.svg";
import { deleteWorklog } from "../../../../store/actions/worklogs/deleteWorklog.worklogs.action";
import { clearEquipment } from "../../../../store/actions/worklogs/fetchWorklogEquipment.worklogs.action";
import CustomModal from "../../../../components/NewWorklogComponents/Modals/index";
import { student_path } from "../../../../Constants/paths/student";
import propTypes from "prop-types";

const WorklogDetailsForUnitCard = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  var worklogID = data.id;

  //To delete the worklog
  const deleteWorklogs = () => {
    dispatch(deleteWorklog.request(worklogID));
  };

  //To edit the draft worklog
  const handleWorklogCreation = () => {
    dispatch(clearEquipment.request());
  };

  //popup for delete worklog
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  return (
    <>
      <ul className={`${styles.tubularList}`}>
        <li className={`${styles.details}`}>{data.name}</li>
        <li className={`${styles.draftList}`}>
          {data.status === "Draft" ? (
            <span className={`${styles.draft}`}>Draft</span>
          ) : (
            <span className={`${styles.submitted}`} style={{
              color: data.status === "Awaiting Approval" ? "#FEC20E"
                : data.status === "Submitted" ? "#0098C8"
                  : data.status === "Rejected" ? "#ED2024"
                    : data.status === "Approved" ? "#80BE41" : ""
            }}>{data.status}</span>
          )}
        </li>
        <li className={`${styles.dateinfo}`}>
          {/* {data?.transaction_date.slice(0, 10)} */}
          {moment.utc(data?.transaction_date).local().format("DD/MM/YYYY")}{" "}
        </li>
        <li className={`${styles.penIcon}`}>
          {data.status === "Draft" ? (
            <>
              {/* this link will redirect the user to the edit worklog page */}
              <Link to={`${student_path.EDIT_WORKLOG_PATH}/${data.id}`}>
                <img
                  src={pen}
                  className={`${styles.pen}`}
                  alt="pen icon"
                  onClick={() => handleWorklogCreation()}
                />
              </Link>{" "}
              |
              <img
                src={deleteIcon}
                alt="delete"
                className={`${styles.deleteImg}`}
                onClick={() => {
                  handleShow();
                }}
              />
            </>
          ) : (
            //this link will move out the user to the sumbmitted worklog detials page
            <Link to={`${student_path.SUBMITTED_WORKLOG_DETAILS}/${data.id}`}>
              <img
                src={right}
                className={`${styles.right}`}
                onClick={() => handleWorklogCreation()}
                alt="right icon"
              />
            </Link>
          )}
        </li>
      </ul>

      <CustomModal
        modalFor="deleteWorklog"
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        handleDelete={deleteWorklogs}
      />
    </>
  );
};

WorklogDetailsForUnitCard.propTypes = {
  data: propTypes.object,
};

export default WorklogDetailsForUnitCard;
