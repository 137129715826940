import React, {useState} from 'react'
import { Link, Redirect } from 'react-router-dom'

import { 
    signout, 
    isAuthenticated,
} from '../../utils/helper/Student/index'

export default () => {

    const { user } = isAuthenticated();
    const [values, setValues] = useState({
        isLogin: user?true:false,
        didRedirect: false
    })

    const performSignout = () => {
        signout()
        setValues({
            ...values,
            isLogin: false,
            didRedirect: true
        })
    }

    const performRedirect = () => {
        if(values.didRedirect){
            return <Redirect to='/login' />
        }
    }
    

    const component404 = ()=>{
        return (
            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-4">404, Page not found.</h1>
                    <p className="lead">Lost? It seems so.</p>
                    <p>Followings are available Links.</p>
                    <ul>
                        <Link to="/login"><li>Login</li></Link>
                        <Link to="/student/dashboard"><li>Student Dashboard</li></Link>
                        <Link to="/employer/dashboard"><li>Employer Dashboard</li></Link>
                    {
                        user?
                        <a href="!#" className="btn btn-danger" onClick={performSignout}>Sign out</a>:
                        <a href="/login" className="btn btn-success" >SignIn </a>
                    }
                        
                    </ul>
                </div>
                
            </div>
        )
    }


    return (
        <>
            {component404()}
            {performRedirect()}
        </>
    )
}
