import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../../../styles/global.scss";
import "../../../styles/global.module.scss";
import propTypes from "prop-types";

const Modals = (props) => {
  //these are the props passed from parent component
  const {
    show,
    handleClose,
    handleErase,
    modalFor,
    handleDelete,
    handleLogout,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-vcenter"
        centered
        className="p-4"
      >
        <Modal.Body>
          <h5 className="px-1 pt-2 font-weight-bold">
            {modalFor === "saveLater"
              ? "Are you sure you want to save for later?"
              : modalFor === "deleteWorklog"
              ? "Are you sure you want to delete the worklog?"
              : modalFor === "logout"
              ? "Are you sure you want to log out?"
              : "Are you sure you want to quit?"}
          </h5>
          <h6 className="px-1 pt-2 font-weight-bold font-italic">
            {modalFor !== "saveLater" &&
            modalFor !== "deleteWorklog" &&
            modalFor !== "logout"
              ? "Any unsaved progress will be lost"
              : ""}
          </h6>
        </Modal.Body>
        <div className="d-flex justify-content-end px-3 pb-3">
          <Button variant="link" onClick={handleClose}>
            CANCEL
          </Button>

          {modalFor === "saveLater" ? (
            <Button
              variant="link"
              onClick={() => {
                handleErase();
                handleClose();
              }}
            >
              SAVE & QUIT
            </Button>
          ) : modalFor === "deleteWorklog" ? (
            <Button
              variant="link"
              onClick={() => {
                handleDelete();
                handleClose();
              }}
            >
              DELETE
            </Button>
          ) : modalFor === "logout" ? (
            <Button
              variant="link"
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              LOG OUT
            </Button>
          ) : (
            <Button
              variant="link"
              onClick={() => {
                handleErase();
                handleClose();
              }}
            >
              QUIT
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

Modals.propTypes = {
  modalFor: propTypes.string, //for text
  show: propTypes.bool, // for modal show or not
  setShow: propTypes.func, // set show is true or false
  handleClose: propTypes.func, // for close
  handleShow: propTypes.func, // for set value of show in setShow
  handleErase: propTypes.any, //this function will erase the worklog
  handleLogout: propTypes.func, //this function will logout the user from the web
};

export default Modals;
