import * as updateTrainerInfo from "../../actions/trainer/updateTrainerInfo.action"

const initialState = {
  //profile
  loading: false,
  success: false,
  status: "",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
  //profile
    case updateTrainerInfo.UPDATE_TRAINER_INFO.REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        message: action.payload.message,
      };

    case updateTrainerInfo.UPDATE_TRAINER_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        message: action.payload.message,
      };

    case updateTrainerInfo.UPDATE_TRAINER_INFO.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        status: action.payload.status,
        message: action.payload.message,
      };


  //resettting the reducer to initial state
  case updateTrainerInfo.RESET_TRAINER_INFO:
    return {
      ...state,
      loading: false,
      success: false,
      status: "",
      message: "",
    };
    
    
    default:
      return state;
  }
}
