import { combineReducers } from "redux";
import OnBehalfSignIn from './onBehalfSignIn.reducer';


const staffReducers = combineReducers({
OnBehalfSignIn
    
});

export default staffReducers;
