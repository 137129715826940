import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAllWorklogsOfStudentForTrainer } from "../../../../store/actions/trainer/getAllWorklogsOfStudentForTrainer.action";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import styles from "./styles.module.scss"
import WorklogUnit from "../../../../components/EmployerDashboard/WorklogUnit";
import { trainer_path } from "../../../../Constants/paths/trainer";
import EmployerStudentHeader from "../../../../components/EmployerDashboard/EmployerStudentHeader";
import HeaderLabel from "../../../../components/common/HeaderLabel";
import { isSupervisor } from "utils/helper/Student";
import { supervisor_path } from "Constants/paths/supervisor";


const SingleStudentForTrainer = () =>{
    const dispatch = useDispatch();
    const {id} = useParams();
    useEffect(()=>{
        dispatch(getAllWorklogsOfStudentForTrainer.request(id));
    },[dispatch, id])
    
  //this selector will fetch the worklogs that are to be signed by the trainer.
  const worklogsToSignReducer = useSelector((state) => state?.trainerReducers.getAllWorklogOfStudentForTrainerReducer)
  const allWorklogsToBeSigned = worklogsToSignReducer?.worklogs

return (
    <>
    <EmployerStudentHeader id={id} headerFor="Trainer" />
    <HeaderLabel text="To Do" style={{width: '100vw' ,marginTop: "-21px",marginLeft: '-15px'}} />
    <div style={{ marginTop: "120px" }}></div>
      {(allWorklogsToBeSigned==null)? (
        <div className={styles.allSetLabel}>You are all set</div>
      ) : (
        <></>
      )}

    {/* this will show all the worklogs that are to be signed by the trainer */}
<div className={styles.worklogsSignContainer}>
{
  allWorklogsToBeSigned?.length>0 && 
  allWorklogsToBeSigned.map(item =>{
    return <WorklogUnit
    key={item.worklog_id}
    employerData={item}
    text="Worklog Sign"
    path={isSupervisor()?supervisor_path.SUPERVISOR_WORKLOG_SIGN: trainer_path.TRAINER_WORKLOG_SIGN} 
    />
  } )

}
</div>
    </>
)
}


export default SingleStudentForTrainer;