import { call, put, takeLatest } from "@redux-saga/core/effects";
import { EmployerStudentApi } from "../../../services/employer/Employer.Student.services";
import * as apprenticeStudentTodo from "../../actions/employer/apprenticeStudentTodo.action";

function *getApprenticeStudentTodoData(action){
   
    try{
        const {data} = yield call(EmployerStudentApi.getStudentTodo, action.payload)
        //extract the data here    
        yield put(apprenticeStudentTodo.apprenticeStudentTodoData.success(data));
    } catch (e) {
        yield put(apprenticeStudentTodo.apprenticeStudentTodoData.failure(e));
    }
}
function *getEmployerStudentTodoSaga(){
    yield (
        takeLatest(apprenticeStudentTodo.APPRENTICE_STUDENT_TODO.REQUEST, getApprenticeStudentTodoData)
    );
}

export default getEmployerStudentTodoSaga;