import * as ResetPasswordActions from "../../actions/user/resetPassword.user.action"

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailed: false,
  status: null,
  message: "",
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ResetPasswordActions.RESET_PASSWORD_USER.REQUEST:
      return {
        ...state,
        loading: true,
        resetPasswordSuccess: false,
        resetPasswordFailed: false,
        status: null,
        message: "",
        user: {},
      };

    case ResetPasswordActions.RESET_PASSWORD_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: true,
        resetPasswordFailed: false,
        status: action.payload.status,
        message: action.payload.message,
        user: action.payload.user,
      };

    case ResetPasswordActions.RESET_PASSWORD_USER.FAILURE:
      return {
        ...state,

        loading: false,
        resetPasswordSuccess: false,
        resetPasswordFailed: true,
        status: action.payload.status,
        message: action.payload.message,
        user: {},
      };

    default:
      return state;
  }
}
