import axiosInstance from "../../services/interceptor";
import { ApiEndpoints } from "../../Constants/api/employer";

export const EmployerApi = {
    getEmployers: ()=>{
        return axiosInstance.get(ApiEndpoints.getEmployers.url);
    },
    sign: (signature, worklog_id,) =>{
        return axiosInstance.post(ApiEndpoints.sign.url, {signature, worklog_id})
    },
    rejection: (student_id, worklog_id,comment) =>{
        return axiosInstance.put(ApiEndpoints.rejection.url.replace("student_id",student_id).replace("worklog_id", worklog_id),{comment});
    },
    confirmUpcomingWorkshop: ({lesson_id, student_id}) =>{
        return axiosInstance.patch(ApiEndpoints.confirmUpcomingWorkshop.url.replace("student_id",student_id).replace("lesson_id",lesson_id));
    },
    getWorklogDetailsForSign: ({student_id, worklog_id})=>{
        return axiosInstance.get(ApiEndpoints.getWorklogDetailsForSign.url.replace("student_id", student_id).replace("worklog_id", worklog_id));
    },
    getUpcomingWorkshop: ()=>{
        return axiosInstance.get(ApiEndpoints.getUpcomingWorkshop.url);
    },
    getStudentDetails: ()=>{
        return axiosInstance.get(ApiEndpoints.getStudentDetails.url);
    },
    getUpcomingStudentWorkshop: ()=>{
        return axiosInstance.get(ApiEndpoints.getUpcomingStudentWorkshop.url);
    },
    unitSign: (student_id, enrolled_unit_id, signature,) =>{
        return axiosInstance.patch(ApiEndpoints.unitSign.url, {student_id, enrolled_unit_id, signature})
    },

}
