import { createRequestTypes, action } from "../../../utils/redux";
export const FETCH_WORKLOG_EQUIPMENT = createRequestTypes("FETCH_WORKLOG_EQUIPMENT");
export const fetchWorklogEquipment= {
  request: (data) => action(FETCH_WORKLOG_EQUIPMENT.REQUEST, data),
  success: (data) => action(FETCH_WORKLOG_EQUIPMENT.SUCCESS, data),
  failure: (error) => action(FETCH_WORKLOG_EQUIPMENT.FAILURE, error),
};


export const CLEAR_WORKLOG_EQUIPMENT = "CLEAR_WORKLOG_EQUIPMENT";
export const clearEquipment = {
  request : () => action(CLEAR_WORKLOG_EQUIPMENT)
}
