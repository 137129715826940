import { combineReducers } from "redux";
import studentCurrentPageReducer from "../student/student.currentPage.reducer"
import studentOwnContactReducer from "./studentOwnContact.reducer";
import addNewSupervisorReducer from "./addNewSupervisor.reducer";
import studentCourseProgressReducer from "./studentCourseProgress.reducer";
import studentTheoryAssessmentsReducer from "./studentTheoryAssessments.reducer"

const studentReducers = combineReducers({
    studentCurrentPageReducer,
    studentOwnContactReducer,
    addNewSupervisorReducer,
    studentCourseProgressReducer,
    studentTheoryAssessmentsReducer
    
});

export default studentReducers;
