import * as WorklogActions from "../../../store/actions/user/worklog.user.actions";

const initialState = {
  allworklogs: {},
  worklogForUnit: {},
  requestWorklogForUnit: false,
  successWorklogForUnit: false,
  failureWorklogForUnit: false,
  status: "",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WorklogActions.FETCH_WORKLOGS_FOR_UNIT.REQUEST:
      return {
        ...state,
        requestWorklogForUnit: true,
      };
    case WorklogActions.FETCH_WORKLOGS_FOR_UNIT.SUCCESS:
      return {
        ...state,
        requestWorklogForUnit: false,
        successWorklogForUnit: true,
        failureWorklogForUnit: false,
        message: action.payload.message,
        status: action.payload.status,
        //save the worklog details to the state -- TO do
      };
    case WorklogActions.FETCH_WORKLOGS_FOR_UNIT.FAILURE:
      return {
        ...state,
        requestWorklogForUnit: false,
        successWorklogForUnit: false,
        failureWorklogForUnit: true,
      };

    default:
      return state;
  }
}
