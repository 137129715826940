const SUPERVISOR_DASHBOARD_PATH = "/supervisor/dashboard";
const SUPERVISOR_APPRENTICE_PATH = "/supervisor/apprentices";
const SUPERVISOR_VIEW_SETTING_PATH= "/supervisor/dashboard/settings";
const SUPERVISOR_WORKLOG_SIGN="/supervisor/dashboard/worklog/sign";
const SUPERVISOR_STUDENTS_ID = '/supervisor/students/:id' 


export const supervisor_path = {
    SUPERVISOR_DASHBOARD_PATH,
    SUPERVISOR_APPRENTICE_PATH,
    SUPERVISOR_VIEW_SETTING_PATH,
    SUPERVISOR_WORKLOG_SIGN,
    SUPERVISOR_STUDENTS_ID

}