import { call, put, takeLatest } from "@redux-saga/core/effects";
import { StudentApi } from "services/student/Student.services";
import * as studentTheoryAssessments from "../../actions/student/studentTheoryAssessments.action";

function *getStudentTheoryAssessmentsData(action){
   
    try{
        const {data} = yield call(StudentApi.fetchStudentTheoryAssessments, action.payload)
        //extract the data here     
        yield put(studentTheoryAssessments.studentTheoryAssessments.success(data));
    } catch (e) {
        yield put(studentTheoryAssessments.studentTheoryAssessments.failure(e));
    }
}
function *getStudentTheoryAssessmentsSaga(){
    yield (
        takeLatest(studentTheoryAssessments.STUDENT_THEORY_ASSESSMENTS.REQUEST, getStudentTheoryAssessmentsData)
    );
}

export default getStudentTheoryAssessmentsSaga;