import * as confirmUpcomingWorkshop from "../../actions/employer/confirmUpcomingWorkshop.action"

const initialState = {
  loading: false,
  confirmsuccess: false,
  confirmFailure: false,
  status: "",
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
  //worklogData
    case confirmUpcomingWorkshop.CONFIRM_UPCOMINGWORKSHOP.REQUEST:
      return {
        ...state,
        loading: true,
        confirmsuccess: false,
        rejectionsuccess: false,
        message: action.payload.message,
      };

    case confirmUpcomingWorkshop.CONFIRM_UPCOMINGWORKSHOP.SUCCESS:
      return {
        ...state,
        loading: false,
        rejectionsuccess: true,
        confirmsuccess: true,
        status: action.payload.status,
        message: action.payload.message,
      };

    case confirmUpcomingWorkshop.CONFIRM_UPCOMINGWORKSHOP.FAILURE:
      return {
        ...state,
        loading: false,
        confirmsuccess: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
