import { call, put, takeLatest } from "redux-saga/effects";
import { FetchFrequentEquipments } from "../../../services/worklogs/fetchFrequentEquipments.service";
import { fetchFrequentEquipments, FETCH_FREQUENT_EQUIPMENTS } from "../../../store/actions/worklogs/fetchFrequentWorklogEquiment.action";




function* handleFetchFrequentEquipments({ payload }) {
  try {
    
    const { data:resData } = yield call(FetchFrequentEquipments.getFrequentEquipments, payload);
    const data = resData?.data
    yield put(fetchFrequentEquipments.success(data));
  } catch (e) {
    yield put(fetchFrequentEquipments.failure(e.data));
  }
}

function* fetchFrequentEquipmentsSaga() {
  yield takeLatest(FETCH_FREQUENT_EQUIPMENTS.REQUEST, handleFetchFrequentEquipments);
}

export default fetchFrequentEquipmentsSaga;
