import React from 'react';
import ReactDOM from 'react-dom';
// Store
import { Provider, ReactReduxContext  } from 'react-redux';
import configureStore, { history } from './store';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
// components
import App from './App';



// initialising states
const store = configureStore()


ReactDOM.render(
  <Provider store={store} context={ReactReduxContext} >
    <App history={history} context={ReactReduxContext} />
  </Provider>,
  document.getElementById('root')
);