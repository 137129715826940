import * as FetchWorklogLessonsActions from "../../../store/actions/worklogs/fetchWorklogLessons.worklogs.action"

const initialState = {
    loadingWorklog: false,
    lsnDate:"",
    lesStudId:"",
    studentConfirmed:"",
    status:"",
    message:"",
    worklogSubmitted:"",
    overdueTheoryAssessmentsCount:0
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FetchWorklogLessonsActions.FETCH_WORKLOG_LESSONS.REQUEST:
            return {
                ...state,
                loadingWorklog: true,  
            };
        case FetchWorklogLessonsActions.FETCH_WORKLOG_LESSONS.SUCCESS:
            return {
                ...state,
                loadingWorklog: false,
                lsnDate:action.payload.lsnDate,
                lesStudId:action.payload.lesStudId,
                studentConfirmed:action.payload.studentConfirmed,
                status:action.payload.status,
                message:action.payload.message,
                worklogFetchError:false,
                worklogSubmitted:action.payload.worklogSubmitted,
                overdueTheoryAssessmentsCount : action.payload.overdueTheoryAssessmentsCount
            };
           
        case FetchWorklogLessonsActions.FETCH_WORKLOG_LESSONS.FAILURE:
            return {
            ...state,
            loadingWorklog: false,
            worklogFetchError:true,
            };
        default:
            return state;
    }
}