import { createRequestTypes, action } from "../../../utils/redux";
export const FETCH_PROFILE = createRequestTypes("FETCH_PROFILE");

export const fetchProfile = {
  request: (data) => action(FETCH_PROFILE.REQUEST, data),
  success: (data) => action(FETCH_PROFILE.SUCCESS, data),
  failure: (error) => action(FETCH_PROFILE.FAILURE, error),
};




