import React, { useEffect, useState } from "react";
import  "../Input/index.scss";
import iconinActive from "../../../assets/images/Pin.png";
import iconActive from "../../../assets/images/PinActive.png";
import propTypes from "prop-types";

const InputField = (props) => {
 
  const error = props.error==="false" ? false : props.error
 
  const [value, setValue] = useState(props.value);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // trying to make this controlled input for email delete event
  useEffect(() => {
    setValue(props.value);
  }, [props.value]); 

  //it is used for the action perform when you click out of the input
  const focusOutHandler = () => {
    setActive(false);
    
  };
  
  //it is used for the action perform when you click in the input
  const focusInHandler = () => {
    setActive(true);
   
  };

  //Change the type on Click the eye icon Text to Password
  const togglePasswordField = (inputId) => {
    setShowPassword(!showPassword);
    if (inputId) {
      var passwordField = document.getElementById(inputId);
      var passwordFieldType = passwordField.getAttribute("type");
      passwordFieldType === "text"
        ? passwordField.setAttribute("type", "password")
        : passwordField.setAttribute("type", "text");
    } else {
      passwordField = document.getElementById("password");
      passwordFieldType = passwordField.getAttribute("type");
      passwordFieldType === "text"
        ? passwordField.setAttribute("type", "password")
        : passwordField.setAttribute("type", "text");
    }
  };

  //change the Eye Color
  const eyeActive = true;

  //function rerender when the showpassword value changes
  useEffect(() => {}, [showPassword]);

  return (
    <>
      <div
        className={`input-group mb-2 borderActive
                ${error ? "border-error" : ""}
                ${eyeActive ? "eyeActive" : "eyeinActive"}
                `}
      >
        {props.icon ? (
          <span>
            <i className={`icon ${props.icon}`}></i>
          </span>
        ) : (
          <span className="IconDialpad">
            <img src={iconinActive} className="active" alt="icon" />
            <img src={iconActive} className="inactive" alt="icon" />
          </span>
        )}
        <div
          className={`${
            active || props.value ? "inputFieldtop" : "inputField"
          }`}
        >
          {props.label && (
            <label
              className={`label ${props.label} ${
                active || props.value ? "show-label" : "hide-label"
              }`}
            >
              {props.label}
            </label>
          )}
          <input
            className="form-control"
            type={props.type}
            value={value} //controlled value
            defaultValue={props.defaultValue}
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onFocus={() => focusInHandler()}
            onBlur={() => focusOutHandler()}
            autoComplete="off"
            {...props}
          />
        </div>

        {((props.id === "password" && props.value) ||
          (props.id === "confirmPassword" && props.value)) && (
          <span>
            {showPassword ? (
              <i
                style={{ color: "#0098c8" }}
                className="fas fa-eye-slash icon iconActive1"
                onClick={() => togglePasswordField(props.id)}
              ></i>
            ) : (
              <i
                className="fas fa-eye icon1 iconActive2"
                onClick={() => togglePasswordField(props.id)}
              ></i>
            )}
          </span>
        )}
        {((props.id === "pin" && props.value) ||
          (props.id === "confirmPin" && props.value)) && (
          <span>
            {showPassword ? (
              <i
                style={{ color: "#0098c8" }}
                className="fas fa-eye-slash icon iconActive1"
                onClick={() => togglePasswordField(props.id)}
              ></i>
            ) : (
              <i
                className="fas fa-eye icon1 iconActive2"
                onClick={() => togglePasswordField(props.id)}
              ></i>
            )}
          </span>
        )}

        {props.id === "email" && props.value ? (
          <span>
            {
              <span>
                <i
                  className={`fas fa-times-circle icon crossActive`}
                  aria-hidden="true"
                  onClick={props.Click}
                ></i>
              </span>
            }
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? (
        <span className="form-text text-danger">{error.toString()}</span>
      ) : null}
      {props.errorPin ? (
        <span className="form-text text-danger">
          {props.errorPin.toString()}
        </span>
      ) : null}
    </>
  );
};

InputField.propTypes = {
  type: propTypes.string,
  label: propTypes.string,
  id: propTypes.string,
  inputmode: propTypes.string,
  name: propTypes.string,
  defaultValue: propTypes.string,
  placeholder: propTypes.string,
  icon: propTypes.string,
  error: propTypes.string,
  onChange: propTypes.func,
  onKeyDown: propTypes.string,
  Click: propTypes.func,
  errorPin: propTypes.string,
  autoComplete: propTypes.string,
};

export default InputField;
