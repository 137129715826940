import * as StudentCurrentPage from "../../actions/student/student.curerntPage.action"

const initialState = {
   currentPage:""
}


export default function returnstate(state = initialState, action) {
    switch (action.type) {
      case StudentCurrentPage.STUDENT_CURRENT_PAGE.SUCCESS:

        return {
          ...state,
          currentPage:action.payload
        };

      default: return state      
    }
}